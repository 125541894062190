const { ENTITY_TYPES } = require('../constants');
const getEntityIdWithPrefix = require('./getEntityIdWithPrefix');

const getEntitySlug = ({ entityType, entity, parents, country, format } = {}, skipPrefix) => {
  if ([ENTITY_TYPES.SEASON].includes(entityType)) {
    return entity?.id;
  }

  if (!entity?.slug) {
    return null;
  }

  const { slug } = entity;

  let finalSlug = slug;

  let cityCountry = country;

  if (entityType === ENTITY_TYPES.CITY && !cityCountry?.slug) {
    cityCountry = parents?.find(parent => parent.entityType === ENTITY_TYPES.COUNTRY)?.entity;
  }

  if (cityCountry?.slug) {
    finalSlug = `${cityCountry?.slug}/${slug}`;
  }

  if (format?.slug) {
    finalSlug = `${slug}/${format?.slug}`;
  }

  if (
    [ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION, ENTITY_TYPES.PRODUCTION].includes(entityType) &&
    !/^.*-[a-z]\d+$/.test(slug)
  ) {
    const { id: entityIdWithPrefix } = getEntityIdWithPrefix({ entityType, entity, skipPrefix });

    return `${finalSlug}-${entityIdWithPrefix}`;
  }

  if (entityType === ENTITY_TYPES.AGENCY && entity?.id) {
    return `${finalSlug}-${entity?.id}`;
  }

  if (finalSlug) {
    return finalSlug;
  }

  return null;
};

module.exports = getEntitySlug;
