import { TP, ORGANIZATION_TYPE_IDS } from 'constants/index';

export const search_types = {
  PROFILES: 'profiles',
  PRODUCTIONS: 'productions',
  COMPANIES: 'company',
  VENUES: 'venues',
  AGENCIES: 'agencies',
  COMPOSERS: 'composers',
  WORKS: 'works',
  FESTIVALS: 'festival',
  ALL: 'All',
  UNKNOWN: 'unknown',
  OPERA_ONLINE: 'videos',
  ORGANIZATION: 'organization',
};

export const ADVANCED_SEARCH_TABS_NAMES = {
  ALL: 'All',
  PERFORMANCES: 'Performances',
  ARTISTS: 'Artists',
  COMPANIES: 'Companies',
  FESTIVAL: 'Festivals',
  MANAGER: 'Managers',
  VENUES: 'Venues',
  MUSICAL_WORKS: 'Musical Works',
  OPERA_ONLINE: 'Opera Online',
};

export const INDEX_PAGE_SEARCH_TABS_MAPPING = {
  '/search': ADVANCED_SEARCH_TABS_NAMES.ALL,
  '/artists': ADVANCED_SEARCH_TABS_NAMES.ARTISTS,
  '/productions': ADVANCED_SEARCH_TABS_NAMES.PERFORMANCES,
  '/productions/search': ADVANCED_SEARCH_TABS_NAMES.PERFORMANCES,
  '/companies/[[...action]]': ADVANCED_SEARCH_TABS_NAMES.COMPANIES,
  '/venues': ADVANCED_SEARCH_TABS_NAMES.VENUES,
  '/venues/search': ADVANCED_SEARCH_TABS_NAMES.VENUES,
  '/venues/[country]': ADVANCED_SEARCH_TABS_NAMES.VENUES,
  '/managers': ADVANCED_SEARCH_TABS_NAMES.MANAGER,
  '/managers/search': ADVANCED_SEARCH_TABS_NAMES.MANAGER,
  '/managers/[id]': ADVANCED_SEARCH_TABS_NAMES.MANAGER,
  '/works': ADVANCED_SEARCH_TABS_NAMES.MUSICAL_WORKS,
  '/works/search': ADVANCED_SEARCH_TABS_NAMES.MUSICAL_WORKS,
  '/festivals/[[...action]]': ADVANCED_SEARCH_TABS_NAMES.FESTIVAL,
  '/videos': ADVANCED_SEARCH_TABS_NAMES.OPERA_ONLINE,
  '/videos/search': ADVANCED_SEARCH_TABS_NAMES.OPERA_ONLINE,
};

export const ADVANCED_SEARCH_TABS_NAMES_LABELS = {
  [ADVANCED_SEARCH_TABS_NAMES.ALL]: `${TP}.FN_FILTER_ALL`,
  [ADVANCED_SEARCH_TABS_NAMES.PERFORMANCES]: `${TP}.FN_PERFORMANCES`,
  [ADVANCED_SEARCH_TABS_NAMES.ARTISTS]: `${TP}.m_ARTISTS`,
  [ADVANCED_SEARCH_TABS_NAMES.OPERA_ONLINE]: `${TP}.OPERA_ONLINE`,
  [ADVANCED_SEARCH_TABS_NAMES.COMPANIES]: `${TP}.m_OPCOS`,
  [ADVANCED_SEARCH_TABS_NAMES.FESTIVAL]: `${TP}.m_FESTIVALS`,
  [ADVANCED_SEARCH_TABS_NAMES.MUSICAL_WORKS]: `${TP}.FN_MUSICAL_WORKS_SECTION_NAME`,
  [ADVANCED_SEARCH_TABS_NAMES.MANAGER]: `${TP}.AS_MANAGER`,
  [ADVANCED_SEARCH_TABS_NAMES.VENUES]: `${TP}.m_VENUES`,
};

export const ADVANCED_SEARCH_TABS_LIST = [...Object.values(ADVANCED_SEARCH_TABS_NAMES)];

export const OPERA_ONLINE_TYPES = {
  COMPOSER: 'composer',
  MUSICAL_WORK: 'work',
  ORGANIZATION: 'organization',
  ARTISTS: 'artists',
  RESET: 'reset',
};

export const STATISTICS_TYPES = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  SEASONS: 'seasons',
  MUSICAL_WORK: 'work',
  COUNTRY: 'country',
  LINES: 'lines',
  RESET: 'reset',
};

export const COMPETITIONS_TYPES = {
  COMPETITION: 'competition',
  LOCATION: 'location',
  AGE_LIMIT_MAN: 'ageLimitMan',
  AGE_LIMIT_WOMAN: 'ageLimitWomen',
  DATE: 'date',
  STATUS: 'status',
  RESET: 'reset',
};

export const PERFORMANCE_TYPES = {
  LOCATION: 'location',
  ORGANIZATION: 'organization',
  COMPOSER: 'composer',
  MUSICAL_WORK: 'musicalWork',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  RESET: 'reset',
};

export const SEARCH_CATEGORIES_IDS = {
  GENRE: 'genre',
  WHO: 'who',
  WHERE: 'where',
  WHAT: 'what',
  DATE: 'date',
};

export const SEARCH_CATEGORIES = [
  {
    id: SEARCH_CATEGORIES_IDS.GENRE,
    label: `${TP}.GENRES`,
    placeholder: `${TP}.GENRE_PLACEHOLDER`,
  },
  {
    id: SEARCH_CATEGORIES_IDS.WHO,
    label: `${TP}.WHO`,
    placeholder: `${TP}.WHO_PLACEHOLDER`,
  },
  {
    id: SEARCH_CATEGORIES_IDS.WHERE,
    label: `${TP}.WHERE`,
    placeholder: `${TP}.WHERE_PLACEHOLDER`,
  },
  {
    id: SEARCH_CATEGORIES_IDS.WHAT,
    label: `${TP}.m_MUSICALWORK`,
    placeholder: `${TP}.WHAT_PLACEHOLDER`,
  },

  {
    id: SEARCH_CATEGORIES_IDS.DATE,
    label: `${TP}.m_DATE`,
    placeholder: `${TP}.DATES_PLACEHOLDER`,
  },
];

export const CATEGORY_SEARCH_CONFIG = {
  [SEARCH_CATEGORIES_IDS.WHO]: {
    tabs: [
      { key: 'suggested', label: `${TP}.FN_SUGGESTED`, params: { entity_type: 'profile' } },
      { key: 'artists', label: `${TP}.m_ARTISTS`, params: { entity_type: 'profile', has_creations: false } },
      { key: 'creators', label: `${TP}.CREATORS`, params: { entity_type: 'profile', has_creations: true } },
    ],
  },
  [SEARCH_CATEGORIES_IDS.WHERE]: {
    tabs: [
      {
        key: 'suggested',
        label: `${TP}.FN_SUGGESTED`,
        params: { entity_type: 'region,country,city,venue,organization' },
      },
      { key: 'countries', label: `${TP}.COUNTRIES`, params: { entity_type: 'country' } },
      { key: 'cities', label: `${TP}.FN_CITIES`, params: { entity_type: 'city' } },
      { key: 'venues', label: `${TP}.m_VENUES`, params: { entity_type: 'venue' } },
      {
        key: 'companies',
        label: `${TP}.m_OPCOS`,
        params: { entity_type: 'organization', organization_type_id: ORGANIZATION_TYPE_IDS.COMPANY },
      },
      {
        key: 'festivals',
        label: `${TP}.m_FESTIVALS`,
        params: { entity_type: 'organization', organization_type_id: ORGANIZATION_TYPE_IDS.FESTIVAL },
      },
      {
        key: 'orchestra',
        label: `${TP}.m_FN_ORCHESTRA`,
        params: { entity_type: 'organization', organization_type_id: ORGANIZATION_TYPE_IDS.ORCHESTRA },
      },
    ],
  },
  [SEARCH_CATEGORIES_IDS.WHAT]: {
    tabs: [{ key: 'works', params: { entity_type: 'work' } }],
  },
};
