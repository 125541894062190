const { ENTITY_TYPES, ENTITY_TYPES_TRANSLATION_KEY_MAP, ATTRIBUTE_TYPES, ROUTE_DATE_FORMATS } = require('../constants');
const dayjs = require('../../server/utils/dayjs');

const getEntityHeadlineParts = ({ entity, entityType, attributes, country, parents, translationFn }) => {
  let relevantAttributes = [];
  let headline = [];
  let composers = [];

  if (entityType || attributes) {
    const {
      [ENTITY_TYPES.ORGANIZATION_TYPE]: orgTypeAttributes,
      [ENTITY_TYPES.STATE]: stateAttributes,
      [ENTITY_TYPES.CITY]: cityAttributes,
      [ENTITY_TYPES.PROFESSION]: professionAttributes,
      [ENTITY_TYPES.PROFILE]: profileAttributes,
      ...restAttributes
    } =
      attributes?.[ATTRIBUTE_TYPES.ENTITY]?.reduce((acc, attribute) => {
        if (!acc[attribute.entityType]) {
          acc[attribute.entityType] = [];
        }

        acc[attribute.entityType].push(attribute);

        return acc;
      }, {}) || {};

    let entityTypeTranslationKey = entityType && ENTITY_TYPES_TRANSLATION_KEY_MAP[entityType]?.singular;

    if (translationFn) {
      entityTypeTranslationKey = translationFn(entityTypeTranslationKey);
    }

    if (entityType === ENTITY_TYPES.WORK) {
      composers = profileAttributes?.map(attribute => ({
        name: attribute.entity?.name,
        identifier: { entityType: attribute.entityType, entityId: attribute.entity?.id },
      }));
    }

    let countryAttribute = country;

    if (!countryAttribute && entityType === ENTITY_TYPES.CITY) {
      countryAttribute = parents?.find(parent => parent.entityType === ENTITY_TYPES.COUNTRY)?.entity;
    }

    relevantAttributes = [
      professionAttributes?.length > 0 ? professionAttributes : [],
      cityAttributes?.length > 0
        ? cityAttributes.reduce((acc, attribute) => {
            const prevSavedCityName = acc[0]?.name;
            const cityName = attribute.name;

            if (!prevSavedCityName) {
              return [attribute];
            }

            return cityName.length < prevSavedCityName.length ? [attribute] : acc;
          }, [])
        : [],
      stateAttributes?.length > 0 ? stateAttributes : [],
      countryAttribute?.id ? [countryAttribute] : [],
      orgTypeAttributes?.length > 0 ? orgTypeAttributes : [],
    ].reduce((acc, groupedAttributes) => {
      if (groupedAttributes?.length > 0) {
        acc.push(groupedAttributes.map(attr => attr.entity?.name).join(', '));
      }

      return acc;
    }, []);

    const birthDate = attributes?.[ATTRIBUTE_TYPES.BIRTH_DATE];
    const deathDate = attributes?.[ATTRIBUTE_TYPES.DEATH_DATE];

    if (birthDate && deathDate) {
      const formattedBirthDate = dayjs(birthDate).format(ROUTE_DATE_FORMATS.YEAR);
      const formattedDeathDate = dayjs(deathDate).format(ROUTE_DATE_FORMATS.YEAR);
      relevantAttributes.push(`${formattedBirthDate} - ${formattedDeathDate}`);
    }

    const attributeNames = Object.values(restAttributes).reduce((acc, groupedAttributes) => {
      if (groupedAttributes?.length > 0) {
        acc.push(groupedAttributes.map(attr => attr.entity?.name).join(', '));
      }

      return acc;
    }, []);

    headline = [...(entityTypeTranslationKey ? [entityTypeTranslationKey] : []), ...attributeNames];
  }

  const sanitisedHeadlineParts = [...new Set(headline)].filter(value => Boolean(value) && value !== entity?.name);
  const sanitisedRelevantAttributes = [...new Set(relevantAttributes)].filter(
    value => Boolean(value) && value !== entity?.name,
  );

  return {
    headline: sanitisedHeadlineParts,
    relevantAttributes: sanitisedRelevantAttributes,
    composers,
  };
};

module.exports = getEntityHeadlineParts;
