const DEFAULT_LANGUAGE = 'en';
const TP = 'operabase.general';
const SUPPORTED_LANGUAGES = (process.env.FRONTBASE_LANGUAGES || '').split(',');

const ENTITY_TYPES = {
  PROFILE: 'profile',
  ORGANIZATION: 'organization',
  ORGANIZATION_TYPE: 'organizationType',
  WORK: 'work',
  WORK_TYPE: 'workType',
  STAGING_TYPE: 'stagingType',
  AGENCY: 'agency',
  REGION: 'region',
  COUNTRY: 'country',
  STATE: 'state',
  CITY: 'city',
  DATE: 'date',
  PRODUCTION: 'production',
  PROFESSION: 'profession',
  SEASON: 'season',
  LANGUAGE: 'language',
  SURTITLE: 'surtitle',
  UNKNOWN: 'unknown',
  ENSEMBLE_TYPE: 'ensembleType',
};

const DEPRECATED_ENTITY_TYPES = {
  PERFORMANCE: 'performance',
  VENUE: 'venue',
};

const ENTITY_TYPE_ID_PREFIX = {
  [ENTITY_TYPES.PROFILE]: 'a',
  [ENTITY_TYPES.ORGANIZATION]: 'o',
  [ENTITY_TYPES.PRODUCTION]: 'p',
  [ENTITY_TYPES.AGENCY]: 'm',
  [ENTITY_TYPES.ORGANIZATION_TYPE]: 'j',
  [ENTITY_TYPES.WORK]: 'w',
  [ENTITY_TYPES.WORK_TYPE]: 'u',
  [ENTITY_TYPES.STAGING_TYPE]: 'x',
  [ENTITY_TYPES.REGION]: 'n',
  [ENTITY_TYPES.COUNTRY]: 'l',
  [ENTITY_TYPES.CITY]: 'b',
  [ENTITY_TYPES.LANGUAGE]: 't',
  [ENTITY_TYPES.PROFESSION]: 'r',
};

const ATTRIBUTE_TYPES = {
  ENTITY: 'entity',
  BIRTH_DATE: 'date_of_birth',
  DEATH_DATE: 'date_of_death',
};

const ENTITY_VALIDATION_STATUS = {
  APPROVED: 'approved',
  APPROVED_HIDDEN: 'approved-hidden',
  PENDING: 'pending',
  REJECTED: 'rejected',
};

const ENTITY_TYPES_TRANSLATION_KEY_MAP = {
  [ENTITY_TYPES.PROFILE]: {
    plural: `${TP}.m_ARTISTS`,
    singular: `${TP}.m_ARTIST`,
  },
  [ENTITY_TYPES.ORGANIZATION]: {
    plural: `${TP}.FN_ARTS_ORGANISATIONS`,
    singular: `${TP}.FN_ORG`,
  },
  [ENTITY_TYPES.WORK]: {
    plural: `${TP}.FN_MUSICAL_WORKS`,
    singular: `${TP}.FN_WORK`,
  },
  [ENTITY_TYPES.PRODUCTION]: {
    plural: `${TP}.FN_PERFORMANCES`,
    singular: `${TP}.FN_ADD_PRODUCTION_PERFORMACE`,
  },
  [ENTITY_TYPES.AGENCY]: {
    plural: `${TP}.m_MANAGERS`,
    singular: `${TP}.AS_MANAGER`,
  },
  [ENTITY_TYPES.WORK_TYPE]: {
    plural: `${TP}.FN_GENRES`,
    singular: `${TP}.FN_GENRE`,
  },
  [ENTITY_TYPES.STAGING_TYPE]: {
    plural: `${TP}.FN_STAGING`,
    singular: `${TP}.FN_STAGING`,
  },
};

const RESPONSE_STATUS_CODES = {
  SUCCESS: 200,
  PERMANENT_REDIRECT: 301,
  TEMPORARY_REDIRECT: 302,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  CONFLICT: 409,
};

const ROUTE_VALIDITY = {
  VALID: 'VALID',
  INVALID: 'INVALID',
  FIXED: 'FIXED',
};

const ORG_TYPES = {
  VENUE: 'venue',
};

const PROFESSION_TYPES = {
  VOICE_TYPE: 'voice-type',
  INSTRUMENTALIST: 'instrumentalist',
  PROFESSION: 'profession',
  ROLE_REPERTOIRE: 'role-repertoire',
};

const PROFESSION_SLUG_TYPE_MAP = {
  singer: PROFESSION_TYPES.VOICE_TYPE,
  instrumentalist: PROFESSION_TYPES.INSTRUMENTALIST,
};

const ROUTE_RESERVED_KEYWORDS = {
  about: 'about',
  academia: 'academia',
  'account-settings': 'account-settings',
  agency_modif: 'agency_modif',
  agencies: 'agencies',
  'analytics-terms': 'analytics-terms',
  'roster-add': 'roster-add',
  'roster-update': 'roster-update',
  articles: 'articles',
  artists: 'artists',
  bio: 'bio',
  calendar: 'calendar',
  casting: 'casting',
  companies: 'companies',
  'company-graph': 'company-graph',
  'role-graph': 'role-graph',
  'venue-graph': 'venue-graph',
  composers: 'composers',
  contact: 'contact',
  cookpol: 'cookpol',
  details: 'details',
  edit: 'edit',
  featured: 'featured',
  highlights: 'highlights',
  images: 'images',
  intro: 'intro',
  journalist: 'journalist',
  link: 'link',
  login: 'login',
  managers: 'managers',
  maps: 'maps',
  'modify-production': 'modify-production',
  'new-and-rare': 'new-and-rare',
  onthisday: 'onthisday',
  organisations: 'organisations',
  overview: 'overview',
  payment: 'payment',
  integrate: 'integrate',
  reactivate: 'reactivate',
  'payment-terms': 'payment-terms',
  performances: 'performances',
  privpol: 'privpol',
  pro: 'pro',
  proaudience: 'proaudience',
  productions: 'productions',
  register: 'register',
  rentals: 'rentals',
  reviews: 'reviews',
  revivals: 'revivals',
  search: 'search',
  recent: 'recent',
  visited: 'visited',
  'search-productions': 'search-productions',
  seasons: 'seasons',
  statistics: 'statistics',
  termpol: 'termpol',
  validation: 'validation',
  debug: 'debug',
  venues: 'venues',
  videos: 'videos',
  repertoire: 'repertoire',
  'watch-online': 'watch-online',
  works: 'works',
  past: 'past',
  upcoming: 'upcoming',
  today: 'today',
  tomorrow: 'tomorrow',
  'next-15-days': 'next-15-days',
  'next-30-days': 'next-30-days',
  'next-90-days': 'next-90-days',
  'cast-crew': 'cast-crew',
};

const MONTHS = [
  ROUTE_RESERVED_KEYWORDS.january,
  ROUTE_RESERVED_KEYWORDS.february,
  ROUTE_RESERVED_KEYWORDS.march,
  ROUTE_RESERVED_KEYWORDS.april,
  ROUTE_RESERVED_KEYWORDS.may,
  ROUTE_RESERVED_KEYWORDS.june,
  ROUTE_RESERVED_KEYWORDS.july,
  ROUTE_RESERVED_KEYWORDS.august,
  ROUTE_RESERVED_KEYWORDS.september,
  ROUTE_RESERVED_KEYWORDS.october,
  ROUTE_RESERVED_KEYWORDS.november,
  ROUTE_RESERVED_KEYWORDS.december,
];

const QUERY_PARAMS_RESERVED = {
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_TERM: 'utm_term',
  UTM_CONTENT: 'utm_content',
  SCROLL_TO: 'st',
  OPEN_MODAL: 'm',
  AGENCY_CONNECTION_ID: 'connection_id',
};

const QUERY_PARAMS_VALID = {
  SCROLL_TO: 'scroll_to',
  OPEN_MODAL: 'm',
  AGENCY_CONNECTION_ID: 'agency_connection_id',
};

const ALLOWED_QUERY_PARAMS = [Object.values(QUERY_PARAMS_RESERVED), Object.values(QUERY_PARAMS_VALID)].flat();

const ROUTE_DATE_FORMATS = {
  YEAR: 'YYYY',
  MONTH_YEAR: 'MMMM-YYYY',
  DATE_MONTH_YEAR: 'DD-MMMM-YYYY',
  STANDARD: 'YYYY-MM-DD',
  FULL_DATE: 'DD MMMM YYYY',
};

const ENTITY_DETAILS_TABS = {
  REPERTOIRE: 'repertoire',
};

const FILTER_TYPES = {
  WHO: 'who',
  WHERE: 'where',
  WHAT: 'what',
  DATE: 'date',
  ORGANIZATION_TYPE: 'org-type',
  REGION: 'region',
  COUNTRY: 'country',
  CITY: 'city',
  GENRE: 'genre',
  STAGING_TYPE: 'format',
  PROFESSION: 'profession',
  PAGE: 'page',
  QUERY: 'query',
  LETTER: 'letter',
  SEASON: 'season',
  TICKETS_WATCH_ONLINE: 'tickets-watch-online',
  ONGOING_UPCOMING: 'ongoing-upcoming',
  PERFORMANCE_HIGHLIGHT: 'highlight',
  COVER_DEBUT: 'cover-debut',
  PRODUCER: 'producer',
  CO_PRODUCER: 'co-producer',
  PRODUCTION_PERIOD: 'production-period',
  CO_CAST: 'co-cast',
  PROFILE: 'profile',
  CONDUCTOR: 'conductor',
  COMPOSER: 'composer',
  DIRECTOR: 'stage-director',
  CHOREOGRAPHER: 'choreographer',
  VENUE: 'venue',
  CREATOR: 'creator',
  WORK: 'work',
  LANGUAGE: 'language',
  REPERTOIRE_STATUS: 'repertoire-status',
  ORGANIZATION: 'organization',
  CAST_CREW: 'cast-crew',
  CONTRIBUTION_EXPRESSION: 'contribution-expression',
  ROLE_PROFESSION_REPERTOIRE: 'role-profession-repertoire',
  ROLE_PROFESSION: 'role-profession',
  ROLE: 'role',
  ENSEMBLE: 'ensemble',
  TICKET: 'ticket',
  WATCH_ONLINE: 'watch-online',
  SURTITLE: 'surtitle',
  PRODUCER_TYPE: 'producer-type',
  INDIVIDUAL: 'individual',
  WORK_TYPE: 'workType',
  CONTRIBUTION_TYPE: 'contributionType',
  BOOLEAN_SEARCH: 'expression',
  SINCE_YEAR: 'since_year',
  WORK_ROLE: 'work_role_id',
  WORK_ROLE_REFERENCE: 'work_role_reference',
  DATE_FROM: 'date_from',
  PAST_PERFORMANCE_COUNT: 'past_perf_count',
  FUTURE_PERFORMANCE_COUNT: 'future_perf_count',
};

const FILTER_LABELS = {
  [FILTER_TYPES.SINCE_YEAR]: `${TP}.FN_SINCE_YEAR`,
  [FILTER_TYPES.SURTITLE]: `${TP}.SURTITLE`,
  [FILTER_TYPES.LANGUAGE]: `${TP}.FN_LANGUAGE_LABEL`,
  [FILTER_TYPES.PERFORMANCE_HIGHLIGHT]: `${TP}.FN_PERFORMANCE_HIGHLIGHTS`,
  [FILTER_TYPES.TICKETS_WATCH_ONLINE]: `${TP}.FN_TICKETS_WATCH_ONLINE`,
  [FILTER_TYPES.ONGOING_UPCOMING]: `${TP}.FN_ONGOING_UPCOMING`,
  [FILTER_TYPES.COUNTRY]: `${TP}.FN_COUNTRY_CITY`,
  [FILTER_TYPES.COMPOSER]: `${TP}.m_COMPOSER`,
  [FILTER_TYPES.WORK]: `${TP}.FN_MUSICAL_WORKS_SECTION_NAME`,
  [FILTER_TYPES.WORK_TYPE]: `${TP}.FN_GENRE_STAGING`,
  [FILTER_TYPES.PROFESSION]: `${TP}.FN_PROFESSION`,
};

const FILTER_GROUP = {
  DEFAULT: [
    [FILTER_TYPES.TICKETS_WATCH_ONLINE],
    [FILTER_TYPES.WORK_TYPE, FILTER_TYPES.COUNTRY, FILTER_TYPES.WORK, FILTER_TYPES.PROFESSION],
    [FILTER_TYPES.LANGUAGE, FILTER_TYPES.SURTITLE, FILTER_TYPES.PERFORMANCE_HIGHLIGHT],
    [FILTER_TYPES.CONTRIBUTION_EXPRESSION],
  ],
  REPERTOIRE: [[FILTER_TYPES.WORK_TYPE, FILTER_TYPES.PROFESSION, FILTER_TYPES.COMPOSER, FILTER_TYPES.WORK]],
};

const AGGREGATION_TYPES = {
  [FILTER_TYPES.ROLE]: 'profession_id',
  [FILTER_TYPES.ENSEMBLE]: 'ensemble_type_id',
  [FILTER_TYPES.CO_PRODUCER]: 'contributor_id',
  [FILTER_TYPES.VENUE]: 'contributor_id',
  [FILTER_TYPES.PRODUCER]: 'contributor_id',
  [FILTER_TYPES.LANGUAGE]: 'language_id',
  [FILTER_TYPES.SURTITLE]: 'surtitle_id',
  [FILTER_TYPES.PERFORMANCE_HIGHLIGHT]: 'highlight_type',
  [FILTER_TYPES.PRODUCER_TYPE]: 'producer_type',
  [FILTER_TYPES.PRODUCER]: 'producer_id',
  [FILTER_TYPES.PROFESSION]: 'profession_id',
  [FILTER_TYPES.WORK_TYPE]: 'work_type_id',
  [FILTER_TYPES.CITY]: 'city_id',
  [FILTER_TYPES.COUNTRY]: 'country_id',
  [FILTER_TYPES.CONTRIBUTION_TYPE]: 'contribution_type',
  [FILTER_TYPES.WORK]: 'work_id',
  [FILTER_TYPES.COMPOSER]: 'work_creator_id',
  [FILTER_TYPES.TICKET]: 'has_tickets',
  [FILTER_TYPES.WATCH_ONLINE]: 'has_online',
  [FILTER_TYPES.SINCE_YEAR]: 'year_id',
  [FILTER_TYPES.WORK_ROLE]: 'work_role_id',
  [FILTER_TYPES.PAST_PERFORMANCE_COUNT]: 'past_perf_count',
  [FILTER_TYPES.FUTURE_PERFORMANCE_COUNT]: 'future_perf_count',
  CONTRIBUTOR_ID: 'contributor_id',
};

const EXCLUDE_IN_SLUG_FILTER_TYPES = [
  FILTER_TYPES.WHO,
  FILTER_TYPES.WHAT,
  FILTER_TYPES.WHERE,
  FILTER_TYPES.DATE,
  FILTER_TYPES.ORGANIZATION_TYPE,
  FILTER_TYPES.REGION,
  FILTER_TYPES.COUNTRY,
  FILTER_TYPES.CITY,
  FILTER_TYPES.GENRE,
  FILTER_TYPES.STAGING_TYPE,
  FILTER_TYPES.PROFESSION,
  FILTER_TYPES.SEASON,
];

const OPERATORS = {
  AND: ',',
  OR: '~',
  NOT: '!',
  CONCAT: '-',
  TYPE_SEPARATOR: ':',
  SEPARATOR: '|',
  FILTER_TYPE_OPERATOR: '~',
  CONTRIBUTION_EXP_OPERATOR: ';;',
  CONTRIBUTOR_SEPARATOR: ';',
  FILTER_TYPE_NEGATED: '-',
};

const OPERATOR_LABELS = {
  [OPERATORS.AND]: `${TP}.FN_BOOLEAN_OPERATOR_AND`,
  [OPERATORS.OR]: `${TP}.FN_BOOLEAN_OPERATOR_OR`,
  [OPERATORS.NOT]: `${TP}.FN_BOOLEAN_OPERATOR_NOT`,
};

const BOOL_VAL_OPERATORS = [OPERATORS.AND, OPERATORS.OR, OPERATORS.NOT].join('');

const REGULAR_EXPRESSIONS = {
  BOOLEAN_ID_SPLITTER: new RegExp(`[${BOOL_VAL_OPERATORS}]`),
  BOOLEAN_VALUE_SPLITTER: new RegExp(`(?=[${BOOL_VAL_OPERATORS}])`),
  BOOLEAN_VALUE_OPERATOR_MATCH: new RegExp(`^([${BOOL_VAL_OPERATORS}]?)([^${[BOOL_VAL_OPERATORS].join('')}]+)`),
  YEAR_MATCH: new RegExp('^(\\d{4})$'),
  MONTH_MATCH: new RegExp(`^${MONTHS.join('|')}$`),
  DATE_MATCH: new RegExp('^(\\d{2})$'),
  NUMBERS_ONLY: new RegExp('^[0-9]+$'),
  PREFIXED_ID: new RegExp(`^(${Object.values(ENTITY_TYPE_ID_PREFIX).join('|')})([0-9]+)$`),
  NON_PREFIXED_SLUG_MATCH: new RegExp(`(.+-)([0-9]+)$`),
  REPLACE_ID_PREFIX_SLUG_WITH_ID: new RegExp(/(.+-)\w([0-9]+)$/),
  URL_REPLACE_LANGUAGE_TRAILING_SLASH: new RegExp(`(/(${SUPPORTED_LANGUAGES.join('|')})|/)$`),
  NEGATED_ENTITY_ID: new RegExp(`^${OPERATORS.FILTER_TYPE_NEGATED}`),
  PREFIXED_PROFILE_ID: new RegExp(`^${ENTITY_TYPE_ID_PREFIX[ENTITY_TYPES.PROFILE]}`),
};

const ID_PREFIX_SKIPPED_FILTER_TYPES = [
  FILTER_TYPES.LANGUAGE,
  FILTER_TYPES.SURTITLE,
  FILTER_TYPES.PROFESSION,
  FILTER_TYPES.COUNTRY,
  FILTER_TYPES.CITY, // NOTE: added as we are showing city filter on org index page
  FILTER_TYPES.PRODUCER,
  FILTER_TYPES.ORGANIZATION_TYPE,
];

const FILTER_PATH_PRECEDENCE_ORDER = [
  FILTER_TYPES.WHO,
  FILTER_TYPES.ORGANIZATION_TYPE,
  FILTER_TYPES.WHERE,
  FILTER_TYPES.REGION,
  FILTER_TYPES.SEASON,
  FILTER_TYPES.COUNTRY,
  FILTER_TYPES.CITY,
  FILTER_TYPES.WHAT,
  FILTER_TYPES.GENRE,
  FILTER_TYPES.STAGING_TYPE,
  FILTER_TYPES.PROFESSION,
  FILTER_TYPES.CONDUCTOR,
  FILTER_TYPES.COMPOSER,
  FILTER_TYPES.DIRECTOR,
  FILTER_TYPES.CHOREOGRAPHER,
  FILTER_TYPES.PRODUCER,
  FILTER_TYPES.DATE, // NOTE: this has to be at last
];

const PATH_PRECEDENCE_ORDER = [
  ENTITY_TYPES.PROFILE,
  ENTITY_TYPES.ORGANIZATION,
  ENTITY_TYPES.REGION,
  ENTITY_TYPES.COUNTRY,
  ENTITY_TYPES.CITY,
  ENTITY_TYPES.WORK,
  ENTITY_TYPES.WORK_TYPE,
  ENTITY_TYPES.STAGING_TYPE,
  FILTER_TYPES.DATE,
];

const ROUTE_PART_TYPE = {
  VARIABLE: 'variable',
  RESERVED: 'reserved',
  INVALID: 'invalid',
};

const PAGE_DYNAMIC_ROUTES = {
  ACTIONS: '[[...action]]',
  ID: '[id]',
  COUNTRY: '[[...country]]',
  MONTH: '[month]',
  DAY: '[day]',
  TYPE: '[type]',
};

const FILTER_TYPE_ENTITY_TYPE_MAP = {
  [FILTER_TYPES.LANGUAGE]: ENTITY_TYPES.LANGUAGE,
  [FILTER_TYPES.WORK]: ENTITY_TYPES.WORK,
  [FILTER_TYPES.PROFESSION]: ENTITY_TYPES.PROFESSION,
  [FILTER_TYPES.SURTITLE]: ENTITY_TYPES.LANGUAGE,
};

const CASTING_RESULT_TYPES_VALUES = {
  ALL: 'all',
  PERFORMED: 'performed',
  BEST_FIT: 'best-fit',
  JUMP_IN: 'jump-in',
  DESIRED_ROLE: 'desired-role',
  ROLE_DEBUT: 'role-debut',
};


module.exports = {
  ATTRIBUTE_TYPES,
  OPERATORS,
  REGULAR_EXPRESSIONS,
  ROUTE_RESERVED_KEYWORDS,
  QUERY_PARAMS_RESERVED,
  QUERY_PARAMS_VALID,
  ALLOWED_QUERY_PARAMS,
  ENTITY_TYPES,
  DEPRECATED_ENTITY_TYPES,
  ENTITY_TYPE_ID_PREFIX,
  ORG_TYPES,
  ROUTE_DATE_FORMATS,
  FILTER_TYPES,
  FILTER_PATH_PRECEDENCE_ORDER,
  PATH_PRECEDENCE_ORDER,
  PROFESSION_TYPES,
  PROFESSION_SLUG_TYPE_MAP,
  ROUTE_PART_TYPE,
  PAGE_DYNAMIC_ROUTES,
  RESPONSE_STATUS_CODES,
  DEFAULT_LANGUAGE,
  TP,
  ROUTE_VALIDITY,
  ENTITY_TYPES_TRANSLATION_KEY_MAP,
  OPERATOR_LABELS,
  EXCLUDE_IN_SLUG_FILTER_TYPES,
  AGGREGATION_TYPES,
  FILTER_LABELS,
  SUPPORTED_LANGUAGES,
  FILTER_GROUP,
  FILTER_TYPE_ENTITY_TYPE_MAP,
  ENTITY_DETAILS_TABS,
  ID_PREFIX_SKIPPED_FILTER_TYPES,
  ENTITY_VALIDATION_STATUS,
  CASTING_RESULT_TYPES_VALUES,
};
