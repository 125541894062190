import { getList, searchProfiles } from 'utils/API';
import { getLanguage } from 'utils/queriesUtil';
import { getQuery, transformQueryFn } from 'utils/globals/queries';

import { transformGlobalSearch } from './transformers';

const ENDPOINTS = {
  SEARCH: 'search',
};

const defaultConfig = {
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 10 * 60 * 1000,
  cacheTime: 10000,
  getNextPageParam: lastPage => {
    const { limit, page, total } = lastPage;
    const totalFetched = limit * page;

    return totalFetched < total ? page + 1 : undefined;
  },
  getPreviousPageParam: () => false,
};

const fetchSearchResults = ({ queryParams = {}, limit = 20 }, cookies) =>
  getList(ENDPOINTS.SEARCH, { queryParams, pagination: { limit } }, cookies).then(response => response.data);

const queries = {
  getArtists: (
    { ARTIST_TYPE_ID, query, exclude_id, pagination, filters, limit, queryConfig = {} },
    cookies,
    language,
  ) => ({
    queryKey: [getLanguage(language), 'GET_ARTISTS_LIST', query],
    queryFn: async () => searchProfiles(ARTIST_TYPE_ID, query, exclude_id, pagination, filters, limit, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
  getDropDownList: ({ endpoint, queryParams, limit = 20, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_AGGREGATIONS', limit, queryParams],
    queryFn: async ({ pageParam = 1 }) =>
      getList(
        endpoint,
        {
          queryParams,
          pagination: { page: pageParam, limit },
        },
        cookies,
      ),
    ...defaultConfig,
    ...queryConfig,
  }),
  getSelectedOption: ({ identifier, endpoint, queryParams, queryConfig = {} }, cookies, language) => {
    const query = {
      queryParams,
    };

    return {
      queryKey: [getLanguage(language), 'GET_SELECTED_OPTION', queryParams, identifier],
      queryFn: async () => getList(endpoint, query, cookies),
      select: response => (response?.data?.data || [])?.map(item => ({ ...item, label: item?.name }))?.[0] || null,
      ...defaultConfig,
      ...queryConfig,
    };
  },
  getGlobalSearch: getQuery('GET_GLOBAL_SEARCH', transformQueryFn(fetchSearchResults, transformGlobalSearch)),
};

export default queries;
