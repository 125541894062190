import { getList } from 'utils/API';
import { getOrderedMediaList } from 'utils/common';
import { formatResponseToList } from 'utils/crudUtils';
import { isPortraitMedia } from 'utils/media';

import { MEDIA_TAG_RELATIONS, MEDIA_TAGS_TYPES_IDS, SORT_OPTION_VALUES, VIDEO_ATTRIBUTE_SLUGS } from 'constants/index';

import { ENTITIES, MEDIA_ATTRIBUTES_TYPES_IDS, MEDIA_TYPES_IDS } from 'constants/consts';
import { ENTITY_TYPES } from 'operabase-router/constants';
import { getQuery } from 'utils/globals/queries';

const ENDPOINTS = {
  STATIC: {
    MEDIA: 'media',
    VIDEOS: 'proxy/videos',
    SHOWREELS: 'media/showreelsmedia',
    SUGGESTIONS: 'media/suggestions',
  },
};

const ENTITY_MEDIA_TAG_RELATIONS = {
  [ENTITY_TYPES.PROFILE]: MEDIA_TAG_RELATIONS.CAST,
  [ENTITY_TYPES.ORGANIZATION]: MEDIA_TAG_RELATIONS.ORGANIZATION,
  [ENTITY_TYPES.WORK]: MEDIA_TAG_RELATIONS.WORK,
};

const ENTITY_MEDIA_TAG_TYPES = {
  [ENTITY_TYPES.PROFILE]: MEDIA_TAGS_TYPES_IDS.PROFILE,
  [ENTITY_TYPES.ORGANIZATION]: MEDIA_TAGS_TYPES_IDS.ORGANIZATION,
  [ENTITY_TYPES.PRODUCTION]: MEDIA_TAGS_TYPES_IDS.PRODUCTION,
  [ENTITY_TYPES.WORK]: MEDIA_TAGS_TYPES_IDS.WORK,
};

const ENTITY_PRODUCTION_MEDIA_TAG_TYPES = {
  [ENTITY_TYPES.PROFILE]: MEDIA_TAGS_TYPES_IDS.PRODUCTION_CONTRIBUTION,
  [ENTITY_TYPES.ORGANIZATION]: MEDIA_TAGS_TYPES_IDS.PRODUCTION_CONTRIBUTION,
  [ENTITY_TYPES.WORK]: MEDIA_TAGS_TYPES_IDS.PRODUCTION,
  [ENTITY_TYPES.PRODUCTION]: MEDIA_TAGS_TYPES_IDS.PRODUCTION,
};

const transformMediaFile = media => ({
  ...media,
  ...isPortraitMedia(media),
  matchingTags: [],
  tags: [],
  attributes: [],
});

const getMediaParams = ({ entityType, entityId, mediaTypeId, page, limit = 10 }) => {
  const params = {
    queryParams: {
      media_tag_id: entityId,
      media_type_id: mediaTypeId,
      media_tag_type_id: ENTITY_MEDIA_TAG_TYPES[entityType],
      exclude_media_attribute_id: MEDIA_ATTRIBUTES_TYPES_IDS.BANNER,
      sort: 'order,-createdAt',
    },
    pagination: {
      limit,
      page,
    },
  };

  return params;
};

const getProductionMediaParams = ({
  entityType,
  entityId,
  mediaTypeId,
  page,
  limit = 25,
  sort = 'order,-createdAt',
  filters,
}) => {
  const params = {
    queryParams: {
      sort,
      ...filters,
      media_tag_id: entityId,
      media_type_id: mediaTypeId,
      media_tag_type_id: ENTITY_PRODUCTION_MEDIA_TAG_TYPES[entityType],
      media_tag_relation: ENTITY_MEDIA_TAG_RELATIONS[entityType],
      exclude_media_attribute_id: MEDIA_ATTRIBUTES_TYPES_IDS.BANNER,
    },
    pagination: {
      limit,
      page,
    },
  };

  return params;
};

const fetchMedia = (params, cookies) =>
  getList(ENDPOINTS.STATIC.MEDIA, params, cookies).then(response => {
    const formattedResponse = formatResponseToList(response);

    const orderedResponse = {
      ...formattedResponse,
      data: getOrderedMediaList(formattedResponse?.data, params?.media_tag_type_id, params?.production_id)?.map(
        transformMediaFile,
      ),
    };

    return orderedResponse;
  });

const fetchEntityShowreelVideos = ({ entityType, entityId, ids: showreelTypeIds, page = 1, limit }, cookies) => {
  const entityTypeId = {
    [ENTITY_TYPES.PROFILE]: ENTITIES.PROFILE_ENTITY,
    [ENTITY_TYPES.ORGANIZATION]: ENTITIES.ORGANIZATION,
  };

  const tagTypeId = entityTypeId[entityType];

  const params = {
    queryParams: {
      entity_type_id: tagTypeId,
      entity_id: entityId,
      show_reel_type_id: showreelTypeIds,
    },
    pagination: {
      limit,
      page,
    },
  };

  return getList(ENDPOINTS.STATIC.SHOWREELS, params, cookies).then(response => {
    const formattedResponse = formatResponseToList(response);

    const orderedResponse = {
      ...formattedResponse,
      data: getOrderedMediaList(formattedResponse?.data, tagTypeId, entityId, true),
    };

    return orderedResponse;
  });
};

const fetchFeaturedMedia = mediaTypeId => (props, cookies) => {
  const params = getMediaParams({ ...props, mediaTypeId });

  return fetchMedia(params, cookies);
};

const fetchProductionMedia = mediaTypeId => (props, cookies) => {
  const params = getProductionMediaParams({ ...props, mediaTypeId });

  return fetchMedia(params, cookies);
};

const fetchProductionTrailer = async ({ id }, cookies) => {
  if (!id) {
    return null;
  }

  const params = {
    queryParams: {
      media_attribute_slug: VIDEO_ATTRIBUTE_SLUGS.TRAILER,
      media_attribute_type_id: MEDIA_ATTRIBUTES_TYPES_IDS.VIDEO,
      media_tag_id: id,
      media_tag_type_id: MEDIA_TAGS_TYPES_IDS.PRODUCTION,
      media_type_id: MEDIA_TYPES_IDS.VIDEO,
    },
    pagination: {
      limit: 1,
      page: 1,
    },
  };

  return fetchMedia(params, cookies).then(response => response?.data?.[0]);
};

const fetchExpiredDigitalMedia = ({ page = 1, limit = 12 }, cookies) => {
  const params = {
    queryParams: {
      only_expiring_media: true,
      media_type_id: MEDIA_TYPES_IDS.LS,
      has_posters: true,
      is_production_published: true,
      is_valid_ls_vod: true,
      validation_status_id: '1,2,3,5',
      sort: SORT_OPTION_VALUES.PERFORMANCE_DESC,
    },
    pagination: {
      limit,
      page,
    },
  };

  return getList(ENDPOINTS.VIDEOS, params, cookies).then(response => response?.data);
};

const fetchDigitalMedia = ({ entityType, entityId, page = 1, limit = 10 }, cookies) => {
  const extraParams = {
    [ENTITY_TYPES.PROFILE]: {
      media_tag_relation: MEDIA_TAG_RELATIONS.CAST,
      media_tag_type_id: MEDIA_TAGS_TYPES_IDS.PRODUCTION_CONTRIBUTION,
    },
    [ENTITY_TYPES.ORGANIZATION]: {
      media_tag_relation: MEDIA_TAG_RELATIONS.ORGANIZATION,
      media_tag_type_id: MEDIA_TAGS_TYPES_IDS.PRODUCTION_CONTRIBUTION,
    },
    [ENTITY_TYPES.PRODUCTION]: {
      media_tag_type_id: MEDIA_TAGS_TYPES_IDS.PRODUCTION,
    },
    [ENTITY_TYPES.WORK]: {
      media_tag_relation: MEDIA_TAG_RELATIONS.WORK,
      media_tag_type_id: MEDIA_TAGS_TYPES_IDS.PRODUCTION,
    },
  };

  const params = {
    queryParams: {
      media_tag_id: entityId,
      ...(extraParams[entityType] || {}),
      media_type_id: [MEDIA_TYPES_IDS.LS],
      has_posters: true,
      is_valid_ls_vod: true,
      is_production_published: true,
      sort: SORT_OPTION_VALUES.PERFORMANCE_DESC,
      display_status: 1,
    },
    pagination: {
      limit,
      page,
    },
  };

  return getList(ENDPOINTS.STATIC.VIDEOS, params, cookies).then(response => response?.data);
};

const fetchMediaSuggestions = ({ entityType, entityId, isMobileDevice }, cookies) => {
  const extraParams = {
    [ENTITY_TYPES.PROFILE]: {
      entity_type: MEDIA_TAGS_TYPES_IDS.PROFILE,
    },
    [ENTITY_TYPES.ORGANIZATION]: {
      entity_type: MEDIA_TAGS_TYPES_IDS.ORGANIZATION,
    },
    [ENTITY_TYPES.PRODUCTION]: {
      entity_type: MEDIA_TAGS_TYPES_IDS.PRODUCTION,
    },
  };

  return getList(
    ENDPOINTS.STATIC.SUGGESTIONS,
    {
      queryParams: {
        entity_id: entityId,
        ...(extraParams[entityType] || {}),
      },
      pagination: { limit: 5, page: 1 },
    },
    cookies,
  ).then(response => {
    const data = response?.data;

    if (isMobileDevice) {
      const videos = data?.data?.filter(({ mediaType }) => mediaType === 'video');

      return {
        ...data,
        data: videos?.length > 0 ? videos : data?.data,
      };
    }

    return data;
  });
};

const queries = {
  getFeaturedPhotos: getQuery('GET_FEATURED_PHOTOS', fetchFeaturedMedia(MEDIA_TYPES_IDS.IMAGE)),
  getFeaturedVideos: getQuery('GET_FEATURED_VIDEOS', fetchFeaturedMedia(MEDIA_TYPES_IDS.VIDEO)),
  getProductionPhotos: getQuery('GET_PRODUCTION_PHOTOS', fetchProductionMedia(MEDIA_TYPES_IDS.IMAGE)),
  getProductionVideos: getQuery('GET_PRODUCTION_VIDEOS', fetchProductionMedia(MEDIA_TYPES_IDS.VIDEO)),
  getDigitalVideos: getQuery('GET_DIGITAL_VIDEOS', fetchDigitalMedia),
  getExpiredDigitalVideos: getQuery('GET_EXPIRED_DIGITAL_VIDEOS', fetchExpiredDigitalMedia),
  getShowreelVideos: getQuery('GET_SHOWREEL_VIDEOS', fetchEntityShowreelVideos),
  getProductionTrailer: getQuery('GET_PRODUCTION_TRAILER', fetchProductionTrailer),
  getMediaSuggestions: getQuery('GET_MEDIA_SUGGESTIONS', fetchMediaSuggestions),
};

export default queries;
