const serverAPI = require('../../server/utils/serverAPI');

const getEntities = async ({ slugs, language: lang }, session) => {
  try {
    const entities = await serverAPI.get('entities', { slug: slugs.join(','), lang }, 5000, session);

    return entities;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    throw err;
  }
};

const getProductionStats = async ({ filters, language }, session) => {
  try {
    const stats = await serverAPI.get(
      'productions/aggregations',
      { facets: ['has_tickets', 'has_online'], ...filters, language },
      5000,
      session,
    );

    return stats;
  } catch (err) {
    return null;
  }
};

module.exports = {
  getEntities,
  getProductionStats,
};
