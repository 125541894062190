// eslint-disable-next-line import/no-cycle
import productionQueries from 'containers/Productions/queries'; // TODO: FB-000000 Solve dependency cycle
import globalQueries from 'containers/Globals/queries';
import mediaQueries from 'containers/Media/queries';
import reviewQueries from 'containers/Reviews/queries';
import artistQueries from 'containers/Artist/queries';
import workQueries from 'containers/Work/queries';
import orgQueries from 'containers/Organizations/queries';
import accountsQueries from 'containers/Accounts/queries';
import managerQueries from 'containers/Managers/queries';

import {
  DATE_FORMATS,
  PRODUCTION_VALIDITY_IDENTIFIERS,
  AGGREGATION_ON,
  LOCATION_TYPE,
  UPCOMING_PRODUCTIONS_MEDIA_SORT_ORDER,
  PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
  SORT_OPTION_VALUES,
  ENTITY_DETAILS_TAB,
  PROFILE_TYPES,
  REPERTOIRE_SECTION_TYPES,
  PRIVACY_ATTRIBUTE_IDS,
  MEDIA_SHOWREEL_OPTIONS,
  SEARCH_ENTITY_MAP,
  SORT_OPTION_TYPES,
} from 'constants/index';

import {
  transformUpcomingPerformanceFilters,
  transformPastDateFilters,
  transformUpcomingDateFilters,
} from 'utils/globals/filters';
import { getBaseSeasonFilters } from 'utils/productions';

import { createDate } from 'utils/date';
import { ENTITY_TYPES } from 'operabase-router/constants';

const QUERIES = {
  ACCOUNTS: {
    GET_USER_DETAILS: () => ({
      queryFn: accountsQueries.getUserDetails,
      isEnabled: ({ extras }) => !!extras?.user?.id,
      getProps: ({ extras }) => ({
        id: extras?.user?.id,
      }),
    }),
    GET_USER_PERMISSIONS: () => ({
      queryFn: accountsQueries.getUserPermissions,
      isEnabled: ({ extras }) => !!extras?.user?.id,
      getProps: ({ extras }) => ({
        id: extras?.user?.id,
      }),
    }),
    GET_USER_SUBSCRIPTIONS: () => ({
      queryFn: accountsQueries.getUserSubscriptions,
      isEnabled: ({ extras }) => !!extras?.user?.id,
      getProps: ({ extras }) => ({
        id: extras?.user?.id,
      }),
    }),
    GET_USER_PROFILES: () => ({
      queryFn: accountsQueries.getUserProfiles,
      isEnabled: ({ extras }) => !!extras?.user?.id,
      getProps: ({ extras }) => ({
        id: extras?.user?.id,
      }),
    }),
    GET_ACTIVE_PROFILE_SUBSCRIPTIONS: () => ({
      queryFn: accountsQueries.getTargetProfileSubscriptions,
      isEnabled: ({ extras }) => !!extras?.profileData?.id,
      getProps: ({ extras }) => ({
        id: extras?.profileData?.id,
        activeProfile: extras?.activeProfile,
      }),
    }),
  },
  GLOBAL: {
    GET_ENTITY_DETAILS: ({ entityType, entityId, showApprovedHidden, asEdit, program_published, queryConfig }) => {
      let fetchEntityDetails = null;

      switch (entityType) {
        case ENTITY_TYPES.PROFILE:
          fetchEntityDetails = artistQueries.getArtistDetails;
          break;
        case ENTITY_TYPES.ORGANIZATION:
          fetchEntityDetails = orgQueries.getOrganizationDetails;
          break;
        case ENTITY_TYPES.WORK:
          fetchEntityDetails = workQueries.getWorkDetails;
          break;
        case ENTITY_TYPES.AGENCY:
          fetchEntityDetails = managerQueries.getManagerDetails;
          break;
        case ENTITY_TYPES.PRODUCTION:
          fetchEntityDetails = productionQueries.getProductionDetails;
          break;
        default: {
          fetchEntityDetails = () => ({});
          break;
        }
      }

      return {
        queryFn: fetchEntityDetails,
        getProps: () => ({
          id: entityId,
          showApprovedHidden,
          asEdit,
          program_published,
          queryConfig,
        }),
      };
    },
    GET_HOME_NUMBERS: () => ({
      queryFn: globalQueries.getHomeNumbers,
    }),
    GET_TESTIMONIALS: ({ limit }) => ({
      queryFn: globalQueries.getTestimonials,
      getProps: () => ({
        limit,
      }),
    }),
    GET_ENTITY_RECOMMENDATIONS: ({ limit, inline }) => ({
      queryFn: globalQueries.getEntityRecommendations,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        ...(!inline && { filters: { excludeIds: [context?.page?.entity?.id] } }),
        limit,
      }),
    }),
    GET_LOCATION: () => ({
      queryFn: globalQueries.getLocation,
      isEnabled: ({ context }) => !!context?.countrySlug,
      getProps: ({ context }) => ({
        type: LOCATION_TYPE.COUNTRY,
        filters: {
          slug: context?.countrySlug,
        },
      }),
    }),
    GET_COMPOSER_LIST: ({ query }) => ({
      queryFn: globalQueries.getComposerList,
      getProps: () => ({
        filters: {
          query,
        },
        page: 1,
      }),
    }),
    GET_GLOBAL_SEARCH_SUGGESTIONS: ({ limit = 16 }) => ({
      queryFn: globalQueries.getGlobalSearchSuggestions,
      getProps: ({ context }) => ({
        filters: {
          ...context?.filterParams,
          entity_type: SEARCH_ENTITY_MAP[context?.page?.entityType],
        },
        limit,
      }),
    }),
    // TODO: FB-000000 Add tests for GET_PROFILE_VIEWED query
    GET_PROFILE_VIEWED: ({ limit = 10 }) => ({
      queryFn: globalQueries.getProfilesViewed,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        filters: {
          excludeIds: [context?.page?.entity?.id],
        },
        limit,
      }),
    }),
  },
  ARTISTS: {
    GET_PROFESSION_DETAILS: ({ slug }) => ({
      queryFn: artistQueries.getProfessionDetails,
      getProps: () => ({
        filters: {
          slug,
        },
      }),
    }),
    GET_ARTISTS: ({ filters, limit, infinite }) => ({
      queryFn: artistQueries.getArtists,
      getProps: () => ({
        filters,
        limit,
      }),
      ...(infinite && { infinite: true }),
    }),
    GET_FEATURED: ({ limit }) => ({
      queryFn: artistQueries.getArtists,
      getProps: () => ({
        filters: {
          sort: 'random',
          tier: 'A',
        },
        limit,
      }),
    }),
    GET_PROFESSION_OPTIONS: () => ({
      queryFn: artistQueries.getProfessionOptions,
    }),
    GET_ALL_PROFESSIONS_LIST: () => ({
      queryFn: artistQueries.getArtistProfessions,
    }),
    GET_TRENDING_ARTISTS: ({ filters = {}, limit }) => ({
      queryFn: artistQueries.getTrendingArtist,
      getProps: ({ context }) => ({
        filters: {
          ...context?.filterParams,
          ...filters,
        },
        limit,
      }),
      infinite: true,
    }),
    GET_PROFILE_PROFESSION_AGGREGATION: ({ value }) => ({
      queryFn: artistQueries.getProfileProfessionAggregation,
      getProps: () => ({
        filters: {
          aggregation_type: value,
        },
      }),
    }),
    GET_BIO: () => ({
      queryFn: globalQueries.getEntityBioDetails,
      getProps: ({ context }) => ({
        isEnabled: ({ extras }) => extras?.entity?.stats?.about?.exists,
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
      }),
    }),
    GET_PREFERRED_ENTITIES: ({ section }) => ({
      queryFn: artistQueries.getFeaturedEntities,
      isEnabled: ({ extras }) => {
        const isPaidProfile = extras?.entity?.subscriptionStatus === PROFILE_TYPES.PRO;

        return isPaidProfile && extras?.entity?.stats?.preferredEntities?.[section]?.visibility;
      },
      getProps: ({ context }) => ({
        id: context?.page?.entity?.id,
        filters: {
          section,
        },
      }),
    }),
    GET_CAREER_GRAPH: () => ({
      queryFn: artistQueries.getCompanyGraphDetails,
      getProps: ({ context }) => ({
        id: context?.page?.entity?.id,
        filters: {
          ...context?.filterParams,
          sort: SORT_OPTION_VALUES.YEAR_ASC,
        },
      }),
    }),
    GET_ROLE_GRAPH: () => ({
      queryFn: artistQueries.getRoleGraphDetails,
      getProps: ({ context }) => ({
        id: context?.page?.entity?.id,
        filters: {
          ...context?.filterParams,
          sort: SORT_OPTION_VALUES.YEAR_ASC,
        },
      }),
    }),
    GET_REPERTOIRE_DETAILS: ({ type, limit, overview, isCastingProfile, sort }) => ({
      queryFn: artistQueries.getRepertoireDetails,
      isEnabled: ({ extras }) => {
        const isPaidProfile = extras?.entity?.subscriptionStatus === PROFILE_TYPES.PRO;
        const stats = extras?.entity?.stats;
        if (isCastingProfile) {
          return stats?.repertoire?.exists;
        }

        if (!isPaidProfile) {
          return false;
        }

        if (type === REPERTOIRE_SECTION_TYPES.FUTURE) {
          return stats.repertoire?.future?.exists && stats?.customizations?.futureRepertoire?.visibility;
        }

        return stats?.repertoire?.past?.exists && stats?.customizations?.repertoire?.visibility;
      },
      getProps: ({ context }) => ({
        id: context?.page?.entity?.id,
        type,
        filters: {
          ...context?.filterParams,
          ...(overview && { overview }),
          ...(sort && { sort }),
        },
        limit,
      }),
    }),
  },
  ORGANIZATIONS: {
    GET_CUSTOMER_LOGOS: ({ limit = 12 }) => ({
      queryFn: orgQueries.getCustomerLogos,
      getProps: ({ context }) => ({
        city_id: context?.location?.city?.id,
        country_id: context?.location?.country?.id,
        region_id: context?.location?.region?.id,
        limit,
      }),
    }),
    GET_ORGANIZATION_TYPES: () => ({
      queryFn: orgQueries.getAggregatedOrgTypes,
    }),
    GET_TRENDING_ORGANIZATIONS: ({ filters }) => ({
      queryFn: orgQueries.getTrendingOrganizations,
      getProps: ({ context }) => ({
        filters: filters || context?.filterParams,
        limit: 8,
      }),
      infinite: true,
    }),
    GET_ORGANIZATIONS: ({ filters, limit = 16, infinite = false }) => ({
      queryFn: orgQueries.getOrganizations,
      getProps: ({ context }) => ({
        filters: filters || context?.filterParams,
        limit,
      }),
      infinite,
    }),
    GET_TRANSLATIONS: () => ({
      queryFn: orgQueries.getTranslations,
      isEnabled: ({ extras }) => extras?.entity?.stats?.about?.exists,
      getProps: ({ context }) => ({
        id: context?.page?.entity?.id,
      }),
    }),
    GET_ASSOCIATED_ORGANIZATIONS: () => ({
      queryFn: orgQueries.getAssociatedOrganizations,
      getProps: ({ context }) => ({
        id: context?.page?.entity?.id,
      }),
    }),
  },
  PRODUCTIONS: {
    GET_RENTALS: ({ limit }) => ({
      queryFn: productionQueries.getRentals,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        filters: context?.filterParams,
        limit,
      }),
      infinite: true,
    }),
    GET_FEATURED: ({ limit, infinite = false }) => ({
      queryFn: productionQueries.getPerformances,
      getProps: () => {
        const today = createDate(new Date()).format(DATE_FORMATS.FULL_DATE);

        return {
          filters: {
            date_from: today,
            date_to: today,
            is_producer_approved: true,
            approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
            sort: SORT_OPTION_VALUES.YEAR_ASC,
          },
          limit,
        };
      },
      infinite,
    }),
    GET_COUNTRIES: ({ aggregationOn = AGGREGATION_ON.PRODUCTIONS, filters, limit = 300 }) => ({
      queryFn: productionQueries.getCountries,
      getProps: () => ({
        aggregationOn,
        filters,
        limit,
      }),
    }),
    GET_RENTAL_CONTACTS: () => ({
      queryFn: orgQueries.getProductionRentalContacts,
      isEnabled: ({ extras }) => extras?.entity?.rentals?.length > 0,
      getProps: ({ extras }) => ({
        entity: extras.entity,
      }),
    }),
    GET_DESCRIPTIONS: () => ({
      queryFn: productionQueries.getProductionDescriptions,
      isEnabled: ({ extras }) => extras?.entity?.stats?.about?.exists,
      getProps: ({ context }) => ({
        id: context?.page?.entity?.id,
      }),
    }),
    GET_UPCOMING_PRODUCTIONS: ({ limit, infinite }) => ({
      queryFn: productionQueries.getProductions,
      isEnabled: ({ extras }) => extras?.entity?.stats?.productions?.future?.exists,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        limit,
        filters: transformUpcomingPerformanceFilters(context?.filterParams),
      }),
      infinite,
    }),
    GET_SEASON_PRODUCTIONS: ({ limit = 10, upcoming }) => ({
      queryFn: productionQueries.getProductions,
      isEnabled: ({ context, extras }) => {
        if (extras?.isTrustedBot) {
          return true;
        }

        const { filterTypeUsage } = context;

        return !filterTypeUsage?.limitExceeded;
      },
      getProps: ({ context, extras }) => {
        const { entityType, mainPath, subPath, filterParams } = context;
        const { entity, selectedSeasonKey } = extras;
        const isUpcomingProduction =
          upcoming || (mainPath && subPath !== ENTITY_DETAILS_TAB.PAST && !filterParams?.season_id?.[0]);
        const baseFilters = getBaseSeasonFilters({ upcoming: isUpcomingProduction, entityType, entity });

        return {
          entityType,
          entityId: entity?.id,
          filters: {
            ...baseFilters,
            ...filterParams,
            ...(selectedSeasonKey && { season_id: selectedSeasonKey }),
          },
          limit,
        };
      },
      infinite: true,
    }),
    GET_SEASON_PERFORMANCES: ({ limit = 15 }) => ({
      queryFn: productionQueries.getPerformances,
      isEnabled: ({ context, extras }) => {
        if (extras?.isTrustedBot) {
          return extras?.entity?.stats?.performances?.future?.exists;
        }

        const { filterTypeUsage } = context;
        return extras?.entity?.stats?.performances?.future?.exists && !filterTypeUsage?.limitExceeded;
      },
      getProps: ({ context, extras }) => {
        const { entityType, subPath, filterParams } = context;
        const { entity, selectedSeasonKey } = extras;
        const isUpcomingProduction = subPath !== ENTITY_DETAILS_TAB.PAST && !filterParams?.season_id?.[0];
        const baseFilters = getBaseSeasonFilters({ upcoming: isUpcomingProduction, entityType, entity });

        return {
          entityType,
          entityId: entity?.id,
          filters: {
            ...baseFilters,
            ...filterParams,
            ...(selectedSeasonKey && { season_id: selectedSeasonKey }),
          },
          limit,
        };
      },
      infinite: true,
    }),
    GET_MEDIA_PAST_PRODUCTIONS: ({ limit, mediaLimit }) => ({
      queryFn: productionQueries.getProductions,
      isEnabled: ({ extras }) =>
        extras?.entity?.stats?.productions?.past?.exists &&
        extras?.entity?.stats?.media?.images?.production?.past?.exists,
      getProps: ({ context }) => {
        const baseFilters = transformPastDateFilters();

        return {
          entityType: context?.page?.entityType,
          entityId: context?.page?.entity?.id,
          filters: {
            ...baseFilters,
            ...context?.filterParams,
            has_media: true,
            is_cancelled: false,
            mediaLimit,
            sort: SORT_OPTION_TYPES.YEAR_DESC.value,
            ctx_entity:
              context?.page?.entityType === ENTITY_TYPES.PROFILE
                ? `a${context?.page?.entity?.id}`
                : `o${context?.page?.entity?.id}`, // NOTE: we will be moving this to filter params in operabase-router
          },
          limit,
        };
      },
      infinite: true,
    }),
    GET_PRODUCTION_CREDITS: ({ limit = 5, sort, params = {}, enabled = false }) => ({
      queryFn: productionQueries.getProductions,
      isEnabled: ({ extras }) => enabled || extras?.entity?.stats?.productions?.exists,
      getProps: ({ context }) => {
        const { filterParams, page } = context;
        const { date_to: _dateTo, ...restFilters } = getBaseSeasonFilters({
          dateTo: filterParams?.date_to,
          dateFrom: filterParams?.date_from,
          entityType: page?.entityType,
          entity: page?.entity,
          sort,
        });
        return {
          entityType: context?.page?.entityType,
          entityId: context?.page?.entity?.id,
          filters: {
            ...filterParams,
            ...restFilters,
            ...params,
          },
          limit,
        };
      },
      infinite: true,
    }),
  },
  MEDIA: {
    GET_MEDIA_SUGGESTIONS: () => ({
      queryFn: mediaQueries.getMediaSuggestions,
      isEnabled: ({ extras, context }) => {
        if (context?.page?.entityType === ENTITY_TYPES.PRODUCTION) {
          return extras?.entity?.stats?.media?.exists;
        }

        return !context?.mainPath && extras?.entity?.stats?.media?.widget?.exists;
      },
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
      }),
    }),
    GET_DIGITAL_VIDEOS: ({ limit, sort }) => ({
      queryFn: mediaQueries.getDigitalVideos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.videos?.digital?.exists,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        filters: {
          ...context?.filterParams,
          sort,
        },
        limit,
      }),
      infinite: true,
    }),
    GET_FEATURED_VIDEOS: ({ limit, infinite, enabled = true }) => ({
      queryFn: mediaQueries.getFeaturedVideos,
      isEnabled: ({ extras }) => !!enabled || extras?.entity?.stats?.media?.videos?.featured?.exists,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        filters: context?.filterParams,
        limit,
      }),
      infinite,
    }),
    GET_PRODUCTION_PAST_VIDEOS: ({ distinctLimit }) => ({
      queryFn: mediaQueries.getProductionVideos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.videos?.production?.past?.exists,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        filters: transformPastDateFilters(context?.filterParams),
        distinctLimit,
        sort: PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
      }),
      infinite: true,
    }),
    GET_PRODUCTION_UPCOMING_VIDEOS: ({ distinctLimit }) => ({
      queryFn: mediaQueries.getProductionVideos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.videos?.production?.future?.exists,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        filters: transformUpcomingDateFilters(context?.filterParams),
        distinctLimit,
        sort: UPCOMING_PRODUCTIONS_MEDIA_SORT_ORDER,
      }),
      infinite: true,
    }),
    GET_FEATURED_PHOTOS: ({ limit, infinite = true }) => ({
      queryFn: mediaQueries.getFeaturedPhotos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.images?.featured?.exists,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        filters: context?.filterParams,
        limit,
      }),
      infinite,
    }),
    GET_PRODUCTION_PAST_PHOTOS: ({ distinctLimit }) => ({
      queryFn: mediaQueries.getProductionPhotos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.images?.production?.past?.exists,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        filters: transformPastDateFilters(context?.filterParams),
        distinctLimit,
        sort: PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
      }),
      infinite: true,
    }),
    GET_PRODUCTION_UPCOMING_PHOTOS: ({ distinctLimit }) => ({
      queryFn: mediaQueries.getProductionPhotos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.images?.production?.future?.exists,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        filters: transformUpcomingDateFilters(context?.filterParams),
        distinctLimit,
        sort: UPCOMING_PRODUCTIONS_MEDIA_SORT_ORDER,
      }),
      infinite: true,
    }),
    GET_SHOWREEL_VIDEOS: ({ limit = 10 }) => ({
      queryFn: mediaQueries.getShowreelVideos,
      isEnabled: ({ extras }) =>
        extras?.entity?.stats?.showreels?.intro?.exists || extras?.entity?.stats?.showreels?.audition?.exists,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        ids: [MEDIA_SHOWREEL_OPTIONS.AUDITION.id, MEDIA_SHOWREEL_OPTIONS.INTRO.id],
        limit,
      }),
      infinite: true,
    }),
    GET_PRODUCTION_VIDEOS: ({ limit, infinite = true }) => ({
      queryFn: mediaQueries.getProductionVideos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.videos?.production?.exists,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        limit,
        filters: {
          ...context?.filterParams,
          sort: UPCOMING_PRODUCTIONS_MEDIA_SORT_ORDER,
        },
      }),
      infinite,
    }),
    GET_PRODUCTION_PHOTOS: ({ limit, infinite = true }) => ({
      queryFn: mediaQueries.getProductionPhotos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.images?.production?.exists,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        limit,
        filters: {
          ...context?.filterParams,
          sort: SORT_OPTION_VALUES.CUSTOM_DATE,
        },
      }),
      infinite,
    }),
  },
  REVIEWS: {
    GET_FEATURED_REVIEWS: ({ limit, infinite = false }) => ({
      queryFn: reviewQueries.getFeaturedReviews,
      isEnabled: ({ extras }) => extras?.entity?.stats?.reviews?.featured?.exists,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        filters: context?.filterParams,
        limit,
      }),
      infinite,
    }),
    GET_PRODUCTION_REVIEWS: ({ limit, infinite = true }) => ({
      queryFn: reviewQueries.getProductionReviews,
      isEnabled: ({ extras }) => extras?.entity?.stats?.reviews?.production?.exists,
      getProps: ({ context }) => ({
        entityType: context?.page?.entityType,
        entityId: context?.page?.entity?.id,
        filters: context?.filterParams,
        limit,
      }),
      infinite,
    }),
  },
  MANAGERS: {
    GET_AGENCIES: ({ enabled = true }) => ({
      queryFn: managerQueries.getEntityManagerRepresentations,
      isEnabled: ({ extras }) => enabled && extras?.entity?.stats?.agencies?.exists,
      getProps: ({ context }) => ({
        entityId: context?.page?.entity?.id,
        entityType: context?.page?.entityType,
        filters: {
          privacy_attribute_id: [
            PRIVACY_ATTRIBUTE_IDS.PUBLIC,
            PRIVACY_ATTRIBUTE_IDS.CASTING_TOOL,
            PRIVACY_ATTRIBUTE_IDS.AGENCY,
            PRIVACY_ATTRIBUTE_IDS.AOS,
            PRIVACY_ATTRIBUTE_IDS.ARTIST,
            PRIVACY_ATTRIBUTE_IDS.FREE_AND_PRO,
          ],
        },
      }),
    }),
  },
};

export default QUERIES;
