import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import _isEmpty from 'lodash-es/isEmpty';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { Blurhash } from 'react-blurhash';
import useTracking from 'components/Globals/Analytics';
import MediaAttribution from 'components/Media/MediaAttribution';

import { getCloudinaryURL, getRenderDimensions } from 'utils/media';

import classes from './Image.module.scss';

const NextImage = dynamic(() => import('next/image'));
const PreloadImage = dynamic(() => import('../PreloadImage'));

const customCloudinaryLoader = ({ transformations, disableCloudinary }) => ({ src, quality }) => {
  if (disableCloudinary) {
    return src;
  }

  const { radius: _radius, ...restTransformations } = transformations || {};

  return getCloudinaryURL({
    src,
    transformations: {
      ...(restTransformations && restTransformations),
      quality: quality ?? transformations?.quality ?? 'auto',
    },
  })?.fallback;
};

const PictureTag = ({
  src,
  srcSet,
  alt,
  width,
  height,
  lazy,
  useIntersectionObserver = false,
  preload = false,
  fitWidth = false,
  attribution,
  attributionClassName,
  ...props
}) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(() => !useIntersectionObserver || preload);

  const handleObserver = useCallback(
    entries => {
      const [target] = entries;

      if (target?.isIntersecting && !isVisible) {
        setIsVisible(true);
      }
    },
    [isVisible],
  );

  useEffect(() => {
    let observer;
    const element = ref.current;

    if (useIntersectionObserver && !preload) {
      const option = { threshold: 0 };
      observer = new IntersectionObserver(handleObserver, option);
      observer.observe(element);
    }

    return () => {
      if (observer) {
        observer.unobserve(element);
      }
    };
  }, [preload, handleObserver, useIntersectionObserver]);

  const hasValidAttribution = !!attribution?.source_info || !!attribution?.credit || !!attribution?.copyright;

  return (
    <>
      {preload && <PreloadImage srcSet={srcSet} />}
      {useIntersectionObserver && !preload && !isVisible && (
        <noscript>
          <img src={src} alt={alt} width={width} height={height} loading="lazy" {...props} />
        </noscript>
      )}
      <picture
        className={classnames(classes.pictureTag, {
          [classes.fitWidth]: fitWidth,
          [classes.pictureWithAttribution]: hasValidAttribution,
        })}
        ref={ref}
      >
        {srcSet &&
          srcSet?.map(({ path, type, width: srcWidth }, index) => (
            <source
              key={`${index}_${type}`}
              srcSet={path}
              type={type}
              {...(srcWidth ? { media: `(max-width:${srcWidth}px)` } : {})}
            />
          ))}
        {isVisible && (
          <img src={src} alt={alt} width={width} height={height} loading={lazy ? 'lazy' : 'eager'} {...props} />
        )}
        {hasValidAttribution && (
          <MediaAttribution
            className={classnames(classes.mediaAttribution, { [attributionClassName]: !!attributionClassName })}
            attribution={attribution}
          />
        )}
      </picture>
    </>
  );
};

const ImageElement = ({
  blurHash,
  alt,
  src,
  srcSet,
  height,
  width,
  lazy = true,
  useIntersectionObserver = false,
  fitWidth = false,
  attribution,
  attributionClassName,
  ...props
}) => {
  const showBlurHash = !!(blurHash && width && height);

  const pictureTag = (
    <PictureTag
      src={src}
      srcSet={srcSet}
      alt={alt}
      width={width}
      height={height}
      lazy={lazy}
      useIntersectionObserver={useIntersectionObserver}
      fitWidth={fitWidth}
      attribution={attribution}
      attributionClassName={attributionClassName}
      {...props}
    />
  );

  if (showBlurHash) {
    return (
      <div className={classnames(classes.blurHashWrapper, { [classes.fitWidth]: fitWidth })}>
        {showBlurHash && <Blurhash hash={blurHash} width="100%" height="100%" />}
        {pictureTag}
      </div>
    );
  }

  return pictureTag;
};

const Image = ({
  src,
  alt = '',
  width,
  height,
  minWidth,
  minHeight,
  aspectRatio,
  blurHash,
  transformations: initialTransformations,
  disableCloudinary = false,
  disableNextImage = false,
  lazy,
  useIntersectionObserver = false,
  fitWidth,
  onClick,
  trackingData,
  attribution,
  attributionClassName,
  ...rest
}) => {
  const track = useTracking();
  const { imageWidth, imageHeight, renderHeight, renderWidth } = useMemo(() => {
    const transformHeight = initialTransformations?.resize?.height || height;
    const transformWidth = initialTransformations?.resize?.width || width;
    const actualHeight = height || initialTransformations?.resize?.height;
    const actualWidth = width || initialTransformations?.resize?.width;

    const renderAspectRatio = aspectRatio || (transformWidth && transformHeight && transformWidth / transformHeight);

    const renderDimensions = getRenderDimensions({
      width:
        width ||
        (actualHeight && renderAspectRatio && actualHeight * renderAspectRatio) ||
        (renderAspectRatio && actualHeight && actualHeight * renderAspectRatio),
      height:
        height ||
        (actualWidth && renderAspectRatio && actualWidth / renderAspectRatio) ||
        (renderAspectRatio && actualWidth && actualWidth / renderAspectRatio),
      minWidth,
      minHeight,
      aspectRatio: renderAspectRatio,
    });

    return {
      imageWidth: transformWidth || (transformHeight && renderAspectRatio && transformHeight * renderAspectRatio),
      imageHeight: transformHeight || (transformWidth && renderAspectRatio && transformWidth / renderAspectRatio),
      renderHeight: renderDimensions?.height,
      renderWidth: renderDimensions?.width,
    };
  }, [initialTransformations, height, width, minWidth, minHeight, aspectRatio]);

  const transformations = useMemo(
    () => ({
      quality: 'auto',
      ...(initialTransformations && initialTransformations),
      resize: {
        ...(initialTransformations?.resize || {}),
        ...(imageWidth && { width: Math.ceil(imageWidth) }),
        ...(imageHeight && { height: Math.ceil(imageHeight) }),
      },
    }),
    [initialTransformations, imageWidth, imageHeight],
  );

  const loader = useMemo(
    () =>
      customCloudinaryLoader({
        transformations,
        disableCloudinary,
        width: imageWidth,
        height: imageHeight,
      }),
    [transformations, disableCloudinary, imageWidth, imageHeight],
  );

  const style = useMemo(() => {
    if (transformations?.radius > 0) {
      return {
        borderRadius: transformations?.radius,
      };
    }

    return null;
  }, [transformations]);

  const handleClick = e => {
    if (onClick) {
      onClick(e);
    }
    if (!_isEmpty(trackingData)) {
      track.click({ ...trackingData, meta: { ...trackingData.meta, src } });
    }
  };

  if (!src || typeof src !== 'string') {
    return null;
  }

  if (disableNextImage || !renderWidth || !renderHeight) {
    let imageSrc = src;
    let srcSet = null;

    if (!disableCloudinary) {
      const { fallback, sources } = getCloudinaryURL({ src, transformations });
      imageSrc = fallback;
      srcSet = sources;
    }

    const dimensions = {};

    if (renderWidth) {
      dimensions.width = renderWidth;
    }
    if (renderHeight) {
      dimensions.height = renderHeight;
    }

    return (
      <ImageElement
        src={imageSrc}
        srcSet={srcSet}
        alt={alt}
        blurHash={blurHash}
        lazy={lazy}
        useIntersectionObserver={useIntersectionObserver}
        fitWidth={fitWidth}
        onClick={handleClick}
        attribution={attribution}
        attributionClassName={attributionClassName}
        {...(style ? { style } : {})}
        {...dimensions}
        {...rest}
      />
    );
  }

  return (
    <NextImage
      src={src}
      alt={alt}
      loader={loader}
      width={renderWidth}
      height={renderHeight}
      onClick={handleClick}
      {...(style ? { style } : {})}
      {...rest}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string,
};

export default Image;
