import { getComposerFromCreators } from 'utils/composer';

import { ENTITY_TYPES } from 'operabase-router/constants';

export const transformGlobalSearch = response => {
  if (!response?.data?.length) return [];

  return response.data.map(
    ({
      entityType,
      name,
      slug,
      id,
      professions,
      organizationType,
      city,
      country,
      state,
      original_name,
      workType,
      creators,
      personal,
    }) => {
      const baseEntity = { name, slug, id, entityType };

      switch (entityType) {
        case ENTITY_TYPES.PROFILE:
          return {
            ...baseEntity,
            headline: professions?.map(({ profession }) => profession?.name).join(', '),
            personal,
          };

        case ENTITY_TYPES.ORGANIZATION:
          return {
            ...baseEntity,
            organizationType,
            city,
            country,
          };

        case 'venue':
          return {
            ...baseEntity,
            entityType: ENTITY_TYPES.ORGANIZATION,
            organizationType,
            city,
            country,
          };

        case ENTITY_TYPES.CITY:
          return { ...baseEntity, state, country };

        case ENTITY_TYPES.WORK:
          return {
            ...baseEntity,
            original_name,
            workType,
            composer: getComposerFromCreators(creators)?.profile,
          };

        default:
          return baseEntity;
      }
    },
  );
};
