const { ENTITY_TYPES, ENTITY_TYPE_ID_PREFIX, OPERATORS, REGULAR_EXPRESSIONS } = require('../constants');

const getEntityIdWithPrefix = ({
  entity,
  entityType,
  format,
  city,
  operator,
  skipPrefix = false,
  forcePrefix = false,
} = {}) => {
  if (!entity?.id) {
    return {};
  }

  let prefix = null;

  if (
    forcePrefix ||
    (!skipPrefix && ![ENTITY_TYPES.PRODUCTION, ENTITY_TYPES.WORK, ENTITY_TYPES.WORK_TYPE].includes(entityType))
  ) {
    prefix = ENTITY_TYPE_ID_PREFIX[entityType];
  }

  const { id } = entity;

  let entityId = id;

  if (ENTITY_TYPES.WORK_TYPE === entityType && format?.id) {
    entityId += `${OPERATORS.CONCAT}${format?.id}`;
  } else if (ENTITY_TYPES.COUNTRY === entityType && city?.id) {
    entityId += `${OPERATORS.CONCAT}${city?.id}`;
  }

  if (prefix && !REGULAR_EXPRESSIONS.PREFIXED_ID.test(entityId)) {
    return { operator, id: `${prefix}${entityId}` };
  }

  return { operator, id: entityId };
};

module.exports = getEntityIdWithPrefix;
