import mapValues from 'lodash/mapValues';
import invert from 'lodash/invert';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';

import { createDate } from 'utils/date';

import {
  TP,
  DATE_FORMATS,
  ENTITY_MAIN_TABS,
  URL_SLUG_ORDER,
  URL_SLUG_TYPES,
  VIEW_MODES_VALUE,
  SORT_OPTION_TYPES,
  URL_SLUG_PREFIXES,
  BOOLEAN_OPERATOR_REGEX,
  BOOLEAN_OPERATORS_VALUES,
  BOOLEAN_SEARCH_PARTS,
  CASTING_TOOL_SLUGS,
} from 'constants/index';

import {
  TAB_FILTERS,
  ENTITY_TAB_FILTERS,
  FILTER_LABELS,
  FILTER_SLUG_TYPE,
  FILTER_DEFAULT_EXPANDED,
  TAB_H1_FILTER_PARTS,
  VALID_VIEW_MODE_OPTIONS,
  ADVANCED_FILTER_TYPES,
  ENTITY_LISTING_FILTERS,
} from 'constants/filters';

import {
  FILTER_TYPES,
  ENTITY_TYPES,
  ENTITY_TYPE_ID_PREFIX,
  ATTRIBUTE_TYPES,
  OPERATORS,
  ENTITY_DETAILS_TABS,
  FILTER_GROUP,
  REGULAR_EXPRESSIONS,
} from 'operabase-router/constants';
import getEntityIdWithPrefix from 'operabase-router/utils/getEntityIdWithPrefix';

const getEntitySlug = ({ entity, slugType }) => {
  const { slug, id } = entity || {};
  const slugPrefix = URL_SLUG_PREFIXES.find(item => item.type === slugType)?.prefix || '';

  let pathSlug = '';

  if (slug && slugType !== URL_SLUG_TYPES.SEASON) {
    pathSlug += slug;
  }

  if ([URL_SLUG_TYPES.ORGANIZATION_TYPE, URL_SLUG_TYPES.PROFESSION].includes(slugType)) {
    return pathSlug;
  }

  if (id) {
    if (pathSlug?.length > 0) {
      pathSlug += '-';
    }

    if (slugPrefix) {
      pathSlug += slugPrefix;
    }

    pathSlug += id;
  }

  return pathSlug;
};

export const getCustomDateFilter = ({ dateFrom, dateTo }) => {
  const today = createDate();
  const dateFromMoment = createDate(dateFrom);
  const dateToMoment = createDate(dateTo);

  if (dateFrom === dateTo) {
    const formattedToday = today.format(DATE_FORMATS.FULL_DATE);
    if (dateFrom === formattedToday) {
      return {
        id: formattedToday,
        label: `${TP}.m_TODAY`,
      };
    }

    const formattedTomorrow = today.add(1, 'day').format(DATE_FORMATS.FULL_DATE);
    if (dateFrom === formattedTomorrow) {
      return {
        id: formattedTomorrow,
        label: `${TP}.m_TOMORROW`,
      };
    }
  }

  if (dateFromMoment.format(DATE_FORMATS.YEAR) === dateToMoment.format(DATE_FORMATS.YEAR)) {
    if (dateFromMoment.format(DATE_FORMATS.SHORT_MONTH) === dateToMoment.format(DATE_FORMATS.SHORT_MONTH)) {
      const startOfMonth = dateFromMoment.startOf('month').format(DATE_FORMATS.FULL_DATE);
      const endOfMonth = dateFromMoment.endOf('month').format(DATE_FORMATS.FULL_DATE);

      if (startOfMonth === dateFrom && endOfMonth === dateTo) {
        return {
          id: `${dateFrom}-${dateTo}`,
          label: dateFromMoment.format(DATE_FORMATS.MONTH_YEAR),
        };
      }

      return {
        id: `${dateFrom}-${dateTo}`,
        label: `${dateFromMoment.format(DATE_FORMATS.DATE)} - ${dateToMoment.format(DATE_FORMATS.DATE_LABEL)}`,
      };
    }

    const startOfYear = dateFromMoment.startOf('year').format(DATE_FORMATS.FULL_DATE);
    const endOfYear = dateFromMoment.endOf('year').format(DATE_FORMATS.FULL_DATE);

    if (startOfYear === dateFrom && endOfYear === dateTo) {
      return {
        id: `${dateFrom}-${dateTo}`,
        label: dateFromMoment.format(DATE_FORMATS.YEAR),
      };
    }
  }

  return {
    id: `${dateFrom}-${dateTo}`,
    label: `${dateFromMoment.format(DATE_FORMATS.DATE_LABEL)} - ${dateToMoment.format(DATE_FORMATS.DATE_LABEL)}`,
  };
};

export const getContextualFilters = ({ entityId, entityType, tabKey = ENTITY_MAIN_TABS.HOME }) => {
  if (!entityType) {
    return [];
  }

  if (!entityId) {
    return ENTITY_LISTING_FILTERS?.[entityType] || [];
  }

  const validFilters = ENTITY_TAB_FILTERS?.[entityType] || TAB_FILTERS;

  return validFilters[tabKey] || [];
};

export const getFilterDetails = type => {
  const slugType = FILTER_SLUG_TYPE[type];
  if (!slugType) {
    return {};
  }

  return {
    label: FILTER_LABELS[type],
    slugType,
    order: URL_SLUG_ORDER.findIndex(urlSlugType => urlSlugType === slugType),
    queryParam: FILTER_SLUG_TYPE[type],
    isDefaultExpanded: FILTER_DEFAULT_EXPANDED.includes(type),
  };
};

const canURLPathInclude = ({ order, values, slugType, entityType, entityId }) => {
  const hasOrder = order >= 0;
  const isArrayOfOneItem = Array.isArray(values) && values?.length === 1;
  const hasId = !!values?.[0]?.id;
  const isArtistIndexPage = entityType === ENTITY_TYPES.PROFILE && !entityId;
  const isOrganizationIndexPage = entityType === ENTITY_TYPES.ORGANIZATION && !entityId;

  if (URL_SLUG_TYPES.PROFESSION === slugType) {
    if (isArtistIndexPage) {
      return hasOrder && isArrayOfOneItem;
    }

    return false;
  }

  if (URL_SLUG_TYPES.ORGANIZATION_TYPE === slugType) {
    if (isOrganizationIndexPage) {
      return hasOrder && isArrayOfOneItem;
    }

    return false;
  }

  return hasOrder && isArrayOfOneItem && hasId;
};

export const getFilterURLParams = ({
  entityType,
  entityId,
  filters,
  path = '',
  queryParams: initialQueryParams = {},
}) => {
  const queryTypeMap = invert(FILTER_SLUG_TYPE);
  let pathParams = {};
  let queryParams = initialQueryParams;
  let newPath = path;

  mapValues(filters, (values, key) => {
    const filterType = queryTypeMap[key];
    const filterDetails = getFilterDetails(filterType);

    if (!filterDetails) {
      return;
    }

    const { order } = filterDetails;

    if (filterType === FILTER_TYPES.DATE) {
      const dateTo = filters?.[key]?.[`${key}_to`];
      const dateFrom = filters?.[key]?.[`${key}_from`];

      const startOfMonth = createDate(dateFrom).startOf('month');
      const endOfMonth = createDate(dateTo).endOf('month');

      if (dateTo === dateFrom || (!dateTo && dateFrom) || (dateTo && !dateFrom)) {
        pathParams = {
          ...pathParams,
          [order]: createDate(dateFrom || dateTo)
            .locale('en')
            .format(DATE_FORMATS.URL_FULL_DATE)
            .toLowerCase(),
        };
      } else if (
        startOfMonth.format(DATE_FORMATS.FULL_DATE) === dateFrom &&
        endOfMonth.format(DATE_FORMATS.FULL_DATE) === dateTo
      ) {
        if (startOfMonth.format(DATE_FORMATS.SHORT_MONTH) === endOfMonth.format(DATE_FORMATS.SHORT_MONTH)) {
          pathParams = {
            ...pathParams,
            [order]: startOfMonth.format(DATE_FORMATS.URL_MONTH_YEAR).toLowerCase(),
          };
        } else if (startOfMonth.format(DATE_FORMATS.YEAR) === endOfMonth.format(DATE_FORMATS.YEAR)) {
          pathParams = {
            ...pathParams,
            [order]: `${startOfMonth.format(DATE_FORMATS.YEAR)}`,
          };
        } else {
          queryParams = {
            ...queryParams,
            ...values,
          };
        }
      } else {
        queryParams = {
          ...queryParams,
          ...values,
        };
      }
    } else if (filterType === FILTER_TYPES.VIEW_MODE) {
      if (values === VIEW_MODES_VALUE.CALENDAR && path?.length > 0) {
        pathParams = {
          ...pathParams,
          [order]: values,
        };
      } else {
        queryParams = {
          ...queryParams,
          [key]: values,
        };
      }
    } else if (canURLPathInclude({ order, values, slugType: filterDetails.slugType, entityType, entityId })) {
      pathParams = {
        ...pathParams,
        [order]: getEntitySlug({
          entity: values?.[0],
          slugType: filterDetails.slugType,
        }),
      };
    } else if (values !== null && values !== undefined && values !== '') {
      queryParams = {
        ...queryParams,
        [key]: Array.isArray(values) ? values?.map(value => value?.id || value) : values,
      };
    }
  });

  const pathParamsOrder = Object.keys(pathParams).sort();

  if (pathParamsOrder?.length > 0) {
    const paths = pathParamsOrder
      .sort((order1, order2) => order1 - order2)
      .map(key => pathParams[key])
      .join('/');

    if (newPath?.length > 0) {
      newPath += '/';
    }

    newPath += `${paths}`;
  }

  return {
    path: newPath?.toLowerCase(),
    queryParams,
  };
};

export const getTitleFilterParts = ({ filters, tab }) => {
  if (isEmpty(filters)) {
    return '';
  }

  const queryTypeMap = invert(FILTER_SLUG_TYPE);
  let filterPrefixes = {};

  mapValues(filters, (values, key) => {
    const filterType = queryTypeMap[key];

    if (!TAB_H1_FILTER_PARTS?.[tab?.key]?.includes(filterType)) {
      return;
    }

    const filterDetails = getFilterDetails(filterType);

    if (!filterDetails) {
      return;
    }

    const { order } = filterDetails;

    if (filterType === FILTER_TYPES.DATE) {
      const dateTo = filters?.[key]?.[`${key}_to`];
      const dateFrom = filters?.[key]?.[`${key}_from`];

      const startOfMonth = createDate(dateFrom).startOf('month');
      const endOfMonth = createDate(dateTo).endOf('month');

      let dateLabel = '';

      if (
        startOfMonth.format(DATE_FORMATS.FULL_DATE) === dateFrom &&
        endOfMonth.format(DATE_FORMATS.FULL_DATE) === dateTo
      ) {
        dateLabel = `${startOfMonth.format(DATE_FORMATS.YEAR)} ${startOfMonth.format('MMMM')}`;
      } else if (dateTo === dateFrom) {
        dateLabel = `${createDate(dateFrom).format(DATE_FORMATS.DATE_LABEL)}`;
      } else {
        dateLabel = `${createDate(dateFrom).format(DATE_FORMATS.DATE_LABEL)} - ${createDate(dateTo).format(
          DATE_FORMATS.DATE_LABEL,
        )}`;
      }

      if (dateLabel) {
        filterPrefixes = {
          ...filterPrefixes,
          [order]: dateLabel,
        };
      }
    } else if (order >= 0 && Array.isArray(values) && values?.length === 1 && values?.[0]?.label) {
      filterPrefixes = {
        ...filterPrefixes,
        [order]: values?.[0]?.label,
      };
    }
  });

  const prefixOrder = Object.keys(filterPrefixes).sort();

  return prefixOrder
    .sort((order1, order2) => order1 - order2)
    .map(key => filterPrefixes?.[key])
    .join(', ');
};

export const getFiltersFromRouteContext = ({ validFilters, context, queryParams }) => {
  const filters = validFilters?.reduce((data, type) => {
    let appliedFilters = { ...data };
    const slugType = FILTER_SLUG_TYPE[type];

    if (type === FILTER_TYPES.DATE) {
      const dateFrom = queryParams?.[`${slugType}_from`];
      const dateTo = queryParams?.[`${slugType}_to`];

      const yearContext = context?.[URL_SLUG_TYPES.YEAR]?.[0]?.id;
      const monthYearContext = context?.[URL_SLUG_TYPES.MONTH_YEAR]?.[0]?.id;
      const dateContext = context?.[URL_SLUG_TYPES.DATE]?.[0]?.id;

      if (dateFrom && dateTo) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: { [`${slugType}_from`]: dateFrom, [`${slugType}_to`]: dateTo },
        };
      } else if (dateContext) {
        const selectedDate = createDate(dateContext);

        appliedFilters = {
          ...appliedFilters,
          [slugType]: {
            [`${slugType}_from`]: selectedDate.format(DATE_FORMATS.FULL_DATE),
            [`${slugType}_to`]: selectedDate.format(DATE_FORMATS.FULL_DATE),
          },
        };
      } else if (monthYearContext) {
        const selectedMonthYear = createDate(monthYearContext);

        appliedFilters = {
          ...appliedFilters,
          [slugType]: {
            [`${slugType}_from`]: selectedMonthYear.startOf('month').format(DATE_FORMATS.FULL_DATE),
            [`${slugType}_to`]: selectedMonthYear.endOf('month').format(DATE_FORMATS.FULL_DATE),
          },
        };
      } else if (yearContext) {
        const selectedYear = createDate(`${yearContext}`, DATE_FORMATS.YEAR);

        appliedFilters = {
          ...appliedFilters,
          [slugType]: {
            [`${slugType}_from`]: selectedYear.startOf('year').format(DATE_FORMATS.FULL_DATE),
            [`${slugType}_to`]: selectedYear.endOf('year').format(DATE_FORMATS.FULL_DATE),
          },
        };
      }
    } else if (FILTER_TYPES.PRODUCTION_PERIOD === type) {
      const contextFilters = context?.[slugType];

      if (contextFilters?.length > 0) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: contextFilters,
        };
      }
    } else if (FILTER_TYPES.VIEW_MODE === type) {
      const contextFilters = context?.[slugType];
      const viewMode = queryParams?.[slugType] || contextFilters?.[0]?.slug;

      if (VALID_VIEW_MODE_OPTIONS.includes(viewMode)) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: viewMode,
        };
      }
    } else if (FILTER_TYPES.CONTRIBUTION_EXPRESSION === type) {
      const booleanSearchQuery = queryParams?.[slugType];

      if (booleanSearchQuery?.length > 0) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: booleanSearchQuery,
        };
      }
    } else if (FILTER_TYPES.PAGE === type) {
      const page = Number.parseInt(queryParams?.[slugType], 10);

      if (page) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: page,
        };
      }
    } else if ([FILTER_TYPES.LETTER, FILTER_TYPES.QUERY].includes(type)) {
      const value = queryParams?.[slugType];

      if (value) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: value,
        };
      }
    } else {
      const value = context?.[slugType];
      let contextFilters = value;

      if (Array.isArray(value)) {
        contextFilters = value?.map(filter => {
          const id = filter?.id;

          return { id, slug: filter?.slug || id };
        });
      }

      if (contextFilters) {
        appliedFilters = {
          ...appliedFilters,
          [slugType]: contextFilters,
        };
      }
    }

    return appliedFilters;
  }, {});

  return filters;
};

export const transformFiltersToParams = filters => {
  if (!filters) {
    return {};
  }

  const transformedFilters = mapValues(filters, values => {
    if (Array.isArray(values)) {
      return values.map(({ id }) => id);
    }

    return values;
  });

  const { chorus_id, orchestra_id, company_id, festival_id, ...rest } = transformedFilters || {};
  const organization_id = [
    ...(chorus_id || []),
    ...(orchestra_id || []),
    ...(company_id || []),
    ...(festival_id || []),
  ];

  return {
    ...omitBy(omit(rest, [URL_SLUG_TYPES.DATE]), isNil),
    ...(rest?.[URL_SLUG_TYPES.DATE] || {}),
    ...(rest?.[URL_SLUG_TYPES.CREATOR]?.length > 0 && { work_creator_profession: 'composer' }),
    ...(organization_id?.length > 0 && { organization_id }),
  };
};

export const splitBooleanSearchQuery = (query = '') =>
  query
    .replace(BOOLEAN_OPERATOR_REGEX, '*$1*')
    .split('*')
    .reduce((acc, part) => {
      if (part?.length > 0) {
        acc.push({
          type: BOOLEAN_OPERATORS_VALUES.includes(part) ? BOOLEAN_SEARCH_PARTS.OPERATOR : BOOLEAN_SEARCH_PARTS.QUERY,
          value: part,
        });
      }

      return acc;
    }, []);

export const transformUpcomingDateFilters = filters => {
  const { date_from: dateFrom, date_to: dateTo, ...restFilters } = filters || {};
  const today = createDate().startOf('day');
  let dateFilters = {
    from: today,
  };

  if (dateFrom) {
    const selectedDate = createDate(dateFrom).startOf('day');

    if (selectedDate.isAfter(today)) {
      dateFilters = {
        ...dateFilters,
        from: selectedDate,
      };
    }
  }

  if (dateTo) {
    const selectedDate = createDate(dateTo).startOf('day');

    if (selectedDate.isBefore(dateFilters.from) || selectedDate.isSame(dateFilters.from)) {
      dateFilters = {
        ...dateFilters,
        to: selectedDate,
      };
    }
  }

  return {
    ...restFilters,
    date_from: dateFilters?.from?.format(DATE_FORMATS.FULL_DATE),
    ...(dateFilters?.to && { date_to: dateFilters?.to?.format(DATE_FORMATS.FULL_DATE) }),
  };
};

export const transformPastDateFilters = filters => {
  const { date_from: dateFrom, date_to: dateTo, ...restFilters } = filters || {};
  const yesterday = createDate()
    .subtract(1, 'day')
    .endOf('day');
  let dateFilters = {
    to: yesterday,
  };

  if (dateTo) {
    const selectedDate = createDate(dateTo).endOf('day');

    if (selectedDate.isBefore(yesterday)) {
      dateFilters = {
        ...dateFilters,
        to: selectedDate,
      };
    }
  }

  if (dateFrom) {
    const selectedDate = createDate(dateFrom).endOf('day');

    if (selectedDate.isBefore(dateFilters.to) || selectedDate.isSame(dateFilters.to)) {
      dateFilters = {
        ...dateFilters,
        from: selectedDate,
      };
    }
  }

  return {
    ...restFilters,
    date_from: dateFilters?.from?.format(DATE_FORMATS.FULL_DATE),
    ...(dateFilters?.to && {
      date_to: dateFilters?.to?.format(DATE_FORMATS.FULL_DATE),
    }),
  };
};

export const transformUpcomingPerformanceFilters = filters => ({
  ...transformUpcomingDateFilters(filters),
  sort: SORT_OPTION_TYPES.YEAR_ASC.value,
});

export const getH1FilterString = ({ filters, tabKey, separator = '/', asParts = false }) => {
  const filterTypes = TAB_H1_FILTER_PARTS?.[tabKey] || [];
  if (isEmpty(filters) || !filterTypes?.length) {
    return asParts ? [] : '';
  }

  const appliedFilters = filterTypes?.reduce((acc, type) => {
    const typeFilters = filters.find(filter => filter?.type === FILTER_SLUG_TYPE[type]);
    const items = typeFilters?.items || [];
    const { order } = getFilterDetails(type);
    if (order >= 0 && items?.length === 1) {
      acc[order] = items?.[0]?.name || items?.[0]?.id;
    }

    return acc;
  }, {});

  const prefixOrder = Object.keys(appliedFilters).sort();
  const filterParts = prefixOrder.sort((order1, order2) => order1 - order2).map(key => appliedFilters?.[key]);

  return asParts ? filterParts : filterParts.join(` ${separator} `);
};

export const getFilterUsage = ({ entityType, filters, isLoggedIn }) => {
  const queryTypeMap = invert(FILTER_SLUG_TYPE);

  const usage = Object.keys(filters).reduce(
    (acc, key) => {
      const filterType = queryTypeMap[key];
      const isAdvancedFilter = ADVANCED_FILTER_TYPES[entityType]?.includes(filterType);

      if (isAdvancedFilter) {
        acc.advancedFilterTypes += 1;
        acc.usedAdvancedFilters.push(key);
      } else if (![FILTER_TYPES.SEASON].includes(filterType)) {
        acc.freeFilterTypes += 1;
        acc.usedFreeFilters.push(key);
      }

      return acc;
    },
    {
      freeFilterTypes: 0,
      advancedFilterTypes: 0,
      usedFreeFilters: [],
      usedAdvancedFilters: [],
    },
  );

  let limitExceeded = false;

  if (isLoggedIn) {
    limitExceeded = usage.advancedFilterTypes > 1;
  } else {
    limitExceeded = usage.freeFilterTypes > 1;
  }

  const focusFilterTypeKey = usage?.usedAdvancedFilters?.[0] || usage?.usedFreeFilters?.[0] || null;

  return {
    ...usage,
    focusFilterType: focusFilterTypeKey ? queryTypeMap[focusFilterTypeKey] : null,
    limitExceeded,
  };
};

const idToNameMapper = items =>
  items?.reduce((acc, item) => {
    if (item.name && !acc[item?.id?.toString()]) {
      acc[item.id.toString()] = item.name;
    }
    return acc;
  }, {});

const transformContributionExpressionIntoString = ({ data }) => {
  if (!data || typeof data !== 'object') {
    return '';
  }

  return Object.keys(data)
    .reduce((acc, key) => {
      const { prefix, ...rest } = data[key];
      const options = Object.keys(rest).reduce((collection, category) => {
        // NOTE: In cases like 'producer', we can pass only category without values, e.g., x:producer:company
        if (Array.isArray(rest[category]) && rest[category].length > 0) {
          collection.push(`${category}|${rest[category].join(',')}`);
        } else {
          collection.push(category);
        }
        return collection;
      }, []);

      acc.push(`${prefix}:${key}:${options.join(';')}`);
      return acc;
    }, [])
    .join(';;');
};

const splitContributionExpression = ({ expression = '' }) => {
  const tempData = {};
  if (!expression) {
    return tempData;
  }

  expression.split(';;').forEach(segment => {
    if (segment && segment.includes(':')) {
      const [prefix, key, data] = segment.split(':');
      if (data) {
        tempData[key] = { prefix };

        // NOTE: below will be used in case of x:producer:company;individual|a123;festival|o3434;yap|-o123
        if (data.includes(';')) {
          data.split(';').forEach(categoryValuePair => {
            const [category, values] = categoryValuePair.split('|');
            tempData[key][category] = values ? values.split(',') : [];
          });
        } else {
          const [category, values] = data.split('|');
          tempData[key][category] = values ? values.split(',') : [];
        }
      }
    }
  });
  return tempData;
};

/**
 * NOTE: This function is used to remove the applied filter chip from the contribution expression
 */
export const removeAppliedContributionExpressionFilter = ({ appliedFilter, expression, subFilterType }) => {
  if (!subFilterType?.id) {
    return '';
  }

  /**
   * NOTE: Split the expression into an object
   * e.g., x:producer:company;individual|a123;
   * {
   *  producer: {
   *   prefix: 'x',
   *   company: ['a123'],
   * }
   */
  const tempData = splitContributionExpression({ expression }) || {};
  const preSelectedOptions = tempData[subFilterType.id];
  const { prefix: _, ...rest } = preSelectedOptions;
  const key = appliedFilter?.organizationType;
  const value = rest?.[key];

  if (value?.length) {
    /**
     * NOTE: Check if the applied filter is negated, if there is any negated filter, remove the entire key from the object
     * why so because allow user to remove all negated filters at once
     */
    const isNegated = value?.some(item => item?.startsWith(OPERATORS.FILTER_TYPE_NEGATED));
    if (isNegated) {
      delete preSelectedOptions[key];
    } else {
      const remainingOptions = value.filter(id => id !== appliedFilter?.id);
      if (remainingOptions.length) {
        tempData[subFilterType.id] = {
          ...preSelectedOptions,
          [key]: remainingOptions,
        };
        return transformContributionExpressionIntoString({ data: tempData });
      }
      // NOTE: if there are no remaining options, remove the key from the object
      delete preSelectedOptions[key];
    }
  } else if (rest?.[`-${key}`]) {
    // NOTE: If the applied filter is negated, remove the negated filter
    delete preSelectedOptions[`-${key}`];
  }

  const hasMoreFilterOptions = Object.keys(preSelectedOptions).length > 1;
  if (hasMoreFilterOptions) {
    tempData[subFilterType.id] = { ...preSelectedOptions };
  } else {
    delete tempData[subFilterType.id];
  }
  return transformContributionExpressionIntoString({ data: tempData });
};

export const transformGenreWithStageType = (genre, stageType, genreTypeFilter) => {
  const genreMap = idToNameMapper(genre?.items);
  const stageTypeMap = idToNameMapper(stageType?.items);

  return genreTypeFilter.map(item => {
    const [genreId, stageId] = item?.id?.toString().split('-');

    const genreName = genreMap?.[genreId] || '';
    const stageName = stageId ? stageTypeMap?.[stageId] || '' : '';

    const combinedName = stageName ? `${genreName}-${stageName}` : genreName;

    return {
      id: item.id,
      name: combinedName,
      slug: item.slug,
    };
  });
};

export const transformCountryCityFilter = (countries, cities, countryCityFilter) => {
  const countryMap = idToNameMapper(countries?.items);
  const cityMap = idToNameMapper(cities?.items);

  return countryCityFilter.map(item => {
    const [countryId, cityId] = item?.id?.toString().split('-');

    const countryName = countryMap?.[countryId] || '';
    const cityCountryInfo = cityMap?.[cityId]?.split(',') || [];
    const cityName = cityCountryInfo?.[0] || '';

    return {
      id: item.id,
      name: cityName ? `${countryName} / ${cityName}` : countryName,
      slug: item.slug,
    };
  });
};

export const getWorkRolesFromFilterExpression = searchQuery =>
  searchQuery?.reduce((acc, part) => {
    if (part?.type === BOOLEAN_SEARCH_PARTS.QUERY) {
      acc.push({
        workId: part.value?.[CASTING_TOOL_SLUGS.WORK],
        role: part.value?.[CASTING_TOOL_SLUGS.WORK_ROLE],
        roleId: part.value?.[CASTING_TOOL_SLUGS.WORK_ROLE]?.id,
        roleType: part.value?.[CASTING_TOOL_SLUGS.WORK_ROLE]?.type,
      });
    }
    return acc;
  }, []);

const removePrefixFromOptionIds = options =>
  Object.values(options)?.reduce((acc, optionArray) => {
    optionArray.forEach(item => {
      const type = REGULAR_EXPRESSIONS.PREFIXED_PROFILE_ID.test(item?.id)
        ? ENTITY_TYPES.PROFILE
        : ENTITY_TYPES.ORGANIZATION;

      acc.push({
        entity: { ...item, id: item?.id?.toString()?.replace(/[a-zA-Z]/g, '') },
        entityType: type,
      });
    });
    return acc;
  }, []);

// NOTE: This function is used to transform the applied filters into the format that can be used in the URL
export const transformAppliedFilters = ({ options, filters, subFilterType, selectedFilterType }) => {
  const slugType = FILTER_SLUG_TYPE[selectedFilterType];
  const filterParams = omit(filters, [
    slugType,
    ...(selectedFilterType === FILTER_TYPES.COUNTRY ? [FILTER_TYPES.CITY] : []),
  ]);

  switch (selectedFilterType) {
    case FILTER_TYPES.TICKETS_WATCH_ONLINE: {
      const { slug = '' } = options?.[selectedFilterType]?.[0] || {};
      return {
        ...omit(filterParams, [FILTER_TYPES.WATCH_ONLINE, FILTER_TYPES.TICKET]),
        ...(slug && { [slug]: true }),
      };
    }
    case FILTER_TYPES.COUNTRY:
    case FILTER_TYPES.WORK_TYPE: {
      const key = selectedFilterType;
      const entityType = selectedFilterType === FILTER_TYPES?.WORK_TYPE ? ENTITY_TYPES.WORK_TYPE : ENTITY_TYPES.COUNTRY;
      const transformedFilter = Object.keys(options)?.reduce((acc, identifier) => {
        const value = options[identifier];
        if (value?.length === 1) {
          acc.push({
            entity: value?.[0],
            entityType,
          });
          return acc;
        }

        const parent = {
          entity: value?.filter(item => item?.id?.toString() === identifier)?.[0],
          entityType,
        };
        acc.push(
          ...value?.reduce((accumulator, item) => {
            if (item?.id?.toString() !== identifier) {
              accumulator.push({
                ...parent, // NOTE: please keep this line as in getEntitySlug we check for entity
                parent, // NOTE: we need this for country filter to show the country name in url path
                format: { ...item, id: item?.id?.toString()?.replace(/[a-zA-Z]/g, '') },
              });
            }
            return accumulator;
          }, []),
        );

        return acc;
      }, []);

      return {
        ...filterParams,
        [key]: transformedFilter,
      };
    }
    case FILTER_TYPES.COMPOSER: {
      return {
        ...filterParams,
        [selectedFilterType]: removePrefixFromOptionIds(options),
      };
    }
    case FILTER_TYPES.CONTRIBUTION_EXPRESSION: {
      if (
        [
          FILTER_TYPES.CONDUCTOR,
          FILTER_TYPES.COMPOSER,
          FILTER_TYPES.CHOREOGRAPHER,
          FILTER_TYPES.DIRECTOR,
          FILTER_TYPES.CO_PRODUCER,
          FILTER_TYPES.VENUE,
        ].includes(subFilterType?.slug)
      ) {
        return {
          ...filterParams,
          [subFilterType?.slug]: removePrefixFromOptionIds(options),
        };
      }

      const key = subFilterType?.type;
      const preAppliedFilters = filterParams?.[key]?.filter(({ entity: filter }) => filter?.id !== subFilterType?.id);

      if (subFilterType?.type === FILTER_TYPES.PRODUCER) {
        const transformedFilter = Object.keys(options)?.reduce((acc, identifier) => {
          const value = options[identifier];
          const isIndividual = identifier === 'individual';
          let contributors = null;
          if (value) {
            contributors = value?.reduce((bucket, item) => {
              const type = REGULAR_EXPRESSIONS.PREFIXED_PROFILE_ID.test(item?.id)
                ? ENTITY_TYPES.PROFILE
                : ENTITY_TYPES.ORGANIZATION;

              bucket.push({
                entity: item,
                entityType: type,
              });

              return bucket;
            }, []);
          }
          acc.push({
            entity: { id: identifier, name: isIndividual ? 'Individual' : identifier, slug: identifier },
            entityType: isIndividual ? ENTITY_TYPES.PROFILE : ENTITY_TYPES.ORGANIZATION_TYPE,
            contributors,
          });
          return acc;
        }, []);

        return {
          ...filterParams,
          [key]: transformedFilter,
        };
      }

      return {
        ...filterParams,
        [key]: [
          ...(preAppliedFilters || []),
          {
            entity: subFilterType,
            entityType: subFilterType?.type,
            contributors: removePrefixFromOptionIds(options),
          },
        ],
      };
    }
    default:
      return {
        ...filterParams,
        ...(Object.keys(options)?.length > 0 ? { [selectedFilterType]: Object.values(options)?.flat() } : {}),
      };
  }
};

const extractNestedFilterOptions = (options, skipPrefix) => {
  if (!Array.isArray(options) || options.length === 0) return {};

  return options.reduce((acc, { entity: parent, entityType, format }) => {
    const value = { ...parent, id: getEntityIdWithPrefix({ entity: parent, entityType, skipPrefix })?.id };
    acc[value?.id] = [...(acc[value?.id] || [value])];
    if (format?.id) {
      acc[value?.id].push(format);
    }
    return acc;
  }, {});
};

const extractOptionsFilterByCategory = options => {
  if (!Array.isArray(options) || options.length === 0) return {};

  return options.reduce((acc, { entity: contributor, entityType, attributes }) => {
    const value = { ...contributor, id: getEntityIdWithPrefix({ entity: contributor, entityType })?.id };

    if (entityType === ENTITY_TYPES.PROFILE) {
      acc.individual = [...(acc.individual || []), value];
    } else {
      const orgType = attributes?.[ATTRIBUTE_TYPES.ENTITY]?.find(
        ({ entityType: organizationType }) => organizationType === ENTITY_TYPES.ORGANIZATION_TYPE,
      )?.entity?.slug;
      acc[orgType] = [...(acc[orgType] || []), { ...value, ...(orgType?.id ? { organizationType: orgType } : {}) }];
    }

    return acc;
  }, {});
};

const getContributors = contributors =>
  contributors?.reduce((acc, { entity: contributor, entityType, attributes }) => {
    const value = { ...contributor, id: getEntityIdWithPrefix({ entity: contributor, entityType })?.id };
    const orgType = attributes?.[ATTRIBUTE_TYPES.ENTITY]?.find(
      ({ entityType: organizationType }) => organizationType === ENTITY_TYPES.ORGANIZATION_TYPE,
    )?.entity?.slug;

    if (entityType === ENTITY_TYPES.PROFILE) {
      acc.individual = [...(acc.individual || []), value];
    } else {
      acc[orgType] = [...(acc[orgType] || []), { ...value, ...(orgType?.id ? { organizationType: orgType } : {}) }];
    }

    return acc;
  }, {}) || {};

const extractOptionsFilterByGroup = (filters, subFilterType) => {
  const filterByGroup = filters?.[subFilterType?.type];
  if (subFilterType?.type === FILTER_TYPES.PRODUCER) {
    const { contributors, optionsWithoutContributors } =
      filterByGroup?.reduce(
        (acc, { contributors: items, entity }) => {
          if (!items?.length) {
            acc.optionsWithoutContributors = {
              ...acc.optionsWithoutContributors,
              [entity?.slug]: [],
            };
          } else {
            acc.contributors.push(
              ...items.map(({ entity: contributor, entityType, ...rest }) => {
                let { id } = contributor;
                if (id && id.toString().startsWith(OPERATORS.FILTER_TYPE_NEGATED)) {
                  id = `${OPERATORS.FILTER_TYPE_NEGATED}${ENTITY_TYPE_ID_PREFIX[entityType]}${id.toString().slice(1)}`;
                }
                return { entity: { ...entity, id: id?.toString() }, ...rest };
              }),
            );
          }
          return acc;
        },
        { contributors: [], optionsWithoutContributors: {} },
      ) || {};

    return { ...getContributors(contributors), ...optionsWithoutContributors };
  }
  const contributors =
    filterByGroup?.find(({ entity: filterType }) => filterType?.slug === subFilterType?.slug)?.contributors || [];
  return getContributors(contributors);
};

export const getAppliedQuickViewFilters = ({ filters, selectedFilterType, subFilterType }) => {
  switch (selectedFilterType) {
    case FILTER_TYPES.CONTRIBUTION_EXPRESSION: {
      if (
        [
          FILTER_TYPES.CONDUCTOR,
          FILTER_TYPES.COMPOSER,
          FILTER_TYPES.CHOREOGRAPHER,
          FILTER_TYPES.DIRECTOR,
          FILTER_TYPES.CO_PRODUCER,
          FILTER_TYPES.VENUE,
        ].includes(subFilterType?.type)
      ) {
        return extractOptionsFilterByCategory(filters?.[subFilterType?.type]);
      }
      return extractOptionsFilterByGroup(filters, subFilterType);
    }
    case FILTER_TYPES.COMPOSER: {
      return extractOptionsFilterByCategory(filters?.[selectedFilterType]);
    }
    case FILTER_TYPES.SINCE_YEAR:
      return filters?.[selectedFilterType]?.id
        ? {
            [FILTER_TYPES.SINCE_YEAR]: filters?.[selectedFilterType],
          }
        : {};
    case FILTER_TYPES.COUNTRY: {
      const options = [
        ...(filters?.[FILTER_TYPES.COUNTRY] || []),
        ...(filters?.[FILTER_TYPES.CITY] || [])?.map(({ entity, parents }) => ({
          ...parents?.[0],
          format: { ...entity, cityCountry: parents?.name },
        })),
      ];
      return extractNestedFilterOptions(options, true);
    }
    case FILTER_TYPES.WORK_TYPE:
      return extractNestedFilterOptions(filters?.[selectedFilterType], true);
    case FILTER_TYPES.TICKETS_WATCH_ONLINE:
      if (filters?.[FILTER_TYPES.WATCH_ONLINE]) {
        return {
          [FILTER_TYPES.TICKETS_WATCH_ONLINE]: [{ id: FILTER_TYPES.WATCH_ONLINE, slug: FILTER_TYPES.WATCH_ONLINE }],
        };
      }
      return filters?.[FILTER_TYPES.TICKET]
        ? { [FILTER_TYPES.TICKETS_WATCH_ONLINE]: [{ id: FILTER_TYPES.TICKET, slug: FILTER_TYPES.TICKET }] }
        : {};
    default:
      return filters?.[selectedFilterType]
        ? { [selectedFilterType]: filters?.[selectedFilterType]?.map(({ entity }) => entity) }
        : {};
  }
};

export const getValueByPath = ({ option, path = '' }) => {
  if (!path) {
    return path;
  }
  if (REGULAR_EXPRESSIONS.PREFIXED_PROFILE_ID.test(option?.id)) {
    return 'individual';
  }

  return path.split('.').reduce((acc, part) => acc?.[part], option);
};

export const getFilterAsPerPagePath = ({ filters, page }) => {
  if (page && page.pro && page.tab === ENTITY_DETAILS_TABS.REPERTOIRE) {
    return pick(filters, FILTER_GROUP.REPERTOIRE[0]);
  }
  return filters;
};
