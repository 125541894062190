class TimedProcess {
  #name = '';

  #description = '';

  #startTime = null;

  #endTime = null;

  #running = false;

  constructor({ name, description }) {
    if (!name) {
      throw new Error('TimedProcess: name is required');
    }

    this.#name = name;
    this.#description = description;
    this.#running = false;
    this.#endTime = null;
  }

  start() {
    if (this.#running) {
      throw new Error(`TimedProcess ${this.#name}: process is already running`);
    }

    this.#startTime = new Date().getTime();
    this.#running = true;

    return this;
  }

  end() {
    if (!this.#running) {
      throw new Error(`TimedProcess ${this.#name}: process is not running`);
    }

    this.#endTime = new Date().getTime();
    this.#running = false;

    return this;
  }

  duration() {
    if (this.#running) {
      throw new Error(`TimedProcess ${this.#name}: duration is not available for running processes`);
    }

    return this.#endTime - this.#startTime;
  }

  info() {
    return {
      name: this.#name,
      description: this.#description,
      duration: this.duration(),
    };
  }
}

module.exports = TimedProcess;
