const { TP, ENTITY_TYPES, ROUTE_RESERVED_KEYWORDS, FILTER_TYPES } = require('./index');

const FILTER_TYPE_ENTITY_MAP = {
  [FILTER_TYPES.DATE]: ENTITY_TYPES.DATE,
  [FILTER_TYPES.ORGANIZATION_TYPE]: ENTITY_TYPES.ORGANIZATION_TYPE,
  [FILTER_TYPES.GENRE]: ENTITY_TYPES.WORK_TYPE,
  [FILTER_TYPES.STAGING_TYPE]: ENTITY_TYPES.STAGING_TYPE,
};

const ENTITIES_WITH_PAGE = [
  ENTITY_TYPES.PROFILE,
  ENTITY_TYPES.ORGANIZATION,
  ENTITY_TYPES.WORK,
  ENTITY_TYPES.WORK_TYPE,
  ENTITY_TYPES.REGION,
  ENTITY_TYPES.COUNTRY,
  ENTITY_TYPES.DATE,
];

const DATE_PATH_CONTEXT = {
  allowedAsBasePath: true,
  allowedAsSubPath: true,
  subPathForBaseEntities: [
    ENTITY_TYPES.PROFILE,
    ENTITY_TYPES.ORGANIZATION,
    ENTITY_TYPES.WORK,
    ENTITY_TYPES.WORK_TYPE,
    ENTITY_TYPES.REGION,
    ENTITY_TYPES.COUNTRY,
  ],
};

const STUBPAGE_TAB_PATH_CONTEXT = {
  allowedAsSubPath: true,
  subPathForBaseEntities: [ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION],
};

const PRO_STUBPAGE_TAB_PATH_CONTEXT = {
  allowedAsSubPath: true,
  subPathForEntities: [ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION],
};

const ROUTE_CONFIGURATIONS = {
  [ROUTE_RESERVED_KEYWORDS['analytics-terms']]: {
    translationKey: `${TP}.FN_ANALYTICS_TERMS_TITLE`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.about]: {
    translationKey: `${TP}.ABOUT`,
    allowedAsSubPath: true,
    subPathForEntities: [ENTITY_TYPES.PRODUCTION],
    subPathForBaseEntities: [ENTITY_TYPES.ORGANIZATION],
  },
  [ROUTE_RESERVED_KEYWORDS['account-settings']]: {
    translationKey: `${TP}.ACCOUNT_SETTINGS_GENERAL_TITLE`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.agencies]: {
    translationKey: `${TP}.m_MANAGERS`,
    allowedAsSubPath: true,
    subPathForBasePaths: [ROUTE_RESERVED_KEYWORDS.pro],
  },
  [ROUTE_RESERVED_KEYWORDS.agency_modif]: {
    translationKey: `${TP}.m_EDITAGENCY`,
    allowedAsSubPath: true,
    subPathForEntities: [ENTITY_TYPES.AGENCY],
  },
  [ROUTE_RESERVED_KEYWORDS['roster-add']]: {
    translationKey: `${TP}.m_ROSTER_BTN_ADD_NEW`,
    allowedAsSubPath: true,
    subPathForBaseEntities: [ENTITY_TYPES.AGENCY],
  },
  [ROUTE_RESERVED_KEYWORDS['roster-update']]: {
    translationKey: `${TP}.FN_EDIT_REPRESENTATION_DETAILS`,
    allowedAsSubPath: true,
    subPathForBaseEntities: [ENTITY_TYPES.AGENCY],
  },
  [ROUTE_RESERVED_KEYWORDS.articles]: {
    translationKey: `${TP}.m_SIDEBAR_ARTICLES`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.artists]: {
    translationKey: `${TP}.m_ARTISTS`,
    allowedAsBasePath: true,
    allowedAsSubPath: true,
    subPathForBasePaths: [ROUTE_RESERVED_KEYWORDS.pro],
    validEntitiesForPathWithBasePath: [ENTITY_TYPES.PROFESSION],
    allowedWithFilters: true,
    validEntitiesForFilters: [ENTITY_TYPES.PROFESSION],
  },
  [ROUTE_RESERVED_KEYWORDS.bio]: {
    translationKey: `${TP}.m_BIO`,
    allowedAsSubPath: true,
    subPathForBaseEntities: [ENTITY_TYPES.PROFILE],
    subPathForEntities: [ENTITY_TYPES.PROFILE],
  },
  [ROUTE_RESERVED_KEYWORDS.calendar]: {
    translationKey: `${TP}.FN_CALENDAR`,
    ...PRO_STUBPAGE_TAB_PATH_CONTEXT,
  },
  [ROUTE_RESERVED_KEYWORDS.casting]: {
    translationKey: `${TP}.FN_CASTINGTOOL`,
    allowedAsBasePath: true,
    validEntitiesForPathWithBasePath: [
      ENTITY_TYPES.PROFILE,
      ENTITY_TYPES.ORGANIZATION,
      ENTITY_TYPES.REGION,
      ENTITY_TYPES.COUNTRY,
      ENTITY_TYPES.CITY,
      ENTITY_TYPES.WORK,
      ENTITY_TYPES.WORK_TYPE,
      ENTITY_TYPES.STAGING_TYPE,
    ],
    allowedWithFilters: true,
    validEntitiesForFilters: [ENTITY_TYPES.PROFILE],
  },
  [ROUTE_RESERVED_KEYWORDS.composers]: {
    translationKey: `${TP}.m_COMPOSERS`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.companies]: {
    translationKey: `${TP}.FN_ARTS_ORGANISATIONS`,
    allowedAsSubPath: true,
    subPathForBasePaths: [ROUTE_RESERVED_KEYWORDS.pro],
  },
  [ROUTE_RESERVED_KEYWORDS.academia]: {
    translationKey: `${TP}.PROFESSIONAL_B5`,
    allowedAsSubPath: true,
    subPathForBasePaths: [ROUTE_RESERVED_KEYWORDS.pro],
  },
  [ROUTE_RESERVED_KEYWORDS.journalist]: {
    translationKey: `${TP}.PROFESSIONAL_B4`,
    allowedAsSubPath: true,
    subPathForBasePaths: [ROUTE_RESERVED_KEYWORDS.pro],
  },
  [ROUTE_RESERVED_KEYWORDS['company-graph']]: {
    translationKey: `${TP}.FN_COMPANY_GRAPH`,
    ...PRO_STUBPAGE_TAB_PATH_CONTEXT,
  },
  [ROUTE_RESERVED_KEYWORDS['role-graph']]: {
    translationKey: `${TP}.FN_ROLE_GRAPH`,
    ...PRO_STUBPAGE_TAB_PATH_CONTEXT,
  },
  [ROUTE_RESERVED_KEYWORDS['venue-graph']]: {
    translationKey: `${TP}.FN_VENUE_GRAPH`,
    ...PRO_STUBPAGE_TAB_PATH_CONTEXT,
  },
  [ROUTE_RESERVED_KEYWORDS.contact]: {
    translationKey: `${TP}.m_CONTACT`,
    allowedAsBasePath: true,
    ...STUBPAGE_TAB_PATH_CONTEXT,
  },
  [ROUTE_RESERVED_KEYWORDS.cookpol]: {
    translationKey: `${TP}.FN_CPOL`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.details]: {
    translationKey: `${TP}.m_DETAILS`,
    allowedAsSubPath: true,
    subPathForSubPaths: [
      ROUTE_RESERVED_KEYWORDS.artists,
      ROUTE_RESERVED_KEYWORDS.companies,
      ROUTE_RESERVED_KEYWORDS.agencies,
    ],
  },
  [ROUTE_RESERVED_KEYWORDS.edit]: {
    translationKey: `${TP}.m_EDIT`,
    allowedAsSubPath: true,
    subPathForBaseEntities: [ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION],
  },
  [ROUTE_RESERVED_KEYWORDS.featured]: {
    translationKey: `${TP}.FN_FEATURED`,
    allowedAsSubPath: true,
    subPathForSubPaths: [
      ROUTE_RESERVED_KEYWORDS.videos,
      ROUTE_RESERVED_KEYWORDS.reviews,
      ROUTE_RESERVED_KEYWORDS.images,
    ],
  },
  [ROUTE_RESERVED_KEYWORDS.highlights]: {
    translationKey: `${TP}.m_HIGHLIGHTS`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.images]: {
    translationKey: `${TP}.FN_TAB_PHOTOS`,
    subPathForEntities: [ENTITY_TYPES.PRODUCTION],
    ...STUBPAGE_TAB_PATH_CONTEXT,
  },
  [ROUTE_RESERVED_KEYWORDS.intro]: {
    translationKey: `${TP}.m_INTRODUCTION`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.link]: {
    translationKey: `${TP}.FN_EXTERNAL_LINK`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.login]: {
    translationKey: `${TP}.m_LOGIN`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.managers]: {
    translationKey: `${TP}.m_MANAGERS`,
    allowedAsBasePath: true,
    allowedWithFilters: true,
    validEntitiesForFilters: [
      ENTITY_TYPES.AGENCY,
      ENTITY_TYPES.COUNTRY,
      ENTITY_TYPES.PROFESSION,
      ENTITY_TYPES.ORGANIZATION_TYPE,
    ],
    validEntitiesForPathWithBasePath: [
      ENTITY_TYPES.AGENCY,
      ENTITY_TYPES.COUNTRY,
      ENTITY_TYPES.PROFESSION,
      ENTITY_TYPES.ORGANIZATION_TYPE,
    ],
  },
  [ROUTE_RESERVED_KEYWORDS.maps]: {
    translationKey: `${TP}.m_MAPS`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS['modify-production']]: {
    translationKey: `${TP}.FN_EDIT_PRODUCTION`,
    allowedAsSubPath: true,
    subPathForSubPaths: [ROUTE_RESERVED_KEYWORDS.edit],
  },
  [ROUTE_RESERVED_KEYWORDS['new-and-rare']]: {
    translationKey: `${TP}.m_NEW_AND_RARE`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.onthisday]: {
    translationKey: `${TP}.m_ONTHISDAY`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.organisations]: {
    translationKey: `${TP}.FN_ARTS_ORGANISATIONS`,
    allowedAsBasePath: true,
    allowedWithFilters: true,
    validEntitiesForFilters: [ENTITY_TYPES.ORGANIZATION_TYPE, ENTITY_TYPES.COUNTRY, ENTITY_TYPES.CITY],
    validEntitiesForPathWithBasePath: [ENTITY_TYPES.ORGANIZATION_TYPE, ENTITY_TYPES.COUNTRY, ENTITY_TYPES.CITY],
  },
  [ROUTE_RESERVED_KEYWORDS.overview]: {
    translationKey: `${TP}.OVERVIEW`,
    allowedAsSubPath: true,
    subPathForBaseEntities: [ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION],
  },
  [ROUTE_RESERVED_KEYWORDS.payment]: {
    translationKey: `${TP}.m_REGISTRATION_PAYMENT`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.integrate]: {
    translationKey: `${TP}.m_REGISTRATION_PAYMENT`,
    allowedAsSubPath: true,
    subPathForBasePaths: [ROUTE_RESERVED_KEYWORDS.payment],
  },
  [ROUTE_RESERVED_KEYWORDS.reactivate]: {
    translationKey: `${TP}.RA_REACTIVATE_PREMIUM_SUB_LINK`,
    allowedAsSubPath: true,
    subPathForBasePaths: [ROUTE_RESERVED_KEYWORDS.payment],
  },
  [ROUTE_RESERVED_KEYWORDS['payment-terms']]: {
    translationKey: `${TP}.m_SUBSCRIPTION_AND_PAYMENT_TERMS`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.performances]: {
    translationKey: `${TP}.FN_PERFORMANCES`,
    ...STUBPAGE_TAB_PATH_CONTEXT,
  },
  [ROUTE_RESERVED_KEYWORDS.privpol]: {
    translationKey: `${TP}.FN_PRIVPOL`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.pro]: {
    translationKey: `${TP}.FN_PRO_TOOL`,
    allowedAsBasePath: true,
    subPathRequired: true,
  },
  [ROUTE_RESERVED_KEYWORDS.proaudience]: {
    translationKey: `${TP}.PROAUDIENCE`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.productions]: {
    translationKey: `${TP}.FN_PERFORMANCES`,
    allowedAsBasePath: true,
    allowedAsSubPath: true,
    subPathForSubPaths: [ROUTE_RESERVED_KEYWORDS.reviews],
    allowedWithFilters: true,
    validEntitiesForFilters: [ENTITY_TYPES.PRODUCTION, ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION],
    validEntitiesForPathWithBasePath: [
      ENTITY_TYPES.PRODUCTION,
      ENTITY_TYPES.PROFILE,
      ENTITY_TYPES.ORGANIZATION,
      ENTITY_TYPES.DATE,
    ],
  },
  [ROUTE_RESERVED_KEYWORDS.rentals]: {
    translationKey: `${TP}.FN_TAB_RENTALS`,
    allowedAsBasePath: true,
    allowedAsSubPath: true,
    subPathForBaseEntities: [ENTITY_TYPES.ORGANIZATION],
  },
  [ROUTE_RESERVED_KEYWORDS.reviews]: {
    translationKey: `${TP}.m_ARTICLE`,
    subPathForEntities: [ENTITY_TYPES.PRODUCTION],
    ...STUBPAGE_TAB_PATH_CONTEXT,
  },
  [ROUTE_RESERVED_KEYWORDS.register]: {
    translationKey: `${TP}.m_REGISTRATION`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.revivals]: {
    translationKey: `${TP}.FN_REVIVAL`,
    allowedAsSubPath: true,
    subPathForSubPaths: [ROUTE_RESERVED_KEYWORDS.edit],
  },
  [ROUTE_RESERVED_KEYWORDS.search]: {
    translationKey: `${TP}.SEARCH`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.recent]: {
    translationKey: `${TP}.SRCH_RECENT_SEARCHES`,
    allowedAsSubPath: true,
    subPathForBasePaths: [ROUTE_RESERVED_KEYWORDS.search],
  },
  [ROUTE_RESERVED_KEYWORDS.visited]: {
    translationKey: `${TP}.SRCH_RECENTLY_VISITED`,
    allowedAsSubPath: true,
    subPathForBasePaths: [ROUTE_RESERVED_KEYWORDS.search],
  },
  [ROUTE_RESERVED_KEYWORDS['search-productions']]: {
    translationKey: `${TP}.FN_SEARCH_PRODUCTIONS`,
    allowedAsSubPath: true,
    subPathForSubPaths: [ROUTE_RESERVED_KEYWORDS.edit],
  },
  [ROUTE_RESERVED_KEYWORDS.seasons]: {
    translationKey: `${TP}.m_SEASON`,
    allowedAsBasePath: true,
    validEntitiesForPathWithBasePath: [ENTITY_TYPES.SEASON, ENTITY_TYPES.COUNTRY],
    allowedWithFilters: true,
    validEntitiesForFilters: [ENTITY_TYPES.SEASON],
  },
  [ROUTE_RESERVED_KEYWORDS.statistics]: {
    translationKey: `${TP}.m_STATISTICS`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.termpol]: {
    translationKey: `${TP}.FN_TERMCOND`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.validation]: {
    translationKey: `${TP}.FN_PRODUCTION_VALIDATION`,
    allowedAsSubPath: true,
    subPathForSubPaths: [ROUTE_RESERVED_KEYWORDS.edit],
  },
  [ROUTE_RESERVED_KEYWORDS.debug]: {
    translationKey: `${TP}.FN_DEBUG`,
    allowedAsBasePath: true,
  },
  [ROUTE_RESERVED_KEYWORDS.venues]: {
    translationKey: `${TP}.m_VENUES`,
    allowedAsSubPath: true,
    subPathForBaseEntities: [ENTITY_TYPES.ORGANIZATION],
  },
  [ROUTE_RESERVED_KEYWORDS.videos]: {
    translationKey: `${TP}.m_VIDEOS`,
    allowedAsBasePath: true,
    subPathForEntities: [ENTITY_TYPES.PRODUCTION],
    ...STUBPAGE_TAB_PATH_CONTEXT,
  },
  [ROUTE_RESERVED_KEYWORDS['cast-crew']]: {
    translationKey: `${TP}.FN_CAST_CREW_INSTRUMENTALISTS`,
    allowedAsSubPath: true,
    subPathForEntities: [ENTITY_TYPES.PRODUCTION],
  },
  [ROUTE_RESERVED_KEYWORDS['watch-online']]: {
    translationKey: `${TP}.FN_WATCH_ONLINE_VIDEOS`,
    allowedAsSubPath: true,
    subPathForSubPaths: [ROUTE_RESERVED_KEYWORDS.videos],
  },
  [ROUTE_RESERVED_KEYWORDS.repertoire]: {
    translationKey: `${TP}.m_ARTIST_REPERTOIRE`,
    ...STUBPAGE_TAB_PATH_CONTEXT,
    ...PRO_STUBPAGE_TAB_PATH_CONTEXT,
  },
  [ROUTE_RESERVED_KEYWORDS.works]: {
    translationKey: `${TP}.FN_MUSICAL_WORKS`,
    allowedAsBasePath: true,
    allowedAsSubPath: true,
    subPathForBaseEntities: [ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION],
  },
  [ROUTE_RESERVED_KEYWORDS.past]: {
    ...DATE_PATH_CONTEXT,
    translationKey: `${TP}.FN_PAST`,
  },
  [ROUTE_RESERVED_KEYWORDS.upcoming]: {
    ...DATE_PATH_CONTEXT,
    translationKey: `${TP}.FN_UPCOMING`,
  },
  [ROUTE_RESERVED_KEYWORDS.today]: {
    ...DATE_PATH_CONTEXT,
    translationKey: `${TP}.m_TODAY`,
  },
  [ROUTE_RESERVED_KEYWORDS.tomorrow]: {
    ...DATE_PATH_CONTEXT,
    translationKey: `${TP}.m_TOMORROW`,
  },
  [ROUTE_RESERVED_KEYWORDS['next-15-days']]: {
    ...DATE_PATH_CONTEXT,
    translationKey: `${TP}.NEXT_15_DAYS`,
  },
  [ROUTE_RESERVED_KEYWORDS['next-30-days']]: {
    ...DATE_PATH_CONTEXT,
    translationKey: `${TP}.NEXT_30_DAYS`,
  },
  [ROUTE_RESERVED_KEYWORDS['next-90-days']]: {
    ...DATE_PATH_CONTEXT,
    translationKey: `${TP}.NEXT_90_DAYS`,
  },
};

const SPECIAL_KEYWORD_CONTEXT_PARAMS = {
  [ROUTE_RESERVED_KEYWORDS.past]: {
    translationKey: 'Past',
    type: FILTER_TYPES.DATE,
    shiftDateEnd: 0,
  },
  [ROUTE_RESERVED_KEYWORDS.upcoming]: {
    translationKey: 'Upcoming',
    type: FILTER_TYPES.DATE,
    shiftDateStart: 0,
  },
  [ROUTE_RESERVED_KEYWORDS.today]: {
    translationKey: `${TP}.m_TODAY`,
    type: FILTER_TYPES.DATE,
    shiftDateStart: 0,
    shiftDateEnd: 0,
  },
  [ROUTE_RESERVED_KEYWORDS.tomorrow]: {
    translationKey: 'Tomorrow',
    type: FILTER_TYPES.DATE,
    shiftDateStart: 1,
    shiftDateEnd: 1,
  },
  [ROUTE_RESERVED_KEYWORDS['next-15-days']]: {
    translationKey: 'Next 15 days',
    type: FILTER_TYPES.DATE,
    shiftDateStart: 0,
    shiftDateEnd: 15,
  },
  [ROUTE_RESERVED_KEYWORDS['next-30-days']]: {
    translationKey: 'Next 30 days',
    type: FILTER_TYPES.DATE,
    shiftDateStart: 0,
    shiftDateEnd: 30,
  },
  [ROUTE_RESERVED_KEYWORDS['next-90-days']]: {
    translationKey: 'Next 90 days',
    type: FILTER_TYPES.DATE,
    shiftDateStart: 0,
    shiftDateEnd: 90,
  },
};

const INVALID_ROUTE_REASON = {
  SUB_PATH_MISSING: 'SUB_PATH_MISSING',
  NOT_ALLOWED_AS_BASE_PATH: 'NOT_ALLOWED_AS_BASE_PATH',
  NOT_ALLOWED_AS_SUB_PATH: 'NOT_ALLOWED_AS_SUB_PATH',
  NOT_ALLOWED_WITH_FILTERS: 'NOT_ALLOWED_WITH_FILTERS',
  NOT_ALLOWED_ENTITY_TYPE: 'NOT_ALLOWED_ENTITY_TYPE',
  INVALID_SUB_PATH_FOR_BASE_PATH: 'INVALID_SUB_PATH_FOR_BASE_PATH',
  INVALID_SUB_PATH_FOR_SUB_PATH: 'INVALID_SUB_PATH_FOR_SUB_PATH',
  NO_PAGE_FOR_ENTITY: 'NO_PAGE_FOR_ENTITY',
  INVALID_PARENT_PATH: 'INVALID_PARENT_PATH',
  UNKNOWN_ENTITY_TYPE: 'UNKNOWN_ENTITY_TYPE',
  NO_MATCHED_ENTITY: 'NO_MATCHED_ENTITY',
  CONFLICTING_MATCHED_ENTITIES: 'CONFLICTING_MATCHED_ENTITIES',
  MULTIPLE_MATCHED_ENTITIES: 'MULTIPLE_MATCHED_ENTITIES',
};

module.exports = {
  SPECIAL_KEYWORD_CONTEXT_PARAMS,
  ROUTE_CONFIGURATIONS,
  FILTER_TYPE_ENTITY_MAP,
  DATE_PATH_CONTEXT,
  ENTITIES_WITH_PAGE,
  INVALID_ROUTE_REASON,
};
