/* eslint-disable no-dupe-class-members */
const { pick, omit } = require('lodash');
const { FILTER_TYPES, OPERATORS, ENTITY_DETAILS_TABS } = require('../constants');
const convertValuesToString = require('../utils/convertValuesToString');
const {
  convertValuesToContributionExpression,
  convertFilterIntoString,
} = require('../utils/convertValuesToContributionExpression');

const FILTER_API_PARAMS = {
  SEARCH: 'search',
  DATE: 'date',
  LETTER: 'letter',
  PAGE: 'page',
  PROFESSION_ID: 'profession_id',
  ORGANIZATION_TYPE_ID: 'organization_type_id',
  COUNTRY_ID: 'country_id',
  CITY_ID: 'city_id',
  CONTRIBUTION_EXPRESSION: 'contribution_exp',
  LANGUAGE: 'language_id',
  TICKET: 'has_tickets',
  WATCH_ONLINE: 'has_online',
  SURTITLE: 'surtitle_id',
  HIGHLIGHT_TYPE: 'highlight_type',
  WORK_TYPE_ID: 'work_type_id',
  WORK: 'work_id',
  BOOLEAN_SEARCH: 'expression',
  SINCE_YEAR: 'since_year',
  WORK_CREATOR_ID: 'work_creator_id',
  QUERY: 'query',
};

const API_PARAMS_FILTER_TYPE_MAP = {
  [FILTER_API_PARAMS.SEARCH]: [
    FILTER_TYPES.WHO,
    FILTER_TYPES.WHERE,
    FILTER_TYPES.WHAT,
    FILTER_TYPES.GENRE,
    FILTER_TYPES.STAGING_TYPE,
  ],
  [FILTER_API_PARAMS.DATE]: [FILTER_TYPES.DATE],
  [FILTER_API_PARAMS.LETTER]: [FILTER_TYPES.LETTER],
  [FILTER_API_PARAMS.PAGE]: [FILTER_TYPES.PAGE],
  [FILTER_API_PARAMS.PROFESSION_ID]: [FILTER_TYPES.PROFESSION],
  [FILTER_API_PARAMS.ORGANIZATION_TYPE_ID]: [FILTER_TYPES.ORGANIZATION_TYPE],
  [FILTER_API_PARAMS.COUNTRY_ID]: [FILTER_TYPES.COUNTRY],
  [FILTER_API_PARAMS.CITY_ID]: [FILTER_TYPES.CITY],
  [FILTER_API_PARAMS.LANGUAGE]: [FILTER_TYPES.LANGUAGE],
  [FILTER_API_PARAMS.SURTITLE]: [FILTER_TYPES.SURTITLE],
  [FILTER_API_PARAMS.HIGHLIGHT_TYPE]: [FILTER_TYPES.PERFORMANCE_HIGHLIGHT],
  [FILTER_API_PARAMS.TICKET]: [FILTER_TYPES.TICKET],
  [FILTER_API_PARAMS.WORK]: [FILTER_TYPES.WORK],
  [FILTER_API_PARAMS.WORK]: [FILTER_TYPES.WORK],
  [FILTER_API_PARAMS.WORK_TYPE_ID]: [FILTER_TYPES.WORK_TYPE],
  [FILTER_API_PARAMS.WATCH_ONLINE]: [FILTER_TYPES.WATCH_ONLINE],
  [FILTER_API_PARAMS.BOOLEAN_SEARCH]: [FILTER_TYPES.BOOLEAN_SEARCH],
  [FILTER_API_PARAMS.SINCE_YEAR]: [FILTER_TYPES.SINCE_YEAR],
  [FILTER_API_PARAMS.WORK_CREATOR_ID]: [FILTER_TYPES.COMPOSER],
  [FILTER_API_PARAMS.QUERY]: [FILTER_TYPES.QUERY],
  [FILTER_API_PARAMS.CONTRIBUTION_EXPRESSION]: [
    FILTER_TYPES.CONDUCTOR,
    FILTER_TYPES.COMPOSER,
    FILTER_TYPES.CHOREOGRAPHER,
    FILTER_TYPES.ROLE,
    FILTER_TYPES.ENSEMBLE,
    FILTER_TYPES.DIRECTOR,
    FILTER_TYPES.CO_PRODUCER,
    FILTER_TYPES.VENUE,
    FILTER_TYPES.PRODUCER,
  ],
};

class FilterParamsTransformer {
  #transformDateParamValue(value) {
    return {
      ...(value?.date_from && { date_from: value.date_from }),
      ...(value?.date_to && { date_to: value.date_to }),
    };
  }

  #transformFilterValues({ filterType, filterValue, values, entityMap, page }) {
    switch (filterType) {
      case FILTER_TYPES.DATE: {
        return this.#transformDateParamValue(filterValue);
      }
      case FILTER_TYPES.SINCE_YEAR: {
        return filterValue?.[0]?.id;
      }
      case FILTER_TYPES.WHAT:
      case FILTER_TYPES.WHERE:
      case FILTER_TYPES.WHO:
      case FILTER_TYPES.GENRE:
        return {
          ...values,
          [filterType]: convertValuesToString({ values: filterValue }),
        };
      case FILTER_TYPES.ORGANIZATION_TYPE:
      case FILTER_TYPES.PROFESSION:
      case FILTER_TYPES.COUNTRY:
      case FILTER_TYPES.CITY:
      case FILTER_TYPES.LANGUAGE:
      case FILTER_TYPES.SURTITLE:
      case FILTER_TYPES.PERFORMANCE_HIGHLIGHT:
      case FILTER_TYPES.WORK:
      case FILTER_TYPES.WORK_TYPE:
        return convertValuesToString({ values: filterValue, filterType });
      case FILTER_TYPES.COMPOSER: {
        if (page.tab === ENTITY_DETAILS_TABS.REPERTOIRE && page?.pro) {
          return convertFilterIntoString(filterType, filterValue);
        }
        return convertValuesToContributionExpression({
          values,
          filterType,
          filterValue,
          entityMap,
        });
      }
      case FILTER_TYPES.CONDUCTOR:
      case FILTER_TYPES.DIRECTOR:
      case FILTER_TYPES.CHOREOGRAPHER:
      case FILTER_TYPES.ROLE:
      case FILTER_TYPES.PRODUCER:
      case FILTER_TYPES.ENSEMBLE:
      case FILTER_TYPES.CO_PRODUCER:
      case FILTER_TYPES.VENUE:
        return convertValuesToContributionExpression({
          values,
          filterType,
          filterValue,
          entityMap,
        });
      default: {
        return filterValue;
      }
    }
  }

  #transformSearchParamValues(values = {}) {
    if (Object.keys(values).length === 0) {
      return null;
    }

    return Object.entries(values).reduce((acc, [type, value]) => {
      let finalValue = acc;

      if (finalValue?.length > 0) {
        finalValue += OPERATORS.SEPARATOR;
      }

      finalValue += `${type}${OPERATORS.TYPE_SEPARATOR}${value}`;

      return finalValue;
    }, '');
  }

  #transformSearchExpression(filters = {}) {
    const value = Object.keys(filters)
      .reduce((acc, filterType) => {
        const filterValue = filters[filterType];

        acc.push(convertValuesToString({ values: filterValue, forcePrefix: true }));

        return acc;
      }, [])
      .join('|');

    return value;
  }

  #transformContributionExpressionValues(values = {}) {
    if (Object.keys(values).length === 0) {
      return null;
    }

    return Object.entries(values)
      .map(([key, subObj]) =>
        Object.entries(subObj)
          .map(
            ([id, categories]) =>
              `${key}:${id}:${Object.entries(categories)
                .map(([cat, options]) => {
                  if (options.length === 0) {
                    return cat;
                  }
                  return `${cat}|${options.join(OPERATORS.AND)}`;
                })
                .join(OPERATORS.CONTRIBUTOR_SEPARATOR)}`,
          )
          .join(OPERATORS.CONTRIBUTION_EXP_OPERATOR),
      )
      .join(OPERATORS.CONTRIBUTION_EXP_OPERATOR);
  }

  #transformFilterParams(params) {
    return Object.keys(params).reduce((acc, key) => {
      const value = params[key];

      if (Array.isArray(value) || ['number', 'string', 'boolean'].includes(typeof value)) {
        acc[key] = value;
      } else if (typeof value === 'object' && value !== null) {
        return { ...acc, ...this.#transformFilterParams(value) };
      }

      return acc;
    }, {});
  }

  transformFilters({ filters, entityMap, page }) {
    if (Object.keys(filters).length === 0) {
      return {};
    }

    let apiFilterTypeMap = API_PARAMS_FILTER_TYPE_MAP;

    if (page?.tab === ENTITY_DETAILS_TABS.REPERTOIRE) {
      apiFilterTypeMap = omit(apiFilterTypeMap, [FILTER_API_PARAMS.CONTRIBUTION_EXPRESSION]);
    }

    const filterParams = Object.keys(apiFilterTypeMap).reduce((acc, param) => {
      const filterTypes = apiFilterTypeMap[param] || [];
      const relevantFilters = pick(filters, filterTypes);
      let value = Object.keys(relevantFilters).reduce((values, filterType) => {
        const filterValue = relevantFilters[filterType];

        return this.#transformFilterValues({ filterType, filterValue, values, entityMap, page });
      }, {});

      if (param === FILTER_API_PARAMS.SEARCH) {
        value = this.#transformSearchParamValues(value);
      }

      if (param === FILTER_API_PARAMS.CONTRIBUTION_EXPRESSION) {
        value = this.#transformContributionExpressionValues(value);
      }

      return {
        ...acc,
        [param]: value,
        ...(param === FILTER_API_PARAMS.SEARCH && {
          search_exp: this.#transformSearchExpression(relevantFilters),
        }),
      };
    }, {});

    return this.#transformFilterParams(filterParams);
  }
}

module.exports = FilterParamsTransformer;
