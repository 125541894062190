import { useCallback, useEffect, useState } from 'react';

const VISITS_HISTORY_STORAGE_KEY = 'OB_VISITS_HISTORY';
const VISITS_HISTORY_MAX_LENGTH = 10;
const VISITS_HISTORY_UPDATED_EVENT_NAME = 'VISITS_HISTORY_UPDATED';

// Duplicate behavior can be
// ignored - skips adding item to history
// update - pushes item to top, removes duplicated old one
// duplicate - pushes item without checks
const VISITS_DEFAULT_DUPLICATE_BEHAVIOR = 'update';

export const nameToSearchEntityType = {
  profile: 'profiles',
  composer: 'profiles',
  organization: 'organizations',
  company: 'organizations',
  festival: 'organizations',
  work: 'works',
  venue: 'venues',
  manager: 'agencies',
};

const emitVisitsHistoryUpdatedEvent = () => {
  window.dispatchEvent(new CustomEvent(VISITS_HISTORY_UPDATED_EVENT_NAME));
};

export const getVisitsHistory = () => {
  if (typeof window === 'undefined') {
    return [];
  }
  const visitsHistory = localStorage.getItem(VISITS_HISTORY_STORAGE_KEY);
  return visitsHistory ? JSON.parse(visitsHistory) : [];
};

export const saveVisitsHistory = visitsHistory => {
  localStorage.setItem(VISITS_HISTORY_STORAGE_KEY, JSON.stringify(visitsHistory));
  emitVisitsHistoryUpdatedEvent();
};

export const clearVisitsHistory = () => {
  localStorage.removeItem(VISITS_HISTORY_STORAGE_KEY);
  emitVisitsHistoryUpdatedEvent();
};

const transformEntityToSearchVariant = (entity, type) => {
  if (type === 'profiles') {
    return { ...entity, entity: entity?.name };
  }
  if (type === 'organizations') {
    return { ...entity, entity: entity?.name, image: entity?.logo };
  }
  if (type === 'venues') {
    return { ...entity, entity: entity?.name, image: entity?.logo };
  }
  if (type === 'agencies') {
    return { ...entity, entity: entity?.name, image: entity?.logo };
  }
  if (type === 'works') {
    return { ...entity, entity: entity?.name || entity?.original_name, image: entity?.logo };
  }
  return entity;
};

export const pushVisitHistoryItem = (
  entity,
  type,
  duplicateBehavior = VISITS_DEFAULT_DUPLICATE_BEHAVIOR,
  entityTransformEnabled = true,
) => {
  if (!entity?.id) {
    return;
  }
  const visitsHistory = getVisitsHistory();
  if (duplicateBehavior !== 'duplicate') {
    const duplicateIndex = visitsHistory.findIndex(item => item?.entity?.id === entity?.id && item.type === type);
    if (duplicateIndex !== -1) {
      if (duplicateBehavior === 'ignored') {
        return;
      }
      visitsHistory.splice(duplicateIndex, 1);
    }
  }
  visitsHistory.unshift({
    entity: entityTransformEnabled ? transformEntityToSearchVariant(entity, type) : entity,
    type,
  });
  saveVisitsHistory(visitsHistory.slice(0, VISITS_HISTORY_MAX_LENGTH));
};

export const removeVisitHistoryItem = index => {
  const visitsHistory = getVisitsHistory();
  visitsHistory.splice(index, 1);
  saveVisitsHistory(visitsHistory);
};

export const useVisitsHistory = () => {
  const [visitsHistory, setVisitsHistory] = useState(getVisitsHistory());

  const handleVisitHistoryUpdate = useCallback(() => {
    setVisitsHistory(getVisitsHistory());
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener(VISITS_HISTORY_UPDATED_EVENT_NAME, handleVisitHistoryUpdate);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener(VISITS_HISTORY_UPDATED_EVENT_NAME, handleVisitHistoryUpdate);
      }
    };
  }, [handleVisitHistoryUpdate]);

  return visitsHistory?.filter(item => item?.entity?.id);
};
