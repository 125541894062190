/* eslint-disable no-dupe-class-members */
const getProcessedContributors = require('../utils/getProcessedContributors');
const {
  ENTITY_TYPES,
  FILTER_TYPES,
  ROUTE_RESERVED_KEYWORDS,
  REGULAR_EXPRESSIONS,
  OPERATORS,
  ENTITY_TYPE_ID_PREFIX,
  ROUTE_PART_TYPE,
  ROUTE_VALIDITY,
  ATTRIBUTE_TYPES,
  FILTER_TYPE_ENTITY_TYPE_MAP,
} = require('../constants');
const { SPECIAL_KEYWORD_CONTEXT_PARAMS, INVALID_ROUTE_REASON } = require('../constants/configurations');

class LinkPropsExtractor {
  #getInitialFiltersFromQuery(query = {}, entityMap = {}) {
    return Object.keys(query).reduce((acc, key) => {
      if (['date_from', 'date_to'].includes(key)) {
        acc.date = {
          ...(acc.date || {}),
          ...(query[key] && { [key]: query[key] }),
        };

        return acc;
      }

      if (FILTER_TYPES.SINCE_YEAR === key) {
        acc[key] = [{ id: query[key], slug: query[key] }];
        return acc;
      }

      if (
        [
          FILTER_TYPES.LETTER,
          FILTER_TYPES.PAGE,
          FILTER_TYPES.TICKET,
          FILTER_TYPES.QUERY,
          FILTER_TYPES.WATCH_ONLINE,
          FILTER_TYPES.BOOLEAN_SEARCH,
        ].includes(key)
      ) {
        let value = query[key];

        if (!value?.length) {
          return acc;
        }

        value = REGULAR_EXPRESSIONS.NUMBERS_ONLY.test(value) ? parseInt(value, 10) : value;

        if (value === 'true') {
          value = true;
        }

        if (value === 'false') {
          value = false;
        }

        acc[key] = value;

        return acc;
      }

      if (FILTER_TYPES.PERFORMANCE_HIGHLIGHT === key) {
        const value = query[key];

        if (!value?.length) {
          return acc;
        }

        const highlights = query[key]?.split(OPERATORS.OR);
        acc[key] = highlights
          ?.map(highlight => ({
            entity: { id: highlight, name: highlight, slug: highlight },
          }))
          .filter(Boolean);
        return acc;
      }

      if ([FILTER_TYPES.ROLE, FILTER_TYPES.ENSEMBLE, FILTER_TYPES.PRODUCER].includes(key)) {
        const value = query[key];

        if (!value?.length) {
          return acc;
        }

        const filters = query[key]?.split(OPERATORS.SEPARATOR);
        const convertedFilters = filters?.reduce((accumulator, filter) => {
          const [profession, entityIds] = filter.split(OPERATORS.TYPE_SEPARATOR);
          const ids = entityIds?.split(REGULAR_EXPRESSIONS.BOOLEAN_ID_SPLITTER);
          let exactMatch = null;
          if ([FILTER_TYPES.INDIVIDUAL].includes(profession)) {
            exactMatch = {
              entity: {
                id: profession,
                slug: profession,
                name: 'Individual',
              },
              entityType: ENTITY_TYPES.PROFILE,
            };
          } else if (profession.startsWith(OPERATORS.FILTER_TYPE_NEGATED)) {
            // NOTE: This is a special case where we have a negative filter like exclude all companies, etc
            // NOTE: we do not get the entity from the entityMap in this case
            exactMatch = {
              entity: { id: profession, slug: profession, name: profession.slice(1) },
              entityType: ENTITY_TYPES.ORGANIZATION_TYPE,
            };
          } else {
            exactMatch = entityMap[profession]?.find(({ entityType }) => {
              if (key === FILTER_TYPES.ROLE) {
                return entityType === ENTITY_TYPES.PROFESSION;
              }
              if (key === FILTER_TYPES.ENSEMBLE) {
                return entityType === ENTITY_TYPES.ENSEMBLE_TYPE;
              }
              if (key === FILTER_TYPES.PRODUCER) {
                return entityType === ENTITY_TYPES.ORGANIZATION_TYPE;
              }
              return false;
            });
          }
          if (exactMatch?.entity) {
            accumulator.push({
              ...exactMatch,
              contributors: getProcessedContributors({
                options: ids,
                filterKey: key,
                entityMap,
              }),
            });
          }
          return accumulator;
        }, []);

        acc[key] = convertedFilters;
        return acc;
      }

      const values = query[key]?.split(REGULAR_EXPRESSIONS.BOOLEAN_VALUE_SPLITTER) || [];

      if (values?.length) {
        const processedValues = Array.isArray(values)
          ? values.reduce((valAcc, value) => {
              if (!value) {
                return valAcc;
              }

              const [_, operator, id] = value.match(REGULAR_EXPRESSIONS.BOOLEAN_VALUE_OPERATOR_MATCH) || [];
              let [mainId, subId] = id?.split(OPERATORS.CONCAT) || [];

              if ([FILTER_TYPES.GENRE, FILTER_TYPES.WORK_TYPE, FILTER_TYPES.COUNTRY].includes(key)) {
                const parentEntityType = key === FILTER_TYPES.COUNTRY ? ENTITY_TYPES.COUNTRY : ENTITY_TYPES.WORK_TYPE;
                const childEntityType = key === FILTER_TYPES.COUNTRY ? ENTITY_TYPES.CITY : ENTITY_TYPES.STAGING_TYPE;
                mainId = `${ENTITY_TYPE_ID_PREFIX[parentEntityType]}${mainId}`;
                subId = subId ? `${ENTITY_TYPE_ID_PREFIX[childEntityType]}${subId}` : null;
              } else if (key === FILTER_TYPES.WHAT) {
                mainId = `${ENTITY_TYPE_ID_PREFIX[ENTITY_TYPES.WORK]}${mainId}`;
              } else if (
                [FILTER_TYPES.LANGUAGE, FILTER_TYPES.SURTITLE, FILTER_TYPES.PROFESSION, FILTER_TYPES.WORK].includes(key)
              ) {
                mainId = `${ENTITY_TYPE_ID_PREFIX[FILTER_TYPE_ENTITY_TYPE_MAP[key]]}${mainId}`;
              }

              const matchedMainEntity = entityMap[mainId]?.[0] || null;
              const matchedSubEntity = entityMap[subId]?.[0] || null;

              if (matchedMainEntity?.entity?.id) {
                valAcc.push({
                  ...matchedMainEntity,
                  ...(operator && { operator }),
                  ...([FILTER_TYPES.GENRE, FILTER_TYPES.WORK_TYPE].includes(key) &&
                    matchedSubEntity?.entity?.id && { format: matchedSubEntity?.entity }),
                  ...(FILTER_TYPES.COUNTRY === key &&
                    matchedSubEntity?.entity?.id && { city: matchedSubEntity?.entity }),
                });
              }

              return valAcc;
            }, [])
          : values;

        if (processedValues?.length) {
          acc[key] = processedValues;
        }
      }

      return acc;
    }, {});
  }

  #updateEntityParents({ parents, entityType, entity }) {
    if (!entityType || !entity?.id) {
      return parents;
    }

    const updatedParents = (parents || []).filter(parent => parent.entityType !== entityType);

    return [
      ...updatedParents,
      {
        entityType,
        entity,
      },
    ];
  }

  #processRequestPaths({ initialFilters = {}, paths = [], entityMap = {} }) {
    return paths.reduce(
      (acc, part, index) => {
        const { filterTypeKey, ...rest } = part || {};
        acc.paths.push(rest);

        if (!filterTypeKey) {
          if (
            index === 1 &&
            [ENTITY_TYPES.PRODUCTION, ENTITY_TYPES.AGENCY].includes(rest?.context?.entityType) &&
            !!rest?.context?.entity?.id
          ) {
            acc.entityType = rest?.context?.entityType;
            acc.entity = rest?.context?.entity;

            return acc;
          }

          return acc;
        }

        if (!acc.filters[filterTypeKey]?.length) {
          acc.filters[filterTypeKey] = [];
        }

        const { entityType: filterEntityType, entity: filterEntity, parents: filterParents, ...restContext } =
          rest?.context || {};

        if (filterTypeKey === FILTER_TYPES.DATE) {
          acc.filters[filterTypeKey] = {
            ...(restContext.date_from && { date_from: restContext.date_from }),
            ...(restContext.date_to && { date_to: restContext.date_to }),
            path: part.path,
          };
        } else if (FILTER_TYPES.PRODUCER === filterTypeKey) {
          const orgType =
            restContext?.attributes?.[ATTRIBUTE_TYPES.ENTITY]?.find(
              ({ entityType }) => entityType === ENTITY_TYPES.ORGANIZATION_TYPE,
            ) || {};
          acc.filters[filterTypeKey].push({
            entity:
              filterEntityType === ENTITY_TYPES.PROFILE
                ? { id: 'individual', name: 'Individual', slug: 'individual' }
                : orgType?.entity,
            entityType: orgType?.entityType ? orgType?.entityType : ENTITY_TYPES.PROFILE,
            contributors: [
              {
                entity: filterEntity,
                entityType: filterEntityType,
                attributes: restContext?.attributes,
              },
            ],
          });
        } else if (
          [
            FILTER_TYPES.CONDUCTOR,
            FILTER_TYPES.COMPOSER,
            FILTER_TYPES.PROFESSION,
            FILTER_TYPES.DIRECTOR,
            FILTER_TYPES.CHOREOGRAPHER,
          ].includes(filterTypeKey) &&
          entityMap[filterTypeKey]
        ) {
          acc.filters[filterTypeKey].push({
            ...(filterEntityType && { entityType: filterEntityType }),
            ...(filterEntity?.id && {
              entity: {
                id: filterEntity?.id,
                slug: filterEntity?.slug,
                name: filterEntity?.name,
                ...(filterEntity?.type && { type: filterEntity?.type }),
              },
            }),
          });
        } else {
          let countryForCity = null;

          if (filterEntityType === ENTITY_TYPES.CITY && filterParents?.length === 1) {
            const { entityType: parentEntityType, entity: parentEntity } = filterParents[0];

            if (parentEntityType === ENTITY_TYPES.COUNTRY && parentEntity?.id) {
              countryForCity = parentEntity;
            }
          }

          if (filterEntityType === ENTITY_TYPES.COUNTRY && !!paths[index + 1]?.path) {
            const childContext = paths[index + 1]?.context || {};
            const { entityType: childEntityType, parents: childParents } = childContext;

            if (childEntityType === ENTITY_TYPES.CITY && childParents?.length === 1) {
              const isSameAsCountry = childParents[0]?.entity?.id === filterEntity?.id;

              if (isSameAsCountry) {
                return acc;
              }
            }
          }

          acc.filters[filterTypeKey].push({
            ...(filterEntityType && { entityType: filterEntityType }),
            ...(filterEntity?.id && {
              entity: {
                id: filterEntity?.id,
                slug: filterEntity?.slug,
                name: filterEntity?.name,
                ...(filterEntity?.logo && { logo: filterEntity?.logo }),
                ...(filterEntity?.type && { type: filterEntity?.type }),
              },
            }),
            ...(countryForCity && {
              parents: this.#updateEntityParents({
                parents: restContext?.parents,
                entityType: ENTITY_TYPES.COUNTRY,
                entity: countryForCity,
              }),
            }),
            ...(restContext?.attributes && { attributes: restContext?.attributes }),
          });

          if (!rest.valid && rest?.reason === INVALID_ROUTE_REASON.INVALID_PARENT_PATH) {
            const parentPath = paths[index - 1]?.path;

            if (ENTITY_TYPES.CITY === filterEntityType && filterParents?.length === 1) {
              const { entityType: parentEntityType, entity: parentEntity } = filterParents[0];

              if (parentEntityType === ENTITY_TYPES.COUNTRY && parentEntity?.id && parentPath !== parentEntity?.slug) {
                acc.filters[filterTypeKey][acc.filters[filterTypeKey].length - 1].country = parentEntity;
                acc.paths[index].validity = ROUTE_VALIDITY.FIXED;
              }
            }

            if (ENTITY_TYPES.COUNTRY === filterEntityType) {
              acc.filters[filterTypeKey] = acc.filters[filterTypeKey].filter(item => item?.entity?.slug !== parentPath);
              acc.paths[index].validity = ROUTE_VALIDITY.FIXED;
            }
          }
        }

        return acc;
      },
      {
        filters: initialFilters,
        paths: [],
        entityType: null,
        entity: null,
      },
    );
  }

  #extractNamedPaths({ paths = [] }) {
    return paths.reduce(
      (acc, item, index) => {
        if (item.type === ROUTE_PART_TYPE.VARIABLE && !acc.baseDone) {
          acc.baseDone = true;
        } else if (item.type === ROUTE_PART_TYPE.RESERVED && acc.baseDone) {
          acc.variablesDone = true;
        }

        if (item.type === ROUTE_PART_TYPE.RESERVED && !SPECIAL_KEYWORD_CONTEXT_PARAMS[item.path]) {
          if (item.path === ROUTE_RESERVED_KEYWORDS.casting && index === 0) {
            acc.pro = true;
          }

          if (
            (index === 1 && item.path === ROUTE_RESERVED_KEYWORDS.edit) ||
            (index === 2 &&
              [
                ROUTE_RESERVED_KEYWORDS.agency_modif,
                ROUTE_RESERVED_KEYWORDS['roster-add'],
                ROUTE_RESERVED_KEYWORDS['roster-update'],
              ].includes(item.path))
          ) {
            acc.edit = true;
          }

          if (acc.variablesDone && item.path !== ROUTE_RESERVED_KEYWORDS.edit) {
            if (acc.tab.length > 0) {
              acc.tab += '/';
            }

            acc.tab += item.path;

            if (!acc.mainPath) {
              acc.mainPath = item.path;
            } else if (acc.mainPath && !acc.subPath) {
              acc.subPath = item.path;
            }
          } else if (!acc.baseDone) {
            if (acc.basePath.length > 0) {
              acc.basePath += '/';
            }

            acc.basePath += item.path;
          }
        }

        return acc;
      },
      {
        basePath: '',
        tab: '',
        baseDone: false,
        variablesDone: false,
        edit: false,
        pro: false,
        mainPath: '',
        subPath: '',
      },
    );
  }

  #groupFilterValuesByEntityType(values = []) {
    return values.reduce((acc, item) => {
      if (!acc[item.entityType]) {
        acc[item.entityType] = [];
      }

      acc[item.entityType].push(item);

      return acc;
    }, {});
  }

  #sanitizeFilters(filters) {
    const { [FILTER_TYPES.GENRE]: workTypeFilters } = filters || {};
    let overrideFilters = {};

    if (workTypeFilters?.length === 2) {
      const groupedWorkTypeFilters = this.#groupFilterValuesByEntityType(workTypeFilters);

      const stagingTypeFilters = groupedWorkTypeFilters[ENTITY_TYPES.STAGING_TYPE] || [];
      const genreTypeFilters = groupedWorkTypeFilters[ENTITY_TYPES.WORK_TYPE] || [];

      if (stagingTypeFilters.length === 1 && genreTypeFilters.length === 1) {
        overrideFilters = {
          ...overrideFilters,
          [FILTER_TYPES.GENRE]: [
            {
              entityType: ENTITY_TYPES.WORK_TYPE,
              entity: genreTypeFilters[0].entity,
              format: stagingTypeFilters[0].entity,
            },
          ],
        };
      }
    }

    return {
      ...filters,
      ...overrideFilters,
    };
  }

  getProps(state) {
    const { query = {}, entityMap } = state || {};

    const initialFilters = this.#getInitialFiltersFromQuery(query, entityMap);
    const { paths, filters, entityType, entity } = this.#processRequestPaths({
      initialFilters,
      paths: state.paths,
      entityMap,
    });
    const {
      basePath = '',
      tab = '',
      mainPath = '',
      subPath = '',
      edit = false,
      pro = false,
    } = this.#extractNamedPaths({ paths });

    return {
      paths,
      basePath,
      mainPath,
      subPath,
      linkProps: {
        filters: this.#sanitizeFilters(filters),
        ...(entityType && { entityType }),
        ...(entity?.id && { entity }),
        ...(basePath && { basePath }),
        ...(tab && { tab }),
        edit,
        pro,
      },
    };
  }
}

module.exports = LinkPropsExtractor;
