import groupBy from 'lodash/groupBy';
import camelCase from 'lodash/camelCase';

import { getURLSlugComponents } from 'utils/globals';
import { getContextualFilters, getFilterUsage } from 'utils/globals/filters';

import { ENTITY_TYPES, FILTER_TYPES } from 'operabase-router/constants';
import { ENTITY_MAIN_TABS } from 'constants/index';
import { ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';
import { CASTING_TOOL_FILTERS } from 'constants/filters';

export const removeLanguageSuffixFromPath = (path, language) => {
  const regex = new RegExp(`/${language}$`);
  const [pathname, queryString] = path?.split('?');

  const updatedPathname = pathname?.replace(regex, '');

  if (queryString) {
    return `${updatedPathname}?${queryString}`;
  }

  return updatedPathname;
};

export const getValidFilters = ({ entityId, entityType, pathname, mainPath }) => {
  if (pathname?.startsWith(ROUTE_RESERVED_KEYWORDS.casting)) {
    if (entityType === ENTITY_TYPES.PROFILE) {
      if (mainPath === ENTITY_MAIN_TABS.BIO) {
        return [];
      }

      return [
        FILTER_TYPES.GENRE_STAGING,
        FILTER_TYPES.PROFESSION,
        FILTER_TYPES.CREATOR,
        FILTER_TYPES.WORK,
        ...CASTING_TOOL_FILTERS,
      ];
    }

    return [FILTER_TYPES.BOOLEAN_SEARCH, FILTER_TYPES.COUNTRY, FILTER_TYPES.SINCE_YEAR, ...CASTING_TOOL_FILTERS];
  }

  return getContextualFilters({ entityId, entityType, tabKey: mainPath || ENTITY_MAIN_TABS.HOME });
};

const preProcessQueryParams = query => {
  const { lng: _lng, '[...action': _action, subpath: _subpath, ...restQueryParams } = query || {};

  const { utmParams, queryParams, hasActiveUTMParams } = Object.keys(restQueryParams).reduce(
    (acc, key) => {
      if (key.startsWith('utm_')) {
        acc.utmParams[camelCase(key)] = restQueryParams[key];
        acc.hasActiveUTMParams = true;
      } else {
        acc.queryParams[key] = restQueryParams[key];
      }

      return acc;
    },
    { utmParams: {}, queryParams: {}, hasActiveUTMParams: false },
  );

  return {
    utmParams,
    queryParams,
    hasActiveUTMParams,
  };
};

const getRouteContext = (router, requestLanguage) => {
  const { isLoggedIn, obRouteContext } = router;
  const { program_published } = obRouteContext?.query || {};
  const { utmParams, queryParams, hasActiveUTMParams } = preProcessQueryParams(obRouteContext?.query);
  const cleanedAsPath = obRouteContext?.url?.split('?')?.[0] || '';

  const { basePath, mainPath, subPath, page, linkProps } = obRouteContext || {};
  const { entityType, entity, pro, edit } = page || {};
  const { id: entityId, slug: entitySlug } = entity || {};
  const { filters } = linkProps || {};

  const orgTypeFilters = obRouteContext?.linkProps?.filters?.[FILTER_TYPES.ORGANIZATION_TYPE];
  const orgTypeSlug = orgTypeFilters?.length === 1 ? orgTypeFilters?.[0]?.entity?.slug : null;

  const validFilters = [];
  const filterTypeUsage = getFilterUsage({ entityType, filters: {}, isLoggedIn });

  const paths =
    obRouteContext?.paths?.map(item => item?.path)?.filter((value, index) => !(index === 0 && value === basePath)) ||
    [];
  const urlSlugs = paths?.map(item => getURLSlugComponents(item));

  const { ignore: _ignore, ...context } = groupBy(urlSlugs, item => item?.slugType || 'ignore');

  let countrySlug = null;
  let country = null;
  if (filters?.[FILTER_TYPES.COUNTRY]?.length === 1) {
    country = filters[FILTER_TYPES.COUNTRY][0].entity;
    countrySlug = filters[FILTER_TYPES.COUNTRY][0].entity.slug;
  }

  return {
    entityId,
    entitySlug,
    entityType,
    orgTypeSlug,
    program_published,
    pro,
    edit,
    countrySlug, // DEPRECATE
    country,
    context,
    paths,
    basePath,
    mainPath,
    subPath,
    language: requestLanguage,
    urlSlugs,
    productionId: entityType === ENTITY_TYPES.PRODUCTION ? entityId : null,
    queryParams,
    filters: obRouteContext?.linkProps?.filters || {},
    filterParams: obRouteContext?.filterParams || {},
    validFilters,
    filterTypeUsage,
    pathname: cleanedAsPath || '',
    pageURL: obRouteContext?.url,
    utmParams,
    hasActiveUTMParams,
  };
};

export default getRouteContext;
