const { shouldSkipIDPrefix } = require('./index');
const { ENTITY_TYPES, FILTER_TYPES, OPERATORS, ATTRIBUTE_TYPES } = require('../constants/index');
const getEntityIdWithPrefix = require('./getEntityIdWithPrefix');

const FILTER_TYPE_PREFIX = [
  { prefix: 'q', type: ENTITY_TYPES.PROFESSION },
  { prefix: 'h', type: ENTITY_TYPES.ENSEMBLE_TYPE },
  { prefix: 'x', type: FILTER_TYPES.CO_PRODUCER },
  { prefix: 'x', type: ENTITY_TYPES.ORGANIZATION_TYPE },
  { prefix: 'x', type: ENTITY_TYPES.PROFILE },
  { prefix: 'x', type: FILTER_TYPES.VENUE },
];

const CONTRIBUTION_TYPES = {
  [FILTER_TYPES.CO_PRODUCER]: [
    {
      entityType: FILTER_TYPES.CO_PRODUCER,
      entity: { id: 'co-producer', name: 'Co-Producer' },
    },
  ],
  [FILTER_TYPES.VENUE]: [
    {
      entityType: FILTER_TYPES.VENUE,
      entity: { id: 'venue', name: 'Venue' },
    },
  ],
};

const convertFilterIntoString = (filterType, values) => {
  if (
    [
      FILTER_TYPES.CONDUCTOR,
      FILTER_TYPES.COMPOSER,
      FILTER_TYPES.CHOREOGRAPHER,
      FILTER_TYPES.DIRECTOR,
      FILTER_TYPES.CO_PRODUCER,
      FILTER_TYPES.VENUE,
      FILTER_TYPES.PERFORMANCE_HIGHLIGHT,
      FILTER_TYPES.WORK,
      FILTER_TYPES.LANGUAGE,
      FILTER_TYPES.PROFESSION,
      FILTER_TYPES.SURTITLE,
    ].includes(filterType)
  ) {
    // NOTE: Primary filter logic (e.g., conductor)
    const skipPrefix = shouldSkipIDPrefix(filterType);
    return values.map(item => getEntityIdWithPrefix({ ...item, skipPrefix })?.id).join(OPERATORS.OR);
  }

  if ([FILTER_TYPES.WORK_TYPE, FILTER_TYPES.COUNTRY].includes(filterType)) {
    return values
      .map(({ entity: { id }, format }) => (format?.id ? `${id}${OPERATORS.CONCAT}${format?.id}` : id))
      .join(OPERATORS.OR);
  }

  if ([FILTER_TYPES.ROLE, FILTER_TYPES.PRODUCER, FILTER_TYPES.ENSEMBLE].includes(filterType)) {
    // Secondary filter logic (e.g., role)
    const skipPrefix = shouldSkipIDPrefix(filterType);
    return values
      .map(({ entity: { slug }, contributors }) => {
        const keys = contributors?.map(item => getEntityIdWithPrefix({ ...item, skipPrefix })?.id).join(OPERATORS.OR);
        return keys ? `${slug}:${keys}` : slug;
      })
      .join(OPERATORS.SEPARATOR);
  }
  return '';
};

const getContributionExpressionOptions = ({ contributors = {}, skipPrefix }) =>
  contributors?.reduce((collection, { entity: contributor, entityType, attributes }) => {
    const { id } = getEntityIdWithPrefix({ entity: contributor, entityType, skipPrefix });
    const key =
      entityType === ENTITY_TYPES.PROFILE
        ? 'individual'
        : attributes?.[ATTRIBUTE_TYPES.ENTITY]?.find(
            ({ entityType: orgType }) => orgType === ENTITY_TYPES.ORGANIZATION_TYPE,
          )?.entity?.slug;
    if (!collection[key]) {
      return { ...collection, [key]: [id] };
    }
    collection[key].push(id);
    return collection;
  }, {});

const convertValuesToContributionExpression = ({ filterType, entityMap, filterValue, values }) => {
  if (
    [
      FILTER_TYPES.CONDUCTOR,
      FILTER_TYPES.COMPOSER,
      FILTER_TYPES.CHOREOGRAPHER,
      FILTER_TYPES.DIRECTOR,
      FILTER_TYPES.CO_PRODUCER,
      FILTER_TYPES.VENUE,
    ].includes(filterType)
  ) {
    const { entity: filter, entityType: filterEntityType } =
      CONTRIBUTION_TYPES?.[filterType]?.[0] || entityMap?.[filterType]?.[0] || {};

    const prefix = FILTER_TYPE_PREFIX.find(({ type }) => type === filterEntityType)?.prefix;
    const result = values;
    result[prefix] = result[prefix] || {};
    result[prefix][filter?.id] = { ...getContributionExpressionOptions({ contributors: filterValue }) };
    return result;
  }

  if (filterType === FILTER_TYPES.PRODUCER) {
    return filterValue?.reduce((acc, { entity: filter, entityType: filterEntityType, contributors = [] }) => {
      const prefix = FILTER_TYPE_PREFIX.find(({ type }) => type === filterEntityType)?.prefix;
      acc[prefix] = acc?.[prefix] || {};
      acc[prefix][filterType] = acc[prefix]?.[filterType] || {};
      acc[prefix][filterType][filter?.slug] = Object.values(getContributionExpressionOptions({ contributors }))?.flat();
      return acc;
    }, values);
  }

  return filterValue?.reduce((acc, { entity: filter, entityType: filterEntityType, contributors = [] }) => {
    const prefix = FILTER_TYPE_PREFIX.find(({ type }) => type === filterEntityType)?.prefix;
    acc[prefix] = acc[prefix] || {};
    acc[prefix][filter?.id] = { ...getContributionExpressionOptions({ contributors }) };
    return acc;
  }, values);
};

module.exports = {
  convertValuesToContributionExpression,
  convertFilterIntoString,
};
