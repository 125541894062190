import {
  TP,
  ENTITY_MAIN_TABS,
  URL_SLUG_TYPES,
  VIEW_MODES_VALUE,
  AGGREGATION_TYPES,
} from 'constants/index';
import { FILTER_TYPES, ENTITY_TYPES } from 'operabase-router/constants/index';

export const CASTING_TOOL_FILTERS = [
  FILTER_TYPES.CONDUCTOR,
  FILTER_TYPES.DIRECTOR,
  FILTER_TYPES.CAST_CREW,
  FILTER_TYPES.LANGUAGE,
  FILTER_TYPES.CITY,
  FILTER_TYPES.VENUE,
];

export const DISABLED_FILTER_TYPES = {
  [ENTITY_MAIN_TABS.REPERTOIRE]: [
    FILTER_TYPES.CONDUCTOR,
    FILTER_TYPES.DIRECTOR,
    FILTER_TYPES.CAST_CREW,
    FILTER_TYPES.LANGUAGE,
    FILTER_TYPES.CITY,
    FILTER_TYPES.VENUE,
  ],
  [ENTITY_MAIN_TABS.ORG_GRAPH]: [FILTER_TYPES.CONDUCTOR, FILTER_TYPES.DIRECTOR, FILTER_TYPES.CAST_CREW],
  [ENTITY_MAIN_TABS.ROLE_GRAPH]: [FILTER_TYPES.CONDUCTOR, FILTER_TYPES.DIRECTOR, FILTER_TYPES.CAST_CREW],
};

// TODO: To be lokalized
export const FILTER_LABELS = {
  [FILTER_TYPES.PERFORMANCE_HIGHLIGHT]: `${TP}.FN_PERFORMANCE_HIGHLIGHTS`,
  [FILTER_TYPES.TICKETS_WATCH_ONLINE]: `${TP}.FN_TICKETS_WATCH_ONLINE`,
  [FILTER_TYPES.ONGOING_UPCOMING]: `${TP}.FN_ONGOING_UPCOMING`,
  [FILTER_TYPES.COUNTRY]: `${TP}.FN_COUNTRY_CITY`,
  [FILTER_TYPES.PRODUCER]: `${TP}.FN_PRODUCER`,
  [FILTER_TYPES.CO_PRODUCER]: `${TP}.FN_CO_PRODUCER`,
  [FILTER_TYPES.COVER_DEBUT]: `${TP}.FN_COVER_DEBUTS`,
  [FILTER_TYPES.DATE]: 'Date',
  [FILTER_TYPES.CITY]: `${TP}.m_ASKLOCATION`,
  [FILTER_TYPES.COUNTRY]: 'Countries',
  [FILTER_TYPES.ORGANIZATION]: `${TP}.FN_ORG`,
  [FILTER_TYPES.VENUE]: `${TP}.m_VENUE`,
  [FILTER_TYPES.CREATOR]: `${TP}.m_COMPOSERS`,
  [FILTER_TYPES.WORK]: `${TP}.FN_MUSICAL_WORKS_SECTION_NAME`,
  [FILTER_TYPES.LANGUAGE]: `${TP}.FN_LANGUAGE_LABEL`,
  [FILTER_TYPES.REPERTOIRE_STATUS]: 'Future Repertoire',
  [FILTER_TYPES.CONDUCTOR]: `${TP}.m_CONDUCTOR`,
  [FILTER_TYPES.DIRECTOR]: `${TP}.FN_DIRECTOR`,
  [FILTER_TYPES.CO_CAST]: `${TP}.FN_CO_CAST`,
  [FILTER_TYPES.SEASON]: `${TP}.FN_SEASON`,
  [FILTER_TYPES.CAST_CREW]: `${TP}.FN_CAST_CREW`,
  [FILTER_TYPES.GENRE_STAGING]: `${TP}.FN_GENRE_STAGING`,
  [FILTER_TYPES.PROFESSION]: `${TP}.FN_PROFESSION`,
  [FILTER_TYPES.ROLE_PROFESSION]: `${TP}.ROLE_PROFESSIONS_FILTER`,
  [FILTER_TYPES.ROLE_PROFESSION_REPERTOIRE]: `${TP}.ROLE_PROFESSIONS_FILTER`,
};

export const FILTER_AGGREGATION_MAP = {
  [FILTER_TYPES.PERFORMANCE_HIGHLIGHT]: AGGREGATION_TYPES.PERFORMANCE_HIGHLIGHT,
  [FILTER_TYPES.COUNTRY]: AGGREGATION_TYPES.COUNTRY,
  [FILTER_TYPES.LANGUAGE]: AGGREGATION_TYPES.LANGUAGE,
  [FILTER_TYPES.CONDUCTOR]: AGGREGATION_TYPES.CONDUCTOR,
  [FILTER_TYPES.DIRECTOR]: AGGREGATION_TYPES.DIRECTOR,
  [FILTER_TYPES.CO_CAST]: AGGREGATION_TYPES.PROFILE,
  [FILTER_TYPES.VENUE]: AGGREGATION_TYPES.VENUE,
  [FILTER_TYPES.CITY]: AGGREGATION_TYPES.CITY,
  [FILTER_TYPES.CREATOR]: AGGREGATION_TYPES.CREATOR,
  [FILTER_TYPES.WORK]: AGGREGATION_TYPES.WORK,
  [FILTER_TYPES.SEASON]: AGGREGATION_TYPES.SEASON,
  [FILTER_TYPES.ORGANIZATION]: AGGREGATION_TYPES.ORGANIZATION,
  [FILTER_TYPES.CAST_CREW]: AGGREGATION_TYPES.CO_CAST,
  [FILTER_TYPES.WORK_TYPE]: AGGREGATION_TYPES.GENRE,
  [FILTER_TYPES.PROFESSION]: AGGREGATION_TYPES.PROFESSION,
  [FILTER_TYPES.CONTRIBUTION_EXPRESSION]: AGGREGATION_TYPES.CONTRIBUTOR_ID,
  [FILTER_TYPES.ROLE_PROFESSION]: AGGREGATION_TYPES.ROLE_PROFESSION,
  [FILTER_TYPES.ROLE_PROFESSION_REPERTOIRE]: AGGREGATION_TYPES.ROLE_PROFESSION,
  [FILTER_TYPES.SURTITLE]: AGGREGATION_TYPES.SURTITLE,
};

export const FILTER_DEFAULT_EXPANDED = [
  FILTER_TYPES.CITY,
  FILTER_TYPES.DATE,
  FILTER_TYPES.ORGANIZATION,
  FILTER_TYPES.VENUE,
];

export const FILTER_SLUG_TYPE = {
  [FILTER_TYPES.PERFORMANCE_HIGHLIGHT]: URL_SLUG_TYPES.PERFORMANCE_HIGHLIGHT,
  [FILTER_TYPES.DATE]: URL_SLUG_TYPES.DATE,
  [FILTER_TYPES.CITY]: URL_SLUG_TYPES.CITY,
  [FILTER_TYPES.COUNTRY]: URL_SLUG_TYPES.COUNTRY_CITY,
  [FILTER_TYPES.COVER_DEBUT]: URL_SLUG_TYPES.COVER_DEBUT,
  [FILTER_TYPES.ORGANIZATION_TYPE]: URL_SLUG_TYPES.ORGANIZATION_TYPE,
  [FILTER_TYPES.COUNTRY]: URL_SLUG_TYPES.COUNTRY,
  [FILTER_TYPES.ORGANIZATION]: URL_SLUG_TYPES.ORGANIZATION,
  [FILTER_TYPES.VENUE]: URL_SLUG_TYPES.VENUE,
  [FILTER_TYPES.CREATOR]: URL_SLUG_TYPES.CREATOR,
  [FILTER_TYPES.WORK]: URL_SLUG_TYPES.WORK,
  [FILTER_TYPES.LANGUAGE]: URL_SLUG_TYPES.LANGUAGE,
  [FILTER_TYPES.REPERTOIRE_STATUS]: URL_SLUG_TYPES.REPERTOIRE_STATUS,
  [FILTER_TYPES.PRODUCTION_PERIOD]: URL_SLUG_TYPES.PRODUCTION_PERIOD,
  [FILTER_TYPES.BOOLEAN_SEARCH]: URL_SLUG_TYPES.BOOLEAN_SEARCH,
  [FILTER_TYPES.SINCE_YEAR]: URL_SLUG_TYPES.SINCE_YEAR,
  [FILTER_TYPES.PROFILE]: URL_SLUG_TYPES.ARTIST,
  [FILTER_TYPES.CO_CAST]: URL_SLUG_TYPES.CO_CAST,
  [FILTER_TYPES.CONDUCTOR]: URL_SLUG_TYPES.CONDUCTOR,
  [FILTER_TYPES.DIRECTOR]: URL_SLUG_TYPES.DIRECTOR,
  [FILTER_TYPES.SEASON]: URL_SLUG_TYPES.SEASON,
  [FILTER_TYPES.CAST_CREW]: URL_SLUG_TYPES.CO_CAST,
  [FILTER_TYPES.GENRE_STAGING]: URL_SLUG_TYPES.GENRE,
  [FILTER_TYPES.PROFESSION]: URL_SLUG_TYPES.PROFESSION,
  [FILTER_TYPES.LETTER]: URL_SLUG_TYPES.LETTER,
  [FILTER_TYPES.PAGE]: URL_SLUG_TYPES.PAGE,
  [FILTER_TYPES.QUERY]: URL_SLUG_TYPES.QUERY,
  [FILTER_TYPES.CONTRIBUTION_EXPRESSION]: URL_SLUG_TYPES.CONTRIBUTION_EXP,
  [FILTER_TYPES.ROLE_PROFESSION_REPERTOIRE]: URL_SLUG_TYPES.CREDIT_ID,
  [FILTER_TYPES.WORK_ROLE]: URL_SLUG_TYPES.WORK_ROLE,
};

export const TAB_FILTERS = {
  [ENTITY_MAIN_TABS.HOME]: [
    FILTER_TYPES.DATE,
    FILTER_TYPES.CITY,
    FILTER_TYPES.ORGANIZATION,
    FILTER_TYPES.VENUE,
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.WORK,
  ],
  [ENTITY_MAIN_TABS.PERFORMANCES]: [
    FILTER_TYPES.TICKETS_WATCH_ONLINE,
    FILTER_TYPES.ONGOING_UPCOMING,
    FILTER_TYPES.GENRE_STAGING,
    FILTER_TYPES.COUNTRY,
    FILTER_TYPES.VENUE,
    FILTER_TYPES.WORK,
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.LANGUAGE,
    FILTER_TYPES.PERFORMANCE_HIGHLIGHT,
    FILTER_TYPES.CONTRIBUTION_EXPRESSION,
  ],
  [ENTITY_MAIN_TABS.CALENDAR]: [
    FILTER_TYPES.GENRE_STAGING,
    FILTER_TYPES.PROFESSION,
    FILTER_TYPES.SEASON,
    FILTER_TYPES.CITY,
    FILTER_TYPES.ORGANIZATION,
    FILTER_TYPES.VENUE,
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.WORK,
    FILTER_TYPES.LANGUAGE,
  ],
  [ENTITY_MAIN_TABS.VIDEOS]: [],
  [ENTITY_MAIN_TABS.IMAGES]: [],
  [ENTITY_MAIN_TABS.REPERTOIRE]: [FILTER_TYPES.CREATOR, FILTER_TYPES.WORK],
  [ENTITY_MAIN_TABS.ORG_GRAPH]: [
    FILTER_TYPES.CITY,
    FILTER_TYPES.ORGANIZATION,
    FILTER_TYPES.VENUE,
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.WORK,
    FILTER_TYPES.LANGUAGE,
  ],
  [ENTITY_MAIN_TABS.ROLE_GRAPH]: [
    FILTER_TYPES.CITY,
    FILTER_TYPES.ORGANIZATION,
    FILTER_TYPES.VENUE,
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.WORK,
    FILTER_TYPES.LANGUAGE,
  ],
  [ENTITY_MAIN_TABS.REVIEWS]: [],
};

export const ENTITY_LISTING_FILTERS = {
  [ENTITY_TYPES.PROFILE]: [FILTER_TYPES.PROFESSION, FILTER_TYPES.LETTER, FILTER_TYPES.PAGE, FILTER_TYPES.QUERY],
  [ENTITY_TYPES.ORGANIZATION]: [
    FILTER_TYPES.ORGANIZATION_TYPE,
    FILTER_TYPES.COUNTRY,
    FILTER_TYPES.CITY,
    FILTER_TYPES.LETTER,
    FILTER_TYPES.PAGE,
    FILTER_TYPES.QUERY,
  ],
  [ENTITY_TYPES.AGENCY]: [FILTER_TYPES.QUERY],
};

export const ENTITY_TAB_FILTERS = {
  [ENTITY_TYPES.PROFILE]: {
    ...TAB_FILTERS,
    [ENTITY_MAIN_TABS.HOME]: [],
  },
  [ENTITY_TYPES.PRODUCTION]: {
    [ENTITY_MAIN_TABS.HOME]: [FILTER_TYPES.DATE],
  },
  [ENTITY_TYPES.ORGANIZATION]: {
    [ENTITY_MAIN_TABS.HOME]: [],
    [ENTITY_MAIN_TABS.PERFORMANCES]: [
      FILTER_TYPES.GENRE_STAGING,
      FILTER_TYPES.SEASON,
      FILTER_TYPES.CITY,
      FILTER_TYPES.WORK,
      FILTER_TYPES.CREATOR,
      FILTER_TYPES.DIRECTOR,
      FILTER_TYPES.CONDUCTOR,
      FILTER_TYPES.CAST_CREW,
      FILTER_TYPES.LANGUAGE,
      FILTER_TYPES.VENUE,
    ],
    [ENTITY_MAIN_TABS.VIDEOS]: [],
    [ENTITY_MAIN_TABS.IMAGES]: [],
    [ENTITY_MAIN_TABS.REVIEWS]: [],
    [ENTITY_MAIN_TABS.RENTALS]: [
      FILTER_TYPES.SEASON,
      FILTER_TYPES.CITY,
      FILTER_TYPES.VENUE,
      FILTER_TYPES.CREATOR,
      FILTER_TYPES.WORK,
      FILTER_TYPES.LANGUAGE,
    ],
  },
};

export const PRO_FILTERS = [
  FILTER_TYPES.ORGANIZATION,
  FILTER_TYPES.REPERTOIRE_STATUS,
  FILTER_TYPES.CREATOR,
  FILTER_TYPES.LANGUAGE,
];

export const TAB_H1_FILTER_PARTS = {
  [ENTITY_MAIN_TABS.PERFORMANCES]: [FILTER_TYPES.CITY, FILTER_TYPES.WORK, FILTER_TYPES.DATE, FILTER_TYPES.SEASON],
  [ENTITY_MAIN_TABS.VIDEOS]: [FILTER_TYPES.WORK, FILTER_TYPES.VENUE, FILTER_TYPES.DATE],
  [ENTITY_MAIN_TABS.IMAGES]: [FILTER_TYPES.WORK, FILTER_TYPES.VENUE, FILTER_TYPES.DATE],
  [ENTITY_MAIN_TABS.REPERTOIRE]: [FILTER_TYPES.CITY, FILTER_TYPES.WORK, FILTER_TYPES.DATE],
  [ENTITY_MAIN_TABS.REVIEWS]: [FILTER_TYPES.WORK],
};

export const SORT_ORDERS = {
  YEAR_ASCENDING: 'year',
  YEAR_DESCENDING: '-year',
};

export const VALID_VIEW_MODE_OPTIONS = [
  VIEW_MODES_VALUE.SUMMARY,
  VIEW_MODES_VALUE.DETAILED,
  VIEW_MODES_VALUE.CALENDAR,
  VIEW_MODES_VALUE.DAY,
  VIEW_MODES_VALUE.PRO,
  VIEW_MODES_VALUE.AOS_PRO,
];

export const ADVANCED_FILTER_TYPES = {
  [ENTITY_TYPES.PROFILE]: [
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.DIRECTOR,
    FILTER_TYPES.CONDUCTOR,
    FILTER_TYPES.CAST_CREW,
    FILTER_TYPES.LANGUAGE,
    FILTER_TYPES.VENUE,
  ],
  [ENTITY_TYPES.ORGANIZATION]: [
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.DIRECTOR,
    FILTER_TYPES.CONDUCTOR,
    FILTER_TYPES.CAST_CREW,
    FILTER_TYPES.LANGUAGE,
    FILTER_TYPES.VENUE,
  ],
};

export const SUB_FILTER_TYPES = {
  PRODUCER: 'producer',
  CO_PRODUCER: 'co-producer',
  VENUE: 'venue',
};
