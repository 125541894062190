/* eslint-disable no-dupe-class-members */
const { pick } = require('lodash');
const { ROUTE_PART_TYPE, FILTER_TYPES, ENTITY_TYPES, ROUTE_RESERVED_KEYWORDS } = require('../constants');
const { ROUTE_CONFIGURATIONS } = require('../constants/configurations');

const getEntityHeadlineParts = require('../utils/getEntityHeadlineParts');
const getDateRangeLabel = require('../utils/getDateRangeLabel');

class H1TitleResolver {
  #translationFn = value => value;

  constructor({ translationFn }) {
    if (translationFn) {
      this.#translationFn = translationFn;
    }
  }

  #getDateTitlePart({ date_from: dateFrom, date_to: dateTo }) {
    const label = getDateRangeLabel({ dateFrom, dateTo });

    if (label) {
      return {
        name: this.#translationFn(getDateRangeLabel({ dateFrom, dateTo })),
      };
    }

    return null;
  }

  #getReservedTitlePart({ path, entityType }) {
    if (entityType === ENTITY_TYPES.DATE) {
      return null;
    }

    const routeConfig = ROUTE_CONFIGURATIONS[path];

    return {
      name: this.#translationFn(routeConfig?.translationKey) || path,
    };
  }

  #getVariableTitlePart(
    { entityType, entity, attributes, country, parents, format, operator, date_from: dateFrom, date_to: dateTo },
    isPageEntity,
  ) {
    if (entityType === ENTITY_TYPES.DATE) {
      return {
        name: getDateRangeLabel({ dateFrom, dateTo }),
      };
    }

    const shouldSendEntityType = ![ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION].includes(entityType);
    const { headline, relevantAttributes, composers } = getEntityHeadlineParts({
      entityType: shouldSendEntityType ? entityType : null,
      entity,
      attributes,
      country,
      parents,
      format,
      translationFn: this.#translationFn,
    });

    return {
      name: format?.name ? `${entity?.name} (${format.name})` : entity?.name,
      ...(isPageEntity && entity?.logo && { image: entity?.logo }),
      ...(relevantAttributes?.length > 0 && { attributes: relevantAttributes }),
      ...(headline?.length > 0 && { headline }),
      ...(composers?.length > 0 && { composers }),
      operator,
      identifier: { entityType, entityId: entity?.id },
    };
  }

  #getTitlePart({ path, type, context }) {
    const { entityType } = context || {};

    if (type === ROUTE_PART_TYPE.RESERVED) {
      return this.#getReservedTitlePart({ path, entityType });
    }

    return null;
  }

  #checkIfPageEntity({ item, page }) {
    const { type, context } = item || {};

    if (type === ROUTE_PART_TYPE.RESERVED) {
      return false;
    }

    return page?.entityType === context?.entityType && page?.entity?.id === context?.entity?.id;
  }

  #getSearchFiltersForTitle({ filters }, page) {
    const relevantFilters = pick(filters, [
      FILTER_TYPES.WHO,
      FILTER_TYPES.WHERE,
      FILTER_TYPES.WHAT,
      FILTER_TYPES.GENRE,
    ]);

    const {
      [FILTER_TYPES.WHO]: whoFilter,
      [FILTER_TYPES.WHERE]: whereFilter,
      [FILTER_TYPES.WHAT]: whatFilters,
      [FILTER_TYPES.GENRE]: workTypeFilter,
    } = relevantFilters || {};

    const countOfWorkTypeFilters = workTypeFilter?.length;

    let genreFilter = workTypeFilter;
    let stagingFilter = null;

    if (countOfWorkTypeFilters === 1) {
      const { format, ...genre } = workTypeFilter[0];
      genreFilter = [genre];

      if (format) {
        stagingFilter = [
          {
            entityType: ENTITY_TYPES.STAGING_TYPE,
            entity: format,
          },
        ];
      }
    }

    return [whoFilter, whereFilter, whatFilters, genreFilter, stagingFilter].filter(array => {
      if (
        array?.length > 0 &&
        !array.some(item => item.entity?.id === page?.entity?.id && item.entityType === page?.entityType)
      ) {
        return true;
      }

      return false;
    });
  }

  #isPerformanceListingPage({ index, paths, page }) {
    if (index > 0) {
      return false;
    }

    const firstPathPart = paths?.[0];
    const lastPathPart = paths?.[paths.length - 1];

    const isFirstPartVariable =
      firstPathPart?.type === ROUTE_PART_TYPE.VARIABLE || firstPathPart?.context?.entityType === ENTITY_TYPES.DATE;
    const isLastPartVariable =
      lastPathPart?.type === ROUTE_PART_TYPE.VARIABLE || lastPathPart?.context?.entityType === ENTITY_TYPES.DATE;

    const isOverviewTab =
      firstPathPart?.path === lastPathPart?.path && this.#checkIfPageEntity({ item: firstPathPart, page });

    return isFirstPartVariable && isLastPartVariable && !isOverviewTab;
  }

  #getValidEntitiesForPathWithBasePath({ paths }) {
    const firstPathPart = paths?.[0];

    if (firstPathPart?.type === ROUTE_PART_TYPE.VARIABLE || !firstPathPart) {
      return [];
    }

    return ROUTE_CONFIGURATIONS[firstPathPart.path]?.validEntitiesForPathWithBasePath || [];
  }

  #shouldSkipFiltersForTitle({ page }) {
    const { entity, tab } = page || {};

    if (!entity?.id) {
      return false;
    }

    return ![ROUTE_RESERVED_KEYWORDS.performances].includes(tab);
  }

  getH1TitleParts(state) {
    const { paths, page, linkProps } = state || {};
    const searchFilters = this.#getSearchFiltersForTitle(linkProps, page);
    const dateFilter = linkProps?.filters?.[FILTER_TYPES.DATE] || null;
    const validEntitiesForPathWithBasePath = this.#getValidEntitiesForPathWithBasePath({ paths });
    const shouldSkipFiltersForTitle = this.#shouldSkipFiltersForTitle({ page });

    const pathParts = paths?.reduce(
      (acc, item, index) => {
        if (index === 0 && !shouldSkipFiltersForTitle && searchFilters?.length > 0) {
          const filterPaths = searchFilters.map(filter => filter.map(value => this.#getVariableTitlePart(value)));

          acc.push(...filterPaths);
        }

        const isPageEntity = this.#checkIfPageEntity({ item, page });

        if (validEntitiesForPathWithBasePath.includes(item?.context?.entityType) && !isPageEntity) {
          acc.push([this.#getVariableTitlePart(item.context)]);
        }

        if (index === 0 && !shouldSkipFiltersForTitle && dateFilter) {
          const datePart = this.#getDateTitlePart(dateFilter);

          if (datePart) {
            acc.push([datePart]);
          }
        }

        if (!shouldSkipFiltersForTitle && this.#isPerformanceListingPage({ index, paths, page })) {
          acc.push([
            {
              name: ROUTE_CONFIGURATIONS[ROUTE_RESERVED_KEYWORDS.productions].translationKey,
            },
          ]);
        }

        const titlePart = this.#getTitlePart(item);

        if (titlePart && (index > 0 || !page?.entity?.id)) {
          acc.push([titlePart]);
        }

        return acc;
      },
      page?.entity?.id ? [[this.#getVariableTitlePart(page, true)]] : [],
    );

    return pathParts;
  }
}

module.exports = H1TitleResolver;
