const SAFE_DECODE_MAP = {
  '%2F': '/',
  '%3F': '?',
  '%7E': '~',
  '%21': '!',
  '%7C': '|',
  '%3A': ':',
  '%3B': ';',
  '%2C': ',',
};

const SAFE_DECODE_REGEX = new RegExp(Object.keys(SAFE_DECODE_MAP).join('|'), 'g');

const safeDecodeURL = url => {
  if (!url || typeof url !== 'string') {
    return url;
  }

  const safeDecodedPath = url.replace(SAFE_DECODE_REGEX, encodedCharacter => SAFE_DECODE_MAP[encodedCharacter]);

  return safeDecodedPath;
};

module.exports = safeDecodeURL;
