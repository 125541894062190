/* eslint-disable no-dupe-class-members */
const { isEqual } = require('lodash');
const { ENTITY_TYPES, ROUTE_RESERVED_KEYWORDS } = require('../constants');
const { ROUTE_CONFIGURATIONS } = require('../constants/configurations');
const { getProductionStats } = require('../utils/apis');

const getPageEntityInfo = require('../utils/getPageEntityInfo');

class PageDetailsResolver {
  #session = null;

  #statsRetriever = getProductionStats;

  #previousPageInfo = null;

  #previousFilterParams = null;

  #serverTiming = [];

  #fetchTime = 0;

  constructor({ session, statsRetriever, previousPageInfo, previousFilterParams } = {}) {
    if (session) {
      this.#session = session;
    }

    if (statsRetriever) {
      this.#statsRetriever = statsRetriever;
    }

    if (previousPageInfo) {
      this.#previousPageInfo = previousPageInfo;
    }

    if (previousFilterParams) {
      this.#previousFilterParams = previousFilterParams;
    }
  }

  #isSearchable({ basePath }) {
    return !basePath || basePath === ROUTE_RESERVED_KEYWORDS.productions || !ROUTE_CONFIGURATIONS[basePath];
  }

  #isProductionListing({ basePath, tab, entity, pro }) {
    return (
      !basePath ||
      basePath === ROUTE_RESERVED_KEYWORDS.productions ||
      (entity?.id && tab === ROUTE_RESERVED_KEYWORDS.performances) ||
      (pro && !!entity?.id && (!tab || tab === ROUTE_RESERVED_KEYWORDS.calendar))
    );
  }

  #isFilterable({ basePath, pageTab, entityType, pro }) {
    if (pro) {
      return (
        !pageTab ||
        [
          ROUTE_RESERVED_KEYWORDS.calendar,
          ROUTE_RESERVED_KEYWORDS['role-graph'],
          ROUTE_RESERVED_KEYWORDS['company-graph'],
        ].includes(pageTab)
      );
    }
    const basePathConfiguration = ROUTE_CONFIGURATIONS[basePath];

    if (basePathConfiguration) {
      return basePathConfiguration?.allowedWithFilters || false;
    }

    if ([ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION].includes(entityType)) {
      return [ROUTE_RESERVED_KEYWORDS.performances].includes(pageTab);
    }

    return false;
  }

  async #getPageStats({ filterParams, language }) {
    let tickets = false;
    let online = false;

    try {
      const startTime = new Date().getTime();
      const response = await this.#statsRetriever({ filters: filterParams, language }, this.#session);
      const endTime = new Date().getTime();
      this.#fetchTime = endTime - startTime;
      this.#serverTiming = response?.serverTiming || [];

      if (response?.meta) {
        tickets = response.meta.has_tickets || 0;
        online = response.meta.has_online || 0;
      }
    } catch (err) {
      console.error(err);
    }

    return {
      tickets,
      online,
    };
  }

  getServerTiming({ processOrder, processName }) {
    const serverTimings = (this.#serverTiming || []).map((timer, index) => ({
      ...timer,
      name: `${processOrder}.${index + 1}-${processName}-api-${timer.name}`,
      description: `Stats API: ${timer.description}`,
    }));

    return [
      ...serverTimings,
      {
        name: `${processOrder}.${serverTimings.length + 1}-${processName}-api-fetch-frontend`,
        description: `Stats API (F): with network lag`,
        duration: this.#fetchTime,
      },
    ];
  }

  async getDetails(state) {
    const { linkProps, filterParams, language } = state || {};

    const { entityType, entity, attributes } = getPageEntityInfo({
      filters: linkProps?.filters,
      basePath: linkProps?.basePath,
      initEntityType: linkProps?.entityType,
      initEntity: linkProps?.entity,
    });

    const { edit, pro, tab, basePath } = linkProps;
    const pageTab = entity?.id ? tab : null;

    const searchable = this.#isSearchable({ basePath });
    const productionListing = this.#isProductionListing({ basePath, tab, entity, pro });
    const filterable = searchable || this.#isFilterable({ basePath, pageTab, pro });

    let stats = isEqual(this.#previousFilterParams, filterParams) ? this.#previousPageInfo?.stats : null;

    if (filterable && !stats) {
      stats = await this.#getPageStats({ filterParams, language });
    }

    return {
      entityType,
      ...(entity?.id && {
        entity,
        ...(attributes && { attributes }),
        edit,
        pro,
        tab: pageTab,
      }),
      productionListing,
      searchable,
      filterable,
      ...(stats && { stats }),
    };
  }
}

module.exports = PageDetailsResolver;
