/* eslint-disable no-unused-expressions */
import React from 'react';
import {
  compact,
  concat,
  defaultTo,
  filter,
  find,
  get,
  groupBy,
  isArray,
  isEmpty,
  isEqual,
  isObject,
  last,
  map,
  remove,
  sortBy,
  transform,
  orderBy,
  cloneDeep,
  partition,
  isNil,
  head,
  mapValues,
  uniq,
} from 'lodash';
import { getComposerNamesFromCreators } from 'utils/composer';
import {
  TP,
  PROFESSION_IDS,
  ORGANIZATION_TYPE_IDS,
  SUPPORTED_MEDIA_TAGS_TYPES_IDS,
  DATE_FORMATS,
} from 'constants/index';
import route from '../constants/routes';
import {
  AGENT_TYPE_ID,
  AOS_TYPE_ID,
  ARTIST_TYPE_ID,
  contactsIdentifiers,
  dateFormats,
  LOGO_TYPE,
  MAX_YEAR,
  MIN_YEAR,
  PERFORMANCE_TYPES_ID,
  showDateFormats,
  TAGS_AVAILABILITY_TYPES,
  ENTITIES,
  MEDIA_SHOWREEL_OPTIONS,
  MEDIA_SECTIONS,
  MEDIA_TYPES_IDS,
  REVIEW_ENITITES_TYPES,
  ENTITY_DISPLAY_STATUS,
  PRODUCTION_PARTNER_TYPE,
  VALIDATION_STATUS,
  VALIDATION_STATUSES,
  CONTRIBUTION_TYPES,
  RESTRICTED_COMPOSER_NAMES,
  RESTRICTED_WORK_NAMES,
  SINGER_PROFESSION_TYPE_ID,
  PRODUCTION_DATES,
  TAG_TYPE,
} from '../constants/consts';
import { MEDIA_TAGS_TYPES_IDS } from '../constants/index';
import { ENTITY_TYPES, DEPRECATED_ENTITY_TYPES } from '../operabase-router/constants';
import { i18n, Router } from '../src/i18n';
// eslint-disable-next-line import/no-cycle
import { getOptions } from '../containers/InteractiveFilterPanel/aggregationUtil';
// import { createDate } from './date';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import * as API from './API';
import { PAGERULES_ENDPOINT } from '../constants/endpointConstants';
import { getProfileProfession } from '../containers/Artist/utils';
import {
  PERFOMANCE_DATE_MODE_TYPES,
  PERFORMANCE_DATE_MODE,
  PERFORMANCE_DATE_TYPES,
} from '../containers/ProductionUpdateSteps/constants';
import { createDate, isDateToday, createDatewithTz, getTimestampForDisplay } from './date';

require('dotenv').config();

export const baseURL = process.env.FRONTBASE_URL.replace(/\/$/, '');
export const getDateParts = (string = '') => /(?<month>\w+)\s(?<year>\d+)/g.exec(string)?.groups;

export const createSelectFieldOptions = (
  arrayOfObjects,
  valueName,
  labelName = null,
  customProps = {},
  additionalProp,
) => {
  const label = labelName || valueName;
  let options = [];
  if (isArray(arrayOfObjects)) {
    options = arrayOfObjects.map(item => ({
      value: typeof valueName === 'function' ? valueName(item) : get(item, valueName, null),
      label: typeof labelName === 'function' ? labelName(item) : (get(item, label, '') || '').toString(),
      ...customProps,
      ...(additionalProp && item),
    }));
  }

  return options;
};

const emptyString = '';
const getNotNullFieldValue = (v, defaultValue) => (v === null ? defaultValue : v);

export const getOption = (object, valueName = 'id', labelName = 'name', props = {}, allProps) => {
  let option;
  if (object) {
    option = {
      ...props,
      ...(allProps && object),
      // lodash 'get' returns default value only if the value is resolved as undefined
      value: getNotNullFieldValue(get(object, valueName, emptyString), emptyString),
      label:
        typeof labelName === 'function'
          ? labelName(object)
          : getNotNullFieldValue(get(object, labelName, emptyString), emptyString),
    };
  }
  return option;
};

export const isCreatorExistInMusicalWork = (creator, musicalWork) => {
  if (!musicalWork || !musicalWork?.creators?.length) {
    return false;
  }

  return musicalWork.creators.some(c => c.creatorId === creator.entityId);
};

export const dateSendFormat = date => {
  const momentData = createDate(date);
  return momentData.isValid() ? momentData.format(dateFormats.basic) : null;
};

export const dateShowFormat = date => createDate(date).format(DATE_FORMATS.USER_FRIENDLY_DATE);

export const formatDateFromString = (date, format) => (date ? createDate(date).format(format) : null);

export const getRoutePartWithSlug = infoObject => `${get(infoObject, 'slug', '')}-${get(infoObject, 'id')}`;

export const getRouteWithSlug = (mainRoute, infoObject) => `${mainRoute}/${getRoutePartWithSlug(infoObject)}`;
export const getURLWithSlug = (mainRoute, infoObject) => `${baseURL}${getRouteWithSlug(mainRoute, infoObject)}`;

export const getRouteWithCountryAndSlug = (mainRoute, infoObject) =>
  `${mainRoute}/${get(infoObject, 'country.slug')}/${getRoutePartWithSlug(infoObject)}`;

export const getRouteWithYearCountryAndSlug = (mainRoute, infoObject) =>
  `${mainRoute}/${get(infoObject, 'year')}/${get(infoObject, 'country.slug')}/${getRoutePartWithSlug(infoObject)}`;

export const getDynamicRouteProps = (rootRoute, object) => ({
  href: route.DYNAMIC_ID_ROUTE(rootRoute),
  as: getRouteWithSlug(rootRoute, object),
});

export const getDynamicRoutePropsWithAction = (rootRoute, object) => ({
  href: route.DYNAMIC_ID_ROUTE_WITH_ACTION(rootRoute),
  as: getRouteWithSlug(rootRoute, object),
});

export const getDynamicRoutePropsForNewTab = (rootRoute, object, target) => ({
  ...getDynamicRouteProps(rootRoute, object),
  target: target || '_self',
});

export const removeWithTranslationProps = props => {
  const strippedProps = { ...props };
  delete strippedProps.defaultNS;
  delete strippedProps.i18n;
  delete strippedProps.i18nOptions;
  delete strippedProps.lng;
  delete strippedProps.reportNS;
  delete strippedProps.t;
  delete strippedProps.tReady;
  delete strippedProps.forwardedRef;
  delete strippedProps.subpath;
  delete strippedProps.signed_login_token;
  return strippedProps;
};

export const getPageParamFromQuery = (path, query) => {
  const cleanQuery = removeWithTranslationProps(query);
  const pageParams = Object.entries(cleanQuery);
  const compactQuery = compact(pageParams.map(([key, val]) => (val ? `${key}=${val}` : null)));
  const stringifiedPageParams = `?${compactQuery.join('&')}`;
  return `${path}${pageParams.length ? stringifiedPageParams : ''}`;
};

export const getIdFromSlugRoute = idWithSlug => idWithSlug && last(idWithSlug.split('-'))?.replace(/[^0-9]/g, '');
export const getSlugFromSlugRoute = idWithSlug => {
  if (typeof idWithSlug === 'string') {
    const slugParts = idWithSlug.split('-');
    if (slugParts.length > 1) {
      const lastItem = slugParts.pop();
      const reg = new RegExp('^\\d+$');
      if (!lastItem.match(reg)) {
        slugParts.push(lastItem);
      }
    }

    return slugParts.join('-');
  }
  return null;
};

export const getPeriodFromDates = (dateFrom, dateTo) => {
  if (dateFrom && dateTo) {
    const yearFrom = createDate(dateFrom).year();
    const yearTo = createDate(dateTo).year();
    return yearFrom === yearTo ? yearFrom : `${yearFrom} - ${yearTo}`;
  }

  if (dateFrom) {
    return createDate(dateFrom).year();
  }

  return undefined;
};

const pattern = /^(https?:\/\/)/;

export const addProtocolIfNeed = href => {
  if (typeof href !== 'string') {
    return href;
  }

  let newUrl = href?.trim().replace(/\s/g, '');

  if (newUrl.startsWith('mailto:') || newUrl.startsWith('tel:')) {
    return newUrl;
  }

  if (/^(:?\/\/)/.test(newUrl)) {
    newUrl = newUrl.replace(/^(:?\/\/)/, '');
    return `https://${newUrl}`;
  }
  if (!pattern.test(href)) {
    return `https://${newUrl}`;
  }

  return newUrl;
};

export const addMailTolIfNeed = address => {
  if (!address || typeof address !== 'string') {
    return address;
  }

  const trimAddress = address.trim().replace(/\s/g, '');

  if (trimAddress.startsWith('mailto:')) {
    return trimAddress;
  }

  return `mailto:${trimAddress}`;
};

export const addTelIfNeed = phone => {
  if (!phone || typeof phone !== 'string') {
    return phone;
  }

  const trimPhone = phone.trim().replace(/\s/g, '');

  if (trimPhone.startsWith('tel:')) {
    return trimPhone;
  }

  return `tel:${trimPhone}`;
};

export const reduceCollectionToTypes = (collection, types, reducer) => types.map(item => reducer(collection, item));

export const getDateRangeFormat = (minDate, maxDate) =>
  `${createDate(minDate).format('DD MMM YY')} - ${createDate(maxDate).format('DD MMM YY')}`;

export const getShortMonthYearFormat = date => createDate(date).format(dateFormats.shortTextMonthAndYear);

export const getShortDateRangeFormat = (minDate, maxDate) => {
  const minDateMoment = createDate(minDate);
  const maxDateMoment = createDate(maxDate);

  if (!minDate && maxDate) {
    return getShortMonthYearFormat(maxDate);
  }

  if (!maxDate) {
    return getShortMonthYearFormat(maxDate);
  }

  if (
    minDateMoment.format(dateFormats.shortTextMonthAndYear) === maxDateMoment.format(dateFormats.shortTextMonthAndYear)
  ) {
    return getShortMonthYearFormat(maxDate);
  }

  if (minDateMoment.format(dateFormats.year) === maxDateMoment.format(dateFormats.year)) {
    return `${createDate(minDate).format('MMM')} - ${getShortMonthYearFormat(maxDate)}`;
  }

  if (minDateMoment.format(dateFormats.year) !== maxDateMoment.format(dateFormats.year)) {
    return `${getShortMonthYearFormat(minDate)} - ${getShortMonthYearFormat(maxDate)}`;
  }

  return '';
};

export const sortOptionToLabel = (option, t) => {
  const direction = option.startsWith('-') ? `↑` : `↓`;
  const optionName = `${TP}.SORT_${option
    .replace(/^-/, '')
    .split('.')
    .join('_')
    .toUpperCase()}`;
  return `${t(optionName)} ${t(direction)}`;
};

export const createSortingOptions = (options, t, reverse = true) => {
  const sortingOptions = [];
  options.forEach(sortOption => {
    sortingOptions.push({
      value: sortOption,
      label: sortOptionToLabel(sortOption, t),
    });
    if (reverse)
      sortingOptions.push({
        value: `-${sortOption}`,
        label: sortOptionToLabel(`-${sortOption}`, t),
      });
  });
  return sortingOptions;
};

export const getCastGroupByRole = castGroupByRole => {
  let subObject;
  if (castGroupByRole.undefined) {
    subObject = groupBy(castGroupByRole.undefined, 'workRoleReference');
  }
  const newCastGroupByRole = { ...castGroupByRole };
  delete newCastGroupByRole.undefined;
  return { ...newCastGroupByRole, ...subObject };
};

export const getOtherColumnData = crewGroupByRole => {
  let subObject;
  if (crewGroupByRole.undefined) {
    subObject = groupBy(crewGroupByRole.undefined, 'workRoleReference');
  }
  const newOtherGroupByRole = { ...crewGroupByRole };
  return { ...newOtherGroupByRole, ...subObject };
};

export const getCrewGroupByRole = crewGroupByRole => {
  const newCrewGroupByRole = { ...crewGroupByRole };
  delete newCrewGroupByRole.undefined;
  return newCrewGroupByRole;
};

export const getFilteredTourDates = dates =>
  dates.filter(date => {
    const filteredPerformanceTypes = [];
    if (date?.isTour) filteredPerformanceTypes.push(date);
    return filteredPerformanceTypes?.length > 0;
  });

export const yearsToShowCountOptions = (fromYear = MIN_YEAR) => {
  const years = [];
  // eslint-disable-next-line no-plusplus
  for (let i = MAX_YEAR; i >= fromYear; i--) {
    years.push({ value: i, label: `${i}` });
  }
  return years;
};

export const getProductionTypeWithDates = production => {
  const dates = get(production, 'dates');
  let active = false;
  if (dates !== null) {
    const sortedDates = sortBy(
      dates?.filter(date => typeof get(date, 'date') === 'string'),
      [date => date.date],
    );

    sortedDates.forEach(date => {
      const dateValue = get(date, 'date');
      const momentDate = createDate(dateValue, dateFormats.basic);
      if (momentDate && momentDate.isValid()) {
        active = momentDate.format(dateFormats.basic) >= createDate().format(dateFormats.basic);
      }
    });
  }
  return active ? 'live' : 'past';
};

const transformDate = (date, momentDate, currentDate, isPerformed) => {
  const active = momentDate.format(dateFormats.basic) === createDate().format(dateFormats.basic);
  const past = momentDate.isBefore(currentDate);
  const cancelled = date?.isCancelled;
  const matinee = date?.isMatinee;
  const tour = date?.isTour;
  const performed = date?.performed || isPerformed(date);
  return {
    active,
    past,
    cancelled,
    matinee,
    tour,
    date: momentDate,
    startDateTime: date?.startDateTime,
    id: date?.id,
    month: momentDate.format(dateFormats.productionMonth),
    performed,
    abbreviatedMonth: momentDate.format('MMMM YYYY'),
    presentedBy: date?.presentedBy,
    isLiveStream: date?.mode === PERFORMANCE_DATE_MODE.LIVESTREAM,
  };
};

export const getGroupedProductionsDatesWithinRange = (
  production,
  range,
  isPerformed = () => false,
  groupedBy = 'month',
) => {
  const { date_from, date_to } = range;
  const dates = get(production, 'performances');
  const currentDate = createDate().format(dateFormats.basic);
  let groupedDates = [];
  if (dates !== null) {
    const sortedDates = sortBy(
      dates.filter(date => typeof get(date, 'startdate') === 'string'),
      [date => date.date],
    );
    const filterDates = sortedDates.filter(
      date => date.mode !== PERFOMANCE_DATE_MODE_TYPES[PERFORMANCE_DATE_TYPES.FULL_VIDEO],
    );

    groupedDates = groupBy(
      filterDates
        .map(date => {
          const dateValue = get(date, 'startDate');
          const momentDate = createDate(dateValue, dateFormats.basic);
          const dateFrom = createDate(date_from, dateFormats.basic);
          const dateTo = createDate(date_to, dateFormats.basic);
          if (
            momentDate.format(dateFormats.basic) >= dateFrom.format(dateFormats.basic) &&
            momentDate.format(dateFormats.basic) <= dateTo.format(dateFormats.basic)
          ) {
            return transformDate(date, momentDate, currentDate, isPerformed);
          }
          return null;
        })
        .filter(Boolean),
      groupedBy,
    );
  }
  return groupedDates;
};

export const getGroupedProductionsDateForTheCard = (
  production,
  isFromToday,
  isPerformed = () => false,
  groupedBy = 'month',
) => {
  const dates = get(production, 'performances');
  const currentDate = createDate().format(dateFormats.basic);
  let groupedDates = [];
  if (dates !== null) {
    const sortedDates = sortBy(
      dates?.filter(date => typeof get(date, 'startDate') === 'string'),
      [date => date.startDate],
    );
    const filterDates = sortedDates.filter(
      date => date.mode !== PERFOMANCE_DATE_MODE_TYPES[PERFORMANCE_DATE_TYPES.FULL_VIDEO],
    );

    const datesTransformedValues = [];
    filterDates.forEach(date => {
      const dateValue = get(date, 'startDate');
      const momentDate = createDate(dateValue, dateFormats.basic);
      if (isFromToday && momentDate.format(dateFormats.basic) >= createDate().format(dateFormats.basic)) {
        datesTransformedValues.push(transformDate(date, momentDate, currentDate, isPerformed));
      }
      if (!isFromToday) {
        datesTransformedValues.push(transformDate(date, momentDate, currentDate, isPerformed));
      }
      return null;
    });

    groupedDates = groupBy(datesTransformedValues, groupedBy);
  }

  return groupedDates;
};

export const getChipsForProductionCard = production => {
  const isWorldPremiere = !!production?.performances?.find(item => item.isWorldPremiere);
  const isPremiere = !!production?.performances?.find(item => item.isPremiere);
  const isConcert = false;
  const isNewProduction = production?.isNewProduction ?? false;
  const isCancelled = !!production?.isCancelled;
  const isFestival = !!production?.contributions?.some(
    contribution => contribution?.organization?.organizationType?.id === ORGANIZATION_TYPE_IDS.FESTIVAL,
  );
  const isDoubleBill = !!production?.works?.length > 1;
  const isRecital = false; // isConcert;
  const isMatinee = !!production?.performances?.find(item => item.isMatinee);
  const isTour = !!production?.performances?.find(item => item.isTour);
  const isRevival = !!production?.isRevival;
  return [
    isWorldPremiere,
    isPremiere,
    isConcert,
    isNewProduction,
    isCancelled,
    isFestival,
    isDoubleBill,
    isRecital,
    isMatinee,
    isTour,
    isRevival,
  ];
};

export const getChipsForPerformanceCard = performance => {
  const isWorldPremiere = !!performance?.isWorldPremiere;
  const isPremiere = !!performance?.isPremiere;
  const isConcert = false; // !!performance?.productionTypes?.id === PRODUCTION_TYPES_ID.CONCERT;
  const isNewProduction = performance?.isNewProduction ?? false;
  const isCancelled = !!performance?.isCancelled;
  const isFestival = !!performance?.festivals?.length > 0;
  const isDoubleBill = !!performance?.works?.length > 1;
  const isRecital = false; // !!performance?.productionTypes?.id === PRODUCTION_TYPES_ID.CONCERT;
  const isMatinee = !!performance.isMatinee;
  const isTour = !!performance.isTour;
  const isRevival = !!performance?.isRevival;

  return [
    isWorldPremiere,
    isPremiere,
    isConcert,
    isNewProduction,
    isCancelled,
    isFestival,
    isDoubleBill,
    isRecital,
    isMatinee,
    isTour,
    isRevival,
  ];
};

export const getProductionLabelInitial = index => {
  switch (index) {
    case 0:
      return 'WP';
    case 1:
      return 'NP';
    case 2:
      return 'C';
    case 3:
      return 'New Production';
    case 5:
      return 'Festival';
    case 6:
      return 'DB';
    case 7:
      return 'R';
    case 8:
      return 'm';
    case 9:
      return 't';
    default:
      return '';
  }
};

export const getProductionLabelKeys = index => {
  switch (index) {
    case 0:
      return `${TP}.FN_PERFORMACE_TYPE_WORLD_PREMIERE`;
    case 1:
      return `${TP}.FN_PERFORMACE_TYPE_NATIONAL_PREMIERE`;
    case 2:
      return `${TP}.FN_PERFORMACE_TYPE_CONCERT`;
    case 3:
      return `${TP}.FN_PERFORMACE_TYPE_NEW_PRODUCTION`;
    case 4:
      return `${TP}.PERFORMANCE_CANCELLED`;
    case 5:
      return `${TP}.FN_PERFORMACE_TYPE_FESTIVAL`;
    case 6:
      return `${TP}.FN_DOUBLE_TRIPLE_BILL`;
    case 7:
      return `${TP}.FN_RECITAL`;
    case 8:
      return `${TP}.FN_PERFORMACE_TYPE_MATINEE`;
    case 9:
      return `${TP}.FN_PERFORMACE_TYPE_TOUR`;
    case 10:
      return `${TP}.FN_REVIVAL`;
    case 11:
      return `${TP}.FN_LIVESTREAM`;
    case 12:
      return `${TP}.FN_VIDEO_ON_DEMAND`;
    default:
      return '';
  }
};

export const PRODUCTION_TAG_LABEL = {
  NEW_PRODUCTION: 'New Production',
  FESTIVAL: 'Festival',
  Cancelled: 'cancelled',
};

export const fullDateConversion = (date, lang = 'en', withDay = false) => {
  const options = withDay
    ? { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    : { year: 'numeric', month: 'long', day: 'numeric' };
  return date && new Date(date).toLocaleDateString(lang, options);
};

export const partialDateConversion = ({ year, month, day }) => {
  if (year && month && day) {
    return showDateFormats.getMonthDayAndYear(year, month, day);
  }
  if (year && month) {
    return showDateFormats.getMonthAndYear(year, month);
  }
  if (year && day) {
    return showDateFormats.getDayAndYear(year, day);
  }
  if (month && day) {
    return showDateFormats.getMonthAndDay(month, day);
  }
  if (year) {
    return showDateFormats.getYear(year);
  }
  if (month) {
    return showDateFormats.getMonth(month);
  }
  if (day) {
    return showDateFormats.getDay(day);
  }
  if (!year && !month && !day) {
    return null;
  }
};

export const profileDateConversion = (personal, lang, keys, location = true) => {
  const { birthDate, birthYear, birthMonth, birthDay, deathDate, deathYear, deathMonth, deathDay } = keys;

  if (Object.values(keys).filter(Boolean).length) {
    const dateOfBirth =
      fullDateConversion(get(personal, birthDate), lang) ||
      partialDateConversion(
        { year: get(personal, birthYear), month: get(personal, birthMonth), day: get(personal, birthDay) },
        lang,
      );
    const dateOfDeath =
      fullDateConversion(get(personal, deathDate), lang) ||
      partialDateConversion(
        { year: get(personal, deathYear), month: get(personal, deathMonth), day: get(personal, deathDay) },
        lang,
      );

    const locationB = getBirthLocation(personal, keys);
    const locationD = getDeathLocation(personal, keys);

    let profileDate = '';
    if (dateOfBirth) {
      profileDate += dateOfBirth;
      if (locationB && location) {
        profileDate = `${profileDate} (${locationB}) `;
      }
    } else if (dateOfDeath) {
      profileDate += '? -';
      profileDate += dateOfDeath;
      if (locationD && location) {
        profileDate = `${profileDate} in ${locationD}`;
      }
      return profileDate;
    } else {
      return null;
    }

    if (dateOfDeath) {
      profileDate += ' - ';
      profileDate += dateOfDeath;
      if (locationD && location) {
        profileDate = `${profileDate} (${locationD}) `;
      }
    }

    return profileDate;
  }
  return 'Invalid keys';
};

const getBirthLocation = (personal, keys) => {
  const {
    birthCountry: birthCountryKey,
    birthCountryReference: birthCountryReferenceKey,
    birthCity: birthCityKey,
    birthCityReference: birthCityReferenceKey,
  } = keys;

  const birthCity = get(personal, birthCityKey, '');
  const birthCityReference = get(personal, birthCityReferenceKey);
  const birthCountry = get(personal, birthCountryKey, '');
  const birthCountryReference = get(personal, birthCountryReferenceKey);

  let location = '';
  if (birthCityReference || birthCity) {
    location = birthCityReference || birthCity?.name;
  }
  if (birthCountry || birthCountryReference) {
    if (birthCityReference || birthCity) {
      location += birthCountryReference ? `, ${birthCountryReference}` : `, ${birthCountry?.name}`;
    } else {
      location += birthCountryReference ? `${birthCountryReference}` : `${birthCountry?.name}`;
    }
  }
  return location;
};

const getDeathLocation = (personal, keys) => {
  const {
    deathCountry: deathCountryKey,
    deathCountryReference: deathCountryReferenceKey,
    deathCity: deathCityKey,
    deathCityReference: deathCityReferenceKey,
  } = keys;

  const deathCity = get(personal, deathCityKey, '');
  const deathCityReference = get(personal, deathCityReferenceKey);
  const deathCountry = get(personal, deathCountryKey, '');
  const deathCountryReference = get(personal, deathCountryReferenceKey);

  let location = '';
  if (deathCityReference || deathCity) {
    location = deathCityReference || deathCity?.name;
  }
  if (deathCountry || deathCountryReference) {
    if (deathCityReference || deathCity) {
      location += deathCountryReference ? `, ${deathCountryReference}` : `, ${deathCountry?.name}`;
    } else {
      location += deathCountryReference ? `${deathCountryReference}` : `${deathCountry?.name}`;
    }
  }
  return location;
};

export const buildSocialLinks = contacts =>
  contacts &&
  contacts
    .filter(c => [contactsIdentifiers.sns, contactsIdentifiers.other].includes(get(c, 'contactType.id')))
    .map(item => ({
      id: get(item, 'id'),
      link: get(item, 'value'),
      type: get(item, 'sns') || 'other',
    }));

export const swap = (arr, from, to) => arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);

/**
 * Get artist profile title
 *
 * @param profile
 * @returns {string}
 */
export const getFullProfileTitle = profile => {
  const name = get(profile, 'name');
  const profession = get(profile, 'profession.name') || get(profile, 'summary');
  return [name, profession].filter(item => item).join(', ');
};

export const getEditTitle = (t, title) => {
  const result = [];
  if (title && typeof title === 'string') {
    result.push(title);
  }
  if (t && typeof t === 'function') {
    result.push(t(`${TP}.m_EDIT`));
  }
  return result.join(', ');
};

export const changeLanguageForUrl = (languageCode, url) => {
  try {
    const newUrl = new URL(url);
    if (!newUrl.pathname) {
      return false;
    }
    // get the pathnames
    const paths = newUrl.pathname.split('/');
    // check if language code is matching
    if (paths[paths.length - 1] === languageCode) {
      return false;
    }
    // replace the language code
    paths.splice(paths.length - 1, 1, languageCode);
    newUrl.pathname = paths.join('/');
    return newUrl.toString();
  } catch (err) {
    return false;
  }
};

export const getQueryParamFromPath = (path, paramName) => {
  const regex = new RegExp(`[?&]${paramName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(path);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const scrollToInvalidField = () => {
  setTimeout(() => {
    const invalidElements = document.getElementsByClassName('Mui-error');
    if (invalidElements.length) {
      invalidElements[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
    } else {
      const calendarError = document.getElementsByClassName('tss-cache-ljo8au-field-error');

      if (calendarError?.length > 0) {
        calendarError[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }
  }, 0);
};

export const buildLocationDetailsProduction = production => {
  const venues = get(production, 'venues', []);
  const festivals = get(production, 'festivals', null);
  const company = production?.companies?.find(c => c?.partnerType === PRODUCTION_PARTNER_TYPE.PRODUCER);
  const locationDetails = [];
  const coProducedCompanies = production?.companies?.filter(
    c => c?.partnerType === PRODUCTION_PARTNER_TYPE.CO_PRODUCER,
  );
  if (!isEmpty(company)) {
    if (company?.id)
      locationDetails.push({
        label: 'company',
        value: company?.name,
        href: `${route.COMPANIES}/[country]/[id]/[[...action]]`,
        as: `${route.COMPANIES}/${get(company, 'country.slug', '')}/${getRoutePartWithSlug(company)}`,
        image: company?.image,
      });
    else {
      locationDetails.push({
        label: 'company',
        value: company?.name,
        image: company?.image,
      });
    }
  }

  if (!isEmpty(coProducedCompanies)) {
    coProducedCompanies.map(coCompany => {
      locationDetails.push({
        label: 'co-produced',
        type: `${TP}.FN_CO_PRODUCED`,
        value: coCompany?.name,
        href: `${route.COMPANIES}/[country]/[id]/[[...action]]`,
        as: `${route.COMPANIES}/${get(coCompany, 'country.slug', '')}/${getRoutePartWithSlug(coCompany)}`,
      });
    });
  }

  if (festivals && festivals.length > 0) {
    festivals.map(festival => {
      locationDetails.push({
        label: 'festival',
        type: `${TP}.FN_PERFORMACE_TYPE_PART_OF_FESTIVAL`,
        value: festival?.name,
        href: `${route.FESTIVALS}/[country]/[id]/[[...action]]`,
        as: `${route.FESTIVALS}/${get(festival, 'country.slug')}/${getRoutePartWithSlug(festival)}`,
      });
    });
  }

  if (venues?.length > 0) {
    const locationAddress = `${venues[0]?.name}, ${getCountryCity(venues[0])}`;
    locationDetails.push({
      label: 'location',
      value: venues?.length > 1 ? `${locationAddress} (+${venues?.length - 1} more)` : locationAddress,
      href: `${route.VENUES}/[country]/[id]/[[...action]]`,
      as: getRouteWithSlug(`${route.VENUES}/${get(venues[0], 'country.slug')}`, venues[0]),
    });
  }
  return locationDetails;
};

export const getCastAndCrew = production => concat(get(production, 'crew', []), get(production, 'casts', []));

export const buildContacts = contacts => {
  const contactData = contacts?.map(item => {
    const updatedItem = item ? { ...item } : {};
    if (item?.contactType?.id === 1) {
      updatedItem.href = `mailto:${item.value}`;
    } else if (item?.contactType?.id === 3) {
      updatedItem.href = item.value;
      updatedItem.target = '_blank';
    }
    updatedItem.contactTypeId = item?.contactType?.id;
    return updatedItem;
  });

  const filteredData = contactData?.filter(item => item?.contactType?.id !== 2);
  const sortContacts = sortBy(filteredData, 'contactType.id');
  const data = groupBy(sortContacts, 'contactType.id');
  return data;
};

export const getContactsStateForUpdate = (formValue, contacts) => {
  const newContacts = {};
  const updatedContacts = {};
  const deletedContactsIds = [];
  const flatFormValues = Object.values(formValue).flat();

  // if field array item was removed
  contacts.forEach(contact => {
    if (contact.id && !flatFormValues.find(value => contact?.id === value?.id)) {
      deletedContactsIds.push(contact?.id);
    }
  });
  Object.keys(formValue).forEach(key => {
    const fields = get(formValue, key);
    if (!fields instanceof Array) {
      fields = [fields];
    }
    for (const field of fields) {
      if (field?.value) {
        if (field?.id) {
          const initField = contacts?.find(el => el.id === field?.id);
          if (
            initField?.value === field?.value &&
            initField?.name === field?.name &&
            isEqual(
              (initField?.privacyAttributes || []).map(pa => pa.id),
              (field?.privacyAttributes || []).map(pa => pa.id),
            )
          ) {
            continue;
          }
          if (!updatedContacts[key]) {
            updatedContacts[key] = [];
          }
          updatedContacts[key].push(field);
        } else {
          if (!newContacts[key]) {
            newContacts[key] = [];
          }
          newContacts[key].push(field);
        }
      } else if (field?.id) {
        deletedContactsIds.push(field?.id);
      }
    }
  });
  return { newContacts, updatedContacts, deletedContactsIds };
};

export const checkIfValueExist = (list, value) => find(list, { slug: value });

export const getProductionWork = production => production?.productionWorks?.[0]?.work;
export const getProductionCompany = production =>
  production?.companies?.find(item => item?.isPartner === false) || { name: production?.producerReference };

export const getArtisLabelNameWithRole = profile => {
  const profession = getProfileProfession(profile);
  return profession
    ? `${profile?.fullName?.length > 0 ? profile?.fullName : profile?.name} (${profession})`
    : profile?.name;
};

export const getIdObjectOrNull = value => (value ? { id: value } : null);

export const getUnusedLangsOptions = (items, languages) => {
  const idsOfUsedLangs = items?.map(d => d?.language?.id) || [];
  const langsToClean = [...languages];
  remove(langsToClean, l => idsOfUsedLangs.includes(l?.id));
  return createSelectFieldOptions(langsToClean, 'id', 'name', {}, true);
};

export const isCustomProductionName = production => production?.name?.length > 0;

export const romanize = num => {
  const lookup = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 };
  let roman = '';
  let i;
  for (i in lookup) {
    while (num >= lookup[i]) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
};

export const updateMediaStructure = incomArray => {
  if (incomArray && isArray(incomArray)) {
    return incomArray.map(image => {
      const taggedProfiles = [];
      const otherTags = [];
      const tags = [...(image?.tags || [])];
      tags.forEach(tag => {
        if (
          tag?.tagType?.id === MEDIA_TAGS_TYPES_IDS.PROFILE &&
          (tag?.availabilityTypes || []).includes(TAGS_AVAILABILITY_TYPES.LABEL)
        ) {
          taggedProfiles.push(tag);
        } else {
          otherTags.push(tag);
        }
      });
      return {
        ...image,
        selectedProduction: get(
          (image?.tags || []).find(tag => tag?.tagType?.id === MEDIA_TAGS_TYPES_IDS.PRODUCTION),
          'production',
          null,
        ),
        profileTags: taggedProfiles,
        defaultTags: otherTags,
      };
    });
  }
  return [];
};

export const fetchAbortController = () => {
  let abortController;
  if (typeof window !== 'undefined' && AbortController) {
    abortController = new AbortController();
  }
  return abortController;
};

export const getTagsByType = (tags, tagTypeId) => filter(tags, { tagType: { id: tagTypeId } });

export const filterComposers = composers =>
  composers && composers.length > 0 ? composers.filter(composer => composer.slug !== 'various') : [];

export const isOwner = (entity, typeId, id) => entity?.forEntity === typeId && entity?.forEntityId === id;

export const getProductionOwnerId = (typeId, production) => {
  if (production?.forEntity && production?.forEntityId && production?.forEntity === typeId) {
    return production?.forEntityId;
  }
  return null;
};

export const checkDateForProductionDeletion = (allowedCountOfDates, publishedDate) => {
  if (!allowedCountOfDates) {
    return false;
  }

  const minDeleteDateForPublishedProduction = +allowedCountOfDates;
  let isDateValid = true;
  if (minDeleteDateForPublishedProduction || minDeleteDateForPublishedProduction === 0) {
    const edgeDateOfDeletion = new Date(publishedDate);
    edgeDateOfDeletion.setDate(edgeDateOfDeletion.getDate() + minDeleteDateForPublishedProduction);
    isDateValid = new Date() <= new Date(edgeDateOfDeletion);
  }
  return isDateValid;
};

export const canDoProductionAction = (permission, typeId, entityId, production) =>
  permission && entityId === getProductionOwnerId(typeId, production);

export const validateDate = (value, context, date) => {
  const day = value?.value;
  const month = get(context.parent, date.month.name)?.value;
  const year = get(context.parent, date.year.name)?.value;
  if (day && month && year) {
    return createDate(`${month}/${day}/${year}`, 'M/DD/YYYY').isValid();
  }
  if (day && month) {
    if (month === 2) return day < 30;
    return createDate(`${month}/${day}/${createDate().year}`, 'M/DD/YYYY').isValid();
  }
  return true;
};

export const getDateDifference = (minDate, maxDate) =>
  createDate(minDate, 'YYYY.MM.DD').diff(createDate(maxDate, 'YYYY.MM.DD'), 'days');

export const getDateFormat = (minDate, maxDate) =>
  `${createDate(minDate).format(dateFormats.productionMonth)} - ${createDate(maxDate).format(
    dateFormats.productionMonth,
  )}`;

export const getDateWithDaysFormat = (minDate, maxDate) =>
  createDate(minDate).format('YYYY') === createDate(maxDate).format('YYYY')
    ? `${createDate(minDate).format('D MMM')} - ${createDate(maxDate).format('D MMM YYYY')}`
    : `${createDate(minDate).format('D MMM YYYY')} - ${createDate(maxDate).format('D MMM YYYY')}`;

export const getDatesInSameMonth = (minDate, maxDate) =>
  createDate(minDate).format('D') === createDate(maxDate).format('D')
    ? `${createDate(maxDate).format('D MMM YYYY')}`
    : `${createDate(minDate).format('D')} - ${createDate(maxDate).format('D MMM YYYY')}`;

export const getCurrentTimezoneDate = stringDate => {
  const [year, month, day] = stringDate.split('-');
  return new Date(+year, +month - 1, +day);
};

export const getPerformanceDate = (date, format) => createDate(date).format(format);

export const getEntityAndTypeFromContributor = contributor => {
  const { organization, profile } = contributor || {};
  if (organization) {
    return {
      entityType: ENTITY_TYPES.ORGANIZATION,
      entity: organization,
    };
  }
  if (profile) {
    return {
      entityType: ENTITY_TYPES.PROFILE,
      entity: profile,
    };
  }
  return {};
};

export const getArtistRoles = (production, profile, t) => {
  if (!profile?.id) {
    return null;
  }

  const SEPARATOR = ' / ';
  const contributions = get(production, 'contributions', []);
  const profileId = profile?.id || '-1';

  const casts = [
    ...contributions.filter(c => c?.contributionType === CONTRIBUTION_TYPES.CAST),
    ...contributions.filter(c => c?.contributionType === CONTRIBUTION_TYPES.CREW),
    ...contributions.filter(
      c => c?.contributionType !== CONTRIBUTION_TYPES.CREW && c?.contributionType !== CONTRIBUTION_TYPES.CAST,
    ),
    ...get(production, 'other', []),
  ].filter(c => {
    const { entity } = getEntityAndTypeFromContributor(c);
    return entity?.id ? entity?.id === profileId : profileId === '-1';
  });
  if (casts.length === 0) {
    return null;
  }
  const roles = casts
    .map(cast => {
      if (cast?.professionReference) {
        return cast?.professionReference;
      }
      if (cast?.workRoleReference) {
        return cast?.workRoleReference;
      }
      if (cast?.workRole) {
        return cast?.workRole?.original_name || cast?.workRole?.name;
      }

      if (cast?.ensembleType?.name) {
        return cast?.ensembleType?.name;
      }

      if (cast?.credit) {
        return cast?.credit;
      }

      return cast?.profession?.name;
    })
    .filter(c => c && c.length > 0);

  const type = casts
    .filter(cast => cast?.profession?.id !== SINGER_PROFESSION_TYPE_ID)
    .filter(c => c && c.length > 0)
    .join(SEPARATOR);
  return { roles: roles.join(SEPARATOR), type, uniqueRoles: arrayToStringWithAnd(t, uniq(roles), ', ') };
};

export const isFutureDatesExist = data =>
  data?.find(o => createDate(o.startDate).format(dateFormats.basic) >= createDate().format(dateFormats.basic));

export const getPeriodString = (date1, date2, format = dateFormats.shortMonthEvent) => {
  const dates = compact([date1, date2]);
  return dates.map(d => createDate(d).format(format)).join(' - ');
};

export const getCountryCity = (data, separator = ', ', reverse = false) => {
  const result = [];

  const country = getWithDefault(data, 'country.name');
  if (country) {
    result.push(country);
  }
  const city = getWithDefault(data, 'city.name');
  if (city) {
    result.push(city);
  }

  if (reverse) {
    result.reverse();
  }

  return result.join(separator);
};

export const getFestivalsInfo = festival => {
  const logo = get(festival, 'logo.medium', '');
  const name = get(festival, 'name', '');
  return {
    name,
    region: getCountryCity(festival, ', ', true),
    logo,
    date: getPeriodString(festival.min_date, festival.max_date),
    logoType: LOGO_TYPE.SQUARE,
  };
};

export const getCompanyInfo = company => {
  const logo = get(company, 'logo.medium', '');
  const name = get(company, 'name', '');
  return {
    name,
    region: getCountryCity(company),
    logo,
    logoType: LOGO_TYPE.CIRCLE,
  };
};

// make rental similar to production structure
export const getRentalInfo = rental => ({
  ...rental,
  name: get(rental, 'production.name', '') || getWorkOriginalNameLabel(rental?.work),
  production: {
    ...rental?.production,
    work: {
      ...rental?.work,
      composer: rental?.workComposer,
    },
    website: rental?.url,
    minDate: rental?.minDate,
    maxDate: rental?.maxDate,
    company: rental?.company,
  },
});

const fetchOptionsForSubFilters = async (options, query, serverCookies, language) => {
  const { endpoint, ...restOfQuery } = query;
  const allPromises = options.map(option => {
    if (option.type === 'date') return { [option.type]: [{ id: '-1', name: option.label }] };
    return getOptions(
      { endpoint, query: { ...restOfQuery, aggregation_type: option.type } },
      serverCookies,
      language,
    ).then(data => {
      if (data?.data?.length > 0) return { [option.type]: [{ id: '-1', name: option.label }, ...data.data] };
    });
  });
  return Promise.all(allPromises);
};

export const prefetchSubfilterOptions = async (query, serverCookies, language) => {
  const subFilterOptions = [{ type: 'language_id' }, { type: 'surtitle_id' }, { type: 'country_id' }, { type: 'date' }];
  await fetchOptionsForSubFilters(
    subFilterOptions,
    {
      endpoint: 'productions/aggregations',
      ...query,
    },
    serverCookies,
    language,
  );
};

export const getPremierPerformances = types =>
  types?.filter(
    c =>
      c.id === PERFORMANCE_TYPES_ID.WORLD_PREMIERE ||
      c.id === PERFORMANCE_TYPES_ID.NATIONAL_PREMIERE ||
      c.id === PERFORMANCE_TYPES_ID.DOUBLE_BILL ||
      c.id === PERFORMANCE_TYPES_ID.CONCERT ||
      c.id === PERFORMANCE_TYPES_ID.TOUR ||
      c.id === PERFORMANCE_TYPES_ID.MATINEE ||
      c.id === PERFORMANCE_TYPES_ID.NEW_PRODUCTION,
  );

function isPresentAndTruthy(val) {
  return typeof val !== 'undefined' && val;
}

/**
 * If all of the viewby filters are true , dont send any viewBy filters
 * if one or more of them is true, send the respective values only
 * filters accounted for live_stream and tickets
 * This is where tickets->has_tickets and live_stream -> has_live_stream used
 * to transform before sending it to API
 * @param query
 */
export const conditionallyRemoveViewByFilters = query => {
  const { live_stream, tickets, ...rest } = query;
  let finalQuery = { ...rest };
  // Uncomment below to make live_stream logic to work
  // if (isPresentAndTruthy(live_stream) && isPresentAndTruthy(tickets)) {
  //   return rest;
  // }
  // if (isPresentAndTruthy(live_stream)) {
  //   finalQuery = { ...finalQuery, has_live_stream: live_stream };
  // }
  if (isPresentAndTruthy(tickets)) {
    finalQuery = { ...finalQuery, has_tickets: tickets };
  }
  return finalQuery;
};

/**
 * Same logic as conditionallyRemoveViewByFilters
 * Retains "tickets" as query params instead of has_tickets
 * @param query
 * @returns {{tickets: *}}
 */
export const conditionallyRemoveViewByFiltersForRoute = query => {
  const { live_stream, tickets, ...rest } = query;
  let finalQuery = { ...rest };
  // Uncomment below to make live_stream logic to work
  // if (isPresentAndTruthy(live_stream) && isPresentAndTruthy(tickets)) {
  //   return rest;
  // }
  // if (isPresentAndTruthy(live_stream)) {
  //   finalQuery = { ...finalQuery, live_stream: live_stream };
  // }
  if (isPresentAndTruthy(tickets)) {
    finalQuery = { ...finalQuery, tickets };
  }
  return finalQuery;
};

const getContributor = ({ production, entity }) =>
  production?.contributions?.find(contributor => {
    const { entity: contributorEntity } = getEntityAndTypeFromContributor(contributor);
    return contributorEntity?.id === entity?.id;
  });

export const getRedMaskStatus = ({ production, entity }) => getContributor({ production, entity })?.redMaskStatus;

export const isRedMask = ({ production, entity }) =>
  getContributor({ production, entity })?.redMaskStatus === VALIDATION_STATUS.APPROVED;

export const isRedMaskPending = ({ production, entity }) =>
  getContributor({ production, entity })?.redMaskStatus === VALIDATION_STATUS.PENDING;

export const isRedMaskRejected = ({ production, entity }) =>
  getContributor({ production, entity })?.redMaskStatus === VALIDATION_STATUS.REJECTED;

export const isRejectedProduction = production => production?.validationStatus?.id === VALIDATION_STATUSES.REJECTED;

export const isInvalidProductionAction = production =>
  isRejectedProduction(production) ||
  (production?.producerApproved && production?.validationStatus?.id !== VALIDATION_STATUSES.APPROVED);

export const getVisibleStatus = (production, isVisible) => {
  if (isInvalidProductionAction(production)) {
    return false;
  }

  return isVisible;
};

export const getWorkRoleTranslationName = performance => {
  const work = performance?.workRole || performance?.work || performance?.role;
  return work?.name && work?.name !== work?.original_name ? work?.name : null;
};

export const getWorkRoleCanonicalName = performance => {
  const work = performance?.workRole || performance?.work || performance?.role;
  return work?.original_name || work?.name;
};

/**
 * Converts array to a string with `and`
 *
 * @param t
 * @param data
 * @param separator
 *
 * @returns {string}
 */
export const arrayToStringWithAnd = (t, data, separator = ', ') => {
  if (!Array.isArray(data) || typeof t !== 'function') {
    return '';
  }
  if (data.length === 0) {
    return '';
  }
  if (data.length === 1) {
    return data[0];
  }
  const lastItem = data.pop();
  const and = t(`${TP}.AND`);
  return `${data.join(separator)} ${and} ${lastItem}`;
};

export const isPastDate = date => {
  const momentDate = createDate(date);
  return momentDate.isValid() ? momentDate.isBefore(createDate(), 'day') : false;
};

export const getProductionName = production => {
  if (production?.name) return production?.name;
  if (production?.productionWorks?.length > 0) {
    // check if there are any musical works
    const primaryMusicalWork = production?.productionWorks?.[0];
    return primaryMusicalWork?.work?.original_name;
  }

  // TODO: keeping this for backward compatibility; need to remove
  return production?.work?.original_name;
};

export const pastDateUpTo3Years = createDate()
  .subtract(13, 'year')
  .toDate();
export const futureDateUpTo1Years = createDate()
  .add(1, 'year')
  .toDate();

export const getWithDefault = (obj, valuePath, defaultValue) => defaultTo(get(obj, valuePath), defaultValue);

export const getWorkOriginalNameLabel = (work, withComposer = false) =>
  `${work?.original_name || work?.name || ''}${
    work?.name && work?.original_name !== work?.name ? ` (${work?.name})` : ''
  }${withComposer && get(work, 'composers[0].name') ? `, ${get(work, 'composers[0].name', '')}` : ''}`;

export const getCityWithAreasLabel = city => {
  const areas = city?.areas?.map(area => area?.name).join(', ');
  return `${city?.name} ${areas?.length > 0 ? `(${areas})` : ''}`;
};

export const getDiffernceBetweenObject = (object, base) => {
  const changes = (object, base) =>
    transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });

  return changes(object, base);
};

export const isPerformanceTypePresent = (types, typeId) => find(types, { id: typeId });

export const convertLineBreaksIntoSpaces = initialString => {
  if (typeof initialString === 'string') {
    return initialString.replace(/\n/g, ' ');
  }
  return initialString;
};

export const getProfileFromCastsByProfession = (casts, profession) => {
  let result;
  if (Array.isArray(casts)) {
    const cast = casts.find(item => get(item, 'profession.slug') === profession);
    result = get(cast, 'profile');
  }
  return result;
};

export const checkForAutoRedirect = async (statusCode, autoRedirectParams, ctx) => {
  if (statusCode === 404 && autoRedirectParams?.enabled && !ctx?.res?.writableEnded) {
    try {
      // Attempting to find moved page
      const queryToApi =
        autoRedirectParams?.id && autoRedirectParams?.type
          ? { target_entity_id: autoRedirectParams.id, target_entity_type: autoRedirectParams.type }
          : { url: `${ctx?.req?.url || ctx?.asPath}` };
      const response = await API.getList(PAGERULES_ENDPOINT, { queryParams: queryToApi });
      const newPage = get(response, 'data.data[0]');
      if (newPage) {
        let lng = '';
        if (!/.+\/[a-zA-Z]{2,3}$/.test(newPage.target)) {
          lng = `/${ctx?.req?.lng ? ctx.req.lng : 'en'}`;
        }
        if (typeof window === 'undefined') {
          ctx.res.writeHead(301, {
            Location: `${newPage.target}${lng}`,
            'Content-Type': 'text/html; charset=utf-8',
          });
          ctx.res.end();
        } else {
          window.location.href = `${newPage.target}${lng}`;
        }
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
};

export function fixQueryParamId(queryParam, defaultValue) {
  if (typeof queryParam !== 'undefined') {
    const idAsStr = queryParam; // remove any slash which could potentially throw system error
    const id = Number.parseInt(idAsStr, 10);
    if (Number.isNaN(id) || idAsStr.toString() !== id.toString()) return defaultValue;
    return id;
  }
  return defaultValue;
}

export function isNonNumericValue(id) {
  return typeof fixQueryParamId(id) === 'undefined';
}

export function getLocale(context) {
  return i18n?.language?.toLowerCase() || context?.req?.language;
}

export function redirect(res, url) {
  if (res) {
    res.writeHead(301, {
      Location: url,
      'Content-Type': 'text/html; charset=utf-8',
    });
    res.end();
  } else if (window && window.location) {
    window.location.href = url;
  }
  return {
    statusCode: 301,
  };
}

export const getPerformanceRoleByArtistName = (performance, name) =>
  performance?.casts?.find(item => item?.profile?.name === name);

export function getWorkRoleName(role) {
  return get(role, 'workRole.name') || get(role, 'workRole.original_name') || get(role, 'workRole.profession');
}

export const isValidHttpUrl = urlToCheck => {
  let url;

  try {
    url = new URL(urlToCheck);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const checkChurned = ctx => {
  const { res, query, store } = ctx;
  const subscriptionChurnedStatus = get(store.getState().App, 'subscriptionChurnedStatus', false);
  const profileId = getIdFromSlugRoute(get(query, 'id', ''));
  const userPermissions = get(store.getState().App, 'userPermissions.permissions', []);
  const isAdmin = userPermissions.some(permission => permission.identifier === 'applications.frontbase.admin');

  if (isAdmin) {
    return;
  }

  if (subscriptionChurnedStatus) {
    redirect(res, `${route.ARTISTS}/${profileId}`);
  }
};

const checkNoSubscription = ctx => {
  const { res, query, store } = ctx;
  const activeProfile = get(store.getState().App, 'activeProfileData', {});
  const activeProfileSubscriptions = get(store.getState().App, 'activeProfileSubscriptions');
  const profileId = getIdFromSlugRoute(get(query, 'id', ''));
  const userPermissions = get(store.getState().App, 'userPermissions.permissions', []);
  const isAdmin = userPermissions.some(permission => permission.identifier === 'applications.frontbase.admin');

  if (isAdmin) {
    return;
  }

  if (activeProfile?.profileType?.id === ARTIST_TYPE_ID && activeProfileSubscriptions.length === 0) {
    redirect(res, `${route.ARTISTS}/${profileId}`);
  }
};

const checkProfilePendingAccess = ctx => {
  const { res, query, store } = ctx;
  const activeProfile = get(store.getState().App, 'activeProfileData', {});
  const profileId = getIdFromSlugRoute(get(query, 'id', ''));
  const userPermissions = get(store.getState().App, 'userPermissions.permissions', []);
  const isAdmin = userPermissions.some(permission => permission.identifier === 'applications.frontbase.admin');

  if (isAdmin) {
    return;
  }

  if (activeProfile) {
    redirect(res, `${route.ARTISTS}/${profileId}`);
  }
};

export const withCheckChurned = WrappedComponent => {
  const Wrapper = props => <WrappedComponent {...props} />;

  Wrapper.getInitialProps = async ctx => {
    checkChurned(ctx);
    const { store } = ctx;
    const componentProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));
    return { store, ...componentProps };
  };

  return Wrapper;
};

export const withCheckNoSubscription = WrappedComponent => {
  const Wrapper = props => <WrappedComponent {...props} />;

  Wrapper.getInitialProps = async ctx => {
    checkNoSubscription(ctx);
    const { store } = ctx;
    const componentProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));
    return { store, ...componentProps };
  };

  return Wrapper;
};

export const withCheckProfilePendingAccess = WrappedComponent => {
  const Wrapper = props => <WrappedComponent {...props} />;

  Wrapper.getInitialProps = async ctx => {
    checkProfilePendingAccess(ctx);
    const { store } = ctx;
    const componentProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));
    return { store, ...componentProps };
  };

  return Wrapper;
};

export const getProfileName = profile => {
  switch (profile?.profileType) {
    case AOS_TYPE_ID:
      return profile?.companies[0]?.company?.name || profile?.name;

    case AGENT_TYPE_ID:
      return profile?.agencies[0]?.name || profile?.name;

    default:
      return profile?.name;
  }
};

export const getPrimaryProfessionForProfile = profile => {
  if (Array.isArray(profile?.professions) && profile?.professions.length > 0) {
    return profile?.professions.find(prof => prof?.isPrimary)?.profession;
  }
  return null;
};

export const getButtonTitle = (isLoggedIn, type) => {
  if (!isLoggedIn) {
    return `${TP}.FN_REGISTER_NOW`;
  }
  return type === ARTIST_TYPE_ID ? `${TP}.NEW_LANDING_PAGE_BANNER_CREATE_PROFILE` : `${TP}.PRO_BANNER_CREATE_PAGE`;
};

export const redirectToRegister = (type, pro = false) => {
  Router.push({
    pathname: route.REGISTER,
    query: { as: type, ...(pro ? { pro: true } : {}) },
  });
};

export const getRandomString = length => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

// For LS & VOD

export const getMediaDescription = media => {
  let title = '';
  if (media?.description) title = get(media, 'description', '');
  else if (media?.selectedProduction) {
    const prodName = media?.selectedProduction?.name || media?.selectedProduction?.work?.name;
    const getCityCountryName = getCountryCity(media?.selectedProduction, ', ', true);
    title = getCityCountryName ? `${prodName}, ${getCityCountryName}` : prodName;
  }
  return title;
};
// Part of MIT is_js to not include whole lib.
const comparator = {
  '<': function(a, b) {
    return a < b;
  },
  '<=': function(a, b) {
    return a <= b;
  },
  '>': function(a, b) {
    return a > b;
  },
  '>=': function(a, b) {
    return a >= b;
  },
};

function compareVersion(version, range) {
  const string = `${range}`;
  const n = +(string.match(/\d+/) || NaN);
  const op = string.match(/^[<>]=?|/)[0];
  // eslint-disable-next-line eqeqeq
  return comparator[op] ? comparator[op](version, n) : version == n || n !== n;
}

export const isSafari = function(range) {
  if (typeof navigator === 'undefined') return false;
  const userAgent = (navigator?.userAgent || '').toLowerCase();
  const match = userAgent.match(/version\/(\d+).+?safari/);
  return match !== null && compareVersion(match[1], range);
};

export const isOpera = function(range) {
  if (typeof navigator === 'undefined') return false;
  const userAgent = (navigator?.userAgent || '').toLowerCase();
  const match = userAgent.match(/(?:^opera.+?version|opr)\/(\d+)/);
  return match !== null && compareVersion(match[1], range);
};

export const isChrome = function(range) {
  if (typeof navigator === 'undefined') return false;
  const userAgent = (navigator?.userAgent || '').toLowerCase();
  const vendor = (navigator?.vendor || '').toLowerCase();
  const match = /google inc/.test(vendor) ? userAgent.match(/(?:chrome|crios)\/(\d+)/) : null;
  return match !== null && !isOpera() && compareVersion(match[1], range);
};

export const closest = (needle, haystack) =>
  haystack.reduce((a, b) => {
    const aDiff = Math.abs(a - needle);
    const bDiff = Math.abs(b - needle);

    if (aDiff === bDiff) {
      return a > b ? a : b;
    }
    return bDiff < aDiff ? b : a;
  });
// eslint-disable-next-line consistent-return
export const getSuitableYear = _years => {
  const currentYear = new Date().getFullYear();
  if (!Array.isArray(_years) || _years?.length === 0) {
    return currentYear;
  }

  if (_years.indexOf(currentYear) > -1) {
    return currentYear;
  }
  return closest(currentYear, _years);
};

export const concatenateStrings = (joinWith = '', ...strings) =>
  strings?.filter(str => str !== null && str !== undefined)?.join(joinWith);

// group cast, crew ad others by work
export const groupMembersByWork = production =>
  production?.productionWorks?.map(productionWork => {
    const castFilterByWork = production?.contributions?.filter(
      contribution =>
        contribution.contributionType === 'cast' && contribution?.productionWork?.id === productionWork?.id,
    );
    const crewFilterByWork = production?.contributions?.filter(
      contribution =>
        contribution.contributionType === 'crew' && contribution?.productionWork?.id === productionWork?.id,
    );
    const othersFilterByWork = production?.contributions?.filter(
      contribution =>
        contribution.contributionType !== 'crew' &&
        contribution.contributionType !== 'cast' &&
        contribution.contributionType !== 'producer' &&
        contribution?.productionWork?.id === productionWork?.id,
    ); // Need to recheck with backend othersFilterByWork

    return {
      ...productionWork,
      value: productionWork?.id,
      label: `${productionWork?.work?.name} (${concatenateStrings(
        ': ',
        productionWork?.workType?.name,
        productionWork?.stagingType?.name,
      )})`,
      cast: castFilterByWork,
      crew: crewFilterByWork,
      other: othersFilterByWork,
    };
  });

export const getLiveStreamData = (production, viewType, mode) => {
  const obj = {
    isFuture: null,
    data: {},
  };
  // check livestream for productions
  if (viewType === 'production') {
    const dates = cloneDeep(production?.dates);
    const partitionedByDates = partition(dates, date => new Date(date?.startDate).getTime() > Date.now());
    const futureDates = partitionedByDates[0];
    const pastDates = partitionedByDates[1];
    if (futureDates?.length > 0) {
      const liveStreamExistInFuture = futureDates?.find(
        date => date.mode === PERFOMANCE_DATE_MODE_TYPES[mode] && !isEmpty(getAttributesUrl(date?.attributes)),
      );
      if (liveStreamExistInFuture) {
        return { ...obj, isFuture: true, data: liveStreamExistInFuture };
      }
    }
    if (pastDates?.length > 0) {
      const liveStreamExistInPast = pastDates?.find(date => date.mode === PERFOMANCE_DATE_MODE_TYPES[mode]);
      if (liveStreamExistInPast) {
        return { ...obj, isFuture: false, data: liveStreamExistInPast };
      }
    }
    return obj;
  }

  return null;
};

export const getVODData = (production, viewType, mode) => {
  if (viewType === 'production') {
    const dates = cloneDeep(production?.dates);
    const currentDate = createDate().format(dateFormats.basic);
    const sortedDates = dates?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    return sortedDates?.find(
      date =>
        date.mode === PERFOMANCE_DATE_MODE_TYPES[mode] &&
        !isEmpty(getAttributesUrl(date?.attributes)) &&
        !isEmpty(date.startDate) &&
        currentDate < createDate(date?.endDate).format(dateFormats.basic),
    );
  }
  return null;
};
export const getAttributesUrl = attributes => {
  if (attributes?.length <= 0) {
    return null;
  }
  const attribute = attributes?.filter(a => !isEmpty(a.url));
  return attribute?.length > 0 ? attribute[0].url : null;
};

export const updatePerformanceMedia = dataMedia => {
  dataMedia.forEach(media => {
    const production = getProductionDetails(media?.production);
    const filteredTags = filterMediaTags(media?.tags, production);
    const upcomingProductionTag = getUpcomingPerformanceTag(filteredTags?.sortedDateTags);
    media.tags = [...filteredTags?.sortedDateTags, ...filteredTags?.remainingTags];
    media.upcomingPerformance = upcomingProductionTag?.lsVodData;
    media.productionInfo = production;
    media.productionMinYear = production.minDate;
  });
  return dataMedia;
};

const isValidLSTag = (tag, production) => {
  const performanceDate = tag?.performance;
  // check if performance is happening today or in future
  // check if atleast one of the performance date attributes is having a url
  const validUrls = performanceDate.attributes?.filter(o => !isEmpty(o.url));

  if (!validUrls || validUrls.length == 0) {
    return false;
  }

  const currentDate = createDate();

  const lsStartDate = createDate(performanceDate?.startDate);

  const isStartDateInFuture = currentDate.isBefore(lsStartDate);
  const isLSHappening = currentDate.isSame(lsStartDate, 'day');

  return isStartDateInFuture || isLSHappening;
};

const isValidVODTag = (tag, production) => {
  const performanceDate = tag?.performance;
  const validUrls = performanceDate.attributes?.filter(o => !isEmpty(o.url));

  if (!validUrls || validUrls.length == 0) {
    return false;
  }
  // check if performance is happening today or in future
  const currentDate = createDate();

  const vodStartDate = createDate(performanceDate?.startDate);
  const vodEndDate = createDate(performanceDate?.endDate);

  const isVodStartDateEmpty = isNil(performanceDate?.startDate);
  const isStartDateInFuture = currentDate.isBefore(vodStartDate);
  const isVodHappening = currentDate.isSame(vodStartDate, 'day');
  const isVodEndDateEmpty = isNil(performanceDate?.endDate);
  const isEndDateInFuture = currentDate.isBefore(vodEndDate);

  return isVodStartDateEmpty || isStartDateInFuture || isVodHappening || isVodEndDateEmpty || isEndDateInFuture;
};

const filterMediaTags = (tags, production) => {
  const [dateTags, remainingTags] = partition(tags, tag => tag?.tagType?.id === MEDIA_TAGS_TYPES_IDS.DATE);
  const upcomingPerformance = [];
  const currentDate = createDate();
  dateTags.forEach(tag => {
    let isValidTag = false;
    const performanceDate = tag?.performance;

    if (!performanceDate) {
      return;
    }

    const performanceMode = tag?.performance?.mode;
    if (performanceMode === 'digital-ls') {
      // check for validity
      isValidTag = isValidLSTag(tag, production);
    } else {
      isValidTag = isValidVODTag(tag, production);
    }
    const isStartDateInFuture = currentDate.isBefore(createDate(performanceDate?.startDate));
    const isStartDateToday = currentDate.isSame(createDate(performanceDate?.startDate), 'day');
    // check for valid ls and vod

    if (isValidTag) {
      const lsVodData = {
        isFree: true,
        liveToday: false,
        isFuture: false,
      };
      lsVodData.production = production;
      lsVodData.year = createDate(production?.minDate).format(dateFormats.year);

      const performanceMode = tag?.performance?.mode;
      if (isStartDateToday) {
        lsVodData.liveToday = true;
      }
      if (isStartDateInFuture) {
        lsVodData.isFuture = true;
      }
      lsVodData.mode = performanceMode;
      // lsVodData.isFree = hasUpcomingFreePerformances;
      if (performanceMode === PERFOMANCE_DATE_MODE_TYPES[PERFORMANCE_DATE_TYPES.LIVESTREAM]) {
        // enhance livestream dates
        const result = { ...lsVodData, ...addLSData(performanceDate) };
        tag.lsVodData = result;
      } else {
        // enhance vod dates
        const result = { ...lsVodData, ...addVodData(performanceDate) };
        tag.lsVodData = result;
      }
      upcomingPerformance.push(tag);
    }
  });
  const sortedDateTags = orderBy(upcomingPerformance, datesList => new Date(datesList?.performance?.startDate), [
    'asc',
  ]);

  return {
    sortedDateTags,
    remainingTags,
  };
};

const addVodData = upcomingPerformance => {
  let vodData = {
    isLive: true,
    isEndDateAvailable: false,
    isStartDateAvailable: false,
    mode: 'vod',
  };
  const currentDate = createDate();
  // check if startdate exists and is in future
  if (upcomingPerformance?.startDate) {
    const isStartDateInFuture = currentDate.isBefore(createDate(upcomingPerformance.startDate));
    vodData.isStartDateAvailable = true;
    if (isStartDateInFuture) {
      vodData.isLive = false;
      vodData = {
        ...vodData,
        ...getDateAndTimezoneFormatted(
          upcomingPerformance?.startDate,
          upcomingPerformance?.startTime,
          upcomingPerformance?.timezone,
        ),
      };
    }
    return vodData;
  }
  // check if end date is available
  if (upcomingPerformance?.endDate) {
    // check if end date is in future
    vodData.isEndDateAvailable = true;
    vodData = {
      ...vodData,
      ...getDateAndTimezoneFormatted(
        upcomingPerformance?.endDate,
        upcomingPerformance?.endTime,
        upcomingPerformance?.timezone,
      ),
    };
  }

  return vodData;
};

const addLSData = upcomingPerformance => {
  const lsData = {
    isLive: false,
    mode: 'ls',
  };

  const upcomingProductionStartDate = createDate(upcomingPerformance?.startDate);

  if (isDateToday(upcomingProductionStartDate)) {
    lsData.isLive = true;
  }

  return {
    ...lsData,
    ...getDateAndTimezoneFormatted(
      upcomingPerformance?.startDate,
      upcomingPerformance?.startTime,
      upcomingPerformance?.timezone,
    ),
  };
};

export const getProductionDetails = production => ({
  poster: null,
  name: getProductionNameMedia(production),
  composer: getProductionComposer(production),
  company: getProductionCompanyMedia(production),
  summary: getProductionSummary(production),
  casts: getCasts(production),
  crew: getCrew(production),
  others: getOthers(production),
  minDate: production?.minDate,
  hasTrailers: production?.hasTrailers,
  year: createDate(production?.minDate).format(dateFormats.year),
  id: production?.id,
  slug: production?.slug,
});

const COMMA = ', ';
const getProductionNameMedia = production =>
  production?.productionWorks?.[0]?.work?.name || production?.productionWorks?.[0]?.work?.original_name || null;

const getProductionComposer = production =>
  getComposerNamesFromCreators(production?.productionWorks?.[0]?.work?.creators) || null;

const formatAndJoin = (arrayOfString, seperator) => {
  const filteredStrings = arrayOfString.filter(str => !isEmpty(str));
  return filteredStrings.join(seperator);
};

const getProductionCompanyMedia = production => {
  if (!isEmpty(production?.contributions)) {
    const organizationNames = production?.contributions
      ?.filter(contribution =>
        [ORGANIZATION_TYPE_IDS.COMPANY, ORGANIZATION_TYPE_IDS.FESTIVAL].includes(
          contribution?.organization?.organizationType?.id,
        ),
      )
      ?.map(contribution => {
        const organization = contribution?.organization;
        if (organization) {
          return formatAndJoin([organization?.name, organization?.city?.name, organization?.country?.name], COMMA);
        }
        return null;
      })
      ?.filter(name => name);

    return organizationNames;
  }
  if (!isEmpty(production?.venues)) {
    return production?.venues
      ?.map(v => formatAndJoin([v?.name, v?.city?.name, v?.country?.name], COMMA))
      ?.filter(name => name);
  }
  return null;
};

const getProductionSummary = production => {
  if (!isEmpty(production?.work)) {
    return production?.work?.summary;
  }
  return null;
};

const getDateAndTimezoneFormatted = (date, time, timezone) => {
  const data = {};
  if (!date) {
    return {};
  }
  const dateEntity = createDate(date);
  data.dateString = dateEntity.format(dateFormats.fullTextDayMonthYear);
  data.dateAndMonth = dateEntity?.format('Do MMMM YYYY');
  data.weekName = dateEntity?.format('dddd');
  data.time = time;
  if (data.time && timezone) {
    const tzStartDate = createDatewithTz(dateEntity, timezone);
    data.timezone = getTimestampForDisplay(tzStartDate, dateFormats.abbrevatedTimezone);
  }

  return data;
};

const getCasts = production =>
  production?.contributions?.filter(contribution => contribution?.contributionType === 'cast');

const getCrew = production =>
  production?.contributions?.filter(contribution => contribution?.contributionType === 'crew');

const getOthers = production =>
  production?.contributions.filter(contribution => contribution?.contributionType === 'other');

const getUpcomingPerformanceTag = dateTags => {
  const tagsWithStartDate = dateTags?.filter(o => o.performance?.startDate);
  let upcomingPerformanceTag;
  // check if there are any
  if (tagsWithStartDate?.length !== 0) {
    upcomingPerformanceTag = tagsWithStartDate[0];
  } else upcomingPerformanceTag = dateTags[0];
  // add is Free data
  if (upcomingPerformanceTag)
    upcomingPerformanceTag.lsVodData.isFree = checkIfAnyFreePerformances(upcomingPerformanceTag);

  return upcomingPerformanceTag;
};

const checkIfAnyFreePerformances = tag => {
  let isFree = true;

  const upcomingTagFreeLinks = tag?.performance?.attributes?.filter(o => o.paymentType === 'free');
  if (upcomingTagFreeLinks && upcomingTagFreeLinks?.length === 0) {
    isFree = false;
  }

  return isFree;
};

export const getDaysUntilSubscriptionEnds = subscription => {
  const date1 = new Date();
  const date2 = new Date(subscription?.renewAt || subscription?.cancelledAt || '');
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays;
};

export const getProductionStatuses = production => {
  const isCancelled = Boolean(production?.isCancelled);
  const isArchived = Boolean(production?.isArchived);
  const isPublished = Boolean(production?.published_at);

  return {
    isCancelled,
    isArchived,
    isPublished,
    isDraft: !isPublished,
  };
};

export const getProductionDateRange = production => {
  let productionMinDate = production?.minDate;
  let productionMaxDate = production?.maxDate;

  if (!productionMinDate || !productionMaxDate) {
    const allDates = orderBy(production?.dates, datesList => new Date(datesList?.startDate), ['asc']);

    if (allDates?.length === 0) {
      return '';
    }

    const startDate = head(allDates);
    const endDate = last(allDates);

    productionMinDate = startDate?.startDate;
    productionMaxDate = endDate?.startDate;
  }

  const isProductionDatesInSameMonth =
    new Date(productionMinDate).getMonth() === new Date(productionMaxDate).getMonth() &&
    new Date(productionMinDate).getFullYear() === new Date(productionMaxDate).getFullYear();

  const dateRange = isProductionDatesInSameMonth
    ? getDatesInSameMonth(productionMinDate, productionMaxDate)
    : getDateWithDaysFormat(productionMinDate, productionMaxDate);

  return dateRange;
};

export const getProductionCardDetails = production => {
  const specialCrew = {
    director: {
      title: `${TP}.FN_STAGE_DIRECTOR`,
      filter: item => item?.profession?.id === PROFESSION_IDS.STAGE_DIRECTOR,
      linkProps: item => ({
        href: route.DYNAMIC_ID_ROUTE_WITH_ACTION(route.ARTISTS),
        as: getRouteWithSlug(route.ARTISTS, item?.profile),
      }),
    },
    conductor: {
      title: `${TP}.m_CONDUCTOR`,
      filter: item => item?.profession?.id === PROFESSION_IDS.CONDUCTOR,
      linkProps: item => ({
        href: route.DYNAMIC_ID_ROUTE_WITH_ACTION(route.ARTISTS),
        as: getRouteWithSlug(route.ARTISTS, item?.profile),
      }),
    },
  };

  const typesList = getChipsForProductionCard(production);
  const productionTags = typesList
    .map(
      (tag, index) =>
        tag && {
          initial: getProductionLabelInitial(index),
          label: getProductionLabelKeys(index),
        },
    )
    .filter(Boolean);

  const totalPerformances = production?.performances?.length ?? 0;

  const locationsList =
    groupBy(
      production?.performances?.filter(c => c.city?.name),
      'city.name',
    ) || {};
  const locationsCount = Object.keys(locationsList).length;
  const cityCountryName = getCountryCity(production?.venues?.[0], undefined, true);

  const surtitles = map(production?.surtitles, 'name').join(', ');
  const languages = map(production?.languages, 'name').join(', ');
  const venues = production?.venues;
  const locationDetails = buildLocationDetailsProduction(production);
  const productionCompanyName = locationDetails?.find(value => value?.label === 'company')?.value;

  const crew = production?.contributions?.filter(c => c?.contributionType === 'crew');
  const primaryCrew = Object.keys(specialCrew)?.map(key => {
    const specialCrewMember = specialCrew?.[key];

    return {
      key,
      title: specialCrewMember?.title,
      linkProps: specialCrewMember?.linkProps,
      member: crew?.filter(item => specialCrewMember.filter(item)),
    };
  });
  return {
    dateRange: getProductionDateRange(production),
    tags: productionTags,
    totalPerformances,
    locationsCount,
    cityCountryName,
    subtitles: surtitles,
    surtitles,
    languages,
    venues,
    primaryCrew,
    productionCompanyName,
  };
};

export const getMatchingReviewEntities = (entities, type, entityId, entityRelation) => {
  if (!entityId && !type) {
    return null;
  }

  if (type === REVIEW_ENITITES_TYPES.ORGANIZATIONS.VALUE || type === REVIEW_ENITITES_TYPES.ORGANIZATIONS.NAME) {
    if (entityId) {
      if (!entityRelation) {
        return entities?.filter(entity => entity?.entityType === type && entity?.organization?.id === entityId);
      }

      if (type === REVIEW_ENITITES_TYPES.ORGANIZATIONS.NAME) {
        return entities?.filter(
          entity =>
            entity?.entityType === 'productionContributions' && entity?.contribution?.organization?.id === entityId,
        );
      }
    }

    return entities?.filter(entity => entity?.entityType === type);
  }

  if (type === REVIEW_ENITITES_TYPES.PROFILE.VALUE || type === REVIEW_ENITITES_TYPES.PROFILE.NAME) {
    if (entityId) {
      if (!entityRelation) {
        return entities?.filter(entity => entity?.entityType === type && entity?.profile?.id === entityId);
      }

      if (type === REVIEW_ENITITES_TYPES.PROFILE.NAME) {
        return entities?.filter(
          entity => entity?.entityType === 'productionContributions' && entity?.contribution?.profile?.id === entityId,
        );
      }
    }

    return entities?.filter(entity => entity?.entityType === type);
  }

  if (type === REVIEW_ENITITES_TYPES.PRODUCTION_CAST.NAME || type === REVIEW_ENITITES_TYPES.PRODUCTION_CAST.VALUE) {
    if (entityId) {
      return entities?.filter(
        entity => entity?.entityType === type && entity?.productionCast?.profile?.id === entityId,
      );
    }

    return entities?.filter(entity => entity?.entityType === type);
  }

  if (entityId && type) {
    return entities?.filter(entity => entity?.entityType === type && entity?.entityId === entityId);
  }

  if (entityId) {
    return entities?.filter(entity => entity?.entityId === entityId);
  }

  return entities?.filter(entity => entity?.entityType === type);
};

export const getMatchingReviewTags = (tags, entityType, tagId) => {
  if (!tags && !tagId) {
    return null;
  }

  if (
    [
      REVIEW_ENITITES_TYPES.PRODUCTION_CONTRIBUTIONS.NAME,
      REVIEW_ENITITES_TYPES.PRODUCTION_CONTRIBUTIONS.VALUE,
    ].includes(entityType) &&
    tagId
  ) {
    return tags?.filter(
      tag =>
        tag?.entityType === entityType &&
        (tag?.contribution?.profile?.id === tagId || tag?.contribution?.organization?.id === tagId),
    );
  }

  if ([REVIEW_ENITITES_TYPES.PROFILE.NAME, REVIEW_ENITITES_TYPES.PROFILE.VALUE].includes(entityType) && tagId) {
    return tags?.filter(tag => tag?.entityType === entityType && tag?.profile?.id === tagId);
  }

  return null;
};

export const getOrderedReviewList = (reviews, forEntity, forEntityId, entityRelation) =>
  orderBy(
    reviews?.map(review => ({
      ...review,
      matchingEntities: getMatchingReviewEntities(review?.tags, forEntity, forEntityId, entityRelation),
    })),
    [
      review => review?.matchingEntities?.[0]?.order || 0,
      review => review?.matchingEntities?.[0]?.createdAt || review?.createdAt,
    ],
    ['asc', 'desc'],
  );

export const getMatchingMediaTags = (tags, typeId, tagId) => {
  if (!tagId && !typeId) {
    return null;
  }

  if (typeId === MEDIA_TAGS_TYPES_IDS.PRODUCTION_CONTRIBUTION && tagId) {
    return tags?.filter(
      tag =>
        tag?.tagType?.id === typeId &&
        (tag?.contribution?.profile?.id === tagId || tag?.contribution?.organization?.id === tagId),
    );
  }

  if (tagId && typeId) {
    return tags?.filter(tag => tag?.tagType?.id === typeId && tag?.tagId === tagId);
  }

  if (tagId) {
    return tags?.filter(tag => tag?.tagId === tagId);
  }

  return tags?.filter(tag => tag?.tagType?.id === typeId);
};

export const getOrderedMediaList = (mediaList, tagType, tagId, isShowreel) => {
  if (isShowreel) {
    return orderBy(
      mediaList?.map(({ media, ...rest }) => ({
        ...media,
        matchingTags: [{ ...rest, acknowledged: true }],
        isShowreel: true,
      })),
      [media => media?.matchingTags?.[0]?.order || 0, media => media?.createdAt],
      ['asc', 'desc'],
    );
  }

  return mediaList?.map(media => ({
    ...media,
    matchingTags: getMatchingMediaTags(media?.tags, tagType, tagId),
  }));
};

export const getArtistProductionMediaGrouped = (response, profileId) => {
  const dataGrouped = groupBy(response?.data, media => media?.production?.id || 'ignoreList');

  const { ignoreList: _ignoreList, ...rest } = dataGrouped;

  const productions = mapValues(rest, productionMedia => ({
    production: productionMedia?.[0]?.production,
    data: getOrderedMediaList(productionMedia, MEDIA_TAGS_TYPES_IDS.PRODUCTION_CONTRIBUTION, profileId),
  }));

  return {
    ...response,
    data: orderBy(Object.values(productions), ({ production }) => production?.minDate, ['desc']),
  };
};

export const getProductionMediaGrouped = (response, entityType, entityId) => {
  const dataGrouped = groupBy(response?.data, media => media?.production?.id || 'ignoreList');

  const { ignoreList: _ignoreList, ...rest } = dataGrouped;

  const productions = mapValues(rest, productionMedia => {
    let entity = MEDIA_TAGS_TYPES_IDS.PRODUCTION;

    switch (entityType) {
      case MEDIA_TAGS_TYPES_IDS.PRODUCTION_CONTRIBUTION:
        entity = MEDIA_TAGS_TYPES_IDS.PRODUCTION_CONTRIBUTION;
        break;
      default:
        entity = MEDIA_TAGS_TYPES_IDS.PRODUCTION;
    }

    return {
      production: productionMedia?.[0]?.production,
      data: getOrderedMediaList(productionMedia, entity, entityId),
    };
  });

  return {
    ...response,
    data: orderBy(Object.values(productions), ({ production }) => production?.minDate, ['desc']),
  };
};

export const getMediaTags = ({
  entity,
  entityTypeId,
  tags = [],
  production,
  productionDates,
  isGeneralMedia = false,
  hideMatchingContributionTagDisplayStatus = false,
}) => {
  const mediaTags =
    tags?.filter(tag => Object.values(SUPPORTED_MEDIA_TAGS_TYPES_IDS)?.includes(tag?.tagType?.id)) || [];

  const productionTag = getMatchingMediaTags(mediaTags, MEDIA_TAGS_TYPES_IDS.PRODUCTION, production?.id)?.[0];
  const entityTag = getMatchingMediaTags(mediaTags, entityTypeId, entity?.id)?.[0];

  remove(mediaTags, tag => tag?.tagType?.id === entityTypeId);
  remove(mediaTags, tag => tag?.tagType?.id === MEDIA_TAGS_TYPES_IDS.PRODUCTION);
  const datesTag = remove(mediaTags, tag => tag?.tagType?.id === MEDIA_TAGS_TYPES_IDS.DATE);

  let updatedTags = [];

  if (production?.id) {
    if (productionTag?.tagId !== production?.id) {
      remove(mediaTags, tag => tag?.tagType?.id === MEDIA_TAGS_TYPES_IDS.CAST);
    }

    updatedTags = productionDates?.reduce((res, date) => {
      const matchingDateTag = datesTag?.find(tag => tag?.tagId === date?.id);

      if (date?.id) {
        res.push({
          ...(matchingDateTag?.id ? { id: matchingDateTag?.id } : {}),
          tagType: { id: MEDIA_TAGS_TYPES_IDS.DATE },
          tagId: date?.id,
          availabilityTypes: [TAGS_AVAILABILITY_TYPES.LABEL],
        });
      }

      return res;
    }, []);

    updatedTags.push({
      ...(productionTag?.tagId === production?.id ? { id: productionTag?.id } : {}),
      tagType: { id: MEDIA_TAGS_TYPES_IDS.PRODUCTION },
      tagId: production?.id,
      availabilityTypes: uniq(concat(productionTag?.availabilityTypes || [], TAGS_AVAILABILITY_TYPES.LABEL)),
    });
  } else {
    remove(mediaTags, tag => tag?.tagType?.id === MEDIA_TAGS_TYPES_IDS.CAST);
  }

  if (isGeneralMedia) {
    updatedTags.push({
      ...(entityTag?.id ? { id: entityTag?.id } : {}),
      tagType: { id: entityTypeId },
      tagId: entity?.id,
      availabilityTypes: [TAGS_AVAILABILITY_TYPES.GALLERY],
    });
  }

  if (hideMatchingContributionTagDisplayStatus) {
    const tagList = [...updatedTags, ...mediaTags];
    const matchingTag = getMatchingMediaTags(tagList, MEDIA_TAGS_TYPES_IDS.PRODUCTION_CONTRIBUTION, entity?.id)?.[0];
    return tagList?.filter(tag => tag?.id !== matchingTag?.id);
  }

  return [...updatedTags, ...mediaTags];
};

export const getMediaShowreelTags = ({ entity, options = [] }) => {
  const showReels = options?.map(section => {
    switch (section) {
      case MEDIA_SECTIONS.INTRO_SHOWREEL: {
        return {
          entityTypeId: ENTITIES.PROFILE_ENTITY,
          entityId: entity?.id,
          showReelType: MEDIA_SHOWREEL_OPTIONS.INTRO,
        };
      }
      case MEDIA_SECTIONS.AUDITION_SHOWREEL: {
        return {
          entityTypeId: ENTITIES.PROFILE_ENTITY,
          entityId: entity?.id,
          showReelType: MEDIA_SHOWREEL_OPTIONS.AUDITION,
        };
      }
      case MEDIA_SECTIONS.ORGANIZATION_INTERIOR_PHOTOS: {
        return {
          entityTypeId: ENTITIES.ORGANIZATION,
          entityId: entity?.id,
          showReelType: MEDIA_SHOWREEL_OPTIONS.ORGANIZATION_INTERIOR_PHOTOS,
        };
      }
      case MEDIA_SECTIONS.ORGANIZATION_EXTERIOR_PHOTOS: {
        return {
          entityTypeId: ENTITIES.ORGANIZATION,
          entityId: entity?.id,
          showReelType: MEDIA_SHOWREEL_OPTIONS.ORGANIZATION_EXTERIOR_PHOTOS,
        };
      }
      default: {
        return null;
      }
    }
  });

  return compact(showReels);
};

export const getSelectedMediaOptions = ({ entity, entityTypeId, media }) => {
  const selectedOptions = [];

  const entityTag = getMatchingMediaTags(media?.tags, entityTypeId, entity?.id)?.[0];
  const productionTag = getMatchingMediaTags(media?.tags, MEDIA_TAGS_TYPES_IDS.PRODUCTION)?.[0];

  if (entityTag) {
    if (media?.mediaType?.id === MEDIA_TYPES_IDS.IMAGE) {
      selectedOptions.push(MEDIA_SECTIONS.GENERAL_PHOTOS);
    } else {
      selectedOptions.push(MEDIA_SECTIONS.GENERAL_VIDEOS);
    }
  }

  if (productionTag) {
    selectedOptions.push(MEDIA_SECTIONS.PART_OF_PRODUCTION);
  }

  const selectedShowreels = media?.showReels;

  const SHOWREEL_OPTIONS = {
    [MEDIA_SHOWREEL_OPTIONS.INTRO.id]: MEDIA_SECTIONS.INTRO_SHOWREEL,
    [MEDIA_SHOWREEL_OPTIONS.AUDITION.id]: MEDIA_SECTIONS.AUDITION_SHOWREEL,
    [MEDIA_SHOWREEL_OPTIONS.ORGANIZATION_EXTERIOR_PHOTOS.id]: MEDIA_SECTIONS.ORGANIZATION_EXTERIOR_PHOTOS,
    [MEDIA_SHOWREEL_OPTIONS.ORGANIZATION_INTERIOR_PHOTOS.id]: MEDIA_SECTIONS.ORGANIZATION_INTERIOR_PHOTOS,
  };

  map(selectedShowreels, ({ showReelType }) => {
    selectedOptions.push(SHOWREEL_OPTIONS[showReelType?.id]);
  });

  return selectedOptions;
};

export const getMediaInitialFormValues = ({ entity, entityTypeId, media, options, isPrimary }) => {
  if (!media) {
    return {
      options,
    };
  }

  const productionTag = getMatchingMediaTags(media?.tags, MEDIA_TAGS_TYPES_IDS.PRODUCTION)?.[0];
  const castTag = getMatchingMediaTags(media?.tags, MEDIA_TAGS_TYPES_IDS.CAST, entity?.id)?.[0];

  let selectedDates = [];
  if (productionTag) {
    selectedDates = map(
      media?.tags?.filter(tag => tag?.tagType?.id === MEDIA_TAGS_TYPES_IDS.DATE),
      'performance',
    );
    // productiondate are removed from the api
  }

  return {
    title: media?.title,
    description: media?.description,
    source_info: media?.source_info,
    credit: media?.credit,
    copyright: media?.copyright,
    options: isPrimary
      ? [...getSelectedMediaOptions({ entity, entityTypeId, media }), ...isPrimary]
      : getSelectedMediaOptions({ entity, entityTypeId, media }),
    selectedProduction: media?.production,
    selectedRole: castTag?.cast,
    selectedDates,
  };
};

export const getMediaSectionDeletePayload = ({ entity, entityTypeId, section, media, options }) => {
  const productionTag = getMatchingMediaTags(media?.tags, MEDIA_TAGS_TYPES_IDS.PRODUCTION)?.[0];
  let productionDates = [];

  if (productionTag) {
    productionDates = map(
      media?.tags?.filter(tag => tag?.tagType?.id === MEDIA_TAGS_TYPES_IDS.DATE),
      'performance',
    );
    // productiondate are removed from the api
  }

  const generalMediaSection =
    media?.mediaType?.id === MEDIA_TYPES_IDS.IMAGE ? MEDIA_SECTIONS.GENERAL_PHOTOS : MEDIA_SECTIONS.GENERAL_VIDEOS;

  let showReels = [];
  let tags = [];

  if (section === MEDIA_SECTIONS.INTRO_SHOWREEL || section === MEDIA_SECTIONS.AUDITION_SHOWREEL) {
    showReels = getMediaShowreelTags({
      entity,
      options: options?.filter(option => option !== section),
    });

    tags = getMediaTags({
      entity,
      entityTypeId,
      tags: media?.tags,
      production: productionTag ? media?.production : null,
      productionDates,
      isGeneralMedia: options?.includes(generalMediaSection),
      hideMatchingContributionTagDisplayStatus: true,
    });
  } else {
    showReels = getMediaShowreelTags({
      entity,
      options,
    });

    if (section === generalMediaSection) {
      tags = getMediaTags({
        entity,
        entityTypeId,
        tags: media?.tags,
        production: productionTag ? media?.production : null,
        productionDates,
        isGeneralMedia: false,
        hideMatchingContributionTagDisplayStatus: true,
      });
    }

    if (section === MEDIA_SECTIONS.PART_OF_PRODUCTION) {
      tags = getMediaTags({
        entity,
        entityTypeId,
        tags: media?.tags,
        isGeneralMedia: options?.includes(generalMediaSection),
      });
    }
  }

  return {
    id: media?.id,
    forEntity: entityTypeId,
    forEntityId: entity?.id,
    attributes: media?.attributes,
    showReels,
    tags,
    title: media?.title,
    description: media?.description,
  };
};

export const delayedFetch = (fn, delay = 1000) => () => setTimeout(fn, delay);

const getMatchingEntityOrders = (entities, index) =>
  entities?.map(({ id }) => ({
    id,
    order: index + 1,
  })) || [];

export const getMediaReorderPayload = entities =>
  entities?.reduce((list, entity, index) => {
    const entries = getMatchingEntityOrders(entity?.media?.matchingTags, index);

    return [...list, ...entries];
  }, []);

export const getReviewReorderPayload = entities =>
  entities?.reduce((list, entity, index) => {
    const entries = getMatchingEntityOrders(entity?.matchingEntities, index);

    return [...list, ...entries];
  }, []);

export const getMediaVisibilityStatus = (isImpersonationAccount, displayStatus, media) => {
  let isVisible = true;

  if (isInvalidProductionAction(media?.production)) {
    isVisible = false;
  } else if (isImpersonationAccount) {
    isVisible = displayStatus === ENTITY_DISPLAY_STATUS.SHOW;
  } else if (
    media?.production?.producerApproved &&
    media?.production?.validationStatus?.id === VALIDATION_STATUSES.APPROVED
  ) {
    isVisible = true;
  } else {
    isVisible = false;
  }

  return isVisible;
};

export const formatContent = content =>
  content
    ?.replace(/<br>/gi, '\r\n')
    ?.replace(/<br \/>/gi, '\r\n')
    ?.replace(/<br\/>/gi, '\r\n');

export const errorMessageConverter = error =>
  error?.responseText || error?.responseText?.message || error?.message || error?.original_message;
export const nameInitials = str =>
  str
    ?.replace(/[^a-zA-Z ]/g, '')
    ?.split(' ')
    ?.map(c => c?.charAt(0)?.toUpperCase())
    ?.join('')
    ?.concat(str?.charAt(1)?.toUpperCase())
    ?.substring(0, 2);

export const isRestrictedComposerName = (names = [], { forEntityType, forEntityId }) => {
  if (forEntityType === ENTITY_TYPES.ORGANIZATION && [15843, 44368, 16165, 45024].includes(forEntityId)) {
    return false;
  }

  return names.some(name => RESTRICTED_COMPOSER_NAMES.includes(name));
};

export const isRestrictedWorkName = (names = [], { forEntityType, forEntityId }) => {
  if (forEntityType === ENTITY_TYPES.ORGANIZATION && [15843, 44368, 16165, 45024].includes(forEntityId)) {
    return false;
  }

  return names.some(name => RESTRICTED_WORK_NAMES.includes(name));
};

// eslint-disable-next-line consistent-return
export const getProgramLabel = (production, workCount) => {
  if (production?.isProgramNotAnnounced && production?.productionDates === PRODUCTION_DATES.FUTURE) {
    if (workCount <= 0) {
      return 'MISSING_PROGRAM_FUTURE_PERF_DATE_NO_MW';
    }

    return 'MISSING_PROGRAM_FUTURE_PERF_DATE_ONE_OR_MORE_MW';
  }

  if (production?.isProgramNotAnnounced && production?.productionDates === PRODUCTION_DATES.PAST) {
    if (workCount <= 0) {
      return 'MISSING_PROGRAM_PAST_PERF_DATE_NO_MW';
    }

    return 'MISSING_PROGRAM_PAST_PERF_DATE_ONE_OR_MORE_MW';
  }

  return 'EDIT_PRODUCTIOM_PROGRAM_WORK_NOT_ANNOUNCED';
};

export const getWorkRoleProfessions = workRoleProfessions => {
  const professionString = workRoleProfessions?.reduce((result, { isPrimary, profession: { name } }) => {
    if (isPrimary) {
      result.unshift(name);
    } else {
      result.push(name);
    }

    return result;
  }, []);

  return ` (${professionString?.join(', ')})`;
};

export const getWorkRolePrimaryProfession = (workRoleProfessions = []) =>
  workRoleProfessions?.find(({ isPrimary }) => isPrimary) ?? workRoleProfessions?.[0];

export const getProfessionTags = availabilityList =>
  availabilityList?.reduce((result, availability) => {
    result.push({ category: TAG_TYPE.AVAILABILITY, slug: availability });
    return result;
  }, []);

export const getLanguageCanonicalName = lang => {
  let label = lang?.originalName;

  if (label?.toLocaleLowerCase() !== lang?.name?.toLocaleLowerCase()) {
    label += ` (${lang?.name})`;
  }

  return label;
};
