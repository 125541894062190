import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';
import { useMediaQuery } from '@mui/material';

import Drawer from 'components/uiLibrary/Drawer';
// eslint-disable-next-line import/no-cycle
import { FilterSidebar } from 'components/Filters/Display/FilterFacets';

import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';

import classes from './QuickView.module.scss';

const ProductionQuickView = dynamic(() => import('components/Productions/Display/QuickView'));
// eslint-disable-next-line import/no-cycle
const EntityQuickView = dynamic(() => import('components/Globals/Layout/EntityQuickView/EntityQuickView'));

export const QUICK_VIEW_TYPES = {
  PRODUCTION: 'PRODUCTION',
  FILTERS: 'FILTERS',
  ENTITY: 'ENTITY',
};

const TYPE_TO_COMPONENT_MAP = {
  [QUICK_VIEW_TYPES.PRODUCTION]: ProductionQuickView,
  [QUICK_VIEW_TYPES.FILTERS]: FilterSidebar,
  [QUICK_VIEW_TYPES.ENTITY]: EntityQuickView,
};

const QuickView = ({ hideQuickView, quickViewProps }) => {
  const { t } = useTranslation(['NS_APP_GLOBALS']);
  const isSmallScreen = useMediaQuery('(max-width: 768px)', { noSsr: true });
  const isTabletScreen = useMediaQuery('(max-width: 1000px)', { noSsr: true });
  const type = quickViewProps?.type;

  const placement = useMemo(() => {
    if (!type) {
      return null;
    }

    if (isSmallScreen || type === QUICK_VIEW_TYPES.ENTITY) {
      return 'bottom';
    }

    if (type === QUICK_VIEW_TYPES.FILTERS) {
      if (isTabletScreen) {
        return 'bottom';
      }
      return 'left';
    }

    return 'right';
  }, [isSmallScreen, type, isTabletScreen]);

  const className = useMemo(() => {
    if (isSmallScreen && type !== QUICK_VIEW_TYPES.ENTITY) {
      return classes.mobileViewContainer;
    }

    if (type === QUICK_VIEW_TYPES.FILTERS) {
      return classnames(classes.desktopViewContainer, classes.desktopViewContainer__width_460px);
    }

    if (type === QUICK_VIEW_TYPES.ENTITY) {
      return classes.entityViewContainer;
    }

    return classnames(classes.desktopViewContainer, classes.desktopViewContainer__width_375px);
  }, [isSmallScreen, type]);

  const styles = useMemo(() => {
    if (type === QUICK_VIEW_TYPES.ENTITY) {
      return {
        headerText: classes.entityViewContainer__headerText,
        headerCloseIcon: classes.entityViewContainer__headerCloseIcon,
        content: classes.entityViewContainer__drawerContent,
        drawerRoot: classes.entityViewContainer__drawerRoot,
      };
    }

    if (type === QUICK_VIEW_TYPES.FILTERS) {
      return {
        content: classes.desktopViewContent,
      };
    }

    return {};
  }, [type]);

  const title = useMemo(() => {
    if (type === QUICK_VIEW_TYPES.ENTITY) {
      return `${TP}.PROFILE_MANAGE_PREVIEW`;
    }

    return null;
  }, [type]);

  if (!type) {
    return null;
  }

  const Component = TYPE_TO_COMPONENT_MAP[type];

  if (!Component) {
    return null;
  }

  return (
    <Drawer
      isOpen
      title={t(title)}
      onClose={hideQuickView}
      placement={placement}
      className={className}
      styles={styles}
      disableEnforceFocus
    >
      {Component && <Component onCloseQuickView={hideQuickView} {...quickViewProps?.data} />}
    </Drawer>
  );
};

export default QuickView;
