const { pickBy } = require('lodash');
const dayjs = require('../../server/utils/dayjs');
const { ENTITY_TYPES, ROUTE_DATE_FORMATS } = require('../constants');
const { SPECIAL_KEYWORD_CONTEXT_PARAMS } = require('../constants/configurations');

const getDateRangeLabel = ({ dateFrom, dateTo }) => {
  if (!dateFrom && !dateTo) {
    return '';
  }

  const today = dayjs().startOf('day');
  const dateFromObj = dayjs(dateFrom, ROUTE_DATE_FORMATS.STANDARD, true);
  const dateToObj = dayjs(dateTo, ROUTE_DATE_FORMATS.STANDARD, true);

  if (dateFrom && !dateTo && dateFromObj.isValid()) {
    const startOfDay = dateFromObj.startOf('day');
    const shiftStartDaysFromToday = startOfDay.diff(today, 'days');

    const validSpecialTerms = Object.values(
      pickBy(SPECIAL_KEYWORD_CONTEXT_PARAMS, value => {
        const { type, shiftDateEnd, shiftDateStart } = value;

        if (type === ENTITY_TYPES.DATE) {
          return shiftStartDaysFromToday === shiftDateStart && !shiftDateEnd && shiftDateEnd !== 0;
        }

        return false;
      }),
    );

    if (validSpecialTerms.length === 1) {
      return validSpecialTerms[0]?.translationKey || '';
    }

    return startOfDay.format(ROUTE_DATE_FORMATS.FULL_DATE);
  }

  if (dateTo && !dateFrom && dateToObj.isValid()) {
    const endOfDay = dateToObj.startOf('day');
    const shiftEndDaysFromToday = endOfDay.diff(today, 'days');

    const validSpecialTerms = Object.values(
      pickBy(SPECIAL_KEYWORD_CONTEXT_PARAMS, value => {
        const { type, shiftDateEnd, shiftDateStart } = value;

        if (type === ENTITY_TYPES.DATE) {
          return shiftEndDaysFromToday === shiftDateEnd && !shiftDateStart && shiftDateStart !== 0;
        }

        return false;
      }),
    );

    if (validSpecialTerms.length === 1) {
      return validSpecialTerms[0]?.translationKey || '';
    }

    return endOfDay.format(ROUTE_DATE_FORMATS.FULL_DATE);
  }

  if (!dateFromObj.isValid() && !dateToObj.isValid()) {
    return null;
  }

  const startOfYear = dateFromObj.startOf('year');
  const endOfYear = dateToObj.endOf('year');

  const isFromStartOfYear = dateFromObj.isSame(startOfYear, 'day');
  const isToEndOfYear = dateToObj.isSame(endOfYear, 'day');

  if (isFromStartOfYear && isToEndOfYear && dateFromObj.isSame(dateToObj, 'year')) {
    return startOfYear.format(ROUTE_DATE_FORMATS.YEAR);
  }

  const startOfMonth = dateFromObj.startOf('month');
  const endOfMonth = dateToObj.endOf('month');

  const isFromStartOfMonth = dateFromObj.isSame(startOfMonth, 'day');
  const isToEndOfMonth = dateToObj.isSame(endOfMonth, 'day');

  if (
    isFromStartOfMonth &&
    isToEndOfMonth &&
    dateFromObj.isSame(dateToObj, 'year') &&
    dateFromObj.isSame(dateToObj, 'month')
  ) {
    return startOfMonth.format(ROUTE_DATE_FORMATS.MONTH_YEAR);
  }

  const startOfDay = dateFromObj.startOf('day');
  const endOfDay = dateToObj.endOf('day');

  const shiftStartDaysFromToday = startOfDay.diff(today, 'days');
  const shiftEndDaysFromToday = endOfDay.diff(today, 'days');

  const validSpecialTerms = Object.values(
    pickBy(SPECIAL_KEYWORD_CONTEXT_PARAMS, value => {
      const { type, shiftDateEnd, shiftDateStart } = value;

      if (type === ENTITY_TYPES.DATE) {
        return shiftStartDaysFromToday === shiftDateStart && shiftEndDaysFromToday === shiftDateEnd;
      }

      return false;
    }),
  );

  const isFromStartOfDay = dateFromObj.isSame(startOfDay, 'day');
  const isToEndOfDay = dateToObj.isSame(endOfDay, 'day');
  const hasOneMatchingSpecialTerm = validSpecialTerms?.length === 1;

  if (hasOneMatchingSpecialTerm && validSpecialTerms?.[0]?.translationKey) {
    return validSpecialTerms?.[0]?.translationKey;
  }

  if (isFromStartOfDay && isToEndOfDay && dateFromObj.isSame(dateToObj, 'day')) {
    const dateFormattedPath = startOfDay.format(ROUTE_DATE_FORMATS.FULL_DATE);

    return dateFormattedPath;
  }

  return `${startOfDay.format(ROUTE_DATE_FORMATS.FULL_DATE)} - ${endOfDay.format(ROUTE_DATE_FORMATS.FULL_DATE)}`;
};

module.exports = getDateRangeLabel;
