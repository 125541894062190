/*
  This middleware is used to detect bots and set a cookie to identify them.

  The cookie is used to avoid calling the fingerprint API on every request.

  The cookie is set with the following format:
    <signature>|<isBot>|<provider>|<trusted>

  The signature is a HMAC digest of the IP and the user agent.

  The isBot flag is set to 1 if the fingerprint API returns a result.

  The provider is the name of the bot provider (google, bing, etc).

  The trusted flag is set to true if the fingerprint API returns a trusted result.
*/

const serverAPI = require('../utils/serverAPI');
const { getHmacDigestValue } = require('../../utils/serverSignature');
const { getIpFromHeaders } = require('../utils');

const COOKIE_NAME = 'obfp';

const getFingerprintFromApi = async ip => {
  try {
    const fingerprint = await serverAPI.get('fingerprints/bot', { ip });

    return fingerprint?.data[0];
  } catch (ex) {
    console.error(`Error fetching fingerprint for ip: ${ip}`, ex);
    return null;
  }
};

const createCookieValue = fingerprint => {
  if (!fingerprint) {
    throw Error('Missing fingerprint');
  }
  const arr = [
    fingerprint.signature,
    fingerprint.isBot ? 1 : 0,
    fingerprint.provider,
    fingerprint.trusted === 'yes' ? 1 : 0,
  ];
  return arr.join('|');
};

const parseCookieValue = value => {
  if (!value) {
    return null;
  }
  const [signature, isBot, provider, trusted] = value.split('|');
  return { signature, isBot: isBot === '1', provider, trusted: trusted === '1' ? 'yes' : 'no' };
};

const isFingerPrintEnabled = () => {
  return process.env.FINGER_PRINT_ENABLED === 'true';
};

const fingerprintMiddleware = async (req, res, next) => {
  if (!isFingerPrintEnabled()) {
    return next();
  }

  // const ip = '207.46.13.10'; // bing bot
  // const ip = '66.249.65.34'; // google bot
  const ip = getIpFromHeaders(req) || null;

  const userAgent = req.headers['user-agent'];
  const signature = getHmacDigestValue(ip, userAgent);

  // ignore static files
  if (req.path.indexOf('.') >= 0 && !req.path.includes('/_next/data/')) {
    return next();
  }

  // fingerprint available as cookie ?
  let fingerprint = null;
  if (req.cookies[COOKIE_NAME]) {
    try {
      fingerprint = parseCookieValue(req.cookies[COOKIE_NAME]);
    } catch {
      fingerprint = null;
    }
  }

  // match signature with fingerprint
  if (signature !== fingerprint?.signature) {
    fingerprint = null;
  }

  if (!fingerprint) {
    // fetch fingerprint from api
    fingerprint = await getFingerprintFromApi(ip);
    fingerprint = {
      isBot: fingerprint?.provider !== 'user' && fingerprint?.provider !== 'unknown',
      provider: fingerprint?.provider || null,
      trusted: fingerprint?.trusted || null, // yes || no
      signature,
    };

    // set cookie
    res.cookie(COOKIE_NAME, createCookieValue(fingerprint), {
      maxAge: 1000 * 60 * 60 * 24, // 1 day
      sameSite: 'lax', // csrf protection
    });
  }

  req.fingerprint = { ...fingerprint, ip, userAgent };

  return next();
};

module.exports = {
  fingerprintMiddleware,
  parseCookieValue,
  COOKIE_NAME,
};
