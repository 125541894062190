import { ENTITY_MAIN_TABS } from 'constants/index';
import { FILTER_GROUP, FILTER_TYPES, ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';
import useAppContext from './useAppContext';

const useGetFilterTypes = clearExcludedTypes => {
  const { obRouteContext } = useAppContext();
  const { linkProps, basePath, page } = obRouteContext;
  const { tab } = linkProps;
  const DEFAULT_FILTER_TYPES = [
    FILTER_TYPES.WORK_TYPE,
    FILTER_TYPES.LANGUAGE,
    FILTER_TYPES.COUNTRY,
    FILTER_TYPES.PERFORMANCE_HIGHLIGHT,
    FILTER_TYPES.PROFESSION,
    FILTER_TYPES.WORK,
    FILTER_TYPES.TICKET,
    FILTER_TYPES.WATCH_ONLINE,
    FILTER_TYPES.SURTITLE,
  ];

  const CLEAR_EXCLUDED_FILTERS = [
    FILTER_TYPES.WHO,
    FILTER_TYPES.WHERE,
    FILTER_TYPES.DATE,
    FILTER_TYPES.WHAT,
    FILTER_TYPES.GENRE,
    FILTER_TYPES.PAGE,
    FILTER_TYPES.LETTER,
    ...(clearExcludedTypes || []),
  ];

  // NOTE: staticFilterTypes is used to get filters stats from facet query
  // NOTE: excludeFilterTypes is used to exclude filters from the filter panel and applied filter chips
  if (basePath === ROUTE_RESERVED_KEYWORDS.CASTING) {
    if (tab === ENTITY_MAIN_TABS.REPERTOIRE) {
      return {
        staticFilterTypes: FILTER_GROUP.REPERTOIRE[0],
        excludeFilterTypes: CLEAR_EXCLUDED_FILTERS,
      };
    }
    if (!page?.entity) {
      return {
        staticFilterTypes: [FILTER_TYPES.SINCE_YEAR, ...DEFAULT_FILTER_TYPES],
        excludeFilterTypes: [...CLEAR_EXCLUDED_FILTERS, FILTER_TYPES.BOOLEAN_SEARCH, FILTER_TYPES.SINCE_YEAR],
      };
    }
  }

  return {
    staticFilterTypes: DEFAULT_FILTER_TYPES,
    excludeFilterTypes: CLEAR_EXCLUDED_FILTERS,
  };
};

export default useGetFilterTypes;
