const { ENTITY_TYPES, FILTER_TYPES, ROUTE_RESERVED_KEYWORDS } = require('../constants');

const getEntityTypeFromBasePath = basePath => {
  switch (basePath) {
    case ROUTE_RESERVED_KEYWORDS.artists:
      return ENTITY_TYPES.PROFILE;
    case ROUTE_RESERVED_KEYWORDS.organisations:
      return ENTITY_TYPES.ORGANIZATION;
    case ROUTE_RESERVED_KEYWORDS.managers:
      return ENTITY_TYPES.AGENCY;
    case ROUTE_RESERVED_KEYWORDS.productions:
      return ENTITY_TYPES.PRODUCTION;
    case ROUTE_RESERVED_KEYWORDS.works:
      return ENTITY_TYPES.WORK;
    default:
      return null;
  }
};

const getPageEntityInfo = ({ filters, basePath, initEntityType = null, initEntity = null }) => {
  const basePathEntityType = initEntityType || getEntityTypeFromBasePath(basePath);

  const whoFilters = filters?.[FILTER_TYPES.WHO] || [];
  const whereFilters = filters?.[FILTER_TYPES.WHERE] || [];
  const venues = whereFilters.filter(({ entityType }) => entityType === ENTITY_TYPES.ORGANIZATION);

  if (whoFilters.length === 1) {
    const { entity, entityType, attributes } = whoFilters[0];

    return {
      entityType,
      entity,
      ...(attributes && { attributes }),
    };
  }

  if (venues.length === 1) {
    const { entity, entityType, attributes } = venues[0];

    return {
      entityType,
      entity,
      ...(attributes && { attributes }),
    };
  }

  return {
    entityType: basePathEntityType,
    entity: initEntity,
  };
};

module.exports = getPageEntityInfo;
