import shuffle from 'lodash/shuffle';
import {
  AGGREGATION_TYPES as CLIENT_SIDE_AGGREGATION_TYPES,
  PROFESSION_IDS,
  SEARCH_ENTITY_TYPE_MAP,
} from 'constants/index';
import { getLanguageCanonicalName, getOption } from 'utils/common';
import { SUB_FILTER_TYPES } from 'constants/filters';
import { FILTER_TYPES, AGGREGATION_TYPES } from 'operabase-router/constants';
import { getWorkTitle } from 'utils/works';
import { getCityCountry } from 'utils/globals';

export const selectData = response => response?.data;

export const transformListToSingleData = response => ({
  ...response?.data,
  data: response?.data?.data?.[0],
});

export const transformGetRandomisedOrderedList = response => {
  const total = response?.data?.length || 0;
  const order = shuffle(Array.from({ length: total }, (_i, i) => i + 1));

  return {
    ...response,
    data: response?.data?.map((item, index) => ({ ...item, order: order[index] || 0 })) || [],
  };
};

export const transformSearchResultsToEntity = response => ({
  ...response,
  data: response?.data?.map(result => {
    const { entity, entity_type, ...rest } = result || {};

    return {
      entityType: SEARCH_ENTITY_TYPE_MAP[entity_type],
      entity: {
        name: entity,
        ...rest,
      },
    };
  }),
});

export const transformLanguageResults = response => ({
  data: response?.data?.data?.map(lang => ({
    ...lang,
    ...getOption(lang, 'id', getLanguageCanonicalName),
  })),
});

export const transformContributionExpressionFilters = (list, filterCategory, aggType) => {
  if (!list?.length) {
    return [];
  }

  const primaryProfessionsGroupKey = 'PRIMARY_PROFESSIONS';
  const commonGroupKey = 'COMMON_GROUP';

  const contributionTypes = list.reduce((acc, item) => {
    const { id, name, slug, total, parentProfession } = item;

    const isPrimaryProfession = [
      FILTER_TYPES.CONDUCTOR,
      FILTER_TYPES.DIRECTOR,
      FILTER_TYPES.CHOREOGRAPHER,
      FILTER_TYPES.COMPOSER,
    ].includes(slug);
    const contributionType = [FILTER_TYPES.CO_PRODUCER, FILTER_TYPES.VENUE, FILTER_TYPES.PRODUCER].includes(slug)
      ? 'contribution_type'
      : '';

    const key = isPrimaryProfession ? primaryProfessionsGroupKey : parentProfession?.id || commonGroupKey;

    // NOTE: use slugs for those filters which do not fall under any category
    const type = isPrimaryProfession || contributionType?.length ? slug : filterCategory;
    const aggregationTypeKey = contributionType?.length ? slug : filterCategory;

    (acc[key] ||= []).push({
      ...item,
      label: name,
      value: id,
      filterType: FILTER_TYPES.CONTRIBUTION_EXPRESSION,
      count: total,
      type,
      aggregationType: contributionType?.length ? AGGREGATION_TYPES[aggregationTypeKey] : aggType,
      selectionCriteria: AGGREGATION_TYPES[aggregationTypeKey] || '',
      contributionType,
    });

    return acc;
  }, {});

  return filterCategory !== FILTER_TYPES.ROLE
    ? Object.values(contributionTypes).filter(arr => arr.length)
    : [
        contributionTypes[primaryProfessionsGroupKey],
        contributionTypes[commonGroupKey],
        contributionTypes[PROFESSION_IDS.SINGER],
        contributionTypes[PROFESSION_IDS.INSTRUMENTATION],
      ].filter(Boolean);
};

export function transformProductionAggregationResponse({
  selectedFilterType,
  subFilterType,
  aggregationType,
  response,
}) {
  if (!response?.data) return { data: [], top5: [] };

  if (!response?.data) return { data: [], top5: [] };

  const getLabel = option => {
    switch (aggregationType) {
      case CLIENT_SIDE_AGGREGATION_TYPES.WORK:
        return getWorkTitle(option, true, true);
      case CLIENT_SIDE_AGGREGATION_TYPES.CITY:
        return `${option.name}${option.country?.name ? `, ${option.country.name}` : ''}`;
      default:
        return option.name;
    }
  };

  const getOptionsId = option =>
    selectedFilterType === FILTER_TYPES.ROLE_PROFESSION_REPERTOIRE
      ? option.workRole?.id || option.profession?.id
      : option.id;

  const requiresCityCountry = [
    FILTER_TYPES.ROLE_PROFESSION,
    FILTER_TYPES.CREATOR,
    FILTER_TYPES.WORK,
    FILTER_TYPES.ROLE_PROFESSION_REPERTOIRE,
    FILTER_TYPES.CONTRIBUTION_EXPRESSION,
  ].includes(selectedFilterType);
  const requiresNestedOptions = selectedFilterType === FILTER_TYPES.WORK_TYPE;
  const isContributionExpression = selectedFilterType === FILTER_TYPES.CONTRIBUTION_EXPRESSION;

  const transFormData = response.data
    .map(option => ({
      ...((requiresCityCountry || isContributionExpression) && { ...option }),
      ...(isContributionExpression && {
        headline: option.headline,
      }),
      ...(requiresNestedOptions && { nestedFilterOptions: option.stagingTypes }),
      cityCountry: option.country?.name || option.city?.name ? getCityCountry(option) : undefined,
      id: getOptionsId(option),
      slug: option.slug,
      value: option.id || option.slug,
      label: getLabel(option),
      total: option.total,
    }))
    .sort((a, b) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase())); // Sort by label

  const includeTop5 =
    subFilterType?.id !== SUB_FILTER_TYPES.PRODUCER &&
    ![FILTER_TYPES.WORK_TYPE, FILTER_TYPES.COUNTRY, FILTER_TYPES.SINCE_YEAR].includes(selectedFilterType);

  return {
    data: transFormData,
    top5: includeTop5 ? transFormData.slice(0, 5) : [],
  };
}
