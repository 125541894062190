/* eslint-disable no-dupe-class-members */
const { convertFilterIntoString } = require('../utils/convertValuesToContributionExpression');
const {
  ENTITY_TYPES,
  FILTER_TYPES,
  FILTER_PATH_PRECEDENCE_ORDER,
  ROUTE_RESERVED_KEYWORDS,
  QUERY_PARAMS_RESERVED,
  OPERATORS,
  PAGE_DYNAMIC_ROUTES,
  DEFAULT_LANGUAGE,
  EXCLUDE_IN_SLUG_FILTER_TYPES,
} = require('../constants');
const getPageEntityInfo = require('../utils/getPageEntityInfo');
const getEntitySlug = require('../utils/getEntitySlug');
const convertValuesToString = require('../utils/convertValuesToString');
const { transformDateToPath } = require('../utils');

const getDynamicPageRoute = ({ basePath }) => {
  switch (basePath) {
    case ROUTE_RESERVED_KEYWORDS['analytics-terms']:
    case ROUTE_RESERVED_KEYWORDS.artists:
    case ROUTE_RESERVED_KEYWORDS.casting:
    case ROUTE_RESERVED_KEYWORDS.composers:
    case ROUTE_RESERVED_KEYWORDS.managers:
    case ROUTE_RESERVED_KEYWORDS.organisations:
    case ROUTE_RESERVED_KEYWORDS.rentals:
    case ROUTE_RESERVED_KEYWORDS.seasons:
    case ROUTE_RESERVED_KEYWORDS.works:
    case ROUTE_RESERVED_KEYWORDS.productions:
      return PAGE_DYNAMIC_ROUTES.ACTIONS;
    case ROUTE_RESERVED_KEYWORDS.pro:
      return PAGE_DYNAMIC_ROUTES.TYPE;
    default:
      return null;
  }
};

class LinkGenerator {
  #language = DEFAULT_LANGUAGE;

  #filterPathPrecedenceOrder = {};

  constructor({ language }) {
    this.#language = language;

    this.#filterPathPrecedenceOrder = FILTER_PATH_PRECEDENCE_ORDER.reduce((acc, filterType, index) => {
      acc[filterType] = index;

      return acc;
    }, {});
  }

  #canURLPathInclude({ order, values, filterType, entity, basePath }) {
    if (
      filterType === FILTER_TYPES.COUNTRY &&
      (!!entity?.id || ![ROUTE_RESERVED_KEYWORDS.organisations, ROUTE_RESERVED_KEYWORDS.managers].includes(basePath))
    ) {
      return false;
    }

    if (filterType === FILTER_TYPES.PROFESSION && (!!entity?.id || basePath === ROUTE_RESERVED_KEYWORDS.productions)) {
      return false;
    }

    if (basePath === ROUTE_RESERVED_KEYWORDS.casting && !entity?.id) {
      return false;
    }

    const hasOrder = order >= 0;
    const isArrayOfOneItem = Array.isArray(values) && values?.length === 1;

    if (filterType === FILTER_TYPES.PRODUCER && Array.isArray(values) && isArrayOfOneItem) {
      const isAnyNegatedValue = values?.[0]?.contributors?.some(value =>
        value?.entity?.id?.toString().startsWith(OPERATORS.FILTER_TYPE_NEGATED),
      );
      if (isAnyNegatedValue || values?.[0]?.contributors?.length > 1) {
        return false;
      }
    }

    const hasId =
      filterType === FILTER_TYPES.PRODUCER ? !!values?.[0]?.contributors?.[0]?.entity?.id : !!values?.[0]?.entity?.id;
    return hasOrder && isArrayOfOneItem && hasId;
  }

  #getPathAndQueryParams({
    page,
    filters,
    queryParams: initialQueryParams,
    subPath,
    scrollTo,
    edit = false,
    basePath,
  }) {
    let pathParams = {};
    let queryParams = {
      ...(initialQueryParams || {}),
      ...(scrollTo && { [QUERY_PARAMS_RESERVED.SCROLL_TO]: scrollTo }),
    };

    const { entityType, entity } = page || {};
    const pageEntitySlug = getEntitySlug({ entityType, entity }) || '';
    Object.entries(filters).forEach(([filterType, values]) => {
      const order = this.#filterPathPrecedenceOrder[filterType];

      if (filterType === FILTER_TYPES.DATE) {
        const dateFrom = values?.[`${filterType}_from`];
        const dateTo = values?.[`${filterType}_to`];

        const { valid, queryParams: dateQueryParams, path } = transformDateToPath({
          dateFrom,
          dateTo,
          path: values?.path,
        });

        if (valid) {
          if (path) {
            pathParams = {
              ...pathParams,
              [order]: path,
            };
          }

          if (dateQueryParams) {
            queryParams = {
              ...queryParams,
              ...dateQueryParams,
            };
          }
        }
      } else if (filterType === FILTER_TYPES.WHERE) {
        const whereFilterInfo = values?.reduce(
          (acc, value) => {
            const { entityType: valueEntityType, operator } = value || {};

            if (!valueEntityType) {
              return acc;
            }

            if (!acc.groupedByEntityType[valueEntityType]) {
              acc.groupedByEntityType[valueEntityType] = [];
              acc[valueEntityType] = 0;
            }

            acc.groupedByEntityType[valueEntityType].push(value);
            acc[valueEntityType] += 1;

            if (operator) {
              if (!acc[operator]) {
                acc[operator] = 0;
              }

              acc[operator] += 1;
            }

            return acc;
          },
          {
            groupedByEntityType: {},
          },
        );

        const hasBooleanOperator =
          Object.keys(whereFilterInfo || {}).filter(key => OPERATORS[key]).length > 0 &&
          whereFilterInfo[OPERATORS.OR] > 0;
        const venueCount = whereFilterInfo[ENTITY_TYPES.ORGANIZATION] || 0;
        const regionCount = whereFilterInfo[ENTITY_TYPES.REGION] || 0;
        const countryCount = whereFilterInfo[ENTITY_TYPES.COUNTRY] || 0;
        const cityCount = whereFilterInfo[ENTITY_TYPES.CITY] || 0;

        let queryValues = values;

        const wherePathParts = [];

        if (!hasBooleanOperator && [venueCount, regionCount, countryCount, cityCount].every(count => count <= 1)) {
          if (venueCount === 1) {
            const slug = getEntitySlug(whereFilterInfo?.groupedByEntityType[ENTITY_TYPES.ORGANIZATION]?.[0]);

            if (slug) {
              wherePathParts.push(slug);

              queryValues = queryValues.filter(value => value?.entityType !== ENTITY_TYPES.ORGANIZATION);
            }
          } else {
            const region = whereFilterInfo?.groupedByEntityType[ENTITY_TYPES.REGION]?.[0];
            const country = whereFilterInfo?.groupedByEntityType[ENTITY_TYPES.COUNTRY]?.[0];
            const city = whereFilterInfo?.groupedByEntityType[ENTITY_TYPES.CITY]?.[0];

            const entityTypeToExclude = [];

            if (region) {
              wherePathParts.push(getEntitySlug(region));
              entityTypeToExclude.push(ENTITY_TYPES.REGION);
            }

            if (country && !city) {
              wherePathParts.push(getEntitySlug(country));
              entityTypeToExclude.push(ENTITY_TYPES.COUNTRY);
            }

            if (city && (!country || city?.country?.slug === country?.slug)) {
              wherePathParts.push(getEntitySlug(city));
              entityTypeToExclude.push(ENTITY_TYPES.CITY);
            }

            queryValues = queryValues.filter(value => !entityTypeToExclude.includes(value?.entityType));
          }
        }

        if (wherePathParts?.length > 0) {
          const path = wherePathParts.filter(slug => pageEntitySlug !== slug).join('/');

          if (path) {
            pathParams = {
              ...pathParams,
              [order]: wherePathParts.filter(slug => pageEntitySlug !== slug).join('/'),
            };
          }
        }

        if (Array.isArray(queryValues) && queryValues?.length > 0) {
          queryParams = {
            ...queryParams,
            [filterType]: convertValuesToString({ values: queryValues }),
          };
        }
      } else if (filterType === FILTER_TYPES.GENRE) {
        const workTypeFilterInfo = values?.reduce(
          (acc, value) => {
            const { entityType: valueEntityType, operator } = value || {};

            if (!valueEntityType) {
              return acc;
            }

            if (!acc.groupedByEntityType[valueEntityType]) {
              acc.groupedByEntityType[valueEntityType] = [];
              acc[valueEntityType] = 0;
            }

            acc.groupedByEntityType[valueEntityType].push(value);
            acc[valueEntityType] += 1;

            if (operator) {
              if (!acc[operator]) {
                acc[operator] = 0;
              }

              acc[operator] += 1;
            }

            return acc;
          },
          {
            groupedByEntityType: {},
          },
        );

        const hasBooleanOperator =
          Object.keys(workTypeFilterInfo || {}).filter(key => OPERATORS[key]).length > 0 &&
          workTypeFilterInfo[OPERATORS.OR] > 0;
        const workTypeCount = workTypeFilterInfo[ENTITY_TYPES.WORK_TYPE] || 0;
        const stagingTypeCount = workTypeFilterInfo[ENTITY_TYPES.STAGING_TYPE] || 0;

        let queryValues = values;

        const workTypePathParts = [];

        if (!hasBooleanOperator && [workTypeCount, stagingTypeCount].every(count => count <= 1)) {
          const workType = workTypeFilterInfo?.groupedByEntityType[ENTITY_TYPES.WORK_TYPE]?.[0];
          const stagingType = workTypeFilterInfo?.groupedByEntityType[ENTITY_TYPES.STAGING_TYPE]?.[0];

          if (workType) {
            workTypePathParts.push(getEntitySlug(workType));
          }

          if (stagingType) {
            workTypePathParts.push(getEntitySlug(stagingType));
          }

          queryValues = queryValues.filter(
            value => ![ENTITY_TYPES.WORK_TYPE, ENTITY_TYPES.STAGING_TYPE].includes(value?.entityType),
          );
        }

        if (workTypePathParts?.length > 0) {
          pathParams = {
            ...pathParams,
            [order]: workTypePathParts.filter(slug => pageEntitySlug !== slug).join('/'),
          };
        }

        if (Array.isArray(queryValues) && queryValues?.length > 0) {
          queryParams = {
            ...queryParams,
            [filterType]: convertValuesToString({ values: queryValues, filterType }),
          };
        }
      } else if (this.#canURLPathInclude({ order, values, filterType, entity, basePath })) {
        let value = values?.[0];
        let skipPrefix = false;
        if (filterType === FILTER_TYPES.PRODUCER) {
          // NOTE: we are skipping prefix because producer id is already prefixed;
          skipPrefix = true;
          value = values?.[0]?.contributors?.[0];
        }

        let path = getEntitySlug(value, skipPrefix);

        const shouldIncludeFilterType = !EXCLUDE_IN_SLUG_FILTER_TYPES.includes(filterType);
        if (shouldIncludeFilterType && path) {
          path = `${filterType}${OPERATORS.FILTER_TYPE_OPERATOR}${path}`;
        }

        if (path && pageEntitySlug !== path) {
          pathParams = {
            ...pathParams,
            [order]: path,
          };
        }
      } else if (values !== null && values !== undefined && values !== '') {
        let queryValue = '';
        if (
          [
            FILTER_TYPES.CONDUCTOR,
            FILTER_TYPES.COMPOSER,
            FILTER_TYPES.PROFESSION,
            FILTER_TYPES.DIRECTOR,
            FILTER_TYPES.CHOREOGRAPHER,
            FILTER_TYPES.ROLE,
            FILTER_TYPES.PRODUCER,
            FILTER_TYPES.ENSEMBLE,
            FILTER_TYPES.CO_PRODUCER,
            FILTER_TYPES.VENUE,
            FILTER_TYPES.PERFORMANCE_HIGHLIGHT,
            FILTER_TYPES.WORK,
            FILTER_TYPES.LANGUAGE,
            FILTER_TYPES.SURTITLE,
            FILTER_TYPES.WORK_TYPE,
            FILTER_TYPES.COUNTRY,
          ].includes(filterType)
        ) {
          queryValue = convertFilterIntoString(filterType, values);
        } else {
          queryValue = convertValuesToString({ values });
        }

        if (queryValue) {
          queryParams = {
            ...queryParams,
            [filterType]: queryValue,
          };
        }
      }
    });

    const pathParamsOrder = Object.keys(pathParams).sort();
    let finalPath = pageEntitySlug;

    if (edit && [ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION].includes(entityType) && entity?.id) {
      finalPath += `/${ROUTE_RESERVED_KEYWORDS.edit}`;
    }

    if (pathParamsOrder?.length > 0) {
      const paths = pathParamsOrder.sort((order1, order2) => order1 - order2).map(key => pathParams[key]);

      finalPath += `/${paths.join('/')}`;
    }

    if (finalPath?.length > 0 && subPath) {
      finalPath += `/${subPath}`;
    }

    return {
      path: finalPath,
      queryParams,
    };
  }

  #getBaseRoute({ mainRoute, page }) {
    const basePath = mainRoute?.split('/')?.[1];
    const rootPage = mainRoute === '/' ? PAGE_DYNAMIC_ROUTES.ACTIONS : getDynamicPageRoute({ basePath });

    if (!rootPage) {
      return mainRoute;
    }

    if (page?.entityType === ENTITY_TYPES.PRODUCTION && !page?.entity?.id) {
      return `/${PAGE_DYNAMIC_ROUTES.ACTIONS}`;
    }

    return `${mainRoute}${mainRoute !== '/' && rootPage.length > 0 ? '/' : ''}${rootPage}`;
  }

  #getURLWithLanguage(asPath, language) {
    const url = `${process?.env?.FRONTBASE_URL || ''}${asPath.replaceAll('//', '/')}`;

    if (!language) {
      return url;
    }

    if (url?.length > 0) {
      const [path, query] = url?.split('?');
      const lastPathPart = path?.split('/')?.slice(-1)?.[0];

      if (lastPathPart?.length === 2 || lastPathPart === 'keys') {
        if (lastPathPart === language) {
          return url;
        }

        const replaceLength = lastPathPart === 'keys' ? -4 : -2;

        return `${path.slice(0, replaceLength)}${language}${query ? `?${query}` : ''}`;
      }

      const pathWithLang = `${path}${path.slice(-1) === '/' ? language : `/${language}`}`;

      if (query?.length > 0) {
        return `${pathWithLang}?${query}`;
      }

      return pathWithLang;
    }

    return url;
  }

  #mapFilterValues({ entityType, values }) {
    return values?.map(value => ({
      entityType,
      entity: value,
    }));
  }

  #legacyFilterTransformations(filters) {
    const { profession_id, ...remainingFilters } = filters || {};

    return {
      ...remainingFilters,
      ...(profession_id?.length > 0 && {
        profession: this.#mapFilterValues({ entityType: ENTITY_TYPES.PROFESSION, values: profession_id }),
      }),
    };
  }

  #hasSearchParamsApplied({ page, filters }) {
    const minimumFiltersToCheck = page?.entity?.id ? 1 : 0;
    const hasDateFilter = Object.values(filters?.[FILTER_TYPES.DATE] || {}).filter(Boolean).length > 0;

    const hasSearchParamsApplied =
      hasDateFilter ||
      [FILTER_TYPES.WHO, FILTER_TYPES.WHERE, FILTER_TYPES.WHAT, FILTER_TYPES.GENRE].reduce((acc, filterType) => {
        const count = filters?.[filterType]?.length || 0;

        return acc + count;
      }, 0) > minimumFiltersToCheck;

    return hasSearchParamsApplied;
  }

  #getSubPath({ legacyPath, tab, filters, page, skipAutoTab = false, shouldLinkToProPage = false }) {
    const subPath = legacyPath || tab;

    if (page?.entity?.id && !skipAutoTab && !subPath) {
      const hasFiltersApplied = this.#hasSearchParamsApplied({ page, filters });

      if (hasFiltersApplied && !shouldLinkToProPage) {
        return ROUTE_RESERVED_KEYWORDS.performances;
      }
    }

    return legacyPath || tab;
  }

  #getBasePath({ basePath: initialBasePath, baseRoute }) {
    const cleanedBaseRoute = baseRoute?.startsWith('/') ? baseRoute.slice(1) : baseRoute;
    const basePath = initialBasePath || cleanedBaseRoute;

    return basePath;
  }

  getLinkProps(props) {
    const {
      baseRoute,
      path: legacyPath = null, // NOTE: LEGACY SUPPORT
      basePath: initialBasePath = null,
      tab = null,
      entityType: initialEntityType,
      entity: initialEntity,
      filters: initialFilters = {},
      queryParams: initialQueryParams = {},
      edit = false,
      pro = false,
      shallow = true,
      rel = null,
      title = null,
      onlyLinkProps = false,
      scrollTo,
      language: overrideLanguage,
      skipAutoTab = false,
    } = props || {};
    const basePath = this.#getBasePath({
      basePath: initialBasePath,
      baseRoute,
      entityType: initialEntityType,
      entity: initialEntity,
    });
    const language = overrideLanguage || this.#language;
    const filters = this.#legacyFilterTransformations(initialFilters);

    const { entityType, entity } = getPageEntityInfo({
      filters,
      basePath,
      initEntityType: initialEntityType,
      initEntity: initialEntity,
    });

    const shouldLinkToProPage =
      entityType === ENTITY_TYPES.PROFILE && (pro || basePath === ROUTE_RESERVED_KEYWORDS.casting);

    const page = entityType
      ? {
          entityType,
          ...(entity?.id && {
            entity: {
              id: entity?.id,
              slug: entity?.slug,
            },
          }),
          edit,
        }
      : null;

    const subPath = this.#getSubPath({ legacyPath, tab, filters, page, skipAutoTab, shouldLinkToProPage });

    const { path, queryParams } = this.#getPathAndQueryParams({
      page,
      filters,
      queryParams: initialQueryParams,
      edit: edit && !pro,
      subPath,
      scrollTo,
      basePath,
    });

    let mainRoute = `/`;

    if (entityType && entity) {
      if (shouldLinkToProPage) {
        mainRoute += ROUTE_RESERVED_KEYWORDS.casting;
      } else if (entityType === ENTITY_TYPES.PRODUCTION) {
        mainRoute += ROUTE_RESERVED_KEYWORDS.productions;
      } else if (entityType === ENTITY_TYPES.AGENCY) {
        mainRoute += ROUTE_RESERVED_KEYWORDS.managers;
      }
    } else if (entityType && !entity) {
      switch (entityType) {
        case ENTITY_TYPES.ORGANIZATION:
          mainRoute += ROUTE_RESERVED_KEYWORDS.organisations;
          break;
        case ENTITY_TYPES.PROFILE:
          if (shouldLinkToProPage) {
            mainRoute += ROUTE_RESERVED_KEYWORDS.casting;
          } else {
            mainRoute += ROUTE_RESERVED_KEYWORDS.artists;
          }

          break;
        case ENTITY_TYPES.PRODUCTION:
          mainRoute += ROUTE_RESERVED_KEYWORDS.productions;
          break;
        case ENTITY_TYPES.WORK:
          mainRoute += ROUTE_RESERVED_KEYWORDS.works;
          break;
        case ENTITY_TYPES.AGENCY:
          mainRoute += ROUTE_RESERVED_KEYWORDS.managers;
          break;
        default:
          break;
      }
    } else if (basePath) {
      mainRoute += basePath;
    }

    if (mainRoute === '/' && !path && Object.keys(queryParams || {}).length > 0) {
      const hasFiltersApplied = this.#hasSearchParamsApplied({ page, filters });

      if (hasFiltersApplied) {
        mainRoute += ROUTE_RESERVED_KEYWORDS.productions;
      }
    }

    let baseHref = this.#getBaseRoute({ mainRoute, page }).replace(/\/+/g, '/');
    let asPath = `${mainRoute}${
      !mainRoute.endsWith('/') && !path.startsWith('/') && path.length > 0 ? '/' : ''
    }${path}`.replace('//', '/');

    let url = asPath;

    if (language) {
      url = this.#getURLWithLanguage(asPath, language);
    }

    const searchParams = new URLSearchParams(queryParams || {});

    if (queryParams) {
      searchParams.sort();
      const queryString = decodeURIComponent(searchParams.toString());

      if (queryString) {
        baseHref = `${baseHref}?${queryString}`;
        asPath = `${asPath}?${queryString}`;
        url = `${url}?${queryString}`;
      }
    }

    return {
      as: asPath,
      href: baseHref,
      shallow,
      ...(rel && { rel }),
      ...(title && { title }),
      ...(!onlyLinkProps && {
        url,
        getSubPath: ({
          title: subPathTitle,
          path: legacySubPath,
          tab: subPathTab,
          queryParams: subPathQueryParams,
          filters: subPathFilters,
          onlyLinkProps: onlySubPathLinkProps,
          mergeFilters = false,
          mergeQueryParams = false,
          scrollTo: scrollToValue,
        }) => {
          const mergedFilters = mergeFilters ? { ...filters, ...subPathFilters } : subPathFilters;
          const mergedQueryParams = mergeQueryParams
            ? { ...initialQueryParams, ...subPathQueryParams }
            : subPathQueryParams;

          let finalSubTab = subPath || '';
          if (legacySubPath || subPathTab) {
            finalSubTab += `/${legacySubPath || subPathTab}`;
          }

          return this.getLinkProps({
            ...props,
            path: null,
            language,
            title: subPathTitle || title,
            tab: finalSubTab,
            scrollTo: scrollToValue,
            filters: mergedFilters,
            queryParams: mergedQueryParams,
            onlyLinkProps: onlySubPathLinkProps,
          });
        },
      }),
    };
  }
}

module.exports = LinkGenerator;
