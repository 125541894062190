import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Typography.module.scss';

const Typography = ({
  className,
  children,
  variant = 'span',
  type = 'regular', // TODO : need to depricate this and use weight everywhere.
  weight,
  size = '14',
  highlight,
  underline,
  strikethrough,
  inline,
  italic,
  truncate,
  blur,
  color = 'primary',
  align,
  vAlign,
  secondaryFont = false,
  textTransform,
  ...rest
}) => {
  const Element = variant;
  const isItalicType = type === 'italic';
  const legacyType = isItalicType ? 'regular' : type;
  const style = weight || legacyType;

  const baseClassSuffix = `${style}-${size}`;
  const suffix = styles[`typography-${baseClassSuffix}`];

  if (!suffix) {
    return null;
  }

  const classes = classnames(suffix, {
    'is-highlighted': highlight,
    'is-underlined': underline,
    'is-striked': strikethrough,
    [styles['typography-inline']]: inline,
    [styles['is-italic']]: italic || isItalicType,
    [styles['is-truncated']]: truncate,
    [styles['is-blur']]: blur,
    [styles[`typography-color-${color}`]]: !!color && color !== 'none',
    [styles[`typography-align-${align}`]]: !!align,
    [styles[`v-align-${vAlign}`]]: !!styles[`v-align-${vAlign}`],
    [styles['typography-secondary-font']]: secondaryFont,
    [styles[`typography-transform-${textTransform}`]]: !!textTransform,
    [className]: !!className,
  });

  let content = children;

  if (strikethrough) {
    content = <strike>{content}</strike>;
  }

  if (underline) {
    content = <u>{content}</u>;
  }

  if (italic || isItalicType) {
    content = <i>{content}</i>;
  }

  if (highlight) {
    content = <mark className={styles['typography-highlight']}>{content}</mark>;
  }

  return (
    <Element
      {...{
        ...(classes ? { className: classes } : {}),
      }}
      {...rest}
    >
      {content}
    </Element>
  );
};

Typography.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.oneOf(['span', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'label', 'q']),
  type: PropTypes.oneOf(['light', 'regular', 'medium', 'bold']),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // in pixels
  highlight: PropTypes.bool,
  underline: PropTypes.bool,
  strikethrough: PropTypes.bool,
  className: PropTypes.string,
  weight: PropTypes.oneOf(['light', 'regular', 'medium', 'bold']),
  italic: PropTypes.bool,
  truncate: PropTypes.bool,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'link',
    'white',
    'tertiary',
    'inherit',
    'orange',
    'error',
    'red',
    'quaternary',
    'none',
    'quinary',
  ]),
  vAlign: PropTypes.oneOf(['top', 'middle', 'bottom']),
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  textTransform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize']),
  secondaryFont: PropTypes.bool,
};

export default Typography;
