const { shouldSkipIDPrefix } = require('./index');
const { OPERATORS, FILTER_TYPES } = require('../constants');
const getEntityIdWithPrefix = require('./getEntityIdWithPrefix');

const convertValuesToString = ({ values, filterType, forcePrefix = false }) => {
  if (!Array.isArray(values)) {
    return ['string', 'number', 'boolean'].includes(typeof values) ? values : '';
  }
  const skipPrefix = !forcePrefix && shouldSkipIDPrefix(filterType);

  return (
    values?.reduce((acc, value, index) => {
      const { id, operator = OPERATORS.AND } = getEntityIdWithPrefix({ ...value, skipPrefix, forcePrefix });
      let separator = index === 0 ? '' : operator;
      // NOTE: as in url we are using ~ but in filter params we need to send as ,
      if (separator) {
        separator = [
          FILTER_TYPES.WORK,
          FILTER_TYPES.WORK_TYPE,
          FILTER_TYPES.COUNTRY,
          FILTER_TYPES.LANGUAGE,
          FILTER_TYPES.SURTITLE,
          FILTER_TYPES.PROFESSION,
        ].includes(filterType)
          ? OPERATORS.AND
          : separator;

        if (filterType === FILTER_TYPES.GENRE) {
          separator = OPERATORS.OR;
        }
      }

      if (id) {
        return `${acc}${separator}${id}`;
      }

      const filterValue = value?.id || value;

      if (['string', 'number'].includes(typeof filterValue)) {
        return `${acc}${separator}${filterValue}`;
      }

      return { operator, id };
    }, '') || ''
  );
};

module.exports = convertValuesToString;
