import React, { createContext, useContext, useMemo, useState } from 'react';
import { EMAIL_SETTING_TYPES } from 'constants/index';
import { modalState } from '../../constants/consts';
const initialData = {
  isContactFormModalOpen: false,
  contactFormType: null,
  displayVideoModal: { STATE: modalState.CLOSE, VIEW: null },
  productionMedia: null,
  isVideoGalleryDialog: false,
  chosenVideoIndex: 0,
  videoList: [],
  updatedSubTitle: '',
  displayRedirectionPopup: false,
  redirectionPopupMedia: null,
  isLoginDialog: { isOpen: false, as: null, source: null, step: 0 },
  productionDetails: null,
  isRegistrationDialog: false,
  giftRegistrationData: {},
  isHelpInfoModal: false,
  helpInfoData: {},
  isNewUser: false,
  isOpenCheckout: { isOpen: false, targetSubscription: null },
  emailAccountSetting: { isOpen: false, settingType: EMAIL_SETTING_TYPES.PASSWORD }, // NOTE: settingType: 'password' | 'email'
  isOpenIdentityVerification: false,
  isAudienceOnboardingModal: false,
  isOnboardingModal: false,
  isCastingProViewModal: false,
  approvedHiddenProfile: false,
  isSwitchProfileModalOpen: false,
  isRequestAccessModalOpen: false,
  isProfileAccessRequestsModalOpen: false,
  isEditDisclaimerModal: false,
  isAlreadyClaimedModal: false,
  isPreviewModalOpen: false,
  isArtistNoAccessModalOpen: false,
  isCustomerFormOpen: false,
  isEmailAlreadyLinked: false,
  showProfileSelectorForCastingTool: { isOpen: false, source: 'castingToolAccess' }, // NOTE: source: 'castingToolAccess' | 'profileAccess'
  isMenuOpen: false,
  isProToolsOpen: false,
};
export const DialogsContext = createContext(initialData);
const DialogsProvider = props => {
  const [isLoginDialog, setIsLoginDialog] = useState(initialData.isLoginDialog);
  const [displayVideoModal, setDisplayVideoModal] = useState(initialData.displayVideoModal);
  const [productionMedia, setProductionMedia] = useState(initialData.productionMedia);
  const [isVideoGalleryDialog, setVideoGalleryDialog] = useState(initialData.productionMedia);
  const [chosenVideoIndex, setChosenVideoIndex] = useState(initialData.chosenVideoIndex);
  const [videoList, setVideoList] = useState(initialData.videoList);
  const [updatedSubTitle, setUpdatedSubTitle] = useState(initialData.updatedSubTitle);
  const [productionDetails, setProductionDetails] = useState(initialData.updatedSubTitle);
  const [displayRedirectionPopup, setDisplayRedirectionPopup] = useState(initialData.displayRedirectionPopup);
  const [redirectionPopupMedia, setRedirectionPopupMedia] = useState(initialData.redirectionPopupMedia);
  const [isRegistrationDialog, setRegistrationDialog] = useState(initialData.isRegistrationDialog);
  const [giftRegistrationData, setGiftRegistrationData] = useState(initialData.giftRegistrationData);
  const [isHelpInfoModal, setIsHelpInfoModal] = useState(initialData.isHelpInfoModal);
  const [helpInfoData, setHelpInfoData] = useState(initialData.helpInfoData);
  const [isNewUser, setIsNewUser] = useState(initialData.isNewUser);
  const [isOpenCheckout, setIsOpenCheckout] = useState(initialData.isOpenCheckout);
  const [emailAccountSetting, setEmailAccountSetting] = useState(initialData.emailAccountSetting);
  const [isOpenIdentityVerification, setIsOpenIdentityVerification] = useState(initialData.isOpenIdentityVerification);
  const [isAudienceOnboardingModal, setIsAudienceOnboardingModal] = useState(initialData.isAudienceOnboardingModal);
  const [isOnboardingModal, setIsOnboardingModal] = useState(initialData.isOnboardingModal);
  const [isCastingProViewModal, setIsCastingProViewModal] = useState(initialData.isCastingProViewModal);
  const [approvedHiddenProfile, setApprovedHiddenProfile] = useState(initialData.approvedHiddenProfile);
  const [isSwitchProfileModalOpen, setIsSwitchProfileModalOpen] = useState(initialData.isSwitchProfileModalOpen);
  const [isRequestAccessModalOpen, setIsRequestAccessModalOpen] = useState(initialData.isRequestAccessModalOpen);
  const [isProfileAccessRequestsModalOpen, setIsProfileAccessRequestsModalOpen] = useState(
    initialData.isProfileAccessRequestsModalOpen,
  );
  const [isEditDisclaimerModal, setIsEditDisclaimerModal] = useState(initialData.isEditDisclaimerModal);
  const [isAlreadyClaimedModal, setIsAlreadyClaimedModal] = useState(initialData.isAlreadyClaimedModal);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(initialData.isPreviewModalOpen);
  const [isContactFormModalOpen, setIsContactFormModalOpen] = useState(initialData.isContactFormModalOpen);
  const [isArtistNoAccessModalOpen, setIsArtistNoAccessModalOpen] = useState(initialData.isArtistNoAccessModalOpen);
  const [isCustomerFormOpen, setIsCustomerFormOpen] = useState(false);
  const [isEmailAlreadyLinked, setIsEmailAlreadyLinked] = useState(initialData.isEmailAlreadyLinked);
  const [showProfileSelectorForCastingTool, setShowProfileSelectorForCastingTool] = useState(
    initialData.showProfileSelectorForCastingTool,
  );
  const [isMenuOpen, setIsMenuOpen] = useState(initialData.isMenuOpen);
  const [isProToolsOpen, setIsProToolsOpen] = useState(initialData.isProToolsOpen);

  const openVideoGalleryDialog = (index, videoSet, subTitle) => {
    setVideoList(videoSet);
    setUpdatedSubTitle(subTitle);
    setChosenVideoIndex(index);
    setVideoGalleryDialog(true);
  };
  const resetDialogState = () => {
    setIsLoginDialog(initialData.isLoginDialog);
    setDisplayVideoModal(initialData.displayVideoModal);
    setProductionMedia(initialData.productionMedia);
    setVideoGalleryDialog(initialData.productionMedia);
    setChosenVideoIndex(initialData.chosenVideoIndex);
    setVideoList(initialData.videoList);
    setUpdatedSubTitle(initialData.updatedSubTitle);
    setProductionDetails(initialData.updatedSubTitle);
    setDisplayRedirectionPopup(initialData.displayRedirectionPopup);
    setRedirectionPopupMedia(initialData.redirectionPopupMedia);
    setApprovedHiddenProfile(initialData.approvedHiddenProfile);
  };

  const openHelpModal = (helpUrl, knowledgeBaseLink) => {
    setHelpInfoData({ helpUrl, knowledgeBaseLink });
    setIsHelpInfoModal(true);
  };

  const dataValue = useMemo(
    () => ({
      displayVideoModal,
      setDisplayVideoModal,
      productionMedia,
      setProductionMedia,
      isVideoGalleryDialog,
      setVideoGalleryDialog,
      chosenVideoIndex,
      setChosenVideoIndex,
      videoList,
      setVideoList,
      updatedSubTitle,
      setUpdatedSubTitle,
      openVideoGalleryDialog,
      displayRedirectionPopup,
      setDisplayRedirectionPopup,
      redirectionPopupMedia,
      setRedirectionPopupMedia,
      productionDetails,
      setProductionDetails,
      isLoginDialog,
      setIsLoginDialog,
      resetDialogState,
      isRegistrationDialog,
      setRegistrationDialog,
      giftRegistrationData,
      setGiftRegistrationData,
      isHelpInfoModal,
      setIsHelpInfoModal,
      helpInfoData,
      openHelpModal,
      isNewUser,
      setIsNewUser,
      setIsOpenCheckout,
      isOpenCheckout,
      emailAccountSetting,
      setEmailAccountSetting,
      isOpenIdentityVerification,
      setIsOpenIdentityVerification,
      isAudienceOnboardingModal,
      setIsAudienceOnboardingModal,
      isOnboardingModal,
      setIsOnboardingModal,
      isCastingProViewModal,
      setIsCastingProViewModal,
      approvedHiddenProfile,
      setApprovedHiddenProfile,
      isSwitchProfileModalOpen,
      setIsSwitchProfileModalOpen,
      isRequestAccessModalOpen,
      setIsRequestAccessModalOpen,
      isProfileAccessRequestsModalOpen,
      setIsProfileAccessRequestsModalOpen,
      isEditDisclaimerModal,
      setIsEditDisclaimerModal,
      isAlreadyClaimedModal,
      setIsAlreadyClaimedModal,
      isPreviewModalOpen,
      setIsPreviewModalOpen,
      isContactFormModalOpen,
      setIsContactFormModalOpen,
      isArtistNoAccessModalOpen,
      setIsArtistNoAccessModalOpen,
      isCustomerFormOpen,
      setIsCustomerFormOpen,
      isEmailAlreadyLinked,
      setIsEmailAlreadyLinked,
      showProfileSelectorForCastingTool,
      setShowProfileSelectorForCastingTool,
      isMenuOpen,
      setIsMenuOpen,
      isProToolsOpen,
      setIsProToolsOpen,
    }),
    [
      displayVideoModal,
      productionMedia,
      isVideoGalleryDialog,
      chosenVideoIndex,
      videoList,
      updatedSubTitle,
      displayRedirectionPopup,
      redirectionPopupMedia,
      productionDetails,
      isLoginDialog,
      isRegistrationDialog,
      giftRegistrationData,
      isHelpInfoModal,
      helpInfoData,
      isNewUser,
      isOpenCheckout,
      emailAccountSetting,
      isOpenIdentityVerification,
      isAudienceOnboardingModal,
      isOnboardingModal,
      isCastingProViewModal,
      approvedHiddenProfile,
      isSwitchProfileModalOpen,
      isRequestAccessModalOpen,
      isProfileAccessRequestsModalOpen,
      isEditDisclaimerModal,
      isAlreadyClaimedModal,
      isPreviewModalOpen,
      isContactFormModalOpen,
      isArtistNoAccessModalOpen,
      isCustomerFormOpen,
      isEmailAlreadyLinked,
      showProfileSelectorForCastingTool,
      isMenuOpen,
      isProToolsOpen,
    ],
  );

  return <DialogsContext.Provider value={dataValue} {...props} />;
};
export const useDialogs = () => useContext(DialogsContext);
export default DialogsProvider;
