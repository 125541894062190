/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import classnames from 'classnames';
import omit from 'lodash/omit';
import Overflow from 'rc-overflow';

import Loader from 'components/Loader';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Popover from 'components/uiLibrary/Popover';
import Modal from 'components/uiLibrary/Modal';
import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
import LinkButton from 'components/uiLibrary/LinkButton';
import CastingRoles from 'components/Casting/Display/CastingRoles';
import useTracking from 'components/Globals/Analytics';
import useQuickView, { QUICK_VIEW_TYPES } from 'components/Globals/Layout/QuickView';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester';
import WorkRoleListing from 'components/Work/Display/WorkRoleListing';

import { createDate } from 'utils/date';
import useScrollBlocker from 'utils/hooks/useScrollBlocker';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import useArchiveLoginModal from 'utils/hooks/useArchiveLoginModal';
import { useQuery } from 'utils/react-query';

import { FILTER_SLUG_TYPE } from 'constants/filters';
import {
  ENTITY_TYPES,
  FILTER_TYPES,
  FILTER_LABELS,
  FILTER_GROUP,
  ROUTE_RESERVED_KEYWORDS,
} from 'operabase-router/constants';
import {
  TP,
  WORK_CREATOR_PROFESSION_TYPES,
  ENTITY_META_DETA_TYPES,
  SEASON_AGGREGATION_TYPES,
  ENTITY_MAIN_TABS,
  DATE_FORMATS,
  ENTITY_DETAILS_TAB,
  CASTING_TOOL_SLUGS,
  ENTITY_AGGREGATION_TYPE_MAP,
} from 'constants/index';

import queries from 'containers/Productions/queries';
import { useTranslation } from 'src/i18n';
import {
  getFilterAsPerPagePath,
  getWorkRolesFromFilterExpression,
  transformAppliedFilters,
} from 'utils/globals/filters';
import useAppContext from 'utils/hooks/useAppContext';
import useScrollIntoView from 'utils/hooks/useScrollIntoView';
import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import { getBooleanExpressionString, getBooleanSearchQuery } from 'utils/casting';
import { getEntityIdWithPrefix } from 'utils/globals';

import useGetFilterTypes from 'utils/hooks/useGetFilterTypes';
import FilterGroupButton from '../FilterGroupButton';
import FilterOptions from '../FilterOptions';

import classes from './FilterFacets.module.scss';

export const LAYOUT = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  QUICK_VIEW: 'quickView',
  BUTTON: 'button',
  BUTTON_GROUP_DETAILED: 'button_group_detailed',
};

const RENDER_FILTERS = {
  FACETS: 'filterFacets',
  ROLES: 'roleFacets',
  SINCE: 'since',
};

const FilterChip = ({
  type,
  count,
  label,
  appliedCount,
  onClick,
  active,
  disable,
  inline,
  isExpanded,
  upcoming,
  hideChevron,
  trackingClasses = '',
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const ref = useRef(null);
  const { getFilterUsageState, baseRoute } = usePageContext();
  const filterState = useMemo(() => getFilterUsageState({ type, upcoming }), [getFilterUsageState, type, upcoming]);
  const isCastingUrl = baseRoute === ROUTE_RESERVED_KEYWORDS.casting;

  return (
    <div
      className={classnames(classes.chip, trackingClasses, {
        [classes.inlineChip]: !!inline,
        [classes.active]: active,
        [classes.disabled]: !!disable,
        [classes.applied]: appliedCount > 0,
      })}
      ref={ref}
      {...(!disable && { onClick: () => onClick({ anchor: ref?.current, type }) })}
    >
      <Typography
        variant="span"
        size={12}
        className={classes.chip__label}
        weight={isExpanded ? 'medium' : 'regular'}
        color={disable ? 'tertiary' : 'primary'}
        truncate
      >
        {label || t(FILTER_LABELS[type])}
      </Typography>
      {!disable && appliedCount > 0 && (
        <Typography variant="span" size={12} className={classes.chip__applied} color="white">
          {appliedCount}
        </Typography>
      )}
      {!inline && !disable && typeof count === 'number' && (
        <Typography size={12} className={classes.chip__count} color="secondary">
          {count}
        </Typography>
      )}
      {filterState.isAdvancedFilter && !isCastingUrl && (
        <SpriteIcon className={classes.chip__lock} icon={filterState?.isRestricted || disable ? 'lock' : 'lock_open'} />
      )}
      {!hideChevron && <SpriteIcon className={classes.chip__icon} icon={inline ? 'expand_more' : 'chevron_right'} />}
    </div>
  );
};

const ExtraFilters = ({ count, onClick }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <div className={classes.chip} onClick={() => onClick({})}>
      {t(`${TP}.FN_PLUS_X_MORE_NO_BRACKET`, { number: count })}
      <SpriteIcon className={classes.chip_icon} icon="expand_more" />
    </div>
  );
};

const OverflowItemWrapper = ({ inline, children }) => {
  if (inline) {
    return <div className={classes.overflowItemWrapper}>{children}</div>;
  }

  return children;
};

const ContributionExpressionFilterChip = ({
  item,
  appliedFiltersStats,
  isActive,
  onClick,
  trackingClasses,
  trackingData,
  enableScroll = false,
  inline,
}) => {
  const { ref: scrollRef } = useScrollIntoView({
    enabled: enableScroll,
  });
  return (
    <div ref={scrollRef}>
      <FilterChip
        key={item?.type}
        label={item?.label}
        type={item?.type}
        active={isActive}
        count={item?.count}
        appliedCount={appliedFiltersStats?.[item?.slug] || 0}
        onClick={onClick}
        trackingData={trackingData}
        trackingClasses={trackingClasses}
        inline={inline}
        hideChevron
      />
    </div>
  );
};

// NOTE : isActive is used to determine if any of contribution exp bucket is active, so we need isActive
const ContributionExpressionFilters = ({
  isActive = false,
  onClick,
  defaultSubFilterType,
  commonFilterParams,
  trackingData,
  trackingClasses,
  inline,
  visibleCount = 2,
  disableLoadMore = false,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { obRouteContext } = useAppContext();
  const { linkProps } = obRouteContext;
  const { filters } = linkProps;
  const [isLoadMore, setIsLoadMore] = useState(!disableLoadMore); // NOTE: true means load more else entire list is loaded
  const [activeContributionType, setActiveContributionType] = useState(null);
  const filtersApplied = useMemo(() => commonFilterParams, [
    commonFilterParams?.date_from,
    commonFilterParams?.date_to,
  ]);
  const { data: contributionTypes, isFetching: isLoading } = useQuery(
    queries.getContributionExpressionFilters({
      filters: filtersApplied,
    }),
  );

  const appliedFiltersStats = useMemo(() => {
    const appliedFilters = {};
    const contributionExp = [...(filters?.[FILTER_TYPES.ROLE] || []), ...(filters?.[FILTER_TYPES.ENSEMBLE] || [])];
    contributionExp.forEach(({ entity, contributors }) => {
      const key = entity?.slug;
      if (key) {
        appliedFilters[key] = contributors?.length || 0;
      }
    });

    const primaryFilters = [
      FILTER_TYPES.CONDUCTOR,
      FILTER_TYPES.DIRECTOR,
      FILTER_TYPES.CO_PRODUCER,
      FILTER_TYPES.PRODUCER,
      FILTER_TYPES.VENUE,
      FILTER_TYPES.CHOREOGRAPHER,
    ];
    primaryFilters.forEach(filterType => {
      if (filters?.[filterType]?.length) {
        appliedFilters[filterType] = filters?.[filterType]?.length || 0;
      }
    });
    return appliedFilters;
  }, [filters]);

  const { types, scrollableItem } = useMemo(() => {
    if (!isLoadMore || isLoading) {
      // NOTE: If load more is disabled or data is still loading, return the entire list
      return {
        types: contributionTypes,
        /**
         NOTE: defaultSubFilterType is initiated from FilterGroupButton.js handleOnClick
         we used this to scroll to the selected contribution type i.e if user click on conductor then we need to scroll to conductor
        */
        scrollableItem:
          !!defaultSubFilterType?.scrollTo &&
          contributionTypes?.flat()?.find(item => item?.slug === defaultSubFilterType?.scrollTo),
      };
    }

    let remaining = visibleCount;
    const list = contributionTypes?.reduce((acc, subArr) => {
      if (remaining <= 0) return acc; // NOTE: If no more elements are needed, return the accumulated result

      if (subArr.length >= remaining) {
        // NOTE: Add only the required number of elements and stop
        acc.push(subArr.slice(0, remaining));
        remaining = 0; // NOTE: Set remaining to 0 to stop further additions
      } else {
        // NOTE: Add the entire sub-array and reduce the remaining count
        acc.push(subArr);
        remaining -= subArr.length;
      }

      return acc;
    }, []);

    return {
      types: list,
      scrollableItem: null,
    };
  }, [contributionTypes, defaultSubFilterType, isLoadMore, isLoading, visibleCount]);

  useEffect(() => {
    if (contributionTypes?.length > 0 && !isLoadMore) {
      setIsLoadMore(contributionTypes?.flat()?.length > types?.flat()?.length);
      if (defaultSubFilterType?.scrollTo && scrollableItem) {
        onClick({ type: FILTER_TYPES.CONTRIBUTION_EXPRESSION, selectedSubFilterType: scrollableItem });
        setActiveContributionType(scrollableItem);
      }
    }
  }, [contributionTypes, types, scrollableItem, defaultSubFilterType]);

  const onClickHandler = useCallback(
    item => {
      setActiveContributionType(item);
      if (onClick) {
        onClick({ type: item?.filterType, selectedSubFilterType: item });
      }
    },
    [onClick],
  );

  const shouldEnableScroll = ({ item }) => {
    if (!scrollableItem) {
      return false;
    }
    return scrollableItem?.id === item?.id && item?.type === scrollableItem?.type;
  };

  if (isLoading) {
    return (
      <OverflowItemWrapper>
        <Loader className={classes.loader} small />
      </OverflowItemWrapper>
    );
  }

  if (!types?.length) {
    return null;
  }

  return (
    <>
      {types?.map(group =>
        group?.map((item, index) => (
          <OverflowItemWrapper inline={inline} key={item?.id}>
            <ContributionExpressionFilterChip
              item={item}
              appliedFiltersStats={appliedFiltersStats}
              defaultSubFilterType={defaultSubFilterType}
              isActive={
                isActive && activeContributionType?.id === item?.id && activeContributionType?.type === item?.type
              }
              onClick={() => onClickHandler(item)}
              trackingData={trackingData}
              trackingClasses={trackingClasses}
              enableScroll={shouldEnableScroll({ item, index })}
              hideChevron
              inline={inline}
            />
            {index === group.length - 1 && !inline && <div className={classes.separator} />}
          </OverflowItemWrapper>
        )),
      )}
      {isLoadMore && (
        <OverflowItemWrapper>
          <LinkButton
            onClick={() => setIsLoadMore(false)}
            styles={{
              root: classnames(classes.loadMore, { [classes.loadMoreInline]: !!inline }),
            }}
            stopPropagation
            preventDefault
          >
            <Typography size={11} color="primary">
              {t(`${TP}.LOAD_MORE`)}
            </Typography>
          </LinkButton>
        </OverflowItemWrapper>
      )}
    </>
  );
};

const RenderFacets = ({
  stats,
  subFilterType,
  expandedFilter,
  onExpandFilter,
  disable = false,
  inline = false,
  upcoming = false,
  disabledTypes,
  hideChevron = false,
  hideFilterCounts = false,
  scrollable,
  trackingData = {},
  commonFilterParams,
  filterGroup,
}) => {
  const { obRouteContext } = useAppContext();
  const { linkProps } = obRouteContext;
  const { filters } = linkProps;
  const { entityType } = usePageContext();
  const trackingClasses = useTrackingClickStyles(trackingData, false);

  const orderedTypes = useMemo(() => {
    const disabledFilterTypes = [FILTER_TYPES.SINCE_YEAR, ...(disabledTypes || [])];
    return filterGroup?.map(subArray =>
      subArray
        .filter(
          type =>
            !disabledFilterTypes?.includes(type) &&
            !([ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION].includes(entityType) && type === FILTER_TYPES.SEASON),
        )
        .map(type => {
          let appliedCount = filters?.[type]?.length || 0;
          if (type === FILTER_TYPES.TICKETS_WATCH_ONLINE) {
            appliedCount = !!filters?.[FILTER_TYPES.TICKET] || !!filters?.[FILTER_TYPES.WATCH_ONLINE] ? 1 : 0;
          }
          return {
            type,
            appliedCount,
            count: hideFilterCounts ? null : stats?.[type],
            active: expandedFilter === type,
            disable: disabledTypes.includes(type) || stats?.[type] === 0,
          };
        })
        .sort((filterA, filterB) => (inline ? filterB.appliedCount - filterA.appliedCount : 0)),
    );
  }, [disabledTypes, entityType, filters, hideFilterCounts, stats, expandedFilter, inline]);

  const Wrapper = scrollable ? HorizontalScroller : 'div';

  return (
    <Wrapper
      className={classnames(classes.renderFacets, {
        [classes.renderFacets__inline]: inline,
      })}
      {...(scrollable && { hideArrow: true })}
    >
      <Overflow
        className={classnames(classes.typeList, { [classes.inline]: inline })}
        data={orderedTypes}
        renderItem={group =>
          group?.map((item, index) =>
            item?.type === FILTER_TYPES.CONTRIBUTION_EXPRESSION ? (
              <ContributionExpressionFilters
                key={`${item?.type}-${index}`}
                onClick={onExpandFilter}
                defaultSubFilterType={subFilterType}
                commonFilterParams={commonFilterParams}
                trackingClasses={trackingClasses}
                trackingData={trackingData}
                inline={inline}
                isActive={item?.active}
                disableLoadMore={!!subFilterType?.scrollTo}
              />
            ) : (
              <OverflowItemWrapper inline={inline} key={`${item?.slug}-${index}`}>
                <FilterChip
                  key={item?.type}
                  type={item?.type}
                  active={item?.active}
                  count={item?.count}
                  appliedCount={item?.appliedCount}
                  onClick={onExpandFilter}
                  disable={disable || item?.disable}
                  inline={inline}
                  upcoming={upcoming}
                  hideChevron={hideChevron}
                  trackingData={trackingData}
                  trackingClasses={trackingClasses}
                />
                {index === group.length - 1 && !inline && <div className={classes.separator} />}
              </OverflowItemWrapper>
            ),
          )
        }
        renderRest={items => <ExtraFilters count={items?.length} onClick={onExpandFilter} />}
        maxCount={!scrollable ? 'responsive' : null}
      />
    </Wrapper>
  );
};

const FilterModalHeader = ({ mainPath, upcoming, hasFiltersApplied, onResetFilters, onClose }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const title = useMemo(() => {
    let baseTitle = t(`${TP}.FILTERS`);

    if (mainPath === ENTITY_MAIN_TABS.PERFORMANCES) {
      baseTitle += upcoming ? ` (${t(`${TP}.FN_UPCOMING`)})` : ` (${t(`${TP}.FN_PAST`)})`;
    }

    return baseTitle;
  }, [t, mainPath, upcoming]);

  return (
    <div className={classes.header}>
      <div className={classes.header__label}>
        <div className={classes.header__icon}>
          <SpriteIcon icon="filter_list" />
        </div>
        <Typography weight="medium">{title}</Typography>
        {hasFiltersApplied && <SpriteIcon icon="replay-bold" onClick={onResetFilters} />}
      </div>
      <SpriteIcon className={classes.closeIcon} icon="close" onClick={onClose} />
    </div>
  );
};

const FilterModalContent = ({
  types,
  stats,
  appliedFilters,
  expandedFilter,
  onAccessArchives,
  onUpdateFilters,
  onExpandFilter,
  onClose,
  upcoming,
  disable,
  disabledTypes,
  trackingData,
  fullHeight = false,
  isSticky = false,
  subFilterType,
  filterGroup,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { obRouteContext } = useAppContext();
  const { linkProps, filterParams } = obRouteContext;
  const { filters } = linkProps;
  const { entity, applyFilters } = usePageContext();

  const {
    preSelectedFilters,
    showHeader,
    showRoleSelection,
    selectedWorksRoles,
    booleanSearch,
    hasMultiRoles,
    total,
  } = useMemo(() => {
    const searchQuery = getBooleanSearchQuery(filters?.expression);
    const worksRoles = getWorkRolesFromFilterExpression(searchQuery);

    const preSelectedFilterList = Object.keys(filters).reduce((acc, key) => {
      if (
        ![FILTER_SLUG_TYPE[FILTER_TYPES.BOOLEAN_SEARCH], FILTER_SLUG_TYPE[FILTER_TYPES.COUNTRY]].includes(key) &&
        filters[key]?.length
      ) {
        acc.push(filters[key]);
      }
      return acc;
    }, []);

    const filterQuery = searchQuery?.filter(q => q.type === 'QUERY');
    let filterTotal = 0;
    if (FILTER_TYPES.TICKETS_WATCH_ONLINE === expandedFilter) {
      filterTotal = (stats?.[FILTER_TYPES.TICKET] || 0) + (stats?.[FILTER_TYPES.WATCH_ONLINE] || 0);
    } else {
      filterTotal =
        expandedFilter === FILTER_TYPES.CONTRIBUTION_EXPRESSION ? subFilterType?.total : stats?.[expandedFilter];
    }

    return {
      showRoleSelection: filterQuery?.length === 1,
      selectedWorksRoles: worksRoles,
      booleanSearch: searchQuery,
      showHeader: filterQuery?.length > 0 || entity?.name,
      preSelectedFilters: preSelectedFilterList,
      hasMultiRoles: selectedWorksRoles?.length > 1,
      total: filterTotal,
    };
  }, [filters, expandedFilter, entity?.name, stats, subFilterType?.total]);

  const [selectedFilterType, setSelectedFilterType] = useState(() => {
    if (expandedFilter === FILTER_TYPES.SINCE_YEAR) {
      return RENDER_FILTERS.SINCE;
    }
    return showRoleSelection && expandedFilter?.length === 1 ? RENDER_FILTERS.ROLES : RENDER_FILTERS.FACETS;
  });
  const [selectedRoleType, setSelectedRoleType] = useState(expandedFilter?.length === 1 ? 'cast' : '');

  useEffect(() => {
    // NOTE: even if above condition is remove but we need this to handle the case when user select multiple roles
    if (!expandedFilter?.trim()?.length && selectedWorksRoles?.length > 1 && stats) {
      const key = Object.keys(stats)?.find(item => stats[item] > 0);
      onExpandFilter({ type: key });
    }
  }, [selectedWorksRoles, stats]);

  const handleSelect = component => {
    if ([RENDER_FILTERS.FACETS, RENDER_FILTERS.SINCE].includes(component)) setSelectedRoleType('');
    if (component === RENDER_FILTERS.ROLES) onExpandFilter({ type: ' ' });
    setSelectedFilterType(component);
  };

  const { commonFilterParams } = useMemo(() => {
    const since = filterParams?.[FILTER_TYPES.SINCE_YEAR];
    const countryIds = filters?.[FILTER_TYPES.COUNTRY];

    return {
      commonFilterParams: {
        ...(since && { date_from: createDate(`${since}`).format(DATE_FORMATS.FULL_DATE) }),
        ...(countryIds && { [filters.COUNTRY]: countryIds }),
      },
      sinceYear: since,
    };
  }, [filters, filterParams]);

  const onSelectRole = useCallback(
    role => {
      const updatedSearch = [...booleanSearch];
      updatedSearch[0] = {
        ...updatedSearch[0],
        value: {
          ...updatedSearch[0]?.value,
          [CASTING_TOOL_SLUGS.WORK_ROLE]: role,
        },
      };

      const newExpression = getBooleanExpressionString(updatedSearch);
      onClose();

      applyFilters({
        baseRoute: ROUTE_RESERVED_KEYWORDS.casting,
        filters: { ...filters, [FILTER_TYPES.BOOLEAN_SEARCH]: newExpression },
      });
    },
    [booleanSearch, filters, applyFilters, onClose],
  );

  return (
    <>
      <div
        className={classnames(classes.filterModal__header, {
          [classes.filterModal__header_isSticky]: isSticky,
        })}
      >
        <div className={classes.filterModal__header_filterText}>
          <SpriteIcon icon="filter_list" className={classes.icon} />
          <Typography weight="bold" className={classes.text}>
            {t(`${TP}.FILTERS`)}
          </Typography>
        </div>
        <div className={classes.filterModal__header_close} onClick={onClose}>
          <LinkButton
            variant="text"
            leftIcon={<SpriteIcon icon="modal_close" size={16} className={classes.icon} />}
            onClick={onClose}
            disableHover
            disableUnderline
          >
            <Typography size="12" weight="medium">
              {t(`${TP}.FN_CLOSE`)}
            </Typography>
          </LinkButton>
        </div>
      </div>
      {showHeader && (
        <div
          className={classnames(classes.filterModal__selectedInfo, {
            [classes.filterModal__selectedInfo_isSticky]: isSticky,
            [classes.filterModal__selectedInfo_isSticky_hasMultiRole]: isSticky && !!hasMultiRoles,
          })}
        >
          {entity?.name ? (
            <Typography weight="bold">{entity.name}</Typography>
          ) : (
            <HorizontalScrollIndicators
              styles={{ leftIndicator: classes.scrollLeftIndicator, rightIndicator: classes.scrollRightIndicator }}
              triggerSize={0}
              isArrowsEnabled
            >
              <CastingRoles isFilterHeader />
            </HorizontalScrollIndicators>
          )}
        </div>
      )}
      <div
        className={classnames(classes.filterModal__content, { [classes.filterModal__content_fullHeight]: fullHeight })}
      >
        <div className={classes.filterModal__content_left}>
          {types?.includes(FILTER_TYPES.SINCE_YEAR) && (
            <div
              className={classes.since}
              onClick={() => {
                onExpandFilter({ type: FILTER_TYPES.SINCE_YEAR });
                handleSelect(RENDER_FILTERS.SINCE);
              }}
            >
              <Typography
                size={12}
                className={classnames(classes.since__title, {
                  [classes.since__title_active]: selectedFilterType === RENDER_FILTERS.SINCE,
                })}
              >
                {t(`${TP}.FN_SINCE`)}
              </Typography>
            </div>
          )}
          {showRoleSelection && (
            <div
              className={classes.workRoles}
              onClick={() => {
                handleSelect(RENDER_FILTERS.ROLES);
              }}
            >
              <WorkRoleListing
                selectedRoleType={selectedRoleType}
                setSelectedRoleType={setSelectedRoleType}
                workId={selectedWorksRoles?.[0]?.workId}
                role={selectedWorksRoles?.[0]?.role}
                filters={commonFilterParams}
                trackingData={trackingData}
                renderRolesTypes
              />
            </div>
          )}
          <div className={classes.facets} onClick={() => handleSelect(RENDER_FILTERS.FACETS)}>
            <Typography weight="bold" className={classes.title}>
              {t(`${TP}.FILTERS`)}
            </Typography>
            <RenderFacets
              filterGroup={filterGroup}
              types={types}
              stats={stats}
              subFilterType={subFilterType}
              commonFilterParams={appliedFilters}
              onExpandFilter={onExpandFilter}
              disable={disable}
              expandedFilter={expandedFilter}
              disabledTypes={disabledTypes}
              upcoming={upcoming}
              trackingData={trackingData}
              hideChevron
              scrollable
            />
          </div>
        </div>
        <div className={classes.filterModal__content_right}>
          {showRoleSelection && selectedFilterType === RENDER_FILTERS.ROLES && (
            <>
              <WorkRoleListing
                workId={selectedWorksRoles?.[0]?.workId}
                role={selectedWorksRoles?.[0]?.role}
                filters={commonFilterParams}
                onSelect={onSelectRole}
                trackingData={trackingData}
                renderRolesNameListing
                selectedRoleType={selectedRoleType}
                setSelectedRoleType={setSelectedRoleType}
                selectedFilters={preSelectedFilters}
              />
            </>
          )}
          {[RENDER_FILTERS.FACETS, RENDER_FILTERS.SINCE].includes(selectedFilterType) &&
            expandedFilter?.trim()?.length > 0 && (
              <FilterOptions
                selectedFilterType={expandedFilter}
                total={total}
                filterParams={appliedFilters}
                onUpdateFilters={onUpdateFilters}
                onAccessArchives={onAccessArchives}
                asPopover={false}
                upcoming={upcoming}
                trackingData={trackingData}
                subFilterType={subFilterType}
                inline
              />
            )}
        </div>
      </div>
    </>
  );
};

const FilterFacets = ({
  mainPath,
  commonFilterParams = {},
  disable = false,
  disabledTypes = [],
  clearExcludedTypes = [],
  upcoming = false,
  layout = LAYOUT.VERTICAL,
  scrollable = false,
  defaultExpandedFilter,
  openInQuickView = true,
  trackingData = {},
  onCloseQuickView,
  customGroup = {},
  styles = {},
  ignoreSmallScreenGroup = false,
  defaultSubFilterType,
  truncateInOneLine,
  viewType,
}) => {
  const { isMobile } = useDeviceTypeLayouts();
  const { obRouteContext } = useAppContext();
  const { linkProps, filterParams, page } = obRouteContext;
  const { filters } = linkProps;
  const { showQuickView } = useQuickView();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const {
    entityType,
    entity,
    applyFilters,
    resetFilters,
    navigate,
    filterTypeUsage,
    paths,
    baseRoute,
    mainPath: currentMainPath,
  } = usePageContext();
  const track = useTracking();
  const { staticFilterTypes: types, excludeFilterTypes } = useGetFilterTypes(clearExcludedTypes);
  const [filterAnchor, setFilterAnchor] = useState(null);
  const [subFilterType, setSubFilterType] = useState(defaultSubFilterType);
  const filterGroup = useMemo(() => {
    if (linkProps?.pro && linkProps?.tab === ENTITY_MAIN_TABS.REPERTOIRE) {
      return FILTER_GROUP.REPERTOIRE;
    }
    return FILTER_GROUP?.[mainPath] || FILTER_GROUP.DEFAULT;
  }, [linkProps?.pro, linkProps?.tab, mainPath]);

  const [expandedFilter, setExpandedFilter] = useState(() => {
    if (filterTypeUsage?.limitExceeded) {
      return filterTypeUsage?.focusFilterType || types[0];
    }

    if (layout === LAYOUT.QUICK_VIEW) {
      return defaultExpandedFilter || filterGroup[0][0];
    }

    return null;
  });

  const isInlineLayout = layout === LAYOUT.HORIZONTAL;

  const openArchiveAccessModal = useArchiveLoginModal();
  useScrollBlocker(expandedFilter);

  const { baseAppliedFilters, facetsApiFilters } = useMemo(() => {
    const defaultFilters = {
      ...(page?.entity?.id && {
        ...([ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION].includes(entityType)
          ? {
              ctx_entity: getEntityIdWithPrefix({ entityType, entityId: page?.entity?.id }),
            }
          : { [ENTITY_AGGREGATION_TYPE_MAP[entityType]]: page?.entity?.id }),
      }),
      ...(types?.includes(FILTER_TYPES.SEASON) && {
        aggregation_season_type:
          entity?.metadata?.find(({ name }) => name === ENTITY_META_DETA_TYPES.SEASON)?.value ||
          SEASON_AGGREGATION_TYPES.CALENDAR.value,
      }),
      ...([FILTER_TYPES.CREATOR, FILTER_TYPES.COMPOSER].includes(expandedFilter) && {
        work_creator_profession: WORK_CREATOR_PROFESSION_TYPES.COMPOSER,
      }),
    };

    let virtualFilter = {};

    if (entityType === ENTITY_TYPES.PROFILE) {
      virtualFilter = {
        include_virtual_contributors: true,
      };
    }

    const sinceYear = filterParams?.[FILTER_TYPES.SINCE_YEAR];

    const params = {
      ...virtualFilter,
      ...defaultFilters,
      ...filterParams,
      ...(sinceYear && {
        date_from: createDate(`${sinceYear}`).format(DATE_FORMATS.FULL_DATE),
      }),
      ...commonFilterParams,
    };
    return {
      baseAppliedFilters: params,
      facetsApiFilters: {
        ...omit(params, [FILTER_TYPES.SINCE_YEAR, FILTER_TYPES.CONTRIBUTION_EXPRESSION]),
      },
    };
  }, [page?.entity?.id, entityType, types, entity?.metadata, expandedFilter, filterParams, commonFilterParams]);

  const appliedFilters = useMemo(
    () => ({
      ...baseAppliedFilters,
      ...(expandedFilter === FILTER_TYPES.CONTRIBUTION_EXPRESSION && {
        ...(subFilterType?.selectionCriteria && {
          [subFilterType.selectionCriteria]: subFilterType.id,
        }),
        ...(subFilterType?.contributionType && {
          [subFilterType.contributionType]: subFilterType.slug,
        }),
      }),
    }),
    [expandedFilter, baseAppliedFilters, subFilterType],
  );

  const isStubOverviewTab =
    [ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION].includes(entityType) &&
    !currentMainPath &&
    [LAYOUT.HORIZONTAL, LAYOUT.BUTTON].includes(layout);

  const { data: stats } = useQuery(
    queries.getFilterCounts({
      types,
      filters: facetsApiFilters,
      extraQueryKeys: [types],
      queryConfig: {
        enabled: !disable && types?.length > 0 && layout === LAYOUT.QUICK_VIEW,
        keepPreviousData: true,
      },
    }),
  );

  const { validFilters, hasFiltersApplied } = useMemo(() => {
    let validParams = omit(filters, excludeFilterTypes);
    validParams = validParams ? Object.keys(getFilterAsPerPagePath({ filters: validParams, page })) : [];
    return {
      hasFiltersApplied: validParams.length > 0,
      validFilters: validParams,
    };
  }, [excludeFilterTypes, filters, page]);

  const onResetFilters = useCallback(
    e => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      resetFilters({ entityType, entity });

      navigate.scrollTo();
    },
    [navigate, resetFilters, entityType, entity],
  );

  const showQuickViewFilters = useCallback(
    ({ type, selectedSubFilterType }) => {
      showQuickView({
        type: QUICK_VIEW_TYPES.FILTERS,
        data: {
          types,
          mainPath,
          commonFilterParams,
          disable,
          disabledTypes,
          clearExcludedTypes,
          upcoming,
          defaultExpandedFilter: type || types[0],
          defaultSubFilterType: selectedSubFilterType,
          openInQuickView: false,
          trackingData,
        },
      });
    },
    [
      clearExcludedTypes,
      commonFilterParams,
      disable,
      disabledTypes,
      mainPath,
      showQuickView,
      trackingData,
      types,
      upcoming,
    ],
  );

  const onExpandFilter = useCallback(
    ({ type, anchor, trackingData: filterTrackingData, selectedSubFilterType }) => {
      if (layout !== LAYOUT.VERTICAL && openInQuickView) {
        showQuickViewFilters({ type, selectedSubFilterType });
      } else {
        setSubFilterType(selectedSubFilterType);
        setFilterAnchor(anchor);
        setExpandedFilter(type || types[0]);
      }

      track.click({
        ...(filterTrackingData || trackingData),
        meta: { filterTab: type || types[0] },
      });
    },
    [types, trackingData?.section, showQuickViewFilters, openInQuickView, layout],
  );

  const onCloseFilter = useCallback(() => {
    setFilterAnchor(null);
    setExpandedFilter(null);
  }, []);

  const onApplyFilters = useCallback(
    options => {
      // NOTE: added this if condition only for testing profession filters, need to remove this

      let filterSubPath;
      const filtersParams = transformAppliedFilters({
        options,
        filters,
        subFilterType,
        selectedFilterType: expandedFilter,
      });

      if (
        mainPath === ENTITY_MAIN_TABS.PERFORMANCES &&
        !upcoming &&
        !filters[FILTER_SLUG_TYPE[FILTER_TYPES.SEASON]]?.length
      ) {
        filterSubPath = ENTITY_DETAILS_TAB.PAST;
      }

      const data = {
        entityType,
        entity,
        mainPath,
        subPath: filterSubPath,
        filters: filtersParams,
      };

      applyFilters(data);
      track.click({
        ...trackingData,
        subComponent: trackingData?.subComponent
          ? `${trackingData.subComponent} / ${SUB_COMPONENTS.SUBMIT_CTA}`
          : SUB_COMPONENTS.SUBMIT_CTA,
        meta: { filters: data.filters },
      });
    },
    [
      filters,
      subFilterType,
      expandedFilter,
      mainPath,
      upcoming,
      entityType,
      entity,
      baseRoute,
      paths,
      applyFilters,
      track,
      trackingData,
    ],
  );

  const onUpdateFilters = useCallback(
    selectedOptions => {
      if (layout === LAYOUT.QUICK_VIEW) {
        onCloseQuickView();
      } else {
        onCloseFilter();
      }

      onApplyFilters(selectedOptions);
    },
    [expandedFilter, onApplyFilters, onCloseFilter],
  );

  const onAccessArchives = useCallback(() => {
    onCloseFilter();
    openArchiveAccessModal();
  }, [onCloseFilter, openArchiveAccessModal]);

  if (layout === LAYOUT.QUICK_VIEW) {
    return (
      <FilterModalContent
        types={types}
        stats={stats}
        subFilterType={subFilterType}
        appliedFilters={appliedFilters}
        expandedFilter={expandedFilter}
        onClose={onCloseQuickView}
        onAccessArchives={onAccessArchives}
        onUpdateFilters={onUpdateFilters}
        onExpandFilter={onExpandFilter}
        upcoming={upcoming}
        disable={disable}
        disabledTypes={disabledTypes}
        filterGroup={filterGroup}
        fullHeight
        isSticky
      />
    );
  }

  if (layout === LAYOUT.BUTTON_GROUP_DETAILED) {
    return (
      <FilterGroupButton
        types={types}
        facetsApiFilters={facetsApiFilters}
        appliedFilters={appliedFilters}
        onExpandFilter={onExpandFilter}
        layout={layout}
        customGroups={customGroup}
        styles={styles}
        ignoreSmallScreenGroup={ignoreSmallScreenGroup}
        truncateInOneLine={truncateInOneLine}
        viewType={viewType}
      />
    );
  }

  if (layout === LAYOUT.BUTTON) {
    const showWorksRoles = Object.keys(appliedFilters).some(
      key => key.includes('expression') && !appliedFilters?.expression?.includes('|'),
    );
    return (
      <LinkButton
        variant="grey"
        styles={{
          root: classes.filterBtn,
        }}
        size="medium"
        onClick={() => {
          onExpandFilter({ type: showWorksRoles ? ' ' : types[0] });
        }}
        leftIcon={<SpriteIcon icon="filter_list" size={14} className={classes.filterBtn__icon} />}
        disableHover
      >
        {t(`${TP}.m_ALL`)} {t(`${TP}.FILTERS`)}
        {validFilters?.length > 0 && (
          <Typography variant="span" size={12} weight="bold" className={classes.filterBtn__count} color="white">
            {validFilters.length}
          </Typography>
        )}
      </LinkButton>
    );
  }

  return (
    <>
      <div className={classnames(classes.root, { [classes.inline]: isInlineLayout })}>
        {!isMobile && (
          <div
            className={classnames(classes.header, {
              [classes.inlineHeader]: isInlineLayout,
              [classes.headerFilterApplied]: hasFiltersApplied,
            })}
            {...(!disable && isInlineLayout && { onClick: () => onExpandFilter({ type: types[0] }) })}
          >
            <>
              <div className={classes.header__icon}>
                <SpriteIcon icon="filter_list" size={isInlineLayout ? 12 : 14} />
              </div>
              <div className={classes.header__label}>
                <Typography
                  weight={isInlineLayout ? 'regular' : 'medium'}
                  size={isInlineLayout ? 12 : 14}
                  className={classnames(classes.header__label_text, {
                    [classes.header__label_textApplied]: validFilters?.length > 0,
                  })}
                >
                  {t(`${TP}.m_ALL`)} {t(`${TP}.FILTERS`)}
                </Typography>
              </div>
              {validFilters?.length > 0 && (
                <Typography size={12} className={classes.chip__applied} color="white">
                  {validFilters.length}
                </Typography>
              )}
            </>
          </div>
        )}
        <RenderFacets
          filterGroup={filterGroup}
          types={types}
          stats={stats}
          commonFilterParams={appliedFilters}
          onExpandFilter={onExpandFilter}
          inline={isInlineLayout}
          scrollable={scrollable}
          disable={disable}
          expandedFilter={expandedFilter}
          disabledTypes={disabledTypes}
          upcoming={upcoming}
          hideFilterCounts={isStubOverviewTab}
          trackingData={trackingData}
        />

        {expandedFilter && layout === LAYOUT.VERTICAL && (
          <Popover
            anchorEl={filterAnchor}
            container={filterAnchor}
            onClose={onCloseFilter}
            styles={{
              root: classes.filterPopover,
            }}
            anchorOrigin={{
              horizontal: 'right',
            }}
            transformOrigin={{
              horizontal: 'left',
            }}
            transition
          >
            <FilterOptions
              selectedFilterType={expandedFilter}
              total={stats?.[expandedFilter]}
              filterParams={appliedFilters}
              onUpdateFilters={onUpdateFilters}
              onAccessArchives={onAccessArchives}
              upcoming={upcoming}
              trackingData={trackingData}
            />
          </Popover>
        )}
      </div>

      {expandedFilter && layout !== LAYOUT.VERTICAL && (
        <Modal
          onClose={onCloseFilter}
          styles={{
            modalContainer: classes.filterModal,
            drawerPaper: classes.filterDrawerPaper,
            content: classes.filterDrawerContent,
          }}
          header={
            <FilterModalHeader
              mainPath={mainPath}
              upcoming={upcoming}
              hasFiltersApplied={hasFiltersApplied}
              onResetFilters={onResetFilters}
              onClose={onCloseFilter}
              trackingData={trackingData}
            />
          }
          isOpen
          allowMobileDrawer
        >
          <FilterModalContent
            types={types}
            stats={stats}
            appliedFilters={appliedFilters}
            expandedFilter={expandedFilter}
            onAccessArchives={onAccessArchives}
            onUpdateFilters={onUpdateFilters}
            onExpandFilter={onExpandFilter}
            upcoming={upcoming}
            disable={disable}
            disabledTypes={disabledTypes}
            trackingData={trackingData}
            onClose={onCloseQuickView}
          />
        </Modal>
      )}
    </>
  );
};

export const FilterSidebar = props => <FilterFacets {...props} openInQuickView={false} layout={LAYOUT.QUICK_VIEW} />;

export default FilterFacets;
