import React, { useCallback, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import TextField from 'components/uiLibrary/TextField';
import Modal from 'components/uiLibrary/Modal';
import Typography from 'components/uiLibrary/Typography';
import LinkButton, { PrimaryButton } from 'components/uiLibrary/LinkButton';
import Switch, { SWITCH_TYPES } from 'components/uiLibrary/Switch';

import classes from './CustomizeModelParams.module.scss';

const ParameterValue = ({ control, variable }) => (
  <div className={classes.parameter}>
    <Controller
      key={variable}
      control={control}
      name={variable}
      render={({ field: { onChange, value: textValue } }) => (
        <TextField
          name={variable}
          label={variable}
          value={textValue || ''}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={onChange}
          size="small"
          fullWidth
        />
      )}
    />
  </div>
);

const CustomizationBlock = ({ identifier, getDetailsForAPI }) => {
  const { name, isEnabled, variables, get, set, enable, disable } = getDetailsForAPI(identifier);

  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
  } = useForm({
    defaultValues: get(),
  });

  const onSaveHandler = useCallback(() => {
    handleSubmit(set)();
  }, [handleSubmit, set]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(get());
    }
  }, [isSubmitSuccessful, reset, get]);

  return (
    <div className={classes.block}>
      <div className={classes.block__header}>
        <Typography variant="p" weight="bold">
          {name()}
        </Typography>
        <Switch
          type={SWITCH_TYPES.ON_OR_OFF}
          leftOption={{
            value: false,
            label: 'Disabled',
          }}
          rightOption={{
            value: true,
            label: 'Enabled',
          }}
          value={isEnabled()}
          onChange={({ value }) => {
            if (value) {
              enable();
            } else {
              disable();
            }
          }}
          controlled
        />
      </div>

      <div className={classes.block__content}>
        {variables().map(variable => (
          <ParameterValue key={variable} variable={variable} control={control} />
        ))}
      </div>
      <div className={classes.block__footer}>
        {isDirty && (
          <Typography variant="p" size={12} weight="medium" italic>
            Unsaved changes
          </Typography>
        )}
        <PrimaryButton size="small" shape="rectangle" onClick={onSaveHandler} disabled={!isDirty}>
          Save
        </PrimaryButton>
      </div>
    </div>
  );
};

const CustomizeModelParams = ({ isOpen, onClose, apiIdentifiers, getDetailsForAPI, onResetState }) => (
  <Modal className={classes.root} isOpen={isOpen} onClose={onClose} title="Customize Model Params">
    <div className={classes.content}>
      {apiIdentifiers.map(identifier => (
        <CustomizationBlock key={identifier} identifier={identifier} getDetailsForAPI={getDetailsForAPI} />
      ))}
    </div>
    <div className={classes.footer}>
      <LinkButton variant="text" onClick={onResetState}>
        RESET TO API DEFAULTS
      </LinkButton>
    </div>
  </Modal>
);

export default CustomizeModelParams;
