/* eslint-disable no-dupe-class-members */
const { uniq } = require('lodash');
const RouteContext = require('./handlers/RouteContext');

const { sanitizeURL } = require('./utils');

class OperabaseRouter {
  #entityFinder = null;

  #translationFn = value => value;

  #statsRetriever = null;

  constructor({ entityFinder, translationFn, statsRetriever } = {}) {
    if (entityFinder) {
      this.#entityFinder = entityFinder;
    }

    if (translationFn) {
      this.#translationFn = translationFn;
    }

    if (statsRetriever) {
      this.#statsRetriever = statsRetriever;
    }
  }

  #getOriginalURL(req) {
    const { nextdata, originalUrl, lng } = req || {};
    const url = originalUrl.replace('/url-context', '');

    if (nextdata) {
      const [path, queryString] = url.split('?');
      const pathFixed = path
        .replace('/_next/data', '')
        .replace(`/${lng}.json`, '')
        .replace(/\/[^/]+/, '');

      const queryObject = new URLSearchParams(queryString || '');
      queryObject.delete('lng');
      queryObject.delete('action');
      queryObject.delete('[...action');
      queryObject.delete('__nextNotFoundSrcPage');
      queryObject.delete('subpath');

      const finalQueryString = queryObject.toString();

      return `${pathFixed}${finalQueryString ? `?${finalQueryString}` : ''}`;
    }

    return sanitizeURL({ url });
  }

  #getReqPathQuery(req) {
    const { path, query, nextdata, lng: language } = req || {};

    let parts = [];

    const { action, subpath: _subpath, lng: _lng, __nextNotFoundSrcPage, ...restQuery } = query || {};

    if (nextdata) {
      const pathParts = path
        .replace('/_next/data', '')
        .replace(`/${language}.json`, '')
        .replace(/\/[^/]+/, '')
        .split('/');
      parts = uniq([...(pathParts || []), ...(action || [])]);
    } else {
      parts = path.split('/');
    }

    return {
      path: parts.filter(part => part && !part.includes('.') && part.length > 2).join('/'),
      query: restQuery,
    };
  }

  async getRequestContext(req) {
    if (!req) {
      return [null, null];
    }

    const { path, query } = this.#getReqPathQuery(req);
    const translationFn = req.t || this.#translationFn;

    const routeContext = new RouteContext({
      path,
      query,
      originalUrl: this.#getOriginalURL(req),
      language: req.lng,
      previousEntityMap: req.previousEntityMap,
      previousPageInfo: req.previousPageInfo,
      previousFilterParams: req.previousFilterParams,
      nextdata: req.nextdata,
      entityFinder: this.#entityFinder,
      translationFn,
      statsRetriever: this.#statsRetriever,
      session: req.session,
    });
    const [state, timing] = await routeContext.context();

    return [state, timing];
  }

  hasURLChanged({ current, next }) {
    const nextURL = sanitizeURL({ url: next });
    const currentURL = sanitizeURL({ url: current });

    return {
      changed: nextURL !== currentURL,
      current: currentURL,
      next: nextURL,
    };
  }
}

module.exports = OperabaseRouter;
