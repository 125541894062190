/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useQuery } from 'utils/react-query';
import classnames from 'classnames';
import { useMediaQuery } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import LinkButton, { QuaternaryButton } from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Tooltip from 'components/uiLibrary/Tooltip';
import Accordion from 'components/uiLibrary/Accordion';
import Loader from 'components/uiLibrary/Loader';
import EntityName from 'components/Globals/EntityName';
import SearchInput from 'components/uiLibrary/FormInputs/SearchInput';
import { FilterFooter } from 'components/Filters/Display/FilterOptions';
import { Radio } from 'components/uiLibrary/RadioGroup';
import NoResult from 'components/Globals/NoResult';
import useTracking from 'components/Globals/Analytics';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester/TrackingTester';
import { COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
// eslint-disable-next-line import/no-cycle
import CastingRoles from 'components/Casting/Display/CastingRoles';

import searchQueries from 'containers/Search/queries';
import queries from 'containers/Work/queries';
import productionQueries from 'containers/Productions/queries';

import { useTranslation } from 'src/i18n';
import { getWorkRoleLabel, getWorkComposerList } from 'utils/works';
import { getWorkRoleProfessions } from 'utils/common';
import { findCreditGroupByRole } from 'utils/casting';
import getLinkProps from 'utils/globals/getLinkProps';

import {
  AGGREGATION_TYPES,
  TP,
  WORK_ROLE_TYPES,
  WORK_ROLE_TYPE_IDS,
  FOUNDING_YEAR,
  PRODUCTION_VALIDITY_IDENTIFIERS,
  URL_SLUG_TYPES,
} from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';
import { ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';
import { FILTER_AGGREGATION_SEARCH, PRODUCTIONS } from 'constants/endpointConstants';
import { CONTRIBUTION_TYPES } from 'constants/consts';

import classes from './WorkRoleListing.module.scss';

const AGGREGATION_ROLE_PROFESSION_TYPE = {
  [WORK_ROLE_TYPES.CAST]: {
    type: AGGREGATION_TYPES.WORK_ROLE,
    workRoleTypeId: WORK_ROLE_TYPE_IDS.CHARACTER,
  },
  [WORK_ROLE_TYPES.INSTRUMENTALIST]: {
    type: AGGREGATION_TYPES.WORK_ROLE,
    workRoleTypeId: WORK_ROLE_TYPE_IDS.INSTRUMENTALIST,
  },
  [WORK_ROLE_TYPES.OTHER_INSTRUMENTALIST]: {
    type: AGGREGATION_TYPES.WORK_ROLE,
    workRoleTypeId: WORK_ROLE_TYPE_IDS.INSTRUMENTALIST,
  },
  [WORK_ROLE_TYPES.OTHER]: {
    type: AGGREGATION_TYPES.WORK_ROLE,
    workRoleTypeId: WORK_ROLE_TYPE_IDS.CHARACTER,
  },
  [WORK_ROLE_TYPES.CREW]: {
    type: AGGREGATION_TYPES.PROFESSION,
  },
  [WORK_ROLE_TYPES.ENSEMBLE]: {
    type: AGGREGATION_TYPES.ENSEMBLE,
  },
};

const CAST_CREW_LABELS = {
  [WORK_ROLE_TYPES.CREW]: `${TP}.FN_CREW_TITLE`,
  [WORK_ROLE_TYPES.CAST]: `${TP}.FN_CAST`,
  [WORK_ROLE_TYPES.OTHER]: `${TP}.FN_OTHER_ROLE`,
  [WORK_ROLE_TYPES.INSTRUMENTALIST]: `${TP}.FN_INDUSTRY_PRO_INSTRUMENTALISTS_LABEL`,
  [WORK_ROLE_TYPES.OTHER_INSTRUMENTALIST]: `${TP}.OTHER_INSTRUMENTALISTS`,
  [WORK_ROLE_TYPES.ENSEMBLE]: `${TP}.FN_ENSEMBLES`,
};

const RenderRolesTypes = ({ castCrewData, updateSelectedRole, selectedRole, role }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const selectedRoleGroupType = useMemo(() => findCreditGroupByRole(castCrewData, role)?.type, [castCrewData, role]);

  return (
    <div className={classes.roleType}>
      <Typography size={14} className={classes.roleType__title} weight="medium">
        {t(`${TP}.m_ROLES`)}
      </Typography>
      {castCrewData?.map(({ type, data }) => (
        <div
          className={classnames(classes.roleType__item, { [classes.roleType__itemActive]: selectedRole === type })}
          key={type}
          onClick={() => {
            updateSelectedRole(type);
          }}
        >
          <Typography size={12} className={classes.label}>
            {t(CAST_CREW_LABELS[type])}
          </Typography>
          <div className={classes.roleTypeCounts}>
            {selectedRoleGroupType === type && (
              <Typography size={12} className={classes.appliedCount}>
                1
              </Typography>
            )}
            <Typography size={12} className={classes.total} color="secondary">
              {data?.length}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

const RenderRolesNameListing = ({ selectedRoleType, group, onSelect, trackingData = {}, role, selectedFilters }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const [query, setQuery] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const onUpdateFilters = useCallback(() => {
    if (onSelect) {
      onSelect(selectedOption);
    }
  }, [onSelect, selectedOption]);

  useEffect(() => {
    if (selectedRoleType) {
      setSelectedOption(null);
    }
  }, [selectedRoleType]);

  useEffect(() => {
    if (role?.id && role?.type === group?.slugType) {
      setSelectedOption({
        ...role,
        type: group?.slugType,
      });
    }
  }, [role, group, selectedRoleType]);

  const filteredData = useMemo(() => {
    if (!group) return [];
    return group.data.filter(item => item.name.toLowerCase().includes(query.toLowerCase()));
  }, [group, query]);

  return (
    <div className={classes.rolesNameListing}>
      <div className={classes.rolesOptions}>
        <Typography weight="medium">
          {t(`${TP}.FN_SELECT`)} {t(CAST_CREW_LABELS[group?.type])}{' '}
          {filteredData?.length > 0 && `(${filteredData?.length})`}
        </Typography>
        <div className={classes.rolesOptions__searchInput}>
          <SearchInput onChange={setQuery} placeholder={t(`${TP}.SEARCH`)} />
        </div>
        {filteredData?.map((item, index) => (
          <>
            <Radio
              key={`${item?.id}-${index}`}
              label={
                <div className={classes.rolesOptions__item}>
                  <Typography size="12" className={classes.rolesOptions__item_label}>
                    {getWorkRoleLabel(item)}
                  </Typography>
                  <Typography size="12" className={classes.rolesOptions__item_count}>
                    {item?.total}
                  </Typography>
                </div>
              }
              onChange={() => {
                track.click({
                  component: COMPONENTS.WORK_ROLE_LISTING,
                  subComponent: SUB_COMPONENTS.ROLE,
                  ...trackingData,
                  meta: {
                    roleId: item?.id,
                  },
                });
                setSelectedOption({
                  ...item,
                  ...AGGREGATION_ROLE_PROFESSION_TYPE[group?.type],
                });
              }}
              name={group?.type}
              styles={{
                radio: classes.rolesOptions__item,
                radioInput: classes.rolesOptions__radioInput,
                radioWrapper: classes.rolesOptions__radioWrapper,
              }}
              checked={selectedOption?.id === item?.id}
            />
          </>
        ))}
        {filteredData?.length === 0 && <NoResult title="No options" sizes={{ icon: 40, title: 14 }} />}
      </div>

      <FilterFooter
        selectedOptions={selectedFilters}
        trackingData={trackingData}
        styles={{ filterDropdown: classes.filterDropdown__footer, submitSearch: classes.submitSearch }}
        onUpdateFilters={onUpdateFilters}
        hasFilterChanged
      />
    </div>
  );
};

const RoleBlock = ({ item, type, onSelect, role, trackingData, trackingClasses, track, isListingView, isMobile }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <Typography
      className={classnames(classes.expandedList__item, trackingClasses, {
        [classes.expandedList__listingViewItem]: isListingView,
        [classes.expandedList__mobileListingViewItem]: isMobile && isListingView,
      })}
      size="14"
      onClick={() => {
        track.click({
          component: COMPONENTS.WORK_ROLE_LISTING,
          subComponent: SUB_COMPONENTS.ROLE,
          ...trackingData,
          meta: {
            roleId: item?.id,
          },
        });
        if (onSelect) {
          onSelect({
            ...item,
            ...AGGREGATION_ROLE_PROFESSION_TYPE[type],
          });
        }
      }}
      weight={[item?.id, item?.slug].includes(role?.id) ? 'bold' : 'medium'}
    >
      <span>
        <Typography size="14" weight="medium" className={classes.expandedList__item_name}>
          {getWorkRoleLabel(item)}
        </Typography>
        {item?.workRoleProfessions?.length ? (
          <Typography color="secondary" size="12" className={classes.expandedList__item_role}>
            {type === CONTRIBUTION_TYPES.CAST && getWorkRoleProfessions(item?.workRoleProfessions)}
          </Typography>
        ) : null}
      </span>
      <span className={classes.expandedList__item_countSection}>
        {item?.totalFromStartYear > item?.total && (
          <Tooltip title={t(`${TP}.ROLE_INFO_CONTENT`)}>
            <span>
              <SpriteIcon icon="info_outline" size={14} className={classes.expandedList__infoIcon} />
            </span>
          </Tooltip>
        )}
        <Typography size="12">{item?.total}</Typography>
        {item?.totalFromStartYear && item?.total !== item?.totalFromStartYear ? (
          <>
            <Typography size="12">/</Typography>
            <Typography color="tertiary" size="12">
              {item?.totalFromStartYear}
            </Typography>
          </>
        ) : null}
        <SpriteIcon icon="chevron_right" className={classes.expandedList__item_arrowIcon} />
      </span>
    </Typography>
  );
};

const RoleGroupBlock = ({ group, onSelect, role, trackingData, isListingView }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const isMobile = useMediaQuery('(max-width: 1000px)');
  const track = useTracking();
  const trackingClasses = useTrackingClickStyles(trackingData);
  const [isExpanded, setExpanded] = useState(false);

  return (
    <div
      className={classnames(classes.option, { [classes.option__mobileListingView]: isListingView && isMobile })}
      key={group?.type}
    >
      <LinkButton
        variant="text"
        styles={{
          root: classnames(classes.option__titleBtn, {
            [classes.option__mobileListingViewTitleBtn]: isListingView && isMobile,
          }),
        }}
        rightIcon={
          <SpriteIcon
            className={isExpanded ? classes.expandLessIcon : classes.expandMoreIcon}
            icon={isExpanded ? 'expand_less' : 'expand_more'}
            size={18}
          />
        }
        onClick={() => setExpanded(!isExpanded)}
        disableHover
        disableUnderline
        trackingData={{ ...trackingData, meta: { clickText: group?.type } }}
      >
        <span>
          <Typography weight="bold" size="14" textTransform="uppercase">
            {t(CAST_CREW_LABELS[group?.type])}
          </Typography>
          <Typography color="secondary" size="14" weight="medium" className={classes.option__count}>
            {group?.data?.length}
          </Typography>
        </span>
      </LinkButton>
      {isExpanded && (
        <div className={classes.option__content}>
          {group?.data?.map((item, index) => (
            <RoleBlock
              key={`${item?.id}-${index}`}
              item={item}
              type={group?.type}
              onSelect={onSelect}
              role={role}
              trackingData={trackingData}
              trackingClasses={trackingClasses}
              track={track}
              isListingView={isListingView}
              isMobile={isMobile}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const OtherRolesBlock = ({
  type,
  onSelect,
  role,
  trackingData,
  trackingClasses,
  track,
  groupedCastAndCrewData,
  isListingView,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const groupType = type === WORK_ROLE_TYPES.CAST ? WORK_ROLE_TYPES.OTHER : WORK_ROLE_TYPES.OTHER_INSTRUMENTALIST;

  if (!groupedCastAndCrewData[groupType]?.length) {
    return null;
  }

  return (
    <div>
      <Accordion
        title={t(CAST_CREW_LABELS[groupType])}
        arrowPosition="end"
        styles={{
          accordion: classes.accordion,
          title: classes.accordion__title,
          collapsedItem: classes.accordion__collapsedItem,
        }}
        typographyProps={{
          size: '14',
          weight: 'bold',
          italic: true,
          textTransform: 'uppercase',
          color: 'secondary',
          expandedColor: 'link',
        }}
      >
        <Typography size="11" italic className={classes.accordion__subTitle}>
          {t(`${TP}.OTHER_ROLE_MESSAGE`)}
        </Typography>
        <div className={classnames(classes.expandedList__content, classes.accordion__content)}>
          {groupedCastAndCrewData[groupType]?.map((item, index) => (
            <RoleBlock
              key={`${item?.id}-${index}`}
              item={item}
              type={groupType}
              onSelect={onSelect}
              role={role}
              trackingData={trackingData}
              trackingClasses={trackingClasses}
              track={track}
              isListingView={isListingView}
            />
          ))}
        </div>
      </Accordion>
    </div>
  );
};

const WorkComposer = ({ workId, trackingData }) => {
  const [composer, selectComposer] = useState(null);

  const { data: work } = useQuery(
    searchQueries.getSelectedOption({
      identifier: workId,
      endpoint: FILTER_AGGREGATION_SEARCH(PRODUCTIONS),
      queryParams: {
        work_id: workId,
        aggregation_type: AGGREGATION_TYPES.WORK,
      },
      queryConfig: {
        enabled: !!workId,
      },
    }),
  );

  useEffect(() => {
    if (work) {
      const selectedComposer = getWorkComposerList(work)?.[0];
      selectComposer(selectedComposer);
    }
  }, [work]);

  return (
    <Typography>
      <Typography weight="bold">
        <EntityName
          entityType={ENTITY_TYPES.WORK}
          entity={work}
          trackingData={trackingData}
          typographyProps={{
            work: {
              size: 12,
              weight: 'bold',
            },
            translation: {
              style: {
                display: 'none',
              },
            },
          }}
        />
        ,
      </Typography>{' '}
      <Typography size={12} italic color="secondary">
        <EntityName
          name={composer?.profile?.name}
          entityType={ENTITY_TYPES.ARTIST}
          entity={composer?.profile}
          trackingData={trackingData}
          isRaw
        />
      </Typography>
    </Typography>
  );
};

const RentalStatItem = ({ label, value, hideSeparator = false }) => {
  if (!value) {
    return null;
  }

  return (
    <Typography size={12} color="secondary" className={!hideSeparator ? classes.statsCard__separator : null}>
      {label}{' '}
      <Typography size={12} weight="medium" color="secondary">
        {value}
      </Typography>
    </Typography>
  );
};

const Rentals = ({ workId, trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  const linkProps = getLinkProps({
    baseRoute: ROUTE_RESERVED_KEYWORDS.rentals,
    queryParams: {
      work_id: workId,
    },
    enabled: !!workId,
    onlyLinkProps: true,
  });
  const { data: rentals } = useQuery(
    productionQueries.getRentalStats({
      filters: {
        has_active_rental: true,
        approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
        work_id: workId,
      },
      queryConfig: {
        enabled: !!workId,
        select: response => {
          const rentalStats = {
            productionCount: response?.actualTotal,
            ...response?.data?.reduce((stats, { slug, total }) => ({ ...stats, [slug]: total }), {}),
          };

          return rentalStats;
        },
      },
    }),
  );

  if (!rentals?.productionCount) {
    return null;
  }

  return (
    <QuaternaryButton
      styles={{ root: classes.statsCard }}
      trackingData={{ ...trackingData, component: COMPONENTS.RENTALS, subComponent: SUB_COMPONENTS.NAVIGATE_CTA }}
      {...linkProps}
      target="_blank"
      isLink
    >
      <div>
        <div>
          <Typography size={12}>
            <Typography size={12} weight="medium" textTransform="uppercase">
              {t(`${TP}.FN_TAB_RENTALS`)}:
            </Typography>{' '}
            {t(`${TP}.FN_FOR`)} <WorkComposer workId={workId} trackingData={trackingData} />
          </Typography>
        </div>
        <div>
          <RentalStatItem label={t(`${TP}.m_PROD`)} value={rentals?.productionCount} hideSeparator />
          <Typography size={12} color="secondary">
            {' '}
            (
            <RentalStatItem
              label={t(`${TP}.COSTUME`)}
              value={rentals?.costumes}
              hideSeparator={!rentals?.sets && !!rentals?.costumes}
            />
            <RentalStatItem label={t(`${TP}.SET`)} value={rentals?.sets} hideSeparator={!rentals?.sets} />
            <RentalStatItem label={t(`${TP}.FN_SCORE`)} value={rentals?.score} hideSeparator />){' '}
            {t(`${TP}.AVAILABLE_FOR_RENT`)}
          </Typography>
        </div>
      </div>
      <SpriteIcon icon="open_in_new" size={19} className={classes.statsCard__icon} />
    </QuaternaryButton>
  );
};

const WorkRoleHeader = ({ isListingView }) => {
  const isMobile = useMediaQuery('(max-width: 1000px)');
  const isScrolled = useScrollTrigger({ disableHysteresis: true, threshold: 420 });

  return (
    <div className={classnames(classes.workRoleSection, { [classes.workRoleSectionSticky]: isScrolled })}>
      <CastingRoles
        isFilterHeader
        showSelectRole
        {...(isListingView &&
          isMobile && {
            typographyProps: {
              work: {
                size: 16,
              },
              composer: {
                size: 16,
              },
            },
          })}
      />
    </div>
  );
};

const RoleGroupExpandedList = ({ data, onSelect, role, trackingData, workId, isListingView }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const trackingClasses = useTrackingClickStyles(trackingData);
  const track = useTracking();

  const groupedCastAndCrewData = data?.reduce((accumulator, currentItem) => {
    accumulator[currentItem.type] = currentItem.data;
    return accumulator;
  }, {});
  const hasCast = groupedCastAndCrewData[WORK_ROLE_TYPES.CAST]?.length;
  const hasInstrumentalist = groupedCastAndCrewData[WORK_ROLE_TYPES.INSTRUMENTALIST]?.length;
  const filteredTypes = Object.keys(groupedCastAndCrewData).filter(type => {
    if (type === WORK_ROLE_TYPES.OTHER && hasCast) return false;
    if (type === WORK_ROLE_TYPES.OTHER_INSTRUMENTALIST && hasInstrumentalist) return false;

    return groupedCastAndCrewData[type]?.length;
  });

  return (
    <div>
      <WorkRoleHeader />
      <div className={classes.expandedList}>
        {filteredTypes.map((type, index) => (
          <div key={type} className={classes.expandedList__title}>
            <Typography weight="bold" size="14" textTransform="uppercase">
              {t(CAST_CREW_LABELS[type])}
            </Typography>
            <div className={classes.expandedList__content}>
              {groupedCastAndCrewData[type]?.map(item => (
                <RoleBlock
                  key={`${item?.id}-${index}`}
                  item={item}
                  type={type}
                  onSelect={onSelect}
                  role={role}
                  trackingData={trackingData}
                  trackingClasses={trackingClasses}
                  track={track}
                  isListingView={isListingView}
                />
              ))}
            </div>
            {[WORK_ROLE_TYPES.CAST, WORK_ROLE_TYPES.INSTRUMENTALIST].includes(type) && (
              <OtherRolesBlock
                groupedCastAndCrewData={groupedCastAndCrewData}
                type={type}
                onSelect={onSelect}
                role={role}
                trackingData={trackingData}
                trackingClasses={trackingClasses}
                track={track}
                isListingView={isListingView}
              />
            )}
            {index === filteredTypes?.length - 1 && (
              <div className={classes.expandedList__stats}>
                <Rentals workId={workId} trackingData={trackingData} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const WorkRoleListing = ({
  className,
  workId,
  role,
  filters = {},
  onSelect,
  isExpandedList = false,
  showWorkTitle = false,
  trackingData,
  renderRolesTypes = false,
  renderRolesNameListing = false,
  selectedRoleType,
  setSelectedRoleType,
  selectedFilters,
  isListingView,
  showSelectRole = false,
}) => {
  const isMobile = useMediaQuery('(max-width: 1000px)');
  const { data: castCrewData } = useQuery(
    queries.getCastCrewDetails({
      filters: {
        work_id: workId,
        ...(filters?.[URL_SLUG_TYPES.DATE_FROM] && { [URL_SLUG_TYPES.DATE_FROM]: filters?.[URL_SLUG_TYPES.DATE_FROM] }),
      },
      queryConfig: { enabled: !!workId },
    }),
  );

  const { data: allCastCrewData, isLoading } = useQuery(
    queries.getCastCrewDetails({
      filters: {
        work_id: workId,
        [URL_SLUG_TYPES.DATE_FROM]: FOUNDING_YEAR,
      },
      queryConfig: { enabled: !!workId },
    }),
  );

  const castAndCrewWithStartYearTotals = useMemo(
    () =>
      castCrewData?.map(castCrewItem => {
        const matchingCastCrewItem = allCastCrewData?.find(
          allCastCrewItem =>
            allCastCrewItem?.type === castCrewItem?.type &&
            castCrewItem?.data?.some(castCrewEntry =>
              allCastCrewItem?.data?.some(allCastCrewEntry => castCrewEntry.id === allCastCrewEntry.id),
            ),
        );

        if (matchingCastCrewItem) {
          return {
            ...castCrewItem,
            data: castCrewItem?.data?.map(castCrewEntry => ({
              ...castCrewEntry,
              totalFromStartYear:
                matchingCastCrewItem.data?.find(allCastCrewEntry => allCastCrewEntry.id === castCrewEntry.id)?.total ||
                castCrewEntry.total,
            })),
          };
        }

        return castCrewItem;
      }),
    [allCastCrewData, castCrewData],
  );

  const { data: work } = useQuery(
    searchQueries.getSelectedOption({
      identifier: workId,
      endpoint: FILTER_AGGREGATION_SEARCH(PRODUCTIONS),
      queryParams: {
        work_id: workId,
        aggregation_type: AGGREGATION_TYPES.WORK,
      },
      queryConfig: {
        enabled: showWorkTitle && !!workId,
      },
    }),
  );

  const updateSelectedRole = useCallback(
    value => {
      setSelectedRoleType(value);
    },
    [setSelectedRoleType],
  );

  const filteredCast = useMemo(() => castCrewData?.find(item => item.type === selectedRoleType), [
    castCrewData,
    selectedRoleType,
  ]);

  if (isListingView && isLoading && workId) {
    return <Loader className={classes.expandedListContainer} />;
  }

  if (!castCrewData?.length) {
    return null;
  }

  if (renderRolesTypes) {
    return (
      <RenderRolesTypes
        castCrewData={castCrewData}
        updateSelectedRole={updateSelectedRole}
        selectedRole={selectedRoleType}
        trackingData={trackingData}
        role={role}
      />
    );
  }

  if (renderRolesNameListing) {
    return (
      <div className={classes.rolesListingWrapper}>
        <RenderRolesNameListing
          group={filteredCast}
          selectedRoleType={selectedRoleType}
          onSelect={onSelect}
          role={role}
          trackingData={trackingData}
          selectedFilters={selectedFilters}
        />
      </div>
    );
  }

  return (
    <div className={classnames({ [classes.expandedListContainer]: isExpandedList, [className]: !!className })}>
      {showWorkTitle && (
        <EntityName
          entity={work}
          entityType={ENTITY_TYPES.WORK}
          trackingData={{ component: COMPONENTS.WORK_ROLE_LISTING, ...trackingData }}
          onlyShortName
          className={classes.title}
          typographyProps={{
            work: {
              size: '18',
              weight: 'bold',
            },
            translation: {
              size: '18',
              weight: 'bold',
              color: 'primary',
            },
          }}
        />
      )}
      {isExpandedList ? (
        <RoleGroupExpandedList
          data={castAndCrewWithStartYearTotals}
          onSelect={onSelect}
          role={role}
          trackingData={trackingData}
          workId={workId}
          isListingView={isListingView}
        />
      ) : (
        <>
          {isListingView && isMobile && (
            <div className={classes.workRoleHeader}>
              <WorkRoleHeader isListingView={isListingView} />
            </div>
          )}
          {castAndCrewWithStartYearTotals?.map(
            group =>
              group?.data?.length > 0 && (
                <RoleGroupBlock
                  key={group.type}
                  group={group}
                  onSelect={onSelect}
                  isExpandedList={isExpandedList}
                  role={role}
                  trackingData={trackingData}
                  isListingView={isListingView}
                />
              ),
          )}
          {isMobile && !showSelectRole && (
            <div className={classes.expandedList__stats}>
              <Rentals workId={workId} trackingData={trackingData} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WorkRoleListing;
