const { FILTER_TYPES, REGULAR_EXPRESSIONS, OPERATORS, ENTITY_TYPE_ID_PREFIX } = require('../constants/index');

const getProcessedContributors = ({ options, filterKey, entityMap }) => {
  if (!options?.length) {
    return [];
  }

  return options?.reduce((acc, id) => {
    // NOTE: currently we are doing for producer later we can introduce for other types
    if (filterKey === FILTER_TYPES.PRODUCER) {
      const isIdWithNegation = REGULAR_EXPRESSIONS.NEGATED_ENTITY_ID.test(id);
      const contributorId = id.replace(REGULAR_EXPRESSIONS.NEGATED_ENTITY_ID, '');
      const item = entityMap[contributorId]?.[0];
      const prefix = ENTITY_TYPE_ID_PREFIX[item?.entityType];
      if (item) {
        acc.push({
          ...item,
          entity: {
            ...item.entity,
            id: isIdWithNegation
              ? `${OPERATORS.FILTER_TYPE_NEGATED}${prefix}${item.entity.id}`
              : `${prefix}${item.entity.id}`,
          },
        });
      }
      return acc;
    }
    if (entityMap[id]?.[0]) {
      acc.push(entityMap[id]?.[0]);
    }

    return acc;
  }, []);
};

module.exports = getProcessedContributors;
