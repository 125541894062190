/* eslint-disable no-dupe-class-members */
const { uniqBy, pick } = require('lodash');
const { getEntities } = require('../utils/apis');
const { FILTER_TYPE_ENTITY_MAP, ENTITIES_WITH_PAGE } = require('../constants/configurations');
const {
  ROUTE_PART_TYPE,
  ENTITY_TYPES,
  PROFESSION_SLUG_TYPE_MAP,
  PATH_PRECEDENCE_ORDER,
  PROFESSION_TYPES,
  ENTITY_TYPE_ID_PREFIX,
  ROUTE_RESERVED_KEYWORDS,
  REGULAR_EXPRESSIONS,
  DEFAULT_LANGUAGE,
  ENTITY_VALIDATION_STATUS,
} = require('../constants');

class MatchingEntityFinder {
  #parts = [];

  #slugEntities = {};

  #language = DEFAULT_LANGUAGE;

  #entityFinder = getEntities;

  #fetchTime = 0;

  #serverTiming = [];

  #session = null;

  constructor({ language, parts, previousEntityMap, entityFinder, session }) {
    this.#parts = parts;
    this.#slugEntities = previousEntityMap;
    this.#language = language;

    if (entityFinder) {
      this.#entityFinder = entityFinder;
    }

    if (session) {
      this.#session = session;
    }
  }

  #getPartAtIndex({ index }) {
    return this.#parts[index] || {};
  }

  #findMatchedEntities({ path }) {
    return this.#slugEntities?.[path] || [];
  }

  #getReservedPathValidFilterEntities({ index }) {
    const configuration = this.#parts[index]?.configuration || {};
    const { validEntitiesForPathWithBasePath, validEntitiesForFilters } = configuration;
    return validEntitiesForPathWithBasePath || validEntitiesForFilters || [];
  }

  #getPreviousPathEntityType({ prevIndex }) {
    let previousEntityType = this.#parts[prevIndex]?.matchedEntityType;

    if (!previousEntityType) {
      const { match, multiple } = this.getEntityForPartIndex(prevIndex);

      if (match && !multiple) {
        previousEntityType = match?.entityType;
      }
    }

    return previousEntityType;
  }

  #getSpecialEntitySlugMapping() {
    // NOTE: For Productions & Managers
    const { slugToId, idToSlug } =
      this.#parts?.reduce(
        (acc, item, index) => {
          if (
            index > 0 &&
            item.type === 'variable' &&
            [ROUTE_RESERVED_KEYWORDS.productions, ROUTE_RESERVED_KEYWORDS.managers].includes(
              this.#parts[index - 1].path,
            ) &&
            REGULAR_EXPRESSIONS.NON_PREFIXED_SLUG_MATCH.test(item.path)
          ) {
            const entityType =
              this.#parts[index - 1].path === ROUTE_RESERVED_KEYWORDS.productions
                ? ENTITY_TYPES.PRODUCTION
                : ENTITY_TYPES.AGENCY;

            const slug = item.path || '';
            const idWithPrefix = slug.replace(/[^\d]+/, ENTITY_TYPE_ID_PREFIX[entityType]);
            acc.slugToId[slug] = idWithPrefix;
            acc.idToSlug[idWithPrefix] = slug;
          }

          return acc;
        },
        { slugToId: {}, idToSlug: {} },
      ) || {};

    return { slugToId, idToSlug };
  }

  #getProfessionType(parents = []) {
    const parentProfession = parents?.find(({ entityType }) => entityType === ENTITY_TYPES.PROFESSION);
    const type = PROFESSION_SLUG_TYPE_MAP[parentProfession?.slug] || PROFESSION_TYPES.PROFESSION;

    return type;
  }

  #processAttributes(attributes = []) {
    return attributes.reduce((acc, attribute) => {
      const { attributeType, entityType, ...entity } = attribute || {};

      if (entityType) {
        if (!acc[attributeType]) {
          acc[attributeType] = [];
        }

        acc[attributeType].push({ entityType, entity });
      } else {
        acc[attributeType] = entity?.name;
      }

      return acc;
    }, {});
  }

  #getMatchedEntityFromValidEntities({ index, entities = [] }) {
    const previousPathIndex = index - 1;
    const isPreviousPathReserved = this.#parts[previousPathIndex]?.type === ROUTE_PART_TYPE.RESERVED;

    let validEntitiesFound = entities;

    let validEntityIndex = -1;

    if (validEntitiesFound.length === 1 || isPreviousPathReserved) {
      validEntityIndex = 0;
    } else if (index === 0) {
      validEntitiesFound = validEntitiesFound.filter(({ entityType }) => ENTITIES_WITH_PAGE.includes(entityType));

      if (validEntitiesFound.length > 1) {
        validEntitiesFound = validEntitiesFound.filter(({ parents }) => !parents?.length);
      }

      if (validEntitiesFound.length === 1) {
        validEntityIndex = 0;
      }
    } else {
      const previousEntityType = this.#getPreviousPathEntityType({ prevIndex: previousPathIndex });

      if (previousEntityType) {
        const parentEntityTypeIndex = validEntitiesFound.findIndex(
          ({ entityType }) => entityType === previousEntityType,
        );

        if (parentEntityTypeIndex === -1) {
          validEntityIndex = validEntitiesFound.reduce((matchIndex, { parents }, entityIndex) => {
            if (!parents?.length) {
              return matchIndex;
            }

            if (parents.some(({ entity }) => entity?.slug === this.#parts[previousPathIndex].path)) {
              return entityIndex;
            }

            return matchIndex;
          }, -1);

          if (validEntityIndex === -1) {
            const entitiesWithoutMandatoryParents = validEntitiesFound.filter(({ entityType, parents }) => {
              if (parents?.length) {
                const isParentMandatory = [ENTITY_TYPES.CITY, ENTITY_TYPES.STAGING_TYPE].includes(entityType);

                return !isParentMandatory;
              }

              if (ENTITY_TYPES.STAGING_TYPE === entityType) {
                return false;
              }

              return true;
            });

            if (entitiesWithoutMandatoryParents.length === 1) {
              validEntityIndex = 0;
              validEntitiesFound = entitiesWithoutMandatoryParents;
            }
          }
        } else {
          validEntityIndex = parentEntityTypeIndex + 1;
        }
      }
    }

    const validMatchedEntity = validEntitiesFound[validEntityIndex] || null;

    return validMatchedEntity;
  }

  getServerTiming({ processOrder, processName }) {
    const serverTimings = (this.#serverTiming || []).map((timer, index) => ({
      ...timer,
      name: `${processOrder}.${index + 1}-${processName}-${timer.name}`,
      description: `Entity API: ${timer.description}`,
    }));

    return [
      ...serverTimings,
      {
        name: `${processOrder}.${serverTimings?.length + 1}-${processName}-fetch-frontend`,
        description: `Entity API (F): with network lag`,
        duration: this.#fetchTime,
      },
    ];
  }

  async fetchEntities({ slugs }) {
    const uniqueSlugList = [...new Set(slugs)];
    const missingSlugList = uniqueSlugList.filter(slug => !this.#slugEntities[slug]);

    let entityMap = {};
    const { slugToId, idToSlug } = this.#getSpecialEntitySlugMapping();
    const transformedMissingSlugList = missingSlugList.map(slug => slugToId[slug] || slug);

    if (transformedMissingSlugList.length) {
      const startTime = new Date().getTime();
      const response = await this.#entityFinder(
        {
          slugs: transformedMissingSlugList,
          language: this.#language,
        },
        this.#session,
      );

      const endTime = new Date().getTime();
      this.#fetchTime = endTime - startTime;
      this.#serverTiming = response?.serverTiming || [];

      entityMap = response?.data?.reduce((acc, item) => {
        if (!item) {
          return acc;
        }

        const { slug, entities } = item || {};
        const key = idToSlug[slug] || slug;
        acc[key] = uniqBy(entities, value => `${value.entityType}-${value.id}`).map(value => {
          const { entityType, attributes, parents, logo, ...entity } = value;

          return {
            entityType,
            entity: {
              ...entity,
              ...(entityType === ENTITY_TYPES.AGENCY && {
                slug: entity.slug.replace(REGULAR_EXPRESSIONS.REPLACE_ID_PREFIX_SLUG_WITH_ID, '$1$2'),
              }),
              ...(logo && { logo }),
              ...(entityType === ENTITY_TYPES.PROFESSION && { type: this.#getProfessionType(parents) }),
            },
            ...(attributes?.length && { attributes: this.#processAttributes(attributes) }),
            ...(parents?.length && {
              parents: parents?.map(({ entityType: parentEntityType, ...parentEntity }) => ({
                entityType: parentEntityType,
                entity: parentEntity,
              })),
            }),
          };
        });

        return acc;
      }, {});
    }

    this.#slugEntities = {
      ...this.#slugEntities,
      ...entityMap,
    };

    return pick(this.#slugEntities, slugs);
  }

  getEntityForPartIndex(index) {
    const { path, type, filterType } = this.#getPartAtIndex({ index });

    if (type === ROUTE_PART_TYPE.RESERVED) {
      return {
        match: null,
        found: false,
        multiple: false,
      };
    }

    const matchedEntities = this.#findMatchedEntities({ path });
    const found = matchedEntities?.length > 0;

    if (matchedEntities?.length <= 1) {
      return {
        match: matchedEntities?.[0] || null,
        found,
        multiple: false,
      };
    }

    const previousPathIndex = index - 1;
    const isPreviousPathReserved = this.#parts[previousPathIndex]?.type === ROUTE_PART_TYPE.RESERVED;
    const isBasePathReserved = this.#parts[0]?.type === ROUTE_PART_TYPE.RESERVED;

    let validEntitiesFound = [];

    if (filterType) {
      const validEntityForFilterType = FILTER_TYPE_ENTITY_MAP[filterType];

      if (validEntityForFilterType) {
        validEntitiesFound = matchedEntities.filter(({ entityType }) => validEntityForFilterType === entityType);
      }
    } else {
      let validFilterEntities = isBasePathReserved
        ? this.#getReservedPathValidFilterEntities({ index: 0 })
        : PATH_PRECEDENCE_ORDER;

      if (isPreviousPathReserved) {
        validFilterEntities = this.#getReservedPathValidFilterEntities({ index: previousPathIndex });
      } else if (previousPathIndex >= 0) {
        const previousEntityType = this.#getPreviousPathEntityType({ prevIndex: previousPathIndex });

        const indexOfPreviousEntityType = validFilterEntities.indexOf(previousEntityType);

        if (indexOfPreviousEntityType !== -1) {
          validFilterEntities = validFilterEntities.slice(indexOfPreviousEntityType + 1);
        }
      }

      validEntitiesFound = matchedEntities
        .filter(({ entityType }) => validFilterEntities.includes(entityType))
        .sort((entityX, entityY) => {
          const pathXOrder = validFilterEntities.indexOf(entityX?.entityType);
          const pathYOrder = validFilterEntities.indexOf(entityY?.entityType);

          return pathXOrder - pathYOrder;
        });
    }

    let validMatchedEntity = this.#getMatchedEntityFromValidEntities({ index, entities: validEntitiesFound });

    if (!validMatchedEntity && validEntitiesFound?.length > 0) {
      const onlyApprovedEntities = validEntitiesFound.filter(
        ({ entity }) => ENTITY_VALIDATION_STATUS.APPROVED === entity?.validationStatus?.slug,
      );

      if (onlyApprovedEntities?.length > 0) {
        validMatchedEntity = this.#getMatchedEntityFromValidEntities({ index, entities: onlyApprovedEntities });
      }
    }

    if (validMatchedEntity?.entityType) {
      this.#parts[index].matchedEntityType = validMatchedEntity.entityType;
    }

    return {
      match: validMatchedEntity,
      found,
      multiple: validEntitiesFound.length > 1,
    };
  }
}

module.exports = MatchingEntityFinder;
