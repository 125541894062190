import { getList } from 'utils/API';
import { getLanguage } from 'utils/queriesUtil';
import { transformEntity } from 'utils/globals';
import {
  ENTITY_TYPE,
  PROFESSIONS_AVAILABILITY,
  WORK_ROLE_TYPE_IDS,
  WORK_ROLE_TYPES,
  ENTITY_SLUG_ID_PREFIX,
  SORT_OPTION_VALUES,
  CASTING_TOOL_SLUGS,
} from 'constants/index';
import { VALIDATION_STATUS } from 'constants/consts';

import { getOption } from 'utils/globals/app';
import { getQuery, updateQuery } from 'utils/globals/queries';
import { FILTER_TYPES, AGGREGATION_TYPES } from 'operabase-router/constants';

const ENDPOINTS = {
  STATIC: {
    WORKS: 'works',
    PRODUCTIONS: 'productions',
  },
  DYNAMIC: {
    FILTER_AGGREGATION_SEARCH: endpoint => `${endpoint}/aggregations`,
    UPDATE_WORK_ROLE_DETAILS: ({ id }) => `works/roles/${id}`,
  },
};

const defaultConfig = {
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 10 * 60 * 1000,
  getNextPageParam: lastPage => {
    const { limit, page, total } = lastPage;
    const totalFetched = limit * page;

    return totalFetched < total ? page + 1 : undefined;
  },
  getPreviousPageParam: () => false,
};

const fetchWorks = async (filters, cookies, pagination) => {
  const params = {
    queryParams: {
      ...filters,
    },
    pagination,
  };

  return getList(ENDPOINTS.STATIC.WORKS, params, cookies).then(response => response);
};

const fetchWorkProfile = ({ id }, cookies) => {
  const params = {
    queryParams: {
      id,
    },
  };

  return getList(ENDPOINTS.STATIC.WORKS, params, cookies).then(response =>
    transformEntity(response?.data?.data?.[0], ENTITY_TYPE.WORK),
  );
};

const fetchCreatorProfileWorkDetail = ({ id, entityType, limit = 25, page = 1 }, cookies) => {
  const params = {
    queryParams: {
      work_creator_id: `${ENTITY_SLUG_ID_PREFIX[entityType]}${id}`,
      sort: SORT_OPTION_VALUES.CREATED_AT_DESC_V2,
      validation_status: VALIDATION_STATUS.APPROVED,
    },
    pagination: {
      limit,
      page,
    },
  };
  return getList(ENDPOINTS.STATIC.WORKS, params, cookies).then(response => response.data);
};

const fetchCastCrew = ({ filters = {}, limit = 100, page = 1 }, cookies) => {
  const pagination = {
    limit,
    page,
  };

  const endpoint = ENDPOINTS.DYNAMIC.FILTER_AGGREGATION_SEARCH(ENDPOINTS.STATIC.PRODUCTIONS);

  return Promise.allSettled([
    getList(
      endpoint,
      {
        pagination,
        queryParams: {
          ...filters,
          aggregation_type: AGGREGATION_TYPES[FILTER_TYPES.WORK_ROLE],
          work_role_type_id: WORK_ROLE_TYPE_IDS.CHARACTER,
          is_work_role_standard: true,
        },
      },
      cookies,
    ),
    getList(
      endpoint,
      {
        pagination,
        queryParams: {
          ...filters,
          aggregation_type: AGGREGATION_TYPES[FILTER_TYPES.WORK_ROLE],
          work_role_type_id: WORK_ROLE_TYPE_IDS.CHARACTER,
          is_work_role_standard: false,
        },
      },
      cookies,
    ),
    getList(
      endpoint,
      {
        pagination,
        queryParams: {
          ...filters,
          aggregation_type: AGGREGATION_TYPES[FILTER_TYPES.PROFESSION],
          contribution_type: 'crew',
          availability: PROFESSIONS_AVAILABILITY.CASTING_TOOL,
        },
      },
      cookies,
    ),
    getList(
      endpoint,
      {
        pagination,
        queryParams: {
          ...filters,
          aggregation_type: AGGREGATION_TYPES[FILTER_TYPES.WORK_ROLE],
          work_role_type_id: WORK_ROLE_TYPE_IDS.INSTRUMENTALIST,
          is_work_role_standard: true,
        },
      },
      cookies,
    ),
    getList(
      endpoint,
      {
        pagination,
        queryParams: {
          ...filters,
          aggregation_type: AGGREGATION_TYPES[FILTER_TYPES.WORK_ROLE],
          work_role_type_id: WORK_ROLE_TYPE_IDS.INSTRUMENTALIST,
          is_work_role_standard: false,
        },
      },
      cookies,
    ),
    getList(
      endpoint,
      {
        pagination,
        queryParams: {
          ...filters,
          aggregation_type: AGGREGATION_TYPES[FILTER_TYPES.ENSEMBLE],
        },
      },
      cookies,
    ),
  ]).then(response => {
    const type = [
      { workRoleType: WORK_ROLE_TYPES.CAST, slugType: CASTING_TOOL_SLUGS.WORK_ROLE },
      { workRoleType: WORK_ROLE_TYPES.OTHER, slugType: CASTING_TOOL_SLUGS.WORK_ROLE },
      { workRoleType: WORK_ROLE_TYPES.CREW, slugType: CASTING_TOOL_SLUGS.PROFESSION },
      { workRoleType: WORK_ROLE_TYPES.INSTRUMENTALIST, slugType: CASTING_TOOL_SLUGS.WORK_ROLE },
      { workRoleType: WORK_ROLE_TYPES.OTHER_INSTRUMENTALIST, slugType: CASTING_TOOL_SLUGS.WORK_ROLE },
      { workRoleType: WORK_ROLE_TYPES.ENSEMBLE, slugType: CASTING_TOOL_SLUGS.ENSEMBLE },
    ];
    return response.map((res, index) => {
      const { workRoleType, slugType = '' } = type[index];
      return {
        data: res?.value?.data?.data,
        type: workRoleType,
        slugType,
      };
    });
  });
};

const queries = {
  getWorksList: ({ filters, queryConfig = {}, pagination }, cookies, language) => ({
    queryKey: [
      getLanguage(language),
      'GET_WORK_PROFILE_DETAILS',
      JSON.stringify(filters),
      `${pagination?.limit}`,
      `${pagination?.page}`,
    ],
    queryFn: async () => fetchWorks(filters, cookies, pagination),
    select: response => ({
      ...response?.data,
      data: response?.data?.data?.map(option =>
        getOption(
          option,
          'id',
          'original_name',
          { id: option?.id, slug: option?.slug, name: option?.original_name },
          true,
        ),
      ),
    }),
    ...defaultConfig,
    ...queryConfig,
  }),
  getWorkDetails: ({ id, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_WORK_PROFILE_DETAILS', `${id}`],
    queryFn: async () => fetchWorkProfile({ id }, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
  getCreatorWorksDetails: ({ entityId, entityType, limit, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_CREATOR_WORK_DETAILS', `${entityId}`, `${limit}`],
    queryFn: async ({ pageParam = 1 }) =>
      fetchCreatorProfileWorkDetail({ id: entityId, entityType, limit, page: pageParam }, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
  getCastCrewDetails: getQuery('GET_CAST_CREW', fetchCastCrew),
  updateWorkRole: updateQuery(ENDPOINTS.DYNAMIC.UPDATE_WORK_ROLE_DETAILS),
};

export default queries;
