import {
  ACADEMICS_TYPE_ID,
  AGENCY_TYPE_ID,
  ARTIST_TYPE_ID,
  CASTING_TYPE_ID,
  COMPANY_TYPE_ID,
  JOURNALIST_TYPE_ID,
  TP,
  PLANS,
} from 'constants/index';

export const REGISTRATION_STEPS = {
  userRegistration: '1',
  updateUserInformation: '1.1',
  validateEmail: '1.2',
  reValidateEmail: '1.2.1',
  createProfileExistingUser: '1.3',
  createReferralAccount: '1.4',
  whiteListedDomain: '1.5',
  whiteListedDomainPreference: '1.5.1',
  cohortForm: '2',
  createProfileForm: '3',
  profileMatch: '3.2',
  digitalPresence: '3.3',
  addUserProduction: '3.4',
  employmentTypeForm: '4',
  organizationsForm: '5',
  organizationsPerformanceForm: '6.1',
  organizationsWorkTypeForm: '6.2',
  orgRoleForm: '6.3',
  orgToolPreferenceForm: '6.4',
  subscriptionForm: '8',
  noAccessForm: '8.1',
  submitNoAccessForm: '8.2',
  congratulationForm: '9',
  finished: '10',
};

export const REGISTRATION_COHORT_TYPES = {
  AUDIENCE: 1,
  INDUSTRY_PROFESSIONALS: 2,
  JOURNALIST_AND_MEDIA: 3,
  EDUCATION_AND_ACADEMIA: 4,
};

export const REGISTRATION_FLOW_ID = {
  [ARTIST_TYPE_ID]: REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS,
  [AGENCY_TYPE_ID]: REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS,
  [COMPANY_TYPE_ID]: REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS,
  [JOURNALIST_TYPE_ID]: REGISTRATION_COHORT_TYPES.JOURNALIST_AND_MEDIA,
  [ACADEMICS_TYPE_ID]: REGISTRATION_COHORT_TYPES.EDUCATION_AND_ACADEMIA,
  [CASTING_TYPE_ID]: REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS,
};

export const PAYMENT_STEPS = [`${TP}.PAYMENT_PROGRESS_STEP_1`, `${TP}.PAYMENT_PROGRESS_STEP_2`, `${TP}.FN_SUCCESS`];

export const SUBSCRIPTION_PRODUCT_IDS = {
  CASTING_TOOL: 74,
  ARCHIVE_ACCESS: 88,
  AOS: 78,
  PER_SEAT: 89,
  AGENCY: 11,
  PROFILE_MONTHLY: 4,
  PROFILE_ANNUAL: 5,
};

export const CASTING_TOOL_PLANS = [
  {
    title: `${TP}.FN_CASTINGTOOL`,
    planPrice: '€ 1500',
    subtitle: `${TP}.FN_AOS_PLAN_SUBTITLE_ANNUAL_PRO`,
    bulletTitle: '',
    bulletPoints: [
      `${TP}.FOR_CASTINGTOOL_PRICE_LIST_TALENT`,
      `${TP}.FOR_CASTINGTOOL_PRICE_LIST_ARCHIVE_ACCESS`,
      `${TP}.AS_SUBSCRIPTION_AGENCY_LOSE_CASTING`,
      `${TP}.FOR_CASTINGTOOL_PRICE_LIST_PERFORMANCE`,
    ],
    ctaText: `${TP}.FN_PROCEED`,
    color: '#D9D9D9',
    buttonType: 'primary',
    productId: SUBSCRIPTION_PRODUCT_IDS.CASTING_TOOL,
    slug: 'casting-tool-annual-plan',
    landingPageCTAText: `${TP}.FN_CONTACT_US`,
    buttonShape: 'rectangle',
  },
];

export const CASTING_TOOL_PLANS_NEW = [
  {
    label: `${TP}.FN_CASTINGTOOL`,
    price: '€ 1500',
    taxType: 'VAT',
    subTitle: `${TP}.FN_PLAN_ANNUAL_ARTIST_SUBTITLE`,
    bulletTitle: `${TP}.INCLUDED_BULLET_POINT_TITLE`,
    bulletPoints: [
      {
        text: `${TP}.FOR_CASTINGTOOL_PRICE_LIST_TALENT`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.FOR_CASTINGTOOL_PRICE_LIST_ARCHIVE_ACCESS`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.AS_SUBSCRIPTION_AGENCY_LOSE_CASTING`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.FOR_CASTINGTOOL_PRICE_LIST_PERFORMANCE`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.FOR_CASTINGTOOL_PRICE_LIST_PERFORMANCE_1_ACCES_PAGE`,
        icon: 'done',
        available: true,
      },
    ],
    ctaText: `${TP}.FN_PROCEED`,
    productId: SUBSCRIPTION_PRODUCT_IDS.CASTING_TOOL,
    slug: 'casting-tool-annual-plan',
  },
];

export const ARCHIVE_ACCESS_CHECKOUT = [
  {
    label: `Archive Access`,
    price: '€ 120',
    taxType: 'VAT',
    subTitle: `${TP}.FN_PLAN_ANNUAL_ARTIST_SUBTITLE`,
    bulletTitle: `${TP}.INCLUDED_BULLET_POINT_TITLE`,
    bulletPoints: [
      {
        text: `${TP}.BASIC_PLAN_SEARCH`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.LIMITED_FILTERS`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.ARCHIVE_INFO`,
        icon: 'done',
        available: true,
      },
    ],
    ctaText: `${TP}.FN_PROCEED`,
    productId: SUBSCRIPTION_PRODUCT_IDS.ARCHIVE_ACCESS,
    slug: 'archive-access-annual-EUR-Yearly',
  },
];

export const NEW_ARTIST_PLANS_DETAILS = [
  {
    label: `${TP}.PAYMENTS_PRODUCT_MONTHLY`,
    price: '€15',
    taxType: 'VAT',
    description: `${TP}.PERMONTH_TITLE`,
    bulletTitle: `${TP}.NOT_INCLUDED_BULLET_POINT_TITLE`,
    bulletPoints: [
      {
        text: `${TP}.PERFORMACE_VERFICATION_BULLET`,
        icon: 'close',
        available: false,
      },
      {
        text: `${TP}.ARCHIVE_ACCESS_TITLE`,
        icon: 'close',
        available: false,
      },
    ],
    value: 'monthly',
    isSaving: false,
    savingText: '',
    productId: SUBSCRIPTION_PRODUCT_IDS.PROFILE_MONTHLY,
    slug: 'pro_artist_monthly',
  },
  {
    label: `${TP}.ANNUAL_PLAN`,
    price: '€120',
    taxType: 'VAT',
    perMonthSaving: '€15',
    subTitle: `${TP}.MOTHLY_BILLING_ANNUAL`,
    bulletTitle: `${TP}.INCLUDED_BULLET_POINT_TITLE`,
    bulletPoints: [
      {
        text: `${TP}.PERFORMACE_VERFICATION_BULLET`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.ARCHIVE_ACCESS_TITLE`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.ANNUAL_SAVING_FOR_ARTIST_PREMIUM`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.MONTHLY_PLAN_BULLET_TITLE_2`,
        icon: 'done',
        available: true,
      },
    ],
    value: 'annual',
    isSaving: true,
    savingText: `${TP}.GET_ANNUAL_COST_TITLE`,
    productId: SUBSCRIPTION_PRODUCT_IDS.PROFILE_ANNUAL,
    slug: 'pro_artist_annually',
  },
];

export const ARTIST_PLANS = [
  {
    title: `${TP}.FN_SUBSCRIPTION_PLANS_ARTIST_MONTHLY`,
    planPrice: '€ 15',
    subtitle: `${TP}.FN_PLAN_MONTHLY_ARTIST_SUBTITLE`,
    bulletTitle: `${TP}.FN_ARTIST_PLAN_BULLET_TITLE`,
    bulletPoints: [
      `${TP}.FN_ARTIST_MONTHLY_LI_1`,
      `${TP}.FN_ARTIST_MONTHLY_LI_2`,
      `${TP}.FN_ARTIST_MONTHLY_LI_3`,
      `${TP}.FN_ARTIST_MONTHLY_LI_5`,
      `${TP}.FN_ARTIST_MONTHLY_LI_6`,
    ],
    strikeBulletPoints: [{ text: `${TP}.FN_ARTIST_MONTHLY_LI_7`, icon: 'red_mask' }],
    ctaText: `${TP}.FN_PROCEED`,
    color: '#D9D9D9',
    buttonType: 'secondary',
    productId: 4,
    slug: 'pro_artist_monthly',
    landingPageCTAText: `${TP}.FN_PLANS_START_PREMIUM`,
    freeTitle: `${TP}.FN_PLAN_FREE_ONE_MONTH`,
  },
  {
    title: `${TP}.FN_SUBSCRIPTION_PLANS_ARTIST_YEARLY`,
    planPrice: '€ 10',
    subtitle: `${TP}.FN_ANNUALLY_BILLING_TEXT`,
    bulletTitle: `${TP}.FN_ARTIST_PLAN_BULLET_TITLE`,
    bulletPoints: [
      { text: `${TP}.FN_ARTIST_MONTHLY_LI_7`, icon: 'red_mask' },
      `${TP}.FN_ARTIST_MONTHLY_LI_1_ANNUAL_PRIMARY`,
      `${TP}.FN_ARTIST_MONTHLY_LI_1`,
      `${TP}.FN_ARTIST_MONTHLY_LI_2`,
      `${TP}.FN_ARTIST_MONTHLY_LI_3`,
      `${TP}.FN_ARTIST_MONTHLY_LI_5`,
      `${TP}.FN_ARTIST_MONTHLY_LI_6`,
    ],
    ctaText: `${TP}.FN_PROCEED`,
    buttonType: 'primary',
    color: '#1F52BB',
    productId: 5,
    slug: 'pro_artist_annually',
    landingPageCTAText: `${TP}.FN_PLANS_START_PREMIUM`,
    freeTitle: `${TP}.FN_PLAN_FREE_ONE_MONTH`,
    badge: {
      type: 'primary',
      text: `Save 20%`,
    },
  },
];

export const AOS_PLANS = [
  {
    label: `${TP}.FN_CASTINGTOOL`,
    price: '€ 2000',
    taxType: 'VAT',
    subTitle: `${TP}.FN_PLAN_ANNUAL_ARTIST_SUBTITLE`,
    bulletTitle: `${TP}.INCLUDED_BULLET_POINT_TITLE`,
    bulletPoints: [
      {
        text: `${TP}.FN_AOS_PRO_ANNUAL_LI_1`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.FN_AOS_PRO_ANNUAL_LI_2`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.FN_AOS_PRO_ANNUAL_LI_3`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.FN_AOS_PRO_ANNUAL_LI_4`,
        icon: 'done',
        available: true,
      },
    ],
    ctaText: `${TP}.FN_PROCEED`,
    productId: SUBSCRIPTION_PRODUCT_IDS.AOS,
    slug: 'pro_aos_annually_large_new',
  },
];

export const PER_SEAT = [
  {
    label: `${TP}.FN_CASTINGTOOL`,
    price: '€ 100',
    taxType: 'VAT',
    subTitle: `${TP}.FN_PLAN_ANNUAL_ARTIST_SUBTITLE`,
    bulletTitle: `${TP}.INCLUDED_BULLET_POINT_TITLE`,
    bulletPoints: [
      {
        text: `${TP}.FN_AOS_PRO_ANNUAL_LI_1`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.FN_AOS_PRO_ANNUAL_LI_2`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.FN_AOS_PRO_ANNUAL_LI_3`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.FN_AOS_PRO_ANNUAL_LI_4`,
        icon: 'done',
        available: true,
      },
    ],
    ctaText: `${TP}.FN_PROCEED`,
    productId: SUBSCRIPTION_PRODUCT_IDS.PER_SEAT,
    slug: 'org-outsider',
  },
];

export const AGENCY_PLANS = [
  {
    label: `${TP}.FN_AGENCY_PRO`,
    price: '€ 850',
    taxType: 'VAT',
    subTitle: `${TP}.FN_PLAN_ANNUAL_ARTIST_SUBTITLE`,
    bulletTitle: `${TP}.INCLUDED_BULLET_POINT_TITLE`,
    bulletPoints: [
      {
        text: `${TP}.FN_AGENCY_PRO_LI_1`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.FN_AGENCY_PRO_LI_2`,
        icon: 'done',
        available: true,
      },
      {
        text: `${TP}.FN_AGENCY_PRO_LI_3`,
        icon: 'done',
        available: true,
      },
    ],
    ctaText: `${TP}.FN_PROCEED`,
    productId: SUBSCRIPTION_PRODUCT_IDS.AGENCY,
    slug: 'pro_agent',
  },
];

export const CHECKOUT_TYPES = {
  AOS: 'aos',
  AGENCY: 'agency',
  PER_SEAT: 'perSeatSubscription',
  PROFILE: 'profile',
  ARCHIVE: 'archive',
  CASTING_TOOL: 'castingTool',
};

export const BASIC_PLAN_FEATURES = [
  { label: `${TP}.BASIC_PLAN_SEARCH` },
  { label: `${TP}.LIMITED_FILTERS` },
  { label: `${TP}.NO_ARCHIVE`, icon: 'lock' },
];

export const ARCHIVE_PLAN_FEATURES = [
  { label: `${TP}.BASIC_PLAN_SEARCH` },
  { label: `${TP}.LIMITED_FILTERS` },
  { label: `${TP}.ARCHIVE_INCLUDED` },
];

export const INDUSTRY_PROFESSIONALS_FEATURES = explorePlanToggle => [
  { label: `${TP}.PREMIUM_PROFILE_DISPLAY`, disabled: explorePlanToggle },
  { label: `${TP}.INCREASE_VISIBILITY`, disabled: explorePlanToggle },
  { label: `${TP}.REPERTOIRE_FUTURE_ROLE`, disabled: explorePlanToggle },
  { label: `${TP}.MEDIA_PROFILE`, disabled: explorePlanToggle },
  { label: `${TP}.ARCHIVE_INCLUDED` },
  { label: `${TP}.LIMITED_FILTERS` },
  { label: `${TP}.BASIC_PLAN_SEARCH` },
];
export const CASTING_TOOL_FEATURES = [
  { label: `${TP}.ADVANCE_SEARCH_TITLE` },
  { label: `${TP}.ADVANCE_FILTERS_TITLE` },
  { label: `${TP}.ARCHIVE_INCLUDED` },
  { label: `${TP}.PREMIUM_PAGE_DISPLAY_AOS` },
  { label: `${TP}.INCREASE_VISIBILITY_AOS` },
  { label: `${TP}.SEASON_LISTING_AOS` },
  { label: `${TP}.PRIORITY_PERFORMANCE` },
  { label: `${TP}.RENTALS_AOS_ONLY` },
];

export const FEATURES_ARCHIVE = [
  {
    name: `${TP}.BASIC_PLAN_SEARCH`,
    supportedPlanMonthly: [PLANS.PRO],
    supportedPlanAnnually: [PLANS.PRO],
  },
  {
    name: `${TP}.LIMITED_FILTERS`,
    supportedPlanMonthly: [PLANS.PRO],
    supportedPlanAnnually: [PLANS.PRO],
  },
  {
    name: `${TP}.ARCHIVE_INFO`,
    supportedPlanMonthly: [PLANS.PRO],
    supportedPlanAnnually: [PLANS.PRO],
  },
];

export const FEATURES_CASTING_TOOL = [
  {
    name: `${TP}.ADVANCE_SEARCH_TITLE`,
    supportedPlanMonthly: [PLANS.PRO],
    supportedPlanAnnually: [PLANS.PRO],
  },
  {
    name: `${TP}.ADVANCE_FILTERS_TITLE`,
    supportedPlanMonthly: [PLANS.PRO],
    supportedPlanAnnually: [PLANS.PRO],
  },
  {
    name: `${TP}.ARCHIVE_INFO`,
    supportedPlanMonthly: [PLANS.PRO],
    supportedPlanAnnually: [PLANS.PRO],
  },
  {
    name: `${TP}.PREMIUM_PAGE_DISPLAY_AOS`,
    supportedPlanMonthly: [PLANS.PRO],
    supportedPlanAnnually: [PLANS.PRO],
  },
  {
    name: `${TP}.INCREASE_VISIBILITY_AOS`,
    supportedPlanMonthly: [PLANS.PRO],
    supportedPlanAnnually: [PLANS.PRO],
  },
  {
    name: `${TP}.SEASON_LISTING_AOS`,
    supportedPlanMonthly: [PLANS.PRO],
    supportedPlanAnnually: [PLANS.PRO],
  },
  {
    name: `${TP}.PRIORITY_PERFORMANCE`,
    supportedPlanMonthly: [PLANS.PRO],
    supportedPlanAnnually: [PLANS.PRO],
  },
  {
    name: `${TP}.RENTALS_AOS_ONLY`,
    supportedPlanMonthly: [PLANS.PRO],
    supportedPlanAnnually: [PLANS.PRO],
  },
];

export const CONTACTS_IDENTIFIERS = {
  phone: 0,
  email: 1,
  sns: 2,
  website: 3,
  address: 4,
  other: 5,
};

export const SNS_CONTACTS_NAMES = {
  CUETV: 'cuetv',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  YOUTUBE: 'youtube',
  VK: 'vk',
  VIMEO: 'vimeo',
};

export const PRIVACY_ATTRIBUTE_IDS = {
  PRIVATE: 1,
  PUBLIC: 2,
  PRO: 3,
  FREE_AND_PRO: 4,
  CASTING: 5,
  ARTISTS: 6,
  AGENCY: 7,
  AOS: 8,
};

export const TAG_TYPE = {
  REGISTRATION_COHORT: 'registration-cohort',
};

export const TAG_SLUGS = {
  ON_STAGE_ARTIST: 'on-stage-artist',
  INSTRUMENTALIST: 'instrumentalist',
  OFF_STAGE_ARTIST: 'off-stage-artist',
  ORGANIZATIONS: 'organizations',
  AGENTS: 'agents',
};

export const INDUSTRY_PROFESSIONALS_OPTIONS = [
  {
    id: 'onstage_artist',
    type: 'on_stage_artist',
    label: `${TP}.FN_INDUSTRY_PRO_ONSTAGE_LABEL`,
    description: `${TP}.FN_INDUSTRY_PRO_ONSTAGE_DESC`,
    profileTypeId: 1,
    tag: TAG_SLUGS.ON_STAGE_ARTIST,
    otherProfessionTags: [TAG_SLUGS.OFF_STAGE_ARTIST, TAG_SLUGS.INSTRUMENTALIST],
  },
  {
    id: 'instrumentalists',
    type: 'instrumentalists',
    label: `${TP}.FN_INDUSTRY_PRO_INSTRUMENTALISTS_LABEL`,
    description: `${TP}.FN_INDUSTRY_PRO_INSTRUMENTALISTS_DESC`,
    professionId: 3,
    profileTypeId: 1,
    tag: TAG_SLUGS.INSTRUMENTALIST,
    otherProfessionTags: [TAG_SLUGS.ON_STAGE_ARTIST, TAG_SLUGS.OFF_STAGE_ARTIST],
  },
  {
    id: 'offstage_artist',
    type: 'off_stage_artist',
    label: `${TP}.FN_INDUSTRY_PRO_OFFSTAGE_LABEL`,
    description: `${TP}.FN_INDUSTRY_PRO_OFFSTAGE_DESC`,
    profileTypeId: 1,
    tag: 'off-stage-artist',
    otherProfessionTags: [TAG_SLUGS.ON_STAGE_ARTIST, TAG_SLUGS.INSTRUMENTALIST],
  },
  {
    id: 'arts_organisation_employees_staff',
    type: 'arts_organisation_employees_staff',
    label: `${TP}.FN_INDUSTRY_PRO_AOS_LABEL`,
    description: `${TP}.FN_INDUSTRY_PRO_AOS_DESC`,
    profileTypeId: 3,
    tag: TAG_SLUGS.ORGANIZATIONS,
  },
  {
    id: 'agency',
    type: 'agency_artist_manager',
    label: `${TP}.FN_INDUSTRY_PRO_AGENCY_LABEL`,
    description: `${TP}.FN_INDUSTRY_PRO_AGENCY_DESC`,
    profileTypeId: 2,
    tag: TAG_SLUGS.AGENTS,
  },
];

export const REGISTRATION_COHORTS = [
  {
    type: REGISTRATION_COHORT_TYPES.AUDIENCE,
    label: `${TP}.FN_AUDIENCE`,
    description: `${TP}.FN_AUDIENCE_COHORT_DESCRIPTION`,
    vwoTargetId: 'cohort_audience',
  },
  {
    type: REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS,
    label: `${TP}.FN_PROFESSIONALS`,
    description: `${TP}.FN_PROFESSIONALS_DESCRIPTIONS`,
    bulletPoints: [
      `${TP}.PROFESSIONAL_B1`,
      `${TP}.PROFESSIONAL_B2`,
      `${TP}.PROFESSIONAL_B3`,
      `${TP}.PROFESSIONAL_B4`,
      `${TP}.PROFESSIONAL_B5`,
    ],
    vwoTargetId: 'cohort_industry_professionals',
  },
];

export const MEETING_LINK = 'https://meetings.hubspot.com/operabase-website-update/meet-with-operabase';

export const EMPLOYMENT_TYPES_OPTIONS = [
  {
    type: 'independent',
    label: `${TP}.FN_EMPLOYMENT_TYPE_SELF_EMPLOYED`,
  },
  {
    type: 'consultant',
    label: `${TP}.FN_EMPLOYMENT_TYPE_PART_TIME`,
  },
  {
    type: 'full-time',
    label: `${TP}.FN_EMPLOYMENT_TYPE_FULL_TIME`,
  },
];

export const GIFT_TOKEN_TYPES = {
  USER: 'user',
  GIFT: 'gift',
  ADMIN: 'admin',
};

export const REGISTER_TYPE = {
  [REGISTRATION_COHORT_TYPES.AUDIENCE]: 'audience',
  [REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS]: 'industry_professional',
  [REGISTRATION_COHORT_TYPES.JOURNALIST_AND_MEDIA]: 'journalist-media',
  [REGISTRATION_COHORT_TYPES.EDUCATION_AND_ACADEMIA]: 'academics',
};

export const REG_HUBSPOT_FORM_IDS = ({ isProduction, step, flowId, isOrg, isAddNewOrg }) => {
  switch (step) {
    case REGISTRATION_STEPS.userRegistration:
      return isProduction ? '5679127b-e289-456d-80d8-a09ffb0aa5c5' : '190d648f-cdbc-47d7-ac46-7a6db3cd8d13';
    case REGISTRATION_STEPS.updateUserInformation:
      return isProduction ? 'a3775e44-b202-4d6f-a97d-5247f0a1ab7e' : 'ecceca7f-a5b4-4684-b6f1-9e550bd2421f';
    case REGISTRATION_STEPS.validateEmail:
      return isProduction ? 'a86164b2-f7d2-46a5-9901-7e7f875b8bb5' : '3fe86538-33a7-41a8-a7fd-951b74fe30f9';
    case REGISTRATION_STEPS.reValidateEmail:
      return isProduction ? 'bef024fd-9a29-40f6-86ba-eb1ff78abc4c' : 'fc055b53-a18d-475b-9cc4-7c79f9dd7362';
    case REGISTRATION_STEPS.createProfileExistingUser:
      return isProduction ? '7496c10e-6f07-4c59-95b9-520af6ac697c' : '6c0f7f90-ea54-4807-a2fc-0d34bdf280ce';
    case REGISTRATION_STEPS.cohortForm:
      return isProduction ? 'bdeab2c2-b193-4586-a330-aca574310221' : 'fc0d0912-f250-4b0e-b5d5-05ef8016f975';
    case REGISTRATION_STEPS.createProfileForm:
      if (flowId === REGISTRATION_COHORT_TYPES.AUDIENCE) {
        return isProduction ? 'bdbf0876-f283-452c-8854-feebccb9cbfd' : '5e7856ec-e4e2-42d3-a227-70db1daa7eb9';
      }

      if (flowId === REGISTRATION_COHORT_TYPES.JOURNALIST_AND_MEDIA) {
        return isProduction ? '11e6da77-483a-4304-bd66-234971f96c56' : '6a336ffa-3195-4ed0-8146-6663edd5cf40';
      }

      if (flowId === REGISTRATION_COHORT_TYPES.EDUCATION_AND_ACADEMIA) {
        return isProduction ? '11a3a597-c8fb-439b-9b8b-50b328e30aa2' : 'ca394111-b57f-4dbd-9307-333d8a116712';
      }

      if (flowId === REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS) {
        return isProduction ? '0fcc1e97-41e5-44ef-82e3-c861e971aa8a' : 'ad43f0d3-11c8-4717-906a-a73461b43e1f';
      }
      break;
    case REGISTRATION_STEPS.profileMatch:
      return isProduction ? 'eb90d401-f5be-4847-acbd-77e5724c38e1' : '95a0d193-d98b-4ff9-ae7f-7d4917065042';
    case REGISTRATION_STEPS.digitalPresence:
      return isProduction ? '0c61e634-99b6-4ca1-866f-09107e0a8b34' : '961a461d-0f9f-4888-8ff1-5d965af1e1ff';
    case REGISTRATION_STEPS.addUserProduction:
      return isProduction ? '5ee878a2-ee24-4f11-82ab-9f9386d12201' : '60ba9250-3999-4313-a60a-44d9ea70dd64';
    case REGISTRATION_STEPS.employmentTypeForm:
      return isProduction ? '84e011dc-1e05-4e2c-929d-ec610558adcc' : 'db763311-fdc2-4210-9532-c2e5d5b216d9';
    case REGISTRATION_STEPS.organizationsForm:
      if (!isAddNewOrg) {
        return isProduction ? '9c3bccbb-4ff1-47e3-ad47-73cbdd29c4b5' : '82b97f25-71c0-4ee0-80aa-ff4e643a8f1b';
      }

      if (isOrg) {
        return isProduction ? '99dc6b7e-f485-433c-bde0-af9b49def364' : 'a853cdc7-df84-4b09-bbd2-5f39123129e8';
      }

      return isProduction ? '962e5138-4c91-464d-830e-4df16358798e' : '20c3ce68-37da-4b6e-a9b1-b6dd47b91537';
    case REGISTRATION_STEPS.organizationsPerformanceForm:
      return isProduction ? 'a125dca1-db7b-433f-9925-a3684327c749' : 'd4a4a595-38dc-474d-8c14-7cf7364bdfc0';
    case REGISTRATION_STEPS.organizationsWorkTypeForm:
      return isProduction ? '4462957d-9ddc-4a9b-bde3-72d85b556c58' : 'dcb9f7a4-e0b8-4666-8b3c-c870b36aadf7';
    case REGISTRATION_STEPS.orgRoleForm:
      return isProduction ? '386a1644-c6a0-4e2b-80f8-b23634fc54b6' : 'a971609d-a3dd-4e9b-b96a-a89d58f6966e';
    case REGISTRATION_STEPS.orgToolPreferenceForm:
      return isProduction ? '8a71510a-731a-406f-9d5b-bfdf55b672f5' : '542329ed-b4dc-4d83-8309-a66d473628b2';
    default:
      return null;
  }

  return null;
};
