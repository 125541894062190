import { dehydrate, QueryClient } from 'utils/react-query';
import isMobile from 'ismobilejs';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';

import {
  ENTITY_MAIN_TABS,
  RESPONSE_STATUS_CODES,
  REPERTOIRE_SECTION_TYPES,
  SORT_OPTION_VALUES,
  PAGINATION_LIMITS,
  DATE_FORMATS,
  SORT_OPTION_TYPES,
  FEATURED_ENTITY_SECTIONS,
} from 'constants/index';
import { ROUTE_RESERVED_KEYWORDS, FILTER_TYPES, ENTITY_TYPES } from 'operabase-router/constants';
import { USER_SESSION_INFO } from 'constants/cookieConstants';

import { getArtistIndexQueryParams } from 'utils/artists';
import { getEntityRecommendationApiLimit } from 'utils/globals';
import { getCookiesOnServer } from 'utils/auth';
import { createDate } from 'utils/date';
import { getActiveProfileId } from 'utils/hooks/useAuthenticatedUser';

import { getRedirectPageRoute } from 'containers/Globals/queries';
import { selectTrendingArtistProfessions } from 'containers/Artist/transformers';
import { getServerLanguage } from 'src/i18n';

import QUERIES from './queries';

const getTime = () => Date.now();

const getEntitySSRQueries = (context = {}, extras = {}) => {
  const { basePath, page } = context;
  const entityType = page?.entityType;
  const { isMobileDevice, entity } = extras;
  let queryList = [];
  const includeProQueries =
    entityType !== ENTITY_TYPES.PROFILE || (entityType === ENTITY_TYPES.PROFILE && entity?.stats?.isPro);

  if (basePath === ROUTE_RESERVED_KEYWORDS.casting) {
    switch (page?.tab) {
      case ENTITY_MAIN_TABS.CALENDAR:
        queryList = [QUERIES.PRODUCTIONS.GET_PRODUCTION_CREDITS({ limit: 10, sort: SORT_OPTION_VALUES.YEAR_ASC })];
        break;
      case ENTITY_MAIN_TABS.REPERTOIRE:
        queryList = [
          QUERIES.ARTISTS.GET_REPERTOIRE_DETAILS({
            isCastingProfile: true,
            sort: SORT_OPTION_TYPES.COMPOSERS_ASC?.value,
            limit: entity?.stats?.repertoire?.total,
          }),
        ];
        break;
      case ENTITY_MAIN_TABS.ORG_GRAPH:
        queryList = [QUERIES.ARTISTS.GET_CAREER_GRAPH];
        break;
      case ENTITY_MAIN_TABS.ROLE_GRAPH:
        queryList = [QUERIES.ARTISTS.GET_ROLE_GRAPH];
        break;
      case ENTITY_MAIN_TABS.BIO:
        queryList = [QUERIES.ARTISTS.GET_BIO];
        break;
      default:
        queryList = [QUERIES.PRODUCTIONS.GET_PRODUCTION_CREDITS({ limit: 10 })]; // NOTE: this is being triggered from EntitySeo and Org Overview
        break;
    }

    return queryList;
  }

  switch (page?.tab) {
    case ENTITY_MAIN_TABS.PERFORMANCES: {
      if (entityType === ENTITY_TYPES.PROFILE) {
        queryList = [QUERIES.PRODUCTIONS.GET_PRODUCTION_CREDITS({ limit: 10 })];
      }
      break;
    }
    case ENTITY_MAIN_TABS.VIDEOS: {
      const showreelVideosExist =
        extras?.entity?.stats?.showreels?.intro?.exists || extras?.entity?.stats?.showreels?.audition?.exists;
      const isFeaturedPhotosExist = entity?.stats?.media?.videos?.featured?.exists;
      const combinedVideosTotal =
        entity?.stats?.media?.videos?.featured?.total +
        extras?.entity?.stats?.showreels?.intro?.total +
        extras?.entity?.stats?.showreels?.audition?.total;

      queryList = [
        QUERIES.MEDIA.GET_DIGITAL_VIDEOS({
          limit: isMobileDevice ? 2 : 6,
          sort: SORT_OPTION_TYPES.YEAR_DESC.value,
        }),
        QUERIES.MEDIA.GET_FEATURED_VIDEOS({ limit: 10, infinite: true }),
        ...(entity?.stats?.media?.videos?.featured?.total < 10 ? [QUERIES.MEDIA.GET_SHOWREEL_VIDEOS] : []),
        ...((!isFeaturedPhotosExist && !showreelVideosExist) || combinedVideosTotal < 10
          ? [
              QUERIES.MEDIA.GET_PRODUCTION_VIDEOS({
                limit: 10,
              }),
            ]
          : []),
      ];
      break;
    }
    case ENTITY_MAIN_TABS.IMAGES: {
      if (!includeProQueries) {
        queryList = [];
        break;
      }

      queryList = [
        QUERIES.MEDIA.GET_FEATURED_PHOTOS,
        ...(!entity?.stats?.media?.images?.featured?.exists ? [QUERIES.MEDIA.GET_PRODUCTION_PHOTOS] : []),
      ];
      break;
    }
    case ENTITY_MAIN_TABS.REVIEWS: {
      if (!includeProQueries) {
        queryList = [];
        break;
      }

      queryList = [
        QUERIES.REVIEWS.GET_FEATURED_REVIEWS({ infinite: true, limit: 25 }),
        ...(!entity?.stats?.reviews?.featured?.exists ? [QUERIES.REVIEWS.GET_PRODUCTION_REVIEWS({ limit: 25 })] : []),
      ];

      break;
    }
    case ENTITY_MAIN_TABS.BIO: {
      if (!includeProQueries) {
        queryList = [];
        break;
      }

      queryList = [
        QUERIES.ARTISTS.GET_BIO,
        QUERIES.MEDIA.GET_FEATURED_PHOTOS,
        QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ARTISTS }),
        QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ORGANIZATIONS }),
        QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.VENUES }),
      ];
      break;
    }
    case ENTITY_MAIN_TABS.ABOUT: {
      if (entityType === ENTITY_TYPES.ORGANIZATION) {
        queryList = [QUERIES.ORGANIZATIONS.GET_TRANSLATIONS, QUERIES.ORGANIZATIONS.GET_ASSOCIATED_ORGANIZATIONS];
      }

      break;
    }
    case ENTITY_MAIN_TABS.CONTACT: {
      if (entityType === ENTITY_TYPES.PROFILE) {
        queryList = [
          QUERIES.MANAGERS.GET_AGENCIES,
          ...(includeProQueries
            ? [
                QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ARTISTS }),
                QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ORGANIZATIONS }),
                QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.VENUES }),
              ]
            : []),
        ];
      }

      break;
    }
    case ENTITY_MAIN_TABS.RENTALS: {
      queryList = [QUERIES.PRODUCTIONS.GET_RENTALS];

      break;
    }
    case ENTITY_MAIN_TABS.REPERTOIRE: {
      if (!includeProQueries) {
        queryList = [];
        break;
      }

      queryList = [
        QUERIES.ARTISTS.GET_REPERTOIRE_DETAILS({
          type: REPERTOIRE_SECTION_TYPES.FUTURE,
          limit: extras?.entity?.stats?.repertoire?.future?.total,
        }),
        QUERIES.ARTISTS.GET_REPERTOIRE_DETAILS({
          type: REPERTOIRE_SECTION_TYPES.PAST,
          limit: extras?.entity?.stats?.repertoire?.past?.total,
        }),
        QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ARTISTS }),
        QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ORGANIZATIONS }),
        QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.VENUES }),
      ];
      break;
    }
    case ENTITY_MAIN_TABS.HOME:
    default: {
      switch (entityType) {
        case ENTITY_TYPES.PROFILE: {
          const featuredMediaQueries = [
            QUERIES.MEDIA.GET_FEATURED_PHOTOS({ infinite: false, limit: 10 }),
            ...((entity?.stats?.media?.images?.featured?.total || 0) < 10
              ? [
                  QUERIES.MEDIA.GET_PRODUCTION_PHOTOS({
                    limit: 10 - entity?.stats?.media?.images?.featured?.total,
                  }),
                ]
              : []),
            ...(extras?.entity?.stats?.media?.videos?.featured?.exists
              ? [QUERIES.MEDIA.GET_FEATURED_VIDEOS({ limit: 6 })]
              : []),
            ...(extras?.entity?.stats?.media?.videos?.production?.exists &&
            extras?.entity?.stats?.media?.videos?.featured?.total < 6
              ? [
                  QUERIES.MEDIA.GET_PRODUCTION_VIDEOS({
                    limit: 6 - extras?.entity?.stats?.media?.videos?.featured?.total,
                    infinite: false,
                  }),
                ]
              : []),
          ];

          let futureRepertoireLimit;
          let pastRepertoireLimit;

          if (includeProQueries) {
            const { exists: futureExists, total: futureTotal } = entity?.stats?.repertoire?.future || {};

            if (futureExists) {
              futureRepertoireLimit = futureTotal < 10 ? futureTotal : 10;
              pastRepertoireLimit = futureTotal < 10 ? 10 - futureTotal : 0;
            } else {
              futureRepertoireLimit = 0;
              pastRepertoireLimit = 10;
            }
          }

          queryList = [
            ...(includeProQueries
              ? [
                  ...featuredMediaQueries,
                  QUERIES.ARTISTS.GET_BIO,
                  QUERIES.REVIEWS.GET_FEATURED_REVIEWS,
                  ...(!entity?.stats?.reviews?.featured?.exists
                    ? [QUERIES.REVIEWS.GET_PRODUCTION_REVIEWS({ infinite: false })]
                    : []),
                  QUERIES.ARTISTS.GET_REPERTOIRE_DETAILS({
                    type: REPERTOIRE_SECTION_TYPES.FUTURE,
                    limit: futureRepertoireLimit,
                    overview: true,
                  }),
                  QUERIES.ARTISTS.GET_REPERTOIRE_DETAILS({
                    type: REPERTOIRE_SECTION_TYPES.PAST,
                    limit: pastRepertoireLimit,
                    overview: true,
                  }),
                  QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ARTISTS }),
                  QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ORGANIZATIONS }),
                  QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.VENUES }),
                ]
              : []),
            QUERIES.MEDIA.GET_MEDIA_SUGGESTIONS,
            QUERIES.PRODUCTIONS.GET_MEDIA_PAST_PRODUCTIONS({ limit: 6, mediaLimit: 6 }),
            QUERIES.PRODUCTIONS.GET_PRODUCTION_CREDITS({
              limit: 15,
              params: { date_from: createDate().format(DATE_FORMATS.FULL_DATE) },
            }),
            QUERIES.PRODUCTIONS.GET_MEDIA_PAST_PRODUCTIONS({ limit: 1 }),
            QUERIES.MANAGERS.GET_AGENCIES({ enabled: extras?.entity?.stats?.isPro }),
          ];

          break;
        }
        case ENTITY_TYPES.ORGANIZATION: {
          queryList = [
            ...queryList,
            QUERIES.MEDIA.GET_MEDIA_SUGGESTIONS,
            QUERIES.ORGANIZATIONS.GET_TRANSLATIONS,
            QUERIES.ORGANIZATIONS.GET_ASSOCIATED_ORGANIZATIONS,
            QUERIES.MEDIA.GET_FEATURED_VIDEOS({
              limit: 6,
              enabled: extras?.entity?.stats?.media?.videos?.production?.exists,
            }),
            QUERIES.PRODUCTIONS.GET_UPCOMING_PRODUCTIONS({ limit: 3, infinite: true }),
            QUERIES.PRODUCTIONS.GET_RENTALS({ limit: 3 }),
          ];

          break;
        }
        case ENTITY_TYPES.PRODUCTION: {
          queryList = [
            QUERIES.MEDIA.GET_MEDIA_SUGGESTIONS,
            QUERIES.PRODUCTIONS.GET_RENTAL_CONTACTS,
            QUERIES.PRODUCTIONS.GET_DESCRIPTIONS,
          ];

          break;
        }
        default: {
          break;
        }
      }

      break;
    }
  }

  if (ENTITY_TYPES.ORGANIZATION === entityType) {
    // NOTE: below queries are common all tabs
    queryList.push(
      ...[
        QUERIES.GLOBAL.GET_ENTITY_RECOMMENDATIONS({
          limit: getEntityRecommendationApiLimit({
            entityType,
          }),
        }),
        QUERIES.PRODUCTIONS.GET_PRODUCTION_CREDITS({ limit: 10 }),
      ],
    );
  }

  if (ENTITY_TYPES.PROFILE === entityType) {
    // NOTE: below queries are common all tabs
    if (!entity?.stats?.isPro) {
      queryList.push(
        QUERIES.GLOBAL.GET_ENTITY_RECOMMENDATIONS({
          inline: true,
          limit: getEntityRecommendationApiLimit({
            entityType,
            inline: true,
            isLoggedIn: extras?.isLoggedIn,
          }),
        }),
      );
    } else {
      queryList.push(
        QUERIES.GLOBAL.GET_PROFILE_VIEWED({
          limit: getEntityRecommendationApiLimit({
            entityType,
          }),
        }),
      );
    }
  }

  return queryList;
};

const getIndexSSRQueries = (context = {}, extras = {}) => {
  const { basePath, filterParams, linkProps = {}, page } = context;
  const { country, allProfessionsList, isMobileDevice, selectedOrgType } = extras;
  const { professions, voiceTypes, instrumentTypes } = allProfessionsList?.data || {};
  const isProductionListing = page?.productionListing;
  const { filters = {} } = linkProps;

  if (isProductionListing) {
    return [QUERIES.PRODUCTIONS.GET_PRODUCTION_CREDITS({ limit: 10, enabled: true })];
  }

  switch (basePath) {
    case ROUTE_RESERVED_KEYWORDS.artists: {
      const artistQueries = [];
      if (filters[FILTER_TYPES.PROFESSION]?.length || filters[FILTER_TYPES.QUERY] || filters[FILTER_TYPES.LETTER]) {
        const appliedFilters = omit(
          getArtistIndexQueryParams({
            filterParams,
            filters,
            professions,
            voiceTypes,
            instrumentTypes,
            getSelectedProfession: true,
          }),
          'selectedProfession',
        );

        if (filters[FILTER_TYPES.PROFESSION]?.length || Number(appliedFilters?.page) > 0) {
          artistQueries.push(
            QUERIES.ARTISTS.GET_ARTISTS({
              filters: {
                ...filterParams,
                sort:
                  filterParams?.page > 0
                    ? SORT_OPTION_TYPES.NAME_ASC.value
                    : SORT_OPTION_VALUES.FUTURE_PRODUCTION_COUNT_DESC,
              },
              limit: 16,
            }),
          );
        }

        if (Number(appliedFilters?.page) > 0) {
          artistQueries.push(
            QUERIES.ARTISTS.GET_ARTISTS({
              filters: appliedFilters,
              limit: 30,
            }),
          );
        } else if (appliedFilters?.selectedProfession?.pro > 0) {
          artistQueries.push(
            QUERIES.ARTISTS.GET_TRENDING_ARTISTS({
              filters: appliedFilters,
              limit: 40,
            }),
          );
        } else {
          const trendingProfessions = selectTrendingArtistProfessions(allProfessionsList, 5);
          if (trendingProfessions?.length > 0) {
            trendingProfessions.forEach(item => {
              artistQueries.push(
                QUERIES.ARTISTS.GET_TRENDING_ARTISTS({
                  filters: {
                    primary_profession_id: item?.id,
                  },
                  limit: 8,
                }),
              );
            });
          }
        }
      } else if (Object.keys(filters)?.length === 0) {
        artistQueries.push(QUERIES.GLOBAL.GET_HOME_NUMBERS());
        artistQueries.push(QUERIES.GLOBAL.GET_TESTIMONIALS({ limit: 10 }));
        const trendingProfessions = selectTrendingArtistProfessions(allProfessionsList, 5);

        if (trendingProfessions?.length > 0) {
          trendingProfessions.forEach(item => {
            artistQueries.push(
              QUERIES.ARTISTS.GET_TRENDING_ARTISTS({
                filters: {
                  primary_profession_id: item?.id,
                },
                limit: 8,
              }),
            );
          });
        }

        artistQueries.push(QUERIES.GLOBAL.GET_TESTIMONIALS({ limit: 10 }));
      }

      return artistQueries;
    }
    case ROUTE_RESERVED_KEYWORDS.organisations: {
      const { orgTypes } = extras;
      let organizationQueries = [];
      const hasFiltersApplied = Object.keys(filters).length > 0;
      const showTabularList =
        hasFiltersApplied &&
        (filters?.[FILTER_TYPES.PAGE] > 0 || !!filters?.[FILTER_TYPES.LETTER] || !!filters?.[FILTER_TYPES.QUERY]);

      if (hasFiltersApplied) {
        if (showTabularList) {
          const shouldUseGlobalSearch = filters?.[FILTER_TYPES.QUERY]?.length > 0;

          if (shouldUseGlobalSearch) {
            organizationQueries.push(
              QUERIES.GLOBAL.GET_GLOBAL_SEARCH_SUGGESTIONS({ limit: PAGINATION_LIMITS.ORGANIZATION_INDEX_PAGE_LIST }),
            );
          } else {
            organizationQueries.push(
              QUERIES.ORGANIZATIONS.GET_ORGANIZATIONS({
                filters: { sort: SORT_OPTION_VALUES.NAME_ASC, ...context?.filterParams },
                limit: PAGINATION_LIMITS.ORGANIZATION_INDEX_PAGE_LIST,
              }),
            );
          }
          organizationQueries.push(QUERIES.GLOBAL.GET_TESTIMONIALS);
        } else if (selectedOrgType?.pro >= 8) {
          organizationQueries.push(QUERIES.ORGANIZATIONS.GET_TRENDING_ORGANIZATIONS);
        }
      } else {
        organizationQueries.push(QUERIES.GLOBAL.GET_HOME_NUMBERS());
        organizationQueries.push(QUERIES.GLOBAL.GET_TESTIMONIALS({ limit: 10 }));
        const trendingOrgTypes = orgTypes?.data?.filter(type => type?.total > 8);
        const trendingQueries = trendingOrgTypes?.map(type =>
          QUERIES.ORGANIZATIONS.GET_TRENDING_ORGANIZATIONS({ filters: { organization_type_id: type.id } }),
        );

        organizationQueries = [...organizationQueries, ...trendingQueries];
      }

      return organizationQueries;
    }
    case ROUTE_RESERVED_KEYWORDS.composers: {
      return [QUERIES.GLOBAL.GET_COMPOSER_LIST({ query: filters?.[FILTER_TYPES.QUERY] })];
    }
    case ROUTE_RESERVED_KEYWORDS.rentals: {
      return [
        QUERIES.PRODUCTIONS.GET_RENTALS({
          limit: 18,
          filters: {
            ...filterParams,
            published: true,
          },
        }),
      ];
    }
    case ROUTE_RESERVED_KEYWORDS.seasons: {
      if (country) {
        const years = context?.context?.years || context?.context?.year || [];
        const season_id = years?.[0]?.id || createDate().format(DATE_FORMATS.YEAR);

        const apiFilters = {
          has_productions: 'yes',
          sort: SORT_OPTION_TYPES.CITY_ASC.value,
          ...(country?.id && { country_id: country?.id }),
          ...(season_id && { season_id }),
        };

        return [QUERIES.ORGANIZATIONS.GET_ORGANIZATIONS({ filters: apiFilters, limit: 12, infinite: true })];
      }
      const years = context?.context?.years || context?.context?.year || [];
      const season_id = years?.[0]?.id || createDate().format(DATE_FORMATS.YEAR);

      return [QUERIES.PRODUCTIONS.GET_COUNTRIES({ filters: { season_id } })];
    }
    case '': {
      const artistQueries = [];
      const trendingProfessions = selectTrendingArtistProfessions(allProfessionsList, 10);

      if (trendingProfessions?.length > 0) {
        trendingProfessions.forEach(item => {
          const apiFilters = {};
          if (item.profession_id) {
            apiFilters.primary_profession_id = item.profession_id;
          }
          if (item.profession_qualification_id) {
            apiFilters.primary_profession_qualification_id = item.profession_qualification_id;
          }

          artistQueries.push(
            QUERIES.ARTISTS.GET_TRENDING_ARTISTS({
              filters: {
                primary_profession_id: item?.id,
              },
              limit: 4,
            }),
          );
        });
      }

      return [
        QUERIES.PRODUCTIONS.GET_FEATURED({ limit: isMobileDevice ? 3 : 15, infinite: true }),
        QUERIES.GLOBAL.GET_TESTIMONIALS({ limit: 10 }),
        QUERIES.GLOBAL.GET_HOME_NUMBERS,
        ...artistQueries,
      ];
    }
    default:
      return [];
  }
};

export const withPerformanceMeta = fn => (...args) => {
  const start = getTime();
  const promise = fn(...args);

  const calcElapsedTime = data => {
    const { serverTiming, ...rest } = data || {};

    return {
      ...rest,
      __performance__: {
        serverTiming,
        responseTime: getTime() - start,
        size: `${(new TextEncoder().encode(JSON.stringify({ ...data })).length / 1024).toFixed(2)}kB`,
      },
    };
  };

  return promise.then(calcElapsedTime, calcElapsedTime);
};

export const getEntityDetails = (
  { entityType, entityId, showApprovedHidden, asEdit, program_published, queryConfig },
  cookies,
  language,
) => {
  if (!entityId) {
    return {};
  }

  const { queryFn, getProps = () => {} } = QUERIES.GLOBAL.GET_ENTITY_DETAILS({
    entityType,
    entityId,
    showApprovedHidden,
    asEdit,
    program_published,
    queryConfig,
  });

  return queryFn(getProps(), cookies, language);
};

export const fetchQueryPromises = (
  queryClient,
  queryList,
  { context = {}, extraParams = {}, cookies, language, prefetch = true },
) => {
  const promises = queryList?.reduce((queries, queryInfo) => {
    let queryObj = queryInfo;

    if (typeof queryInfo === 'function') {
      queryObj = queryInfo({});
    }

    const isEnabled = queryObj?.isEnabled ? queryObj.isEnabled({ context, extras: extraParams }) : true;
    if (isEnabled) {
      const { queryFn, getProps, infinite = false } = queryObj;
      const params = getProps ? getProps({ context, extras: extraParams }) : {};
      const getData = queryFn(params, cookies, language);

      const queryProps = { queryKey: getData.queryKey, queryFn: withPerformanceMeta(getData.queryFn) };

      if (infinite) {
        if (prefetch) {
          queries.push(queryClient.prefetchInfiniteQuery(queryProps));
        } else {
          queries.push(queryClient.fetchInfiniteQuery(queryProps));
        }
      } else if (prefetch) {
        queries.push(queryClient.prefetchQuery(queryProps));
      } else {
        queries.push(queryClient.fetchQuery(queryProps));
      }
    }

    return queries;
  }, []);

  return promises || [];
};

export const fetchPrerequisiteQueries = async (queryClient, queries, { context, extraParams, cookies, language }) => {
  const { queryList, queryProcessor } = queries.reduce(
    (acc, entry) => {
      acc.queryList.push(entry?.query);
      acc.queryProcessor.push({
        key: entry?.key,
        select: entry?.select,
      });

      return acc;
    },
    { queryList: [], queryProcessor: [] },
  );

  const promises = fetchQueryPromises(queryClient, queryList, {
    context,
    extraParams,
    cookies,
    language,
    prefetch: false,
  });

  const responses = await Promise.allSettled(promises).then(res => ({
    ...res.reduce((acc, entry, index) => {
      const processor = queryProcessor[index];
      const selector = processor?.select ? processor?.select : response => response;

      return {
        ...acc,
        [processor?.key]: selector(entry?.value || null),
      };
    }, {}),
  }));

  return responses;
};

const getServerTiming = (ctx, { queries, prefetchQueryTime }) => {
  const serverTiming = ctx.res.getHeader('Server-Timing');

  const queryMetrics = queries?.reduce((acc, query, index) => {
    const data = query?.state?.data;
    // eslint-disable-next-line no-underscore-dangle
    const performanceInfo = data?.__performance__ || data?.pages?.[0]?.__performance__ || {};
    const { responseTime = 0, serverTiming: queryServerTiming } = performanceInfo;
    const queryKey = query?.queryKey?.[1]?.toLowerCase();
    const descriptionText = startCase(lowerCase(queryKey));
    let apiMetrics = [];

    if (queryServerTiming) {
      const metrics = queryServerTiming.split(',');
      apiMetrics = metrics.map((metric, idx) => {
        const [apiName, ...rest] = metric.trim().split(';');
        const result = {
          name: `2.${index + 1}.${idx + 1}-${queryKey}-${apiName}`,
          description: `API: ${descriptionText}`,
        };

        rest.forEach(part => {
          const [key, value] = part.trim().split('=');
          if (key === 'dur') {
            result.duration = parseFloat(value);
          }
        });

        return result;
      });

      acc.push(...apiMetrics);
    }

    acc.push({
      name: `2.${index + 1}.${apiMetrics.length + 1}-${queryKey}`,
      description: `API (F): ${descriptionText}`,
      duration: responseTime,
    });

    return acc;
  }, []);

  const metrics = [
    {
      name: '2-getServerProps',
      description: 'getServerSideProps',
      duration: prefetchQueryTime,
    },
    ...queryMetrics,
  ];

  const additionalServerTiming = metrics
    .map(metric => `${metric.name};desc="${metric.description}";dur=${metric.duration}`)
    .join(',');

  return `${serverTiming ? `${serverTiming},` : ''}${additionalServerTiming}`;
};

const getServerProps = async ctx => {
  const start = getTime();
  const { req } = ctx;

  const userAgent = req.headers['user-agent'];
  const isMobileDevice = isMobile(userAgent).any;
  const userSessionInfo = req?.session?.get(USER_SESSION_INFO);
  const { user = null, isLoggedIn = false } = userSessionInfo || {};
  const isTrustedBot = req.fingerprint?.isBot && req.fingerprint?.trusted;
  const isNextDataRequest = req.nextdata || false;

  const language = getServerLanguage(req);
  const cookies = getCookiesOnServer(ctx);
  const context = JSON.parse(JSON.stringify(req.context || ''));
  const { query, basePath, mainPath, page } = context || {};
  const { entityType, edit = false, pro = false } = page || {};
  const entityId = page?.entity?.id;
  const { program_published } = query || {};

  let entity = null;
  let redirect = false;
  let location = null;
  const selectedSeasonKey = null;
  let statusCode = context.status || RESPONSE_STATUS_CODES.SUCCESS;

  const queryClient = new QueryClient();

  const baseUserQueries = [];

  if (isLoggedIn && !isNextDataRequest) {
    baseUserQueries.push({
      query: QUERIES.ACCOUNTS.GET_USER_PROFILES,
      key: 'userProfiles',
      select: response => response?.data,
    });
  }

  const { userProfiles, savedActiveProfileId } = await fetchPrerequisiteQueries(queryClient, baseUserQueries, {
    context,
    extraParams: { user },
    cookies,
    language,
  });

  const activeProfileId = getActiveProfileId(userProfiles, savedActiveProfileId);

  const dependentUserQueries = [];

  if (isLoggedIn && !isNextDataRequest) {
    const userQueries = [
      QUERIES.ACCOUNTS.GET_USER_DETAILS,
      QUERIES.ACCOUNTS.GET_USER_PERMISSIONS,
      QUERIES.ACCOUNTS.GET_USER_SUBSCRIPTIONS,
      QUERIES.ACCOUNTS.GET_ACTIVE_PROFILE_SUBSCRIPTIONS,
    ];

    dependentUserQueries.push(...userQueries);
  }

  if ((!edit && entityType && entityId) || entityType === ENTITY_TYPES.AGENCY) {
    const showApprovedHidden = false; // TODO: FB-000000 Solve for SSR user permissions

    const { queryKey, queryFn } = getEntityDetails(
      { entityType, entityId, showApprovedHidden, asEdit: edit, program_published },
      cookies,
      language,
    );

    entity = queryKey && (await queryClient.fetchQuery(queryKey, withPerformanceMeta(queryFn)));
  }

  const prerequisiteQueries = [
    {
      query: QUERIES.GLOBAL.GET_HOME_NUMBERS,
      key: 'homeNumbers',
    },
  ];

  if ((entityType === ENTITY_TYPES.PROFILE && !entityId) || (!mainPath && !basePath)) {
    prerequisiteQueries.push({
      query: QUERIES.ARTISTS.GET_ALL_PROFESSIONS_LIST,
      key: 'allProfessionsList',
    });
  } else if (entityType === ENTITY_TYPES.ORGANIZATION && !entityId) {
    prerequisiteQueries.push({
      query: QUERIES.ORGANIZATIONS.GET_ORGANIZATION_TYPES,
      key: 'orgTypes',
    });
  }

  const { upcomingSeasons, pastSeasons, allProfessionsList, activeProfile, orgTypes } = await fetchPrerequisiteQueries(
    queryClient,
    prerequisiteQueries,
    {
      context,
      extraParams: { entity, activeProfileId },
      cookies,
      language,
    },
  );

  if (statusCode === RESPONSE_STATUS_CODES.SUCCESS) {
    if (entityType && entityId && entity?.id !== entityId) {
      const newRoute =
        entityId &&
        entityType &&
        (await getRedirectPageRoute({ entityType, entityId, url: req?.url, language }, cookies, language));

      if (newRoute) {
        redirect = true;
        statusCode = RESPONSE_STATUS_CODES.PERMANENT_REDIRECT;
        location = newRoute;

        if (pro && entityType === ENTITY_TYPES.PROFILE) {
          location = `${ROUTE_RESERVED_KEYWORDS.casting}${location}`;
        }
      }
    } else {
      const extraParams = {
        user,
        entity,
        upcomingSeasons,
        pastSeasons,
        isMobileDevice,
        isTrustedBot,
        isLoggedIn,
        selectedSeasonKey,
        allProfessionsList,
        userProfiles,
        savedActiveProfileId,
        activeProfileId,
        activeProfile,
        orgTypes,
      };
      const pageQueryList =
        entityType && entityId ? getEntitySSRQueries(context, extraParams) : getIndexSSRQueries(context, extraParams);
      const queryList = [...dependentUserQueries, ...pageQueryList];

      if (queryList?.length > 0) {
        const promises = fetchQueryPromises(queryClient, queryList, {
          context,
          extraParams,
          cookies,
          language,
          prefetch: true,
        });

        await Promise.allSettled(promises);
      }
    }
  }

  const dehydratedState = JSON.parse(JSON.stringify(dehydrate(queryClient))); // NOTE: Its a fix for variation for data structure support from API

  let redirectProps = null;

  if (redirect) {
    redirectProps = {
      destination: location,
      permanent: statusCode === RESPONSE_STATUS_CODES.PERMANENT_REDIRECT,
      statusCode,
    };
  }

  const prefetchQueryTime = getTime() - start;

  const serverTiming = getServerTiming(ctx, { queries: dehydratedState?.queries, prefetchQueryTime });
  ctx.res.setHeader('Server-Timing', serverTiming);

  return {
    notFound: statusCode === RESPONSE_STATUS_CODES.NOT_FOUND,
    context: {
      entity,
      ...context,
    },
    ...(redirect &&
      !req.nextdata && {
        redirect: redirectProps,
      }),
    props: {
      statusCode,
      redirect: redirectProps,
      prefetchQueryTime,
      userAgent,
      dehydratedState,
    },
  };
};

export default getServerProps;
