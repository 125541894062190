import React, { useState, useReducer, createContext, useContext, useMemo, useCallback } from 'react';

import CustomizeModelParams from 'components/Globals/CustomizeModelParams';

import { setCookie, getCookie, removeCookie } from 'utils/cookie';
import { OB_CUSTOM_ML_PARAMS } from 'constants/cookieConstants';

import usePageContext from './usePageContext';

const CustomModelParamsContext = createContext({});

const ML_APIS = {
  SIMILAR_ARTISTS: 'SIMILAR_ARTISTS',
  ML_CASTING: 'ML_CASTING',
};

const ML_API_NAMES = {
  [ML_APIS.SIMILAR_ARTISTS]: 'Similar Artists',
  [ML_APIS.ML_CASTING]: 'ML Casting',
};

const INITIAL_MODEL_STATE = {
  [ML_APIS.SIMILAR_ARTISTS]: {
    enabled: false,
    params: {
      similarity: null,
      roles_weight: null,
    },
  },
  [ML_APIS.ML_CASTING]: {
    enabled: false,
    params: {
      confidence: null,
    },
  },
};

const getModelParamsFromCookie = state => {
  const savedConfiguration = getCookie(OB_CUSTOM_ML_PARAMS);

  if (savedConfiguration) {
    try {
      const parsedConfiguration = JSON.parse(savedConfiguration);

      return Object.keys(INITIAL_MODEL_STATE).reduce((acc, key) => {
        acc[key] = {
          ...state[key],
          ...parsedConfiguration[key],
          params: {
            ...state[key].params,
            ...parsedConfiguration[key]?.params,
          },
        };
        return acc;
      }, {});
    } catch (e) {
      removeCookie(OB_CUSTOM_ML_PARAMS);

      return state;
    }
  }

  return state;
};

const ACTIONS = {
  RESET: 'RESET',
  UPDATE: 'UPDATE',
  CHANGE_ENABLE: 'CHANGE_ENABLE',
};

const reducer = (state, action) => {
  let updatedState = state;

  switch (action.type) {
    case ACTIONS.RESET: {
      updatedState = INITIAL_MODEL_STATE;
      break;
    }
    case ACTIONS.UPDATE:
    case ACTIONS.CHANGE_ENABLE: {
      const { identifier, params, enabled } = action.payload;

      updatedState = {
        ...state,
        [identifier]: {
          ...state[identifier],
          ...(typeof params !== 'undefined' && { params }),
          ...(typeof enabled !== 'undefined' && { enabled }),
        },
      };
      break;
    }
    default: {
      updatedState = state;
    }
  }

  if (action.type === ACTIONS.RESET) {
    removeCookie(OB_CUSTOM_ML_PARAMS);
  } else {
    setCookie(OB_CUSTOM_ML_PARAMS, JSON.stringify(updatedState));
  }

  return updatedState;
};

export const CustomModelParamsContextProvider = ({ children }) => {
  const [showCustomizationModal, setShowCustomizationModal] = useState(false);
  const [state, dispatch] = useReducer(reducer, INITIAL_MODEL_STATE, getModelParamsFromCookie);

  const dispatcher = (type, payload) => dispatch({ type, payload });

  const { permissions } = usePageContext();
  const isCustomizationAllowed = useMemo(() => permissions?.isAdmin, [permissions?.isAdmin]);

  const isEnabledForAPI = useCallback(
    identifier => {
      if (isCustomizationAllowed) {
        return state?.[identifier]?.enabled || false;
      }

      return false;
    },
    [state, isCustomizationAllowed],
  );

  const setParamsForAPI = useCallback((identifier, params) => dispatcher(ACTIONS.UPDATE, { identifier, params }), []);
  const changeEnableForAPI = useCallback(
    (identifier, enabled) => dispatcher(ACTIONS.CHANGE_ENABLE, { identifier, enabled }),
    [],
  );

  const getDetailsForAPI = useCallback(
    identifier => ({
      isEnabled: () => isEnabledForAPI(identifier),
      name: () => ML_API_NAMES[identifier],
      variables: () => Object.keys(INITIAL_MODEL_STATE?.[identifier]?.params),
      get: () => state?.[identifier]?.params || null,
      set: params => setParamsForAPI(identifier, params),
      enable: () => changeEnableForAPI(identifier, true),
      disable: () => changeEnableForAPI(identifier, false),
    }),
    [changeEnableForAPI, isEnabledForAPI, setParamsForAPI, state],
  );

  const customModelParams = useMemo(
    () => ({
      useCustomParams: identifier => {
        if (identifier) {
          return getDetailsForAPI(identifier);
        }

        return {
          isCustomizationAllowed,
          openCustomizationModal: () => {
            if (isCustomizationAllowed) {
              setShowCustomizationModal(true);
            }
          },
        };
      },
    }),
    [isCustomizationAllowed, getDetailsForAPI],
  );

  return (
    <CustomModelParamsContext.Provider value={customModelParams}>
      <>
        {children}
        <CustomizeModelParams
          isOpen={showCustomizationModal}
          onClose={() => setShowCustomizationModal(false)}
          apiIdentifiers={Object.keys(INITIAL_MODEL_STATE)}
          getDetailsForAPI={getDetailsForAPI}
          onResetState={() => {
            dispatcher(ACTIONS.RESET);
            setShowCustomizationModal(false);
          }}
        />
      </>
    </CustomModelParamsContext.Provider>
  );
};

const useCustomModelParams = identifier => {
  const { useCustomParams } = useContext(CustomModelParamsContext);

  return useCustomParams(identifier);
};

export { ML_APIS };
export default useCustomModelParams;
