const debugSSR = ssrProps => {
  const { dehydratedState } = ssrProps || {};
  const queries = dehydratedState?.queries;
  const totalQueries = queries?.length;
  let totalTime = 0;
  let totalPayload = 0;
  let slowestQuery = null;
  let heaviestQuery = null;

  const langPath = window?.location?.pathname?.match(/(?:\/\w{2})+$/g)?.[0];
  const langPathParts = langPath?.split('/').filter(Boolean);
  const language = langPathParts?.find(path => path.length === 2) || 'en';

  const apis = queries?.map(query => {
    const data = query?.state?.data;
    // eslint-disable-next-line no-underscore-dangle
    const { responseTime = 0, size = 0 } = data?.__performance__ || data?.pages?.[0]?.__performance__ || {};
    const payloadSize = Number.parseFloat(size);

    const response = {
      language: query?.queryKey?.[0],
      isInvalidLanguage: language !== query?.queryKey?.[0],
      key: query?.queryKey,
      data,
      time: responseTime,
      size: payloadSize,
    };

    if (responseTime > 0) {
      if (responseTime > (slowestQuery?.time || 0)) {
        slowestQuery = response;
      }

      totalTime += responseTime;
    }

    if (payloadSize > 0) {
      if (payloadSize > (heaviestQuery?.size || 0)) {
        heaviestQuery = response;
      }

      totalPayload += payloadSize;
    }

    return response;
  });

  const logger = console;

  const logColoredValue = (label, value, unit, limit) => {
    logger.log(
      `${label} %c${value || ''}${unit}`,
      value > limit ? 'color: red' : 'color: green',
      `(Target: ${limit}${unit})`,
    );
  };

  const logQuery = api => {
    logger.groupCollapsed(`%c${api?.key?.[1]}`, 'color: blue');
    logger.log(`Language: %c${api?.language}`, api?.isInvalidLanguage ? 'color: red' : 'color: green');
    logColoredValue('Time:', api?.time, 'ms', 500);
    logColoredValue('Size:', api?.size, 'kB', 50);
    logger.log('Query Key:', api?.key);
    logger.log('Data:', api?.data);
    logger.groupEnd();
  };

  logColoredValue('SSR Time:', totalTime, 'ms', 500);
  logColoredValue('SSR Payload:', totalPayload, 'kB', 50);
  logger.groupCollapsed(`SSR Queries: %c${totalQueries}`, 'color: blue');
  apis.forEach(api => logQuery(api));
  logger.groupEnd();

  if (slowestQuery) {
    logger.groupCollapsed(`Slowest Query %c${slowestQuery?.key?.[1]}`, 'color: blue');
    logColoredValue('Time:', slowestQuery?.time, 'ms', 500);
    logColoredValue('Size:', slowestQuery?.size, 'kB', 50);
    logQuery(slowestQuery);
    logger.groupEnd();
  }

  if (heaviestQuery) {
    logger.groupCollapsed(`Heaviest Query %c${heaviestQuery?.key?.[1]}`, 'color: blue');
    logColoredValue('Time:', heaviestQuery?.time, 'ms', 500);
    logColoredValue('Size:', heaviestQuery?.size, 'kB', 50);
    logQuery(heaviestQuery);
    logger.groupEnd();
  }

  return apis;
};

export default debugSSR;
