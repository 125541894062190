/* eslint-disable no-dupe-class-members */
const RoutePathContext = require('./RoutePathContext');
const ServerTiming = require('./ServerTiming');
const MatchingEntityFinder = require('./MatchingEntityFinder');
const LinkGenerator = require('./LinkGenerator');
const FilterParamsTransformer = require('./FilterParamsTransformer');
const LinkPropsExtractor = require('./LinkPropsExtractor');
const BreadcrumbsResolver = require('./BreadcrumbsResolver');
const H1TitleResolver = require('./H1TitleResolver');

const { createServerTimingHeader } = require('../../server/utils/serverTimingHeader');

const {
  ENTITY_TYPES,
  FILTER_TYPES,
  ROUTE_RESERVED_KEYWORDS,
  ORG_TYPES,
  REGULAR_EXPRESSIONS,
  OPERATORS,
  ENTITY_TYPE_ID_PREFIX,
  ROUTE_PART_TYPE,
  ROUTE_VALIDITY,
  DEFAULT_LANGUAGE,
  FILTER_TYPE_ENTITY_TYPE_MAP,
  RESPONSE_STATUS_CODES,
} = require('../constants');
const { ROUTE_CONFIGURATIONS } = require('../constants/configurations');

const { transformDatePath } = require('../utils');
const PageDetailsResolver = require('./PageDetailsResolver');

const TIMERS = {
  ENTITY_MATCH: 'entity-match', // NOTE: 1.1
  EXTRACT_LINK_PROPS: '1.2-extract-link-props',
  WITH_ROUTE_VALIDITY: '1.3-enrich-state-with-route-validity',
  WITH_FILTER_PARAMS: '1.4-enrich-state-with-filter-params',
  PAGE_STATS: 'page-stats', // NOTE: 1.5
  WITH_STATUS_CODE: '1.6-enrich-state-with-status-code',
  WITH_BREADCRUMBS: '1.7-enrich-state-with-breadcrumbs',
  WITH_H1_TITLE: '1.8-enrich-state-with-h1-title',
};

class RouteContext {
  #language = DEFAULT_LANGUAGE;

  #originalUrl = '';

  #path = '';

  #query = {};

  #previousEntityMap = {};

  #previousPageInfo = null;

  #previousFilterParams = {};

  #entityFinder = null;

  #translationFn = value => value;

  #session = null;

  #statsRetriever = null;

  #nextdata = false;

  constructor({
    originalUrl = '',
    path = '',
    query = {},
    previousEntityMap = {},
    previousPageInfo = null,
    previousFilterParams = null,
    language,
    entityFinder,
    statsRetriever,
    session,
    nextdata,
    translationFn,
  }) {
    this.#originalUrl = originalUrl;
    this.#path = path;
    const { lng: _lng, '[...action': _action, subpath: _subpath, context: _context, debug: _debug, ...restQuery } =
      query || {};
    this.#query = restQuery;
    this.#previousEntityMap = previousEntityMap;

    if (language) {
      this.#language = language;
    }

    if (entityFinder) {
      this.#entityFinder = entityFinder;
    }

    if (translationFn) {
      this.#translationFn = translationFn;
    }

    if (session) {
      this.#session = session;
    }

    if (statsRetriever) {
      this.#statsRetriever = statsRetriever;
    }

    if (previousPageInfo) {
      this.#previousPageInfo = previousPageInfo;
    }

    if (previousFilterParams) {
      this.#previousFilterParams = previousFilterParams;
    }

    if (nextdata) {
      this.#nextdata = nextdata;
    }
  }

  #getTimers() {
    const timer = new ServerTiming(
      [
        {
          name: TIMERS.EXTRACT_LINK_PROPS,
          description: 'Extract link props',
        },
        {
          name: TIMERS.WITH_ROUTE_VALIDITY,
          description: 'Check route validity',
        },
        {
          name: TIMERS.WITH_FILTER_PARAMS,
          description: 'Transform filter params',
        },
        {
          name: TIMERS.WITH_STATUS_CODE,
          description: 'Assign status code',
        },
        {
          name: TIMERS.WITH_BREADCRUMBS,
          description: 'Generate breadcrumbs',
        },
        {
          name: TIMERS.WITH_H1_TITLE,
          description: 'Generate H1 title',
        },
      ],
      this,
    );

    return timer;
  }

  #enrichStateWithRouteValidity(state) {
    const { language, url: originalPath, tokenized, paths, linkProps = {}, ...rest } = state || {};

    let valid = paths.every(part => part.validity !== ROUTE_VALIDITY.INVALID);

    let redirect = null;

    if (valid) {
      const linkGenerator = new LinkGenerator({ language });
      const { as: finalPath } = linkGenerator.getLinkProps({ ...linkProps, onlyLinkProps: true, skipAutoTab: true });
      const originalPathname = originalPath.split('?')[0];
      const finalPathname = finalPath.split('?')[0];
      const shouldRedirect = originalPathname !== finalPathname;

      if (shouldRedirect) {
        valid = false;
        redirect = finalPath;
      }
    }

    return {
      url: originalPath,
      tokenized,
      language,
      ...(redirect && { redirect }),
      valid,
      paths,
      linkProps,
      ...rest,
    };
  }

  async #enrichStateWithPageEntity(state) {
    const { valid, paths, redirect } = state || {};

    if (!valid || redirect?.location || !paths?.length) {
      return [state, []];
    }

    const pageDetailsResolver = new PageDetailsResolver({
      statsRetriever: this.#statsRetriever,
      session: this.#session,
      previousPageInfo: this.#previousPageInfo,
      previousFilterParams: this.#previousFilterParams,
    });
    const page = await pageDetailsResolver.getDetails(state);
    const timing = pageDetailsResolver.getServerTiming({
      processOrder: 1.5,
      processName: TIMERS.PAGE_STATS,
    });

    return [
      {
        ...state,
        page,
      },
      timing,
    ];
  }

  #enrichStateWithFilterParams(state) {
    const { valid, linkProps, entityMap, page } = state || {};

    if (!valid) {
      return state;
    }

    const { filters } = linkProps || {};

    const filterParamsTransformer = new FilterParamsTransformer();
    const filterParams = filterParamsTransformer.transformFilters({ filters, entityMap, page });

    return {
      ...state,
      filterParams,
    };
  }

  #enrichStateWithLinkProps(state) {
    const linkPropsExtractor = new LinkPropsExtractor();
    const { paths, basePath, mainPath, subPath, linkProps } = linkPropsExtractor.getProps(state);

    return {
      ...state,
      paths,
      basePath,
      mainPath,
      subPath,
      linkProps,
    };
  }

  #enrichStateWithStatusCode(state) {
    const { valid, redirect } = state || {};

    let status = RESPONSE_STATUS_CODES.SUCCESS;

    if (!valid) {
      status = redirect ? RESPONSE_STATUS_CODES.PERMANENT_REDIRECT : RESPONSE_STATUS_CODES.NOT_FOUND;
    }

    return {
      status,
      ...state,
    };
  }

  #enrichStateWithBreadcrumbs(state) {
    const { language, valid } = state || {};
    if (!valid) {
      return state;
    }

    const breadcrumbsResolver = new BreadcrumbsResolver({
      language,
      translationFn: this.#translationFn,
    });
    const breadcrumbs = breadcrumbsResolver.resolve(state);

    return {
      ...state,
      breadcrumbs,
    };
  }

  #enrichStateWithH1Title(state) {
    const { valid } = state || {};

    if (!valid) {
      return state;
    }

    const h1TitleResolver = new H1TitleResolver({ translationFn: this.#translationFn });
    const h1Title = h1TitleResolver.getH1TitleParts(state);

    return {
      ...state,
      h1Title,
    };
  }

  #getURLPartToken({ data }) {
    const { entityType, filterType } = data?.context || {};
    const isValid = data.validity === ROUTE_VALIDITY.VALID;
    let urlPartToken = '';

    if (data.type === ROUTE_PART_TYPE.VARIABLE) {
      let token = '';

      if (isValid) {
        token = entityType;

        if (data?.context?.isVenue) {
          token += `|${ORG_TYPES.VENUE}`;
        }
      } else {
        token = ROUTE_PART_TYPE.INVALID;

        if (data.reason) {
          token += `|${data.reason}`;
        }
      }

      if (isValid && filterType && token !== filterType) {
        token += `|${filterType}`;
      }

      urlPartToken = `[${token}]`;
    } else if (isValid) {
      urlPartToken = data.path;
    } else {
      urlPartToken = ROUTE_PART_TYPE.INVALID;

      if (data.reason) {
        urlPartToken += `|${data.reason}`;
      }
    }

    return urlPartToken;
  }

  #getPathParts() {
    const pathParts = this.#path.split('/') || [];

    return pathParts.filter(Boolean);
  }

  #getIdsFromQuery() {
    const query = this.#query || {};
    const ids = Object.keys(query).reduce((acc, key) => {
      if ([FILTER_TYPES.PERFORMANCE_HIGHLIGHT, FILTER_TYPES.BOOLEAN_SEARCH, FILTER_TYPES.SINCE_YEAR].includes(key)) {
        return acc;
      }
      const values =
        query[key]?.split(
          [
            FILTER_TYPES.CONDUCTOR,
            FILTER_TYPES.COMPOSER,
            FILTER_TYPES.CHOREOGRAPHER,
            FILTER_TYPES.DIRECTOR,
            FILTER_TYPES.CO_PRODUCER,
            FILTER_TYPES.ROLE,
            FILTER_TYPES.PRODUCER,
            FILTER_TYPES.ENSEMBLE,
            FILTER_TYPES.VENUE,
          ].includes(key)
            ? OPERATORS.SEPARATOR
            : REGULAR_EXPRESSIONS.BOOLEAN_ID_SPLITTER,
        ) || [];

      if (values.length) {
        if ([FILTER_TYPES.GENRE, FILTER_TYPES.WORK_TYPE, FILTER_TYPES.COUNTRY].includes(key)) {
          const optionIdsList = values.reduce((idList, value) => {
            const [parentId, childId] = value.split(OPERATORS.CONCAT);

            if (parentId) {
              const parentEntityType = key === FILTER_TYPES.COUNTRY ? ENTITY_TYPES.COUNTRY : ENTITY_TYPES.WORK_TYPE;
              const prefix = ENTITY_TYPE_ID_PREFIX[parentEntityType];
              idList.push(`${prefix}${parentId}`);
            }

            if (childId) {
              const childEntityType = key === FILTER_TYPES.COUNTRY ? ENTITY_TYPES.CITY : ENTITY_TYPES.STAGING_TYPE;
              const prefix = ENTITY_TYPE_ID_PREFIX[childEntityType];
              idList.push(`${prefix}${childId}`);
            }

            return idList;
          }, []);

          acc.push(...optionIdsList);
        } else if (key === FILTER_TYPES.WHAT) {
          const workList = values.map(value => {
            const prefix = ENTITY_TYPE_ID_PREFIX[ENTITY_TYPES.WORK];
            return `${prefix}${value}`;
          });

          acc.push(...workList);
        } else if (
          [FILTER_TYPES.LANGUAGE, FILTER_TYPES.WORK, FILTER_TYPES.PROFESSION, FILTER_TYPES.SURTITLE].includes(key)
        ) {
          const entityType = FILTER_TYPE_ENTITY_TYPE_MAP[key];
          const list = values.map(value => {
            const prefix = ENTITY_TYPE_ID_PREFIX[entityType];
            return `${prefix}${value}`;
          });
          acc.push(...list);
        } else if (
          [
            FILTER_TYPES.CONDUCTOR,
            FILTER_TYPES.COMPOSER,
            FILTER_TYPES.CHOREOGRAPHER,
            FILTER_TYPES.DIRECTOR,
            FILTER_TYPES.CO_PRODUCER,
            FILTER_TYPES.VENUE,
          ].includes(key)
        ) {
          acc.push(...[...values.flatMap(str => str.split(REGULAR_EXPRESSIONS.BOOLEAN_ID_SPLITTER))]);
          if (![FILTER_TYPES.CO_PRODUCER].includes(key)) {
            acc.push(key);
          }
        } else if ([FILTER_TYPES.ROLE, FILTER_TYPES.PRODUCER, FILTER_TYPES.ENSEMBLE].includes(key)) {
          const roleIds = values?.reduce((accumulator, value) => {
            const [profession, entityIds] = value.split(OPERATORS.TYPE_SEPARATOR);
            if (!profession?.startsWith(OPERATORS.FILTER_TYPE_NEGATED)) {
              accumulator.push(
                ...[
                  ...(entityIds
                    ?.split(REGULAR_EXPRESSIONS.BOOLEAN_ID_SPLITTER)
                    ?.map(s => s.replace(OPERATORS.FILTER_TYPE_NEGATED, '')) || []),
                  profession,
                ],
              );
            }
            return accumulator;
          }, []);
          acc.push(...roleIds);
        } else {
          const filteredValues = values.filter(value => /^[a-z]\d+$/.test(value));

          acc.push(...filteredValues);
        }
      }

      return acc;
    }, []);

    return ids;
  }

  async context() {
    const timer = this.#getTimers();

    const pathParts = this.#getPathParts();
    const lastIndex = pathParts.length - 1;
    const urlWithSeasonFilter = [ROUTE_RESERVED_KEYWORDS.seasons].includes(pathParts[0]);

    const { parts, slugs } = pathParts.reduce(
      (acc, part, index) => {
        const [path, filterTypePath] = part.split(OPERATORS.FILTER_TYPE_OPERATOR).reverse();
        let configuration = ROUTE_CONFIGURATIONS[path] || null;
        /* NOTE: 
          We are checking configuration here to determine type of path
          because some reserved keywords are not allowed in the URL path.
          Example "january" is a reserved keyword, but it usage is as of variable path
          where "january" can only appear as subset of a variable path of url.
        */
        const type = configuration ? ROUTE_PART_TYPE.RESERVED : ROUTE_PART_TYPE.VARIABLE;
        let filterType = filterTypePath || null;

        if (
          !filterType &&
          type === ROUTE_PART_TYPE.VARIABLE &&
          (lastIndex === index ||
            !!ROUTE_CONFIGURATIONS[pathParts[index + 1]] ||
            (index > 0 && urlWithSeasonFilter && REGULAR_EXPRESSIONS.YEAR_MATCH.test(path)))
        ) {
          const { valid: isValidDate, start, end } = transformDatePath(path);

          if (isValidDate) {
            filterType = urlWithSeasonFilter ? FILTER_TYPES.SEASON : FILTER_TYPES.DATE;
            configuration = {
              entityType: urlWithSeasonFilter ? ENTITY_TYPES.SEASON : ENTITY_TYPES.DATE,
              start,
              end,
            };
          }
        }

        acc.parts.push({
          path,
          filterType,
          type,
          configuration,
        });

        if (type === ROUTE_PART_TYPE.VARIABLE && ![FILTER_TYPES.DATE, FILTER_TYPES.SEASON].includes(filterType)) {
          acc.slugs.push(path);
        }
        if (
          [FILTER_TYPES.CONDUCTOR, FILTER_TYPES.CHOREOGRAPHER, FILTER_TYPES.DIRECTOR, FILTER_TYPES.COMPOSER].includes(
            filterType,
          )
        ) {
          acc.slugs.push(filterType);
        }
        return acc;
      },
      {
        parts: [],
        slugs: [],
      },
    );

    const idsFromQuery = this.#getIdsFromQuery();

    const entityFinder = new MatchingEntityFinder({
      parts,
      previousEntityMap: this.#previousEntityMap,
      entityFinder: this.#entityFinder,
      session: this.#session,
      language: this.#language,
    });

    let entityMap = {};

    const baseState = {
      url: this.#originalUrl,
      query: this.#query,
      language: this.#language,
      tokenized: '',
      paths: [],
      page: {},
      nextdata: this.#nextdata,
    };

    try {
      entityMap = await entityFinder.fetchEntities({ slugs: [...slugs, ...idsFromQuery] });
    } catch (err) {
      if (err.code === 'ECONNABORTED') {
        const timing = timer.timing();

        return [
          {
            ...baseState,
            status: RESPONSE_STATUS_CODES.INTERNAL_SERVER_ERROR,
          },
          createServerTimingHeader(timing),
        ];
      }
    }

    const state = parts.reduce(
      (acc, part, index) => {
        const parentPathState = acc.paths[index - 1];
        const parentEntityType = parentPathState?.context?.entityType;
        const matchedEntity = entityFinder.getEntityForPartIndex(index);

        const routePath = new RoutePathContext({
          index,
          parts,
          matchedEntity,
          parentEntityType,
          baseEntityType: acc.paths[0]?.context?.entityType,
          isParentPathValid: parentPathState?.validity === ROUTE_VALIDITY.VALID,
        });

        const data = routePath.context();
        acc.paths.push(data);

        acc.tokenized += `/${this.#getURLPartToken({ data })}`;

        return acc;
      },
      {
        ...baseState,
        entityMap,
      },
    );

    const withLinkProps = timer.wrap(this.#enrichStateWithLinkProps, TIMERS.EXTRACT_LINK_PROPS)(state);

    const withRouteValidity = timer.wrap(this.#enrichStateWithRouteValidity, TIMERS.WITH_ROUTE_VALIDITY)(withLinkProps);

    const [withPageEntity, pageEntityFetchTiming] = await this.#enrichStateWithPageEntity(withRouteValidity);

    const withFilterParams = timer.wrap(this.#enrichStateWithFilterParams, TIMERS.WITH_FILTER_PARAMS)(withPageEntity);

    const withStatusCode = timer.wrap(this.#enrichStateWithStatusCode, TIMERS.WITH_STATUS_CODE)(withFilterParams);

    const withBreadcrumbs = timer.wrap(this.#enrichStateWithBreadcrumbs, TIMERS.WITH_BREADCRUMBS)(withStatusCode);

    const finalState = timer.wrap(this.#enrichStateWithH1Title, TIMERS.WITH_H1_TITLE)(withBreadcrumbs);

    const timing = timer.timing();

    const matchEntityFinderTiming = entityFinder.getServerTiming({
      processOrder: 1.1,
      processName: TIMERS.ENTITY_MATCH,
    });

    const serverTiming = createServerTimingHeader([...timing, ...matchEntityFinderTiming, ...pageEntityFetchTiming]);

    return [finalState, serverTiming];
  }
}

module.exports = RouteContext;
