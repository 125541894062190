import React, { useState, useMemo, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';

import { transformProductionAggregationResponse } from 'containers/Globals/transformers';
import queries from 'containers/Productions/queries';

import Loader from 'components/uiLibrary/Loader';
import NoResult from 'components/Globals/NoResult';
import CommonTrans from 'components/Globals/CommonTrans';
import LinkButton, { PrimaryButton, TertiaryButton } from 'components/uiLibrary/LinkButton';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Checkbox from 'components/uiLibrary/Inputs/Checkbox';
import Typography from 'components/uiLibrary/Typography';
import SearchInput from 'components/uiLibrary/FormInputs/SearchInput';
import EntityName from 'components/Globals/EntityName';
import { Radio } from 'components/uiLibrary/RadioGroup';
import useTracking from 'components/Globals/Analytics';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import { CATEGORIES, GOOGLE_OLD_TRACKING_SERVICES, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import {
  TP,
  URL_SLUG_TYPES,
  SORT_OPTION_VALUES,
  CONTRIBUTORS,
  CONTRIBUTION_EXP_TYPES,
  CASTING_TOOL_SLUGS,
} from 'constants/index';
import { FILTER_AGGREGATION_MAP, FILTER_SLUG_TYPE, SUB_FILTER_TYPES } from 'constants/filters';
import { FILTER_TYPES, ENTITY_TYPES, AGGREGATION_TYPES, OPERATORS, FILTER_LABELS } from 'operabase-router/constants';

import usePageContext from 'utils/hooks/usePageContext';
import { trackSearchFilter, trackSearch } from 'utils/tracking';
import { useTranslation } from 'src/i18n';
import { useQuery } from 'utils/react-query';
import { determineEntityTypeFromAggregation, getEntityIdWithPrefix } from 'utils/globals';

import { getAppliedQuickViewFilters, getValueByPath, getWorkRolesFromFilterExpression } from 'utils/globals/filters';
import { getBooleanSearchQuery } from 'utils/casting';
import useAppContext from 'utils/hooks/useAppContext';

import classes from './FilterOptions.module.scss';

const CLEAR_EXCLUDED_FILTERS = [FILTER_TYPES.BOOLEAN_SEARCH, FILTER_TYPES.SINCE_YEAR, FILTER_TYPES.COUNTRY];

const DEFAULT_TAB = {
  ALL: {
    id: 'all',
    name: 'All',
    total: 0,
  },
};

// NOTE: Move in future need to check with @sarthak.
const PLACEHOLDERS = {
  [FILTER_TYPES.SEASON]: {
    MULTIPLE: `${TP}.m_SEASON`,
  },
  [FILTER_TYPES.SURTITLE]: {
    MULTIPLE: `${TP}.m_SUBTITLES`,
  },
  [FILTER_TYPES.CAST_CREW]: {
    MULTIPLE: `${TP}.FN_CAST_AND_CREW`,
  },
  [FILTER_TYPES.LANGUAGE]: {
    MULTIPLE: `${TP}.m_LANGUAGES`,
  },
  [FILTER_TYPES.VENUE]: {
    MULTIPLE: `${TP}.m_VENUES`,
  },
  [FILTER_TYPES.CITY]: {
    MULTIPLE: `${TP}.FN_CITIES`,
  },
  [FILTER_TYPES.WORK]: {
    MULTIPLE: `${TP}.FN_MUSICAL_WORKS_SECTION_NAME`,
  },
  [FILTER_TYPES.CREATOR]: {
    MULTIPLE: `${TP}.m_COMPOSERS`,
  },
  [FILTER_TYPES.WORK_TYPE]: {
    MULTIPLE: `${TP}.FN_GENRE_STAGING`,
  },
};

const getFilteredOptions = ({ list, targetId, exactId }) =>
  list.reduce(
    (acc, selectedOption) => {
      const { id } = selectedOption || {};

      if (id === targetId) {
        acc.isOptionExist = true;
      } else {
        acc.filteredList.push(selectedOption);

        if (id?.startsWith(OPERATORS.FILTER_TYPE_NEGATED)) {
          acc.isOptionNegated = id === targetId;
          acc.isAnyOptionNegated = true;
        }

        if (id === exactId) {
          acc.isExactOptionExist = true;
        }
      }

      return acc;
    },
    {
      filteredList: [],
      isOptionExist: false,
      isAnyOptionNegated: false,
      isOptionNegated: false,
      isExactOptionExist: false,
    },
  );

const getCustomFilters = ({ subFilterType, activeGroup }) => {
  if (activeGroup?.id === DEFAULT_TAB.ALL.id || subFilterType?.id !== SUB_FILTER_TYPES.PRODUCER) {
    return {};
  }
  // NOTE later in case of other filters we can use Switch case
  return {
    [AGGREGATION_TYPES[FILTER_TYPES.PRODUCER_TYPE]]: activeGroup?.id,
  };
};

const getFilterConfigMap = ({ selectedFilterType, subFilterType, filterParams, entityId, entityType }) => {
  let filterTypeConfig = {};
  if ([FILTER_TYPES.TICKETS_WATCH_ONLINE].includes(selectedFilterType)) {
    return filterTypeConfig;
  }

  const params = {
    ...filterParams,
    ...([ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION].includes(entityType) &&
      entityId && {
        ctx_entity: getEntityIdWithPrefix({ entityId, entityType }),
      }),
    sort: SORT_OPTION_VALUES.PROD_COUNT_DESC,
  };

  // NOTE: we need to remove FILTER_AGGREGATION_MAP
  const aggregationType =
    AGGREGATION_TYPES[selectedFilterType] ||
    subFilterType?.aggregationType ||
    FILTER_AGGREGATION_MAP[selectedFilterType];
  const searchQuery = getBooleanSearchQuery(params?.expression);
  const worksRoles = getWorkRolesFromFilterExpression(searchQuery);
  const workIds = worksRoles?.map(({ workId }) => workId);
  const baseFilters = omit(params, [FILTER_AGGREGATION_MAP[selectedFilterType]]);

  switch (selectedFilterType) {
    case FILTER_TYPES.ROLE_PROFESSION_REPERTOIRE:
      filterTypeConfig.fetchOptionsCall = {
        aggregationType,
        filters: {
          ...omit(baseFilters, [
            CASTING_TOOL_SLUGS.BOOLEAN_SEARCH,
            FILTER_TYPES.SINCE_YEAR,
            CASTING_TOOL_SLUGS.WORK_ROLE,
            CASTING_TOOL_SLUGS.PROFESSION,
          ]),
          [URL_SLUG_TYPES.CONTRIBUTION_TYPE]: `${CONTRIBUTORS.CAST}, ${CONTRIBUTORS.CREW}`,
        },
      };
      break;
    case FILTER_TYPES.SINCE_YEAR:
    case FILTER_TYPES.COUNTRY:
      filterTypeConfig.fetchOptionsCall = {
        aggregationType,
        filters: omit(baseFilters, [
          FILTER_TYPES.BOOLEAN_SEARCH,
          FILTER_TYPES.SINCE_YEAR,
          CASTING_TOOL_SLUGS.CACHE_KEY,
        ]),
        limit: 20,
      };
      break;
    case FILTER_TYPES.WORK_TYPE:
      filterTypeConfig.fetchOptionsCall = {
        aggregationType,
        filters: {
          ...omit(baseFilters, [URL_SLUG_TYPES.CONTRIBUTION_EXP]),
          ...(workIds?.length > 0 && { [URL_SLUG_TYPES.WORK]: workIds.join(',') }),
        },
        limit: 20,
      };
      break;
    case FILTER_TYPES.CONTRIBUTION_EXPRESSION:
      filterTypeConfig = {
        fetchOptionsCall: {
          aggregationType,
          filters: baseFilters,
        },
      };
      if (subFilterType?.id === SUB_FILTER_TYPES.PRODUCER) {
        const filters = {
          ...omit(baseFilters, [URL_SLUG_TYPES.CONTRIBUTION_TYPE]),
        };
        filterTypeConfig = {
          fetchOptionsCall: {
            aggregationType: AGGREGATION_TYPES[FILTER_TYPES.PRODUCER],
            filters,
          },
          fetchGroupCall: {
            filters: {
              ...omit(filters, [URL_SLUG_TYPES.CONTRIBUTION_EXP, AGGREGATION_TYPES[FILTER_TYPES.PRODUCER]]),
            },
            aggregationType: AGGREGATION_TYPES[FILTER_TYPES.PRODUCER_TYPE],
            limit: 100,
          },
        };
      }
      break;
    case FILTER_TYPES.COMPOSER:
      filterTypeConfig.fetchOptionsCall = {
        aggregationType,
        filters: {
          ...omit(baseFilters, ['search']),
        },
      };
      break;
    default:
      filterTypeConfig.fetchOptionsCall = {
        aggregationType,
        filters: baseFilters,
      };
  }

  return filterTypeConfig;
};

const useGetOptionProps = ({ selectedFilterType, subFilterType, filterParams, isQueryEnabled, stats, onSuccess }) => {
  const { entityType, entityId } = usePageContext();
  const { t } = useTranslation('NS_APP_GLOBALS');

  const { fetchOptionsCall = {}, fetchGroupCall = {} } = getFilterConfigMap({
    selectedFilterType,
    subFilterType,
    filterParams,
    entityType,
    entityId,
  });

  const {
    filters: optionCallFilters,
    aggregationType: optionCallAggType,
    limit: optionCallLimit,
    includeAggTypeInFilter = false,
  } = fetchOptionsCall;

  const {
    filters: groupCallFilters,
    aggregationType: groupCallAggregationType,
    limit: groupCallLimit,
  } = fetchGroupCall;

  const transformFn = (response, aggregationType) =>
    transformProductionAggregationResponse({
      selectedFilterType,
      subFilterType,
      aggregationType,
      response,
      t,
    });

  const getOptionsQueryFn = ({ keepPreviousData = true, enabled = !!optionCallAggType && isQueryEnabled } = {}) => ({
    customFilters = {},
    customAggType,
    customLimit,
    isEnabled = enabled,
  }) => {
    const aggType = customAggType || optionCallAggType;
    const limit = customLimit || optionCallLimit;
    return queries.getFilterOptions({
      filters: { ...optionCallFilters, ...customFilters },
      aggregationType: aggType,
      limit,
      includeAggTypeInFilter,
      queryConfig: {
        keepPreviousData,
        enabled: isEnabled,
        onSuccess: () => onSuccess(aggType),
        select: response => transformFn(response, aggType),
      },
    });
  };

  let optionProps = {
    showGroupHeaders: false,
    isNested: false,
    isMultiSelect: true,
    aggregationType: optionCallAggType,
  };

  switch (selectedFilterType) {
    // case FILTER_TYPES.COVER_DEBUT:
    //   optionProps = {
    //     ...optionProps,
    //     fetchOptionsFn: (customFilters = {}) =>
    //       queries.getCoverAndDebutOptions({
    //         filters: { ...optionCallFilters, ...customFilters },
    //         limit: optionCallLimit,
    //         queryConfig: {
    //           keepPreviousData: false,
    //           enabled: isQueryEnabled,
    //           onSuccess: () => onSuccess(optionCallAggType),
    //           select: response => transformFn({ response, optionCallAggType }),
    //         },
    //       }),
    //   };
    //   break;
    case FILTER_TYPES.TICKETS_WATCH_ONLINE:
      optionProps = {
        defaultOptions: [
          {
            label: t(`${TP}.FN_TICKETS`),
            id: FILTER_TYPES.TICKET,
            slug: FILTER_TYPES.TICKET,
            total: stats?.[FILTER_TYPES.TICKET],
          },
          {
            label: t(`${TP}.FN_WATCH_OPTIONS`),
            id: FILTER_TYPES.WATCH_ONLINE,
            slug: FILTER_TYPES.WATCH_ONLINE,
            total: stats?.[FILTER_TYPES.WATCH_ONLINE],
          },
        ],
        // NOTE: to avoid fetching options
        fetchOptionsFn: () => ({
          queryKey: [FILTER_TYPES.TICKETS_WATCH_ONLINE],
          queryFn: () => {},
          enabled: false,
        }),
      };
      break;
    case FILTER_TYPES.SINCE_YEAR:
      optionProps = {
        ...optionProps,
        isMultiSelect: false,
        fetchOptionsFn: getOptionsQueryFn(),
      };
      break;
    case FILTER_TYPES.CONTRIBUTION_EXPRESSION:
      optionProps = {
        ...optionProps,
        groupBy: 'organizationType.slug',
        fetchOptionsFn: getOptionsQueryFn(),
      };
      if (subFilterType?.id === SUB_FILTER_TYPES.PRODUCER) {
        optionProps = {
          ...optionProps,
          showGroupHeaders: true,
          fetchGroupFn: queries.getFilterOptions({
            filters: groupCallFilters,
            aggregationType: groupCallAggregationType,
            limit: groupCallLimit,
            queryConfig: {
              select: response =>
                [DEFAULT_TAB.ALL, ...response?.data]?.map(item => ({ ...item, id: item?.id?.toLowerCase() })),
            },
          }),
        };
      }
      break;
    case FILTER_TYPES.COUNTRY:
      optionProps = {
        ...optionProps,
        groupBy: 'id',
        isNested: true,
        fetchOptionsFn: getOptionsQueryFn({ keepPreviousData: false, limit: optionCallLimit }),
        fetchNestedOptionsFn: ({ parentId }) =>
          getOptionsQueryFn({ keepPreviousData: false, limit: optionCallLimit })({
            customFilters: { [AGGREGATION_TYPES[FILTER_TYPES.COUNTRY]]: parentId },
            customAggType: AGGREGATION_TYPES[FILTER_TYPES.CITY],
            customLimit: 300,
          }),
      };
      break;
    case FILTER_TYPES.WORK_TYPE:
      optionProps = {
        ...optionProps,
        groupBy: 'id',
        isNested: true,
        fetchOptionsFn: getOptionsQueryFn({ keepPreviousData: false, limit: optionCallLimit }),
      };
      break;
    default: {
      optionProps = {
        ...optionProps,
        fetchOptionsFn: getOptionsQueryFn(),
      };
    }
  }

  return optionProps;
};

const ClearAllCta = ({ t, isOptionsExist, trackingData, onClickHandler }) => (
  <LinkButton
    rightIcon={<SpriteIcon icon="replay-bold" />}
    variant="text"
    size="medium"
    shape="rectangle"
    onClick={onClickHandler}
    disabled={!isOptionsExist}
    styles={{
      root: classnames(classes.clearSearch, {
        [classes.clearSearch__disabled]: !isOptionsExist,
      }),
      icon: classnames(classes.icon, {
        [classes.iconActive]: isOptionsExist,
      }),
    }}
    disableUnderline
    trackingData={{
      ...trackingData,
      subComponent: trackingData?.subComponent
        ? `${trackingData.subComponent} / ${SUB_COMPONENTS.CLEAR_CTA}`
        : SUB_COMPONENTS.CLEAR_CTA,
    }}
    preventDefault
    stopPropagation
  >
    {t(`${TP}.AS_FILTERS_CLEAR_ALL_BTN`)}
  </LinkButton>
);

export const FilterFooter = ({
  selectedOptionsTotal,
  setSelectedOptions,
  selectedOptions,
  onUpdateFilters,
  hasFilterChanged,
  trackingData,
  styles = {},
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { entity, entityType, resetFilters, navigate, subPath } = usePageContext();
  const filterSlugsToIgnore = useMemo(() => CLEAR_EXCLUDED_FILTERS?.map(type => FILTER_SLUG_TYPE[type]), []);
  const isOptionsExist = Object.keys(selectedOptions)?.length > 0;

  const onResetFilters = useCallback(() => {
    if (setSelectedOptions) {
      setSelectedOptions({});
    }
    resetFilters({ entityType, entity, subPath }, filterSlugsToIgnore);
    navigate.scrollTo();
  }, [setSelectedOptions, resetFilters, entityType, entity, subPath, filterSlugsToIgnore, navigate]);

  return (
    <div className={classnames(classes.filterDropdown__footer, { [styles.filterDropdown]: !!styles.filterDropdown })}>
      {selectedOptionsTotal > 1 && (
        <Typography size={14} color="secondary" weight="medium" className={classes.filterDropdown__footer_text}>
          {t(`${TP}.FN_SHOW_TOTAL_RESULTS`, { total: selectedOptionsTotal })}
        </Typography>
      )}
      <div className={classes.filterDropdown__footer_wrapper}>
        <ClearAllCta
          t={t}
          isOptionsExist={isOptionsExist}
          trackingData={trackingData}
          onClickHandler={onResetFilters}
        />
        <LinkButton
          styles={{
            root: classnames(classes.submitSearch, {
              [classes.submitSearch__disabled]: !hasFilterChanged,
              [styles.submitSearch]: !!styles.submitSearch,
            }),
          }}
          variant="orange"
          shape="rectangle"
          onClick={() => onUpdateFilters(selectedOptions)}
          disabled={!hasFilterChanged}
          skipTracking // NOTE: tracked in track.click in FilterFacets
        >
          {t(`${TP}.m_APPLY`)}
        </LinkButton>
      </div>
    </div>
  );
};

const FilterTabs = ({
  fetchGroupFn,
  activeGroup,
  setSelectedGroups,
  withSelectAll = true,
  onSelectAllSelected,
  selectedOptions,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { data, isFetching } = useQuery(fetchGroupFn);
  const [isExcludeAllChecked, setIsExcludeAllChecked] = useState(false);
  const { isSelectAllChecked, isDefaultTabSelected, showSelectAll, selectedTabTotal } = useMemo(() => {
    const total = data?.find(tab => tab?.id === activeGroup?.id)?.total;
    return {
      isSelectAllChecked:
        !activeGroup.isExcludedByUser &&
        (total === activeGroup?.total || selectedOptions[activeGroup?.id]?.length === 0),
      isDefaultTabSelected: activeGroup?.id === DEFAULT_TAB.ALL.id,
      showSelectAll: activeGroup?.id && activeGroup?.id !== DEFAULT_TAB.ALL.id && withSelectAll,
      selectedTabTotal: total,
    };
  }, [activeGroup, data, withSelectAll, selectedOptions]);

  useEffect(() => {
    const key = `-${activeGroup?.id}`;
    if (isExcludeAllChecked || selectedOptions[key]) {
      setIsExcludeAllChecked(
        !!selectedOptions[key] || (!isSelectAllChecked && isExcludeAllChecked && !activeGroup?.total),
      );
    }
  }, [activeGroup?.id, activeGroup?.total, isExcludeAllChecked, isSelectAllChecked, selectedOptions]);

  if (isFetching) {
    return <Loader small />;
  }

  const onChangeHandler = (e, option) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExcludeAllChecked(false);
    setSelectedGroups(value => ({
      ...value,
      [option.id]: { ...option, total: value[option.id]?.total || 0 },
      isActiveTab: option.id,
    }));
  };

  return (
    <div>
      <HorizontalScrollIndicators
        className={classes.orgTypes}
        styles={{ leftIndicator: classes.scrollLeftIndicator, rightIndicator: classes.scrollRightIndicator }}
        triggerSize={0}
        isArrowsEnabled
      >
        {data?.map(option => {
          const isChecked = option?.id === activeGroup?.id;
          return (
            <div
              key={option?.id}
              role="button"
              tabIndex="0"
              className={classnames(classes.orgTypeOption, {
                [classes.orgTypeOption__checked]: isChecked,
              })}
              onClick={e => onChangeHandler(e, option)}
              onKeyDown={e => onChangeHandler(e, option)}
            >
              {isChecked && (
                <>
                  <Checkbox
                    className={classes.orgTypeOption__checkbox}
                    checked
                    label=""
                    size="small"
                    onChange={e => onChangeHandler(e, option)}
                  />
                  {!isDefaultTabSelected && activeGroup?.total > 0 && (
                    <Typography size="12" weight={isChecked ? 'bold' : 'regular'}>
                      {activeGroup?.total}
                    </Typography>
                  )}
                </>
              )}
              <Typography size="12" weight={isChecked ? 'bold' : 'regular'}>
                {option?.name}
              </Typography>
            </div>
          );
        })}
      </HorizontalScrollIndicators>
      {showSelectAll && (
        <div className={classes.orgTypesSelectAll}>
          <Checkbox
            checked={isExcludeAllChecked}
            onChange={(e, checkedStatus) => {
              setSelectedGroups(prev => ({
                ...prev,
                [activeGroup?.id]: { ...activeGroup, total: 0 },
              }));
              setIsExcludeAllChecked(checkedStatus);
              onSelectAllSelected({
                isGroup: true,
                option: activeGroup,
                isChecked: checkedStatus,
                isAllExcluded: true,
              });
            }}
            label={<Typography size={12}>{t(`${TP}.FN_EXCLUDE_ALL`)}</Typography>}
            size="small"
          />
          {!activeGroup.isExcludedByUser && (
            <Checkbox
              checked={!isExcludeAllChecked && isSelectAllChecked}
              onChange={(e, checkedStatus) => {
                setSelectedGroups(prev => ({
                  ...prev,
                  [activeGroup?.id]: { ...activeGroup, total: checkedStatus ? selectedTabTotal : 0 },
                }));
                onSelectAllSelected({ isGroup: true, option: activeGroup, isChecked: checkedStatus });
              }}
              label={<Typography size={12}>{t(`${TP}.FN_SELECT_ALL`)}</Typography>}
              size="small"
            />
          )}
        </div>
      )}
    </div>
  );
};

const getNestedFilterOptionsProps = ({
  option: parent,
  selectedOptions,
  groupBy,
  t,
  nestedFilterOptions,
  groupKey,
}) => {
  const key = groupBy && !groupKey ? getValueByPath({ option: parent, path: groupBy }) : groupKey;
  const options = selectedOptions?.[key];
  return {
    groupKey: key,
    selectedOptions,
    parent,
    options: [
      {
        name: t(`${TP}.m_ALL`),
        label: t(`${TP}.m_ALL`),
        id: parent?.id,
        key: parent?.id,
        total: parent?.total,
        isChecked: options?.length === 1 && options[0]?.id === parent?.id,
        isAllOption: true,
      },
      ...(nestedFilterOptions || []),
    ],
  };
};

const NestedFilterOptions = ({
  parent,
  selectedOptions,
  options,
  trackingClasses,
  onChange,
  fetchNestedOptionsFn,
  groupKey,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const shouldFetchNestedOptions = !!fetchNestedOptionsFn;
  const { data, isFetching } = useQuery(shouldFetchNestedOptions && fetchNestedOptionsFn({ parentId: parent?.id }));

  const handleCheckboxChange = useCallback(
    (e, option, checkedStatus) => {
      e.stopPropagation();
      const isGroup = option?.id in selectedOptions;
      onChange({
        groupKey,
        option: isGroup ? parent : option,
        isChecked: checkedStatus,
        isGroup,
      });
    },
    [groupKey, onChange, parent, selectedOptions],
  );

  const isStatusChecked = useCallback(
    filterOption =>
      filterOption?.isAllOption
        ? filterOption.isChecked
        : selectedOptions[groupKey]?.some(({ id }) => id === filterOption?.id),
    [groupKey, selectedOptions],
  );

  if (shouldFetchNestedOptions && isFetching) {
    return (
      <div className={classes.filterOptions__nestedFilters}>
        <Loader small />
      </div>
    );
  }

  const optionsToRender = shouldFetchNestedOptions
    ? getNestedFilterOptionsProps({
        option: parent,
        selectedOptions,
        nestedFilterOptions: data?.data,
        groupKey,
        t,
      })?.options
    : options;

  return (
    <div className={classes.filterOptions__nestedFilters}>
      {optionsToRender?.map(filterOption => {
        const key = `${parent?.id}-${filterOption?.id}`;
        const isChecked = isStatusChecked(filterOption);

        return (
          <Checkbox
            key={key}
            onChange={(e, checkedStatus) => handleCheckboxChange(e, filterOption, checkedStatus)}
            className={classnames(classes.filterOptions__nestedFilters_checkbox, {
              [classes.filterOptions__checkboxSelected]: isChecked,
              [trackingClasses]: !!trackingClasses,
            })}
            label={
              <div className={classes.filterOptions__nestedFilters_label}>
                <Typography size={12} color="secondary">
                  {filterOption?.name || filterOption?.label}
                </Typography>
                <Typography size={12} color="secondary" className={classes.filterOptions__label_total}>
                  {filterOption?.total}
                </Typography>
              </div>
            }
            checked={isChecked}
            size="small"
          />
        );
      })}
    </div>
  );
};

const renderLabelByFilterType = (type, label, option, aggregationType, trackingData) => {
  switch (type) {
    case FILTER_TYPES.SINCE_YEAR:
      return (
        <Typography weight="bold" size={12} color="secondary">
          {label}
        </Typography>
      );

    case FILTER_TYPES.ROLE_PROFESSION:
    case FILTER_TYPES.ROLE_PROFESSION_REPERTOIRE:
      return (
        <Typography className={classes.roleProfessionOption}>
          <Typography className={classes.roleProfessionOption__primaryLabel}>
            <EntityName
              name={label}
              entity={{ id: option?.id, name: label }}
              entityType={determineEntityTypeFromAggregation(aggregationType)}
              isRaw
              trackingData={trackingData}
            />{' '}
            {option?.profession?.name !== label && <Typography size={12}>({option?.profession?.name})</Typography>}
          </Typography>
          {option?.work?.original_name && (
            <Typography size={12} color="secondary">
              <EntityName entity={option.work} entityType={ENTITY_TYPES.WORK} isRaw trackingData={trackingData} />,{' '}
              <EntityName
                name={option?.composer?.name}
                entity={{ id: option?.composer?.id, name: option?.composer.name }}
                entityType={ENTITY_TYPES.PROFILE}
                isRaw
                trackingData={trackingData}
              />
            </Typography>
          )}
        </Typography>
      );
    case FILTER_TYPES.WORK:
      return (
        <EntityName
          entity={option}
          entityType={ENTITY_TYPES.WORK}
          trackingData={trackingData}
          isColumnView
          typographyProps={{
            work: {
              size: 12,
            },
            translation: {
              size: 10,
              weight: 'regular',
              color: 'secondary',
            },
          }}
        />
      );
    default:
      return (
        <EntityName
          name={label}
          entity={{ id: option?.id, name: label }}
          entityType={determineEntityTypeFromAggregation(aggregationType)}
          isRaw
          trackingData={trackingData}
        />
      );
  }
};

const RenderOptions = ({
  selectedFilterType,
  selectedGroup,
  aggregationType,
  options,
  selectedOptions = {},
  onChange,
  trackingData,
  fetchNestedOptionsFn,
  isNested,
  groupBy,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const trackingClasses = useTrackingClickStyles(trackingData, false);
  const isRadio = selectedFilterType === FILTER_TYPES.SINCE_YEAR;

  const selectedOptionIds = useMemo(() => {
    if (Object.keys(selectedOptions)?.length === 0) {
      return [];
    }

    const key = selectedGroup?.id || null;
    if (key && key in selectedOptions) {
      return selectedOptions[key]?.map(option => option?.id?.toString());
    }

    if (isNested) {
      return Object.keys(selectedOptions);
    }

    return Object.values(selectedOptions)
      ?.flat()
      ?.map(option => option?.id?.toString());
  }, [selectedOptions, isNested, selectedGroup]);

  const optionsList = useMemo(() => {
    if (
      !selectedOptionIds?.length ||
      [FILTER_TYPES.SINCE_YEAR, FILTER_TYPES.TICKETS_WATCH_ONLINE].includes(selectedFilterType)
    ) {
      return options;
    }

    return options?.sort((a, b) => {
      if (selectedOptionIds?.includes(a?.id?.toString())) {
        if (selectedOptionIds?.includes(b?.id?.toString())) {
          return 0;
        }
        return -1;
      }
      return 1;
    });
    // NOTE: We don't want to re-sort the options when the selectedOptionIds change, because UI will be jumpy
  }, [options, selectedFilterType]);

  const isStatusChecked = useCallback(
    option => {
      const optionId = option?.id?.toString();
      if (isNested) {
        return Boolean(selectedOptions?.[optionId]);
      }

      if (selectedGroup?.id) {
        const groupId = selectedGroup?.id;
        if (groupId?.startsWith(OPERATORS.FILTER_TYPE_NEGATED)) {
          return false;
        }

        if (selectedOptions?.[groupId] && selectedOptions?.[groupId]?.length === 0) {
          return true;
        }

        const groupedValue = getValueByPath({ option, path: groupBy });
        const groupOptions = selectedOptions[groupId] || selectedOptions[groupedValue];
        if (groupOptions && groupOptions.length > 0) {
          const { isOptionExist, isOptionNegated, isAnyOptionNegated, isExactOptionExist } = getFilteredOptions({
            list: groupOptions,
            targetId: `-${optionId}`,
            exactId: optionId,
          });

          if (isOptionExist) return false;
          if (isOptionNegated) return false;
          if (isAnyOptionNegated) return true;

          return isExactOptionExist;
        }

        return Boolean(selectedOptions?.[groupedValue]);
      }

      // NOTE: Check flat options only if no groupOptions
      return Object.values(selectedOptions)
        ?.flat()
        ?.some(item => item?.id === option?.id || item?.id?.toString() === option?.id);
    },
    [isNested, selectedGroup?.id, selectedOptions, groupBy],
  );

  if (optionsList?.length > 0) {
    const Component = isRadio ? Radio : Checkbox;
    return (
      <div className={classes.filterOptions}>
        {optionsList?.map(option => {
          const isChecked = isStatusChecked(option);
          const label = option?.cityCountry ? `${option?.label} (${option?.cityCountry})` : option?.label;

          return (
            <div key={`${selectedFilterType}_${option?.id}`}>
              <Component
                onChange={(e, checkedStatus) => {
                  e.stopPropagation();
                  const groupKey = groupBy ? getValueByPath({ option, path: groupBy }) : null;
                  onChange({ groupKey, option, isChecked: checkedStatus || isRadio, isGroup: isNested });
                }}
                {...(isRadio
                  ? {
                      styles: {
                        radio: classes.filterOptions__radioItem,
                        radioInput: classes.filterOptions__radioInput,
                        radioWrapper: classes.filterOptions__radioWrapper,
                      },
                      value: option?.id,
                    }
                  : {
                      className: classnames(classes.filterOptions__checkbox, {
                        [classes.filterOptions__checkboxSelected]: isChecked,
                        [classes.filterOptions__checkbox_hasSubtext]: !!option?.headline,
                        [trackingClasses]: !!trackingClasses,
                      }),
                    })}
                label={
                  <div className={classes.filterOptions__info}>
                    <div
                      className={classnames(classes.filterOptions__info_name, {
                        [classes.filterOptions__info_nameSpacing]:
                          option?.headline || selectedFilterType === FILTER_TYPES.ROLE_PROFESSION,
                      })}
                    >
                      {renderLabelByFilterType(selectedFilterType, label, option, aggregationType, trackingData)}
                      {option?.headline && (
                        <Typography variant="p" size={11} color="secondary">
                          {option.headline}
                        </Typography>
                      )}
                    </div>
                    <Typography size={12} color="secondary" className={classes.filterOptions__info_total}>
                      {option?.total}
                    </Typography>
                  </div>
                }
                checked={isChecked}
                size="small"
              />
              {isNested && isChecked && (
                <NestedFilterOptions
                  {...getNestedFilterOptionsProps({
                    option,
                    selectedOptions,
                    nestedFilterOptions: option?.nestedFilterOptions,
                    groupBy,
                    t,
                  })}
                  fetchNestedOptionsFn={fetchNestedOptionsFn}
                  onChange={onChange}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  return <NoResult title="No options" sizes={{ icon: 40, title: 14 }} />;
};

const LockedFilters = ({ onAccessArchives, isLoggedIn, isRestricted, limitReached }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  if (!isRestricted) {
    return null;
  }

  return (
    <div className={classes.lockedFiltersModal}>
      <div className={classes.lockedFiltersModal__content}>
        <div className={classes.lockedFiltersModal__content_icon}>
          <SpriteIcon icon="tune" />
        </div>
        {isLoggedIn ? (
          <Typography size={16} weight="medium" variant="p" align="center">
            <CommonTrans
              ns="NS_APP_GLOBALS"
              i18nKey={
                limitReached ? t(`${TP}.FN_FILTER_LIMIT_UPGRADE_MSG`) : t(`${TP}.FN_FILTER_ARCHIVED_UPGRADE_MSG`)
              }
            />
          </Typography>
        ) : (
          <Typography size={16} weight="medium" variant="p" align="center">
            {t(`${TP}.FN_FILTERS_LOGIN_REQUIRED`)}
          </Typography>
        )}
        <PrimaryButton
          styles={{
            root: classes.lockedFiltersModal__cta_primary,
          }}
          shape="rectangle"
          onClick={onAccessArchives}
          stretch
        >
          {isLoggedIn ? t(`${TP}.AS_SUBSCRIPTIONS_UPGRADE`) : t(`${TP}.m_LOGIN`)}
        </PrimaryButton>
        {isLoggedIn && (
          <TertiaryButton shape="rectangle" rightIcon={<SpriteIcon icon="chevron_right" />} stretch>
            {t(`${TP}.FN_BOOK_SALES_MEETING`)}
          </TertiaryButton>
        )}
        {!isLoggedIn && (
          <Typography variant="p" align="center" size={11} color="secondary">
            {t(`${TP}.FN_FILTERS_LOCKED_FOOTNOTE`)}
          </Typography>
        )}
      </div>
    </div>
  );
};

const FilterOptions = ({
  selectedFilterType,
  subFilterType,
  total = 0,
  filterParams,
  onUpdateFilters,
  onAccessArchives,
  inline = false,
  upcoming = false,
  trackingData = {},
  asPopover = false,
  stats,
}) => {
  const track = useTracking();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const [query, setQuery] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedGroups, setSelectedGroups] = useState({});
  const { obRouteContext } = useAppContext();
  const { filters = {} } = obRouteContext?.linkProps || {};
  const { getFilterUsageState } = usePageContext();
  const filterState = useMemo(() => getFilterUsageState({ type: selectedFilterType, upcoming }), [
    getFilterUsageState,
    selectedFilterType,
    upcoming,
  ]);
  const showSearch = ![FILTER_TYPES.PERFORMANCE_HIGHLIGHT, FILTER_TYPES.SINCE_YEAR].includes(selectedFilterType);
  const appliedFilters = useMemo(() => getAppliedQuickViewFilters({ filters, selectedFilterType, subFilterType }), [
    filters,
    selectedFilterType,
    subFilterType,
  ]);

  const selectedOptionsTotal = useMemo(() => {
    if (selectedFilterType === FILTER_TYPES.SINCE_YEAR) {
      return 0;
    }

    const totalCount = Object.values(selectedOptions)?.reduce(
      (acc, option) => acc + (typeof option?.total === 'number' ? option.total : 0),
      0,
    );

    return totalCount > 0 ? totalCount : Object.keys(selectedOptions)?.length;
  }, [selectedOptions, selectedFilterType]);

  const {
    showGroupHeaders,
    fetchOptionsFn,
    fetchGroupFn,
    fetchNestedOptionsFn,
    isMultiSelect,
    aggregationType,
    defaultOptions,
    ...restProps
  } = useGetOptionProps({
    selectedFilterType,
    subFilterType,
    isQueryEnabled: !filterState?.isRestricted || [FILTER_TYPES.TICKETS_WATCH_ONLINE].includes(selectedFilterType),
    filterParams,
    stats,
    onSuccess: aggType => {
      if (query?.length > 0) {
        track.click(trackSearch(aggType, query), GOOGLE_OLD_TRACKING_SERVICES);
      }
    },
  });

  useEffect(() => {
    setSelectedOptions(() => (Object.keys(appliedFilters)?.length > 0 ? appliedFilters : {}));
  }, [subFilterType, selectedFilterType]);

  const { customFilters, activeGroup } = useMemo(() => {
    const activeTab = selectedGroups[selectedGroups?.isActiveTab] || DEFAULT_TAB.ALL;
    if (appliedFilters?.[`-${activeTab?.id}`]) {
      activeTab.isExcludedByUser = true;
    }

    return {
      activeGroup: showGroupHeaders ? activeTab : null,
      customFilters: getCustomFilters({ selectedFilterType, subFilterType, activeGroup: activeTab }),
    };
  }, [selectedFilterType, subFilterType, selectedGroups, showGroupHeaders, appliedFilters]);

  const { data, isFetching: isLoading } = useQuery(
    fetchOptionsFn({
      customFilters: { ...customFilters, aggregation_query: query },
      isEnabled: !activeGroup?.isExcludedByUser,
    }),
  );

  const { entityList, top5, showEntityList, showNoOptionsFound } = useMemo(() => {
    const defaultList = Array.from({ length: 5 }, () => ({ id: -1, label: '#######', total: 0 }));
    if (filterState?.isRestricted) {
      return {
        entityList: defaultList,
        top5: defaultList,
        showEntityList: false,
      };
    }

    if (activeGroup?.isExcludedByUser) {
      return {
        entityList: [],
        top5: [],
        showEntityList: false,
      };
    }
    const options = data?.data || defaultOptions;
    const hasData = options?.length > 0;
    const shouldShowList =
      (options?.length >= 15 ||
        (query && hasData) ||
        ([SUB_FILTER_TYPES.PRODUCER].includes(subFilterType?.id) && hasData) ||
        [
          FILTER_TYPES.SINCE_YEAR,
          FILTER_TYPES.COVER_DEBUT,
          FILTER_TYPES.COUNTRY,
          FILTER_TYPES.WORK_TYPE,
          FILTER_TYPES.TICKETS_WATCH_ONLINE,
        ].includes(selectedFilterType)) &&
      !isLoading;

    return {
      entityList: options,
      top5: !activeGroup?.isExcludedByUser && data?.top5 ? data?.top5 : [],
      showEntityList: shouldShowList && !activeGroup?.isExcludedByUser,
      showNoOptionsFound: !activeGroup?.isExcludedByUser && !options?.length && !isLoading,
    };
  }, [
    data,
    filterState?.isRestricted,
    isLoading,
    query,
    selectedFilterType,
    subFilterType,
    activeGroup,
    defaultOptions,
  ]);

  const onTriggerTrackEvent = option => {
    track.click(
      trackSearchFilter(aggregationType, option?.label || option?.name, option?.slug),
      GOOGLE_OLD_TRACKING_SERVICES,
    );
    track.click({
      subComponent: SUB_COMPONENTS.FILTER_OPTION,
      ...trackingData,
      meta: {
        id: option?.id,
        clickText: option?.slug,
        filterTab: selectedFilterType,
      },
    });
  };

  const onHandleGroupSelection = ({ option, isChecked, isAllExcluded }) => {
    if (isChecked) {
      onTriggerTrackEvent(option);
      const values = { ...selectedOptions };
      if (isAllExcluded) {
        delete values[option?.id];
        setSelectedOptions({
          ...values,
          [`-${option?.id}`]: [],
        });
        return;
      }
      delete values[`-${option?.id}`];
      setSelectedOptions({
        ...values,
        [option?.id]: [option],
      });
    } else {
      const values = { ...selectedOptions };
      if (isAllExcluded) {
        delete values[`-${option?.id}`];
        setSelectedOptions(values);
        return;
      }
      delete values[option?.id];
      setSelectedOptions(values);
    }
  };

  const onGroupFilterOptionSelected = ({ option, isChecked, key }) => {
    const currentOptions = selectedOptions[key] || [];
    const targetId = isChecked ? `-${option?.id}` : option?.id;
    const group = selectedGroups[key];
    const { isOptionExist, filteredList } = getFilteredOptions({
      list: currentOptions,
      targetId,
    });

    if (isChecked) {
      onTriggerTrackEvent(option);
      if (selectedOptions[`-${key}`]) {
        onHandleGroupSelection({ option: { id: key }, isChecked: !isChecked, isAllExcluded: true });
      }
      setSelectedGroups({ ...selectedGroups, [key]: { ...group, total: group?.total + 1 } });
      setSelectedOptions(values => ({
        ...values,
        [key]: isOptionExist ? filteredList : uniqBy([option, ...currentOptions], 'id'),
      }));
    } else {
      setSelectedGroups({ ...selectedGroups, [key]: { ...group, total: group?.total - 1 } });
      setSelectedOptions(values => {
        const updatedValues = { ...values };

        if (isOptionExist && filteredList.length === 0) {
          delete updatedValues[key];
        } else {
          updatedValues[key] = isOptionExist ? filteredList : [...filteredList, { ...option, id: `-${option?.id}` }];
        }

        return updatedValues;
      });
    }
  };

  const onOptionSelected = ({ option, key }) => {
    onTriggerTrackEvent(option);
    if (!isMultiSelect) {
      setSelectedOptions({
        [key]: [option],
      });
      return;
    }
    setSelectedOptions(values => ({
      ...values,
      [key]: uniqBy([option, ...(values?.[key] || [])], 'id'),
    }));
  };

  const onOptionUnselected = ({ option, key }) => {
    const filteredOptions = selectedOptions[key]?.filter(
      selectedOption => !selectedOption?.id?.toString()?.startsWith(`${option?.id}`),
    );
    if (!filteredOptions?.length && subFilterType?.id !== SUB_FILTER_TYPES.PRODUCER) {
      delete selectedOptions[key];
      setSelectedOptions({ ...selectedOptions });
      return;
    }
    setSelectedOptions(values => ({
      ...values,
      [key]: filteredOptions,
    }));
  };

  const onOptionSelectedHandler = ({ groupKey, option, isChecked, isGroup, isAllExcluded }) => {
    const key = groupKey || subFilterType?.id || selectedFilterType;
    if (isGroup) {
      onHandleGroupSelection({ option, isChecked, isAllExcluded });
    } else if (showGroupHeaders) {
      onGroupFilterOptionSelected({ option, isChecked, key });
    } else if (isChecked) {
      onOptionSelected({ option, key });
    } else {
      onOptionUnselected({ option, key });
    }
  };

  const stopPropagation = useCallback(e => e.stopPropagation(), []);

  const hasFilterChanged = useMemo(() => {
    const serialize = obj =>
      JSON.stringify(
        Object.keys(obj)
          .sort()
          .reduce((result, key) => {
            const val = { ...result };
            val[key] = obj[key]?.map(filter => filter?.id).sort();
            return val;
          }, {}),
      );

    const selectedHash = serialize(selectedOptions);
    const appliedHash = serialize(appliedFilters);

    return selectedHash !== appliedHash;
  }, [appliedFilters, selectedOptions]);

  return (
    <div
      className={classnames(classes.filterDropdown, { [classes.filterDropdownInline]: !!inline })}
      role="button"
      tabIndex="0"
      onClick={stopPropagation}
      onKeyDown={stopPropagation}
    >
      <div className={classes.filterDropdown__wrapper}>
        <div className={classnames(classes.filterDropdown__content, { [classes.blur]: filterState?.isRestricted })}>
          <Typography className={classes.filterDropdown__title} weight="medium">
            {t(`${TP}.FN_SELECT`)} {subFilterType?.name || t(FILTER_LABELS[selectedFilterType])}{' '}
            {total > 0 && ![FILTER_TYPES.SINCE_YEAR].includes(selectedFilterType) && `(${total})`}
          </Typography>
          {showSearch && (
            <>
              <div
                className={classnames(classes.filterDropdown__header, {
                  [classes.filterDropdown__header_popover]: asPopover,
                })}
              >
                <div
                  className={classnames(classes.filterDropdown__header_input, {
                    [classes.filterDropdown__header_input_popover]: asPopover,
                  })}
                >
                  <SearchInput onChange={setQuery} placeholder={t(`${TP}.SEARCH`)} />
                </div>
              </div>
            </>
          )}
          {showSearch && (
            <ClearAllCta
              t={t}
              isOptionsExist={Object.keys(selectedOptions)?.length > 0}
              trackingData={trackingData}
              onClickHandler={() => setSelectedOptions({})}
            />
          )}
          {showGroupHeaders && (
            <FilterTabs
              fetchGroupFn={fetchGroupFn}
              activeGroup={activeGroup}
              selectedOptions={selectedOptions}
              setSelectedGroups={setSelectedGroups}
              onSelectAllSelected={onOptionSelectedHandler}
              subFilterType={subFilterType}
              contributionExpressionType={CONTRIBUTION_EXP_TYPES.PRODUCER}
              withSelectAll={!showNoOptionsFound}
            />
          )}
          <div
            className={classnames(classes.filterDropdown__options, {
              [classes.filterDropdown__isSearchHidden]: !showSearch,
            })}
          >
            {isLoading && <Loader />}
            {showNoOptionsFound ? (
              <NoResult title={t(`${TP}.AS_TABLE_NO_RESULTS`)} sizes={{ icon: 40, title: 14 }} />
            ) : (
              <>
                {top5?.length > 0 && !query && !isLoading && (
                  <div
                    className={classnames({
                      [classes.filterDropdown__options_maxCountOptions_separator]: showEntityList,
                    })}
                  >
                    <Typography weight="medium" color="secondary" size={12} className={classes.topResultText}>
                      {t(`${TP}.FN_BY_MOST_NUMBER`)}
                    </Typography>
                    <RenderOptions
                      selectedFilterType={selectedFilterType}
                      selectedGroup={activeGroup}
                      options={top5}
                      aggregationType={aggregationType}
                      fetchNestedOptionsFn={fetchNestedOptionsFn}
                      onChange={onOptionSelectedHandler}
                      selectedOptions={selectedOptions}
                      trackingData={{ ...trackingData, category: CATEGORIES.RECOMMENDED }}
                      {...restProps}
                    />
                  </div>
                )}
                {showEntityList && (
                  <div>
                    <Typography weight="bold" size={12} className={classes.allResultsText}>
                      {t(`${TP}.FN_ALL_TYPE`, { type: t(PLACEHOLDERS[selectedFilterType]?.MULTIPLE) })}
                    </Typography>
                    <RenderOptions
                      selectedFilterType={selectedFilterType}
                      selectedGroup={activeGroup}
                      options={entityList}
                      aggregationType={aggregationType}
                      onChange={onOptionSelectedHandler}
                      selectedOptions={selectedOptions}
                      fetchNestedOptionsFn={fetchNestedOptionsFn}
                      trackingData={{
                        ...trackingData,
                        category: query ? CATEGORIES.SEARCH : undefined,
                        searchQuery: query,
                      }}
                      {...restProps}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <LockedFilters {...filterState} onAccessArchives={onAccessArchives} />

      <FilterFooter
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        selectedOptionsTotal={selectedOptionsTotal}
        onUpdateFilters={onUpdateFilters}
        hasFilterChanged={hasFilterChanged}
        trackingData={trackingData}
      />
    </div>
  );
};

export default FilterOptions;
