/* eslint-disable import/no-cycle */
import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Tooltip from 'components/uiLibrary/Tooltip';
import Typography from 'components/uiLibrary/Typography';
import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';
import { FingerprintUsageReport } from 'pages/v4/analytics';
import { DEPRECATED_ENTITY_TYPES, ENTITY_TYPES } from 'operabase-router/constants';
import classes from './TrackingTester.module.scss';

const TrackingContext = createContext({
  showTrackedClicks: false,
});
export const useTrackingTester = () => useContext(TrackingContext);

export const useTrackingClickStyles = (trackingData, skipTracking) => {
  const { showTrackedClicks } = useTrackingTester();
  const hasMissingTracking = showTrackedClicks && !skipTracking && !trackingData?.section;
  const hasTracking = showTrackedClicks && trackingData?.section;
  // Skipped Tracking: (showTrackedClicks && skipTracking)

  if (hasMissingTracking) {
    return 'show_tracked__click-error'; // Classnames is in styles/styles.scss
  }
  if (hasTracking) {
    return 'show_tracked__click-success';
  }
  return '';
};

const entityTypes = [
  ENTITY_TYPES.PROFILE,
  ENTITY_TYPES.ORGANIZATION,
  ENTITY_TYPES.PRODUCTION,
  ENTITY_TYPES.WORK,
  DEPRECATED_ENTITY_TYPES.PERFORMANCE,
  ENTITY_TYPES.AGENCY,
];

const SectionBlock = ({ title, children }) => (
  <div className={classes.section}>
    <Typography className={classes.section_title} variant="p" size="14" weight="bold">
      {title}
    </Typography>
    {children}
  </div>
);

const TrackingTester = ({ children }) => {
  const { permissions } = usePageContext();
  const { appUsageData } = useAppContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeEntities, setActiveEntities] = useState(
    entityTypes.reduce((acc, type) => ({ ...acc, [type]: false }), {}),
  );
  const [showTrackedClicks, setShowTrackedClicks] = useState(false);

  useEffect(() => {
    entityTypes.forEach(type => {
      const className = `show_tracked__${type}`;
      if (activeEntities[type]) {
        document.body.classList.add(className);
      } else {
        document.body.classList.remove(className);
      }
    });
  }, [activeEntities]);

  const toggleEntity = type => {
    setActiveEntities(prev => ({ ...prev, [type]: !prev[type] }));
  };

  const toggleTracking = () => setShowTrackedClicks(!showTrackedClicks);

  const contextValue = useMemo(() => ({ showTrackedClicks }), [showTrackedClicks]);

  if (!permissions?.isAdmin) {
    return children;
  }

  return (
    <TrackingContext.Provider value={contextValue}>
      <div className={classnames(classes.root, { [classes.expanded]: isExpanded })}>
        <Tooltip title="Test Impressions" placement="left">
          <SpriteIcon
            className={classnames({
              [classes.closeIcon]: isExpanded,
            })}
            icon={isExpanded ? 'close' : 'radar_icon'}
            size={32}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </Tooltip>

        {isExpanded && (
          <div className={classes.content}>
            <SectionBlock title="Analytics dashboard connection">
              {typeof window !== 'undefined' && window.analyticsWindow ? 'Connected' : 'Not connected'}
            </SectionBlock>
            <SectionBlock title="Impression Tracking">
              <>
                {entityTypes.map(type => (
                  <div key={type} className={`ob-${type}`}>
                    <label>
                      <input type="checkbox" checked={activeEntities[type]} onChange={() => toggleEntity(type)} />{' '}
                      {type}
                    </label>
                  </div>
                ))}
              </>
            </SectionBlock>
            <SectionBlock title="Click Tracking">
              <label>
                <input type="checkbox" checked={showTrackedClicks} onChange={toggleTracking} /> Verify click tracking
              </label>
            </SectionBlock>
            <SectionBlock>
              <FingerprintUsageReport usage={appUsageData?.usage} thresholds={appUsageData?.thresholds} />
            </SectionBlock>
          </div>
        )}
      </div>
      {children}
    </TrackingContext.Provider>
  );
};

export default TrackingTester;
