const createServerTimingHeader = (metrics = []) =>
  metrics
    .map(metric => {
      const parts = [metric.name]; // Start with the metric name

      // Add description if it exists
      if (metric.description) {
        parts.push(`desc="${metric.description}"`);
      }

      // Add duration if it exists
      if (metric.duration) {
        parts.push(`dur=${metric.duration}`);
      }

      return parts.join('; ');
    })
    .join(', ');

const parseServerTimingHeader = (header = '') => {
  const metrics = header.split(','); // Split the header into individual metrics
  const parsedMetrics = metrics.map(metric => {
    const parts = metric.trim().split(';'); // Split each metric into its parts
    const result = { name: `api-${parts[0].trim()}` };

    // Parse the description and duration if available
    parts.slice(1).forEach(part => {
      const [key, value] = part.trim().split('=');
      if (key === 'desc') {
        result.description = `API ${value?.replace(/"/g, '')}`;
      } else if (key === 'dur') {
        result.duration = parseFloat(value);
      }
    });

    return result;
  });

  return parsedMetrics;
};

module.exports = {
  parseServerTimingHeader,
  createServerTimingHeader,
};
