import uniqBy from 'lodash/uniqBy';
import { getList, getWholeList } from 'utils/API';
import { transformEntity } from 'utils/globals';
import { deleteQuery, getQuery, postQuery, updateQuery } from 'utils/globals/queries';
import { separateExcludedIncludedCountries } from 'utils/globals/app';

import { ENTITY_TYPES } from 'operabase-router/constants';

const ENDPOINTS = {
  STATIC: {
    AGENCIES: 'agencies',
    OFFICES: 'offices',
    AGREEMENTS: 'agreements',
    AGENCY_ARTISTS: 'agencyArtists',
  },
  DYNAMIC: {
    AGENCY_CONNECTIONS: ({ id, connectionId }) =>
      `agencies${id ? `/${id}` : ''}/connections${connectionId ? `/${connectionId}` : ''}`,
    AGENCY_ACTIONS: ({ id }) => `agencies/${id}/actions`,
  },
};

const fetchAgencyArtists = ({ agencyId }, cookies) => {
  const params = {
    queryParams: {
      agency_id: agencyId,
      limit: 1000,
      page: 1,
    },
  };

  return getList(ENDPOINTS.STATIC.AGENCY_ARTISTS, params, cookies).then(response => response.data);
};

const fetchManagers = ({ filters = {}, limit = 12, page = 1 }, cookies) => {
  const params = {
    queryParams: {
      ...filters,
    },
    pagination: {
      limit,
      page,
    },
  };

  return getList(ENDPOINTS.STATIC.AGENCIES, params, cookies).then(response => response.data);
};

const fetchManager = ({ id, asEdit = false }, cookies) => {
  const filters = {
    id,
    view_created_entity: asEdit,
  };

  return fetchManagers({ filters, limit: 1, page: 1 }, cookies).then(response =>
    transformEntity(response?.data?.[0], ENTITY_TYPES.AGENCY),
  );
};

const fetchManagerConnections = ({ id, filters }, cookies) =>
  getWholeList(ENDPOINTS.DYNAMIC.AGENCY_CONNECTIONS({ id }), { queryParams: filters }, cookies).then(
    response => response.data,
  );

const fetchManagerOffices = async ({ id }, cookies) =>
  getList(ENDPOINTS.STATIC.OFFICES, { queryParams: { agency_id: id } }, cookies).then(response => response.data);

const fetchConnectionsAgreements = async ({ ids }, cookies) => {
  if (!ids?.length) {
    return null;
  }

  return getWholeList(ENDPOINTS.STATIC.AGREEMENTS, { queryParams: { agency_connection_id: ids } }, cookies).then(
    response => {
      const agreements = response.data?.data;

      const groupedAgreements = agreements?.reduce((acc, agreement) => {
        const { connection, agencyConnectionProfession, countries, regions, ...restAgreement } = agreement;

        if (!acc[connection.id]) {
          acc[connection.id] = {};
        }

        const groupName = agencyConnectionProfession?.id || 'default';

        if (!acc[connection.id][groupName]) {
          acc[connection.id][groupName] = [];
        }

        const countryList = countries?.map(country => ({ ...country, type: 'country' })) || [];
        const regionList = regions?.map(region => ({ ...region, type: 'region' })) || [];
        const { included, excluded } = separateExcludedIncludedCountries([...countryList, ...regionList]);

        acc[connection.id][groupName].push({
          ...restAgreement,
          includedCountries: included,
          excludedCountries: excluded,
        });

        return acc;
      }, {});

      return {
        ...response.data,
        data: groupedAgreements,
      };
    },
  );
};

const fetchEntityManagerRepresentations = async ({ entityType, entityId, filters }, cookies) => {
  const { agencyId, connectionId, ...restFilters } = filters || {};
  const ENTITY_TYPE_KEY_MAP = {
    [ENTITY_TYPES.PROFILE]: 'artist_profile_id',
    [ENTITY_TYPES.ORGANIZATION]: 'organization_id',
  };

  const identifierKey = ENTITY_TYPE_KEY_MAP[entityType];

  if (!identifierKey && !entityId && !agencyId) {
    return [];
  }

  const params = {
    queryParams: {
      [identifierKey]: entityId,
      ...restFilters,
    },
    pagination: {
      limit: 1000,
      page: 1,
    },
  };

  const agencyConnections = await getList(
    ENDPOINTS.DYNAMIC.AGENCY_CONNECTIONS({ id: agencyId, connectionId }),
    params,
    cookies,
  ).then(response => {
    if (connectionId) {
      return {
        ...response,
        data: [response.data],
      };
    }

    return response.data;
  });
  const agencyConnectionsIds = agencyConnections?.data?.map(({ id }) => id);

  const connectionAgreements = await fetchConnectionsAgreements({ ids: agencyConnectionsIds }, cookies).then(
    response => response.data,
  );

  return {
    ...agencyConnections,
    data: agencyConnections?.data?.map(connection => {
      const { countries: _countries, regions: _regions, initiator: _initiator, ...restConnection } = connection;

      return {
        ...restConnection,
        agencyConnectionProfessions: connection?.agencyConnectionProfessions?.map(connectionProfession => {
          const agreements = connectionAgreements?.[connection.id]?.[connectionProfession.id] || [];
          const contactPersons = uniqBy(
            agreements?.reduce((acc, agreement) => {
              if (agreement?.contactPersons?.length) {
                acc.push(...agreement.contactPersons);
              }

              return acc;
            }, []),
            'id',
          );

          return {
            ...connectionProfession,
            agreements,
            contactPersons,
          };
        }),
        agreements: connectionAgreements?.[connection.id]?.default || [],
      };
    }),
  };
};

const queries = {
  getAgencyArtists: getQuery('GET_AGENCY_ARTISTS', fetchAgencyArtists),
  getManagers: getQuery('GET_MANAGERS', fetchManagers),
  getManagerDetails: getQuery('GET_MANAGER_DETAILS', fetchManager),
  getManagerConnections: getQuery('GET_MANAGER_CONNECTIONS', fetchManagerConnections),
  getManagerOffices: getQuery('GET_MANAGER_OFFICES', fetchManagerOffices),
  getConnectionsAgreements: getQuery('GET_CONNECTIONS_AGREEMENTS', fetchConnectionsAgreements),
  getEntityManagerRepresentations: getQuery('GET_MANAGER_REPRESENTATIONS', fetchEntityManagerRepresentations),
  addAgency: postQuery(ENDPOINTS.STATIC.AGENCIES),
  addEntityToRoster: postQuery(ENDPOINTS.DYNAMIC.AGENCY_CONNECTIONS),
  updateEntityInRoster: updateQuery(ENDPOINTS.DYNAMIC.AGENCY_CONNECTIONS),
  deleteEntityFromRoster: deleteQuery(ENDPOINTS.DYNAMIC.AGENCY_CONNECTIONS),
  createAgreements: postQuery(ENDPOINTS.STATIC.AGREEMENTS),
  createAgencyActions: postQuery(ENDPOINTS.DYNAMIC.AGENCY_ACTIONS),
};

export default queries;
