const TimedProcess = require('./TimedProcess');

class ServerTiming {
  #processMap = {};

  #context = null;

  constructor(timers = [], context) {
    this.#processMap = [];

    if (!Array.isArray(timers)) {
      throw new Error('ServerTimingContext: array argument expected');
    }

    timers.forEach(({ name, description }) => {
      if (!name) {
        throw new Error('ServerTimingContext: name is required');
      }

      const newTimedProcess = new TimedProcess({ name, description });

      this.#processMap[name] = newTimedProcess;
    });

    if (context) {
      this.#context = context;
    }
  }

  #getProcess(name) {
    const process = this.#processMap[name];

    if (!process) {
      throw new Error(`ServerTimingContext: process "${name}" not found`);
    }

    return process;
  }

  start(name) {
    const process = this.#getProcess(name);
    process.start();
  }

  end(name) {
    const process = this.#getProcess(name);
    process.end();
  }

  duration(name) {
    const process = this.#getProcess(name);
    return process.duration();
  }

  wrap(fn, name) {
    const process = this.#getProcess(name);

    return (...args) => {
      process.start();
      const result = fn.apply(this.#context, args);
      process.end();

      return result;
    };
  }

  asyncWrap(fn, name) {
    const process = this.#getProcess(name);

    return async (...args) => {
      process.start();
      const result = await fn.apply(this.#context, args);
      process.end();

      return result;
    };
  }

  timing() {
    return Object.values(this.#processMap).map(process => process.info());
  }
}

module.exports = ServerTiming;
