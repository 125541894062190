/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useCallback } from 'react';
import _isEmpty from 'lodash-es/isEmpty';
import _isUndefined from 'lodash-es/isUndefined';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useTracking from 'components/Globals/Analytics';

import Typography from 'components/uiLibrary/Typography';

import classes from './Accordion.module.scss';

const Accordion = ({
  title,
  children,
  isDefaultExpanded = false,
  isExpanded,
  arrowPosition = 'start',
  typographyProps = {},
  onToggle,
  isDisabled = false,
  trackingData,
  styles = {
    accordion: '',
    title: '',
    arrow: '',
    item: '',
    collapsedItem: '',
  },
}) => {
  const [isOpen, setOpen] = useState(isDefaultExpanded || isExpanded || false);
  const track = useTracking();

  useEffect(() => {
    if (!_isUndefined(isExpanded)) {
      setOpen(isExpanded);
    }
  }, [isExpanded]);

  const trackClick = isAccordianOpen => {
    if (!_isEmpty(trackingData)) {
      track.click({ ...trackingData, meta: { ...trackingData.meta, isOpen: isAccordianOpen } });
    }
  };

  const onToggleHandler = useCallback(() => {
    if (isDisabled) {
      return;
    }

    if (isExpanded === undefined) {
      setOpen(prevState => {
        const newState = !prevState;

        if (onToggle) {
          onToggle(newState);
        }

        trackClick(newState);
        return newState;
      });
    } else if (onToggle) {
      onToggle(!isExpanded);
      trackClick(!isExpanded);
    }
  }, [isExpanded, onToggle, isDisabled]);

  return (
    <div className={classnames(classes.accordion, styles.accordion, { [classes.isDisabled]: isDisabled })}>
      <div
        className={classnames(classes.accordion__title, styles.title, {
          [classes.accordion__title__end]: !isDisabled && arrowPosition === 'end',
        })}
        onClick={onToggleHandler}
      >
        {!isDisabled && (
          <KeyboardArrowUpIcon
            fontSize="small"
            className={classnames(classes.accordion__title_icon, styles.arrow, { [classes.collapsed]: !isOpen })}
          />
        )}
        <Typography
          variant="h2"
          size="20"
          type="medium"
          {...typographyProps}
          {...(isOpen && typographyProps?.expandedColor && { color: typographyProps.expandedColor })}
        >
          {title}
        </Typography>
      </div>
      <div
        className={classnames(classes.accordion__item, styles.item, {
          [classes.collapsed]: !isOpen,
          [styles.collapsedItem]: !isOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Accordion;
