/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import classnames from 'classnames';
import useMediaQuery from '@mui/material/useMediaQuery';
import queries from 'containers/Productions/queries';
import omit from 'lodash/omit';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import { useTranslation } from 'src/i18n';

import { FILTER_SLUG_TYPE } from 'constants/filters';
import { FILTER_TYPES } from 'operabase-router/constants';
import { partialDateConversion } from 'utils/common';
import { useQuery } from 'utils/react-query';
import { TP, PROFESSION_IDS, CONTRIBUTORS, URL_SLUG_TYPES, URL_STATIC_PATHS } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';
import classes from './FilterGroupButton.module.scss';

const VARIANT = {
  DETAILED: 'button_group_detailed',
};

const FILTER_TEXTS = {
  GENRE: `${TP}.FN_GENRE_STAGING`,
  MUSICAL_WORK_AND_COMPOSER: `${TP}.FN_MW_AND_COMPOSER`,
  SINCE: `${TP}.FN_FILTER_SINCE`,
  ORGANIZATION: `${TP}.FN_PRODUCER_FILTER`,
  DIRECTOR: `${TP}.FN_DIRECTOR`,
  CONDUCTOR: `${TP}.m_CONDUCTOR`,
  INSTRUMENTALIST: `${TP}.FN_INDUSTRY_PRO_INSTRUMENTALISTS_LABEL`,
  GENRE_MW_COMPOSER: `${TP}.FN_GENRE_MW_COMPOSER`,
  REST_ALL_FILTERS: `${TP}.FN_REST_ALL_FILTERS`,
  ALL_CAST_CREW: `${TP}.FN_ALL_CAST_CREW`,
  ROLE_AND_PROFESSION: `${TP}.ROLE_PROFESSIONS_FILTER`,
};

const useGetDefaultFilterGroups = ({ enabled, facetsApiFilters, appliedFilters, types, layout }) => {
  /**
   * NOTE: please do not modify below both the query as both of them are being used in side filter facets,
   * Only modify if you are sure about the changes and want to do it explicitly
   */
  const { data: stats, isFetching: isStatsLoading } = useQuery(
    queries.getFilterCounts({
      types,
      filters: facetsApiFilters,
      extraQueryKeys: [types],
      queryConfig: {
        enabled: enabled && types?.length > 0,
        keepPreviousData: true,
      },
    }),
  );

  const { data: contributionTypes, isFetching: isContributionTypesLoading } = useQuery(
    queries.getContributionExpressionFilters({
      filters: {
        ...omit(appliedFilters, [
          FILTER_SLUG_TYPE[FILTER_TYPES.CONTRIBUTION_EXPRESSION],
          FILTER_SLUG_TYPE[FILTER_TYPES.PROFESSION],
        ]),
      },
    }),
  );

  const {
    isDirectorFilterPresent,
    isConductorFilterPresent,
    isInstrumentalistFilterPresent,
    contributionTypesFilterPresent = {},
    isRoleProfessionPresent = false,
  } = useMemo(() => {
    if (isContributionTypesLoading || !contributionTypes || isStatsLoading || !stats) {
      return { isDirectorFilterPresent: false, isConductorFilterPresent: false, isInstrumentalistFilterPresent: false };
    }

    const allContributionTypes = contributionTypes?.flat();
    return {
      isMusicalWorkFilterPresent: stats?.[FILTER_TYPES.WORK] > 0,
      // NOTE: we need to show the role and profession filter in case of role repertoire and scroll to the top of page
      isRoleProfessionPresent: stats?.[FILTER_TYPES.ROLE_PROFESSION] > 0,
      ...allContributionTypes?.reduce(
        (acc, currItem) => {
          if (currItem?.type === FILTER_SLUG_TYPE[FILTER_TYPES.PROFESSION]) {
            if (currItem?.id === PROFESSION_IDS.STAGE_DIRECTOR) {
              acc.isDirectorFilterPresent = true;
            }
            if (currItem?.id === PROFESSION_IDS.CONDUCTOR) {
              acc.isConductorFilterPresent = true;
            }
            if (currItem?.parentProfession?.id === PROFESSION_IDS.INSTRUMENTATION) {
              acc.isInstrumentalistFilterPresent = true;
            }
          }
          if (CONTRIBUTORS.PRODUCER === currItem?.id) {
            acc.contributionTypesFilterPresent.producer = currItem;
          }
          if (CONTRIBUTORS.VENUE === currItem?.id) {
            acc.contributionTypesFilterPresent.venue = currItem;
          }
          return acc;
        },
        {
          isDirectorFilterPresent: false,
          isConductorFilterPresent: false,
          isInstrumentalistFilterPresent: false,
          contributionTypesFilterPresent: {},
        },
      ),
    };
  }, [contributionTypes, isContributionTypesLoading, isStatsLoading, stats]);

  const filterParam = {
    [VARIANT.DETAILED]: [
      {
        name: FILTER_TEXTS.GENRE,
        filterTypes: [FILTER_TYPES.GENRE_STAGING],
        isDisabled: !stats?.[FILTER_TYPES.GENRE_STAGING],
      },
      {
        name: FILTER_TEXTS.ROLE_AND_PROFESSION,
        filterTypes: [FILTER_TYPES.ROLE_PROFESSION],
        isDisabled: !isRoleProfessionPresent,
      },
      {
        name: FILTER_TEXTS.SINCE,
        filterTypes: [FILTER_TYPES.SINCE_YEAR],
        isDisabled: !stats?.[FILTER_TYPES.SINCE_YEAR],
      },
      {
        name: FILTER_TEXTS.ORGANIZATION,
        filterTypes: [FILTER_TYPES.CONTRIBUTION_EXPRESSION],
        subFilterTypes: [URL_SLUG_TYPES.CONTRIBUTION_TYPE],
        ids:
          Object.keys(contributionTypesFilterPresent)?.length > 0
            ? [contributionTypesFilterPresent?.producer?.id || contributionTypesFilterPresent?.venue?.id]
            : null,
        isDisabled: !Object.keys(contributionTypesFilterPresent)?.length,
      },
      {
        name: FILTER_TEXTS.DIRECTOR,
        filterTypes: [FILTER_TYPES.CONTRIBUTION_EXPRESSION],
        ids: [PROFESSION_IDS.STAGE_DIRECTOR],
        subFilterTypes: [FILTER_SLUG_TYPE[FILTER_TYPES.PROFESSION]], // NOTE: we need this to scroll to the correct filter in quick view filter
        isDisabled: !isDirectorFilterPresent,
      },
      {
        name: FILTER_TEXTS.CONDUCTOR,
        filterTypes: [FILTER_TYPES.CONTRIBUTION_EXPRESSION],
        ids: [PROFESSION_IDS.CONDUCTOR],
        subFilterTypes: [FILTER_SLUG_TYPE[FILTER_TYPES.PROFESSION]], // NOTE: we need this to scroll to the correct filter in quick view filter
        isDisabled: !isConductorFilterPresent,
      },
      {
        name: FILTER_TEXTS.INSTRUMENTALIST,
        filterTypes: [FILTER_TYPES.CONTRIBUTION_EXPRESSION],
        ids: [PROFESSION_IDS.INSTRUMENTATION],
        subFilterTypes: [FILTER_SLUG_TYPE[FILTER_TYPES.PROFESSION]], // NOTE: we need this to scroll to the correct filter in quick view filter
        isDisabled: !isInstrumentalistFilterPresent,
      },
    ],
  };
  return filterParam[layout];
};

const FilterGroupButton = ({
  types,
  facetsApiFilters,
  appliedFilters,
  onExpandFilter,
  layout,
  customFilters = null,
  customGroups = null,
  styles = {},
  ignoreSmallScreenGroup = false,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { filters: defaultFilters, entity, urlSlugs, appliedFilterSlugs, paths, navigate } = usePageContext();
  const { isMobile } = useDeviceTypeLayouts();
  const isSmallScreen = useMediaQuery('(max-width:800px)');
  const isTablet = useMediaQuery('(max-width:1200px)');
  const defaultFilterGroups = useGetDefaultFilterGroups({
    enabled: true,
    facetsApiFilters,
    appliedFilters,
    types,
    layout,
  });

  const filters = customFilters || defaultFilters;
  const isCastingProfile = urlSlugs?.[0]?.entityType === ENTITY_TYPES.PROFILE;
  const groups = useMemo(() => customGroups[layout] || defaultFilterGroups, [
    layout,
    customGroups,
    defaultFilterGroups,
  ]);

  const filtersList = useMemo(() => {
    if (!groups) {
      return [];
    }

    const contributionExpFilters = appliedFilterSlugs
      ?.find(filter => filter?.type === FILTER_SLUG_TYPE[FILTER_TYPES.CONTRIBUTION_EXPRESSION])
      ?.items?.reduce((acc, curr) => {
        // NOTE: as in case of multiple instrumentalist the name will be coming as Piano:Instrumentalist
        let key = curr.metadata?.id;
        if (curr.metadata?.name?.split(':')?.[1]?.toLowerCase() === CONTRIBUTORS.INSTRUMENTALIST) {
          key = PROFESSION_IDS.INSTRUMENTATION;
        }

        if ([PROFESSION_IDS.CONDUCTOR, PROFESSION_IDS.INSTRUMENTATION, PROFESSION_IDS.STAGE_DIRECTOR].includes(key)) {
          acc[key] = (acc[key] || 0) + curr?.metadata?.contributors?.inclusions?.length;
        }
        return acc;
      }, {});

    return groups.map(group => {
      const count = group.filterTypes.reduce((acc, filterType) => {
        if (filterType === FILTER_TYPES.CONTRIBUTION_EXPRESSION) {
          return acc + (contributionExpFilters?.[group?.id] || 0);
        }
        return acc + (filters?.[FILTER_SLUG_TYPE[filterType]]?.length || 0);
      }, 0);

      return {
        ...group,
        count,
        isDisabled: !!group?.isDisabled,
      };
    });
  }, [appliedFilterSlugs, groups, isCastingProfile, filters]);

  const handleOnClick = (e, filter) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (filter.isDisabled) return;

    const type = filter.filterTypes?.[0];
    const filterId = filter?.ids?.[0];

    if (type === FILTER_TYPES.ROLE_PROFESSION && paths?.[0] === URL_STATIC_PATHS.CASTING_ROLE_REPERTOIRE) {
      navigate.scrollTo();
      return;
    }

    onExpandFilter({
      type,
      ...(filterId && {
        selectedSubFilterType: { scrollTo: filterId, type: filter?.subFilterTypes?.[0] },
      }),
    });
  };

  const createItems = () => {
    const combinedGroups = (start, end) =>
      filtersList.slice(start, end).reduce(
        (acc, curr) => {
          if (!curr?.isDisabled) {
            acc.isDisabled = false;
            if (curr?.filterTypes?.length) {
              acc.filterTypes.push(...curr?.filterTypes);
            }
            if (curr?.ids?.length) {
              acc.ids.push(...curr?.ids);
            }
            if (curr?.subFilterTypes?.length) {
              acc.subFilterTypes.push(...curr?.subFilterTypes);
            }
          }
          return acc;
        },
        { filterTypes: [], ids: [], subFilterTypes: [], isDisabled: true },
      );

    if (isMobile) {
      return [
        { name: FILTER_TEXTS.GENRE_MW_COMPOSER, ...combinedGroups(0, 2) },
        { name: FILTER_TEXTS.REST_ALL_FILTERS, ...combinedGroups(2) },
      ];
    }

    if (isSmallScreen && !ignoreSmallScreenGroup) {
      return [
        { name: FILTER_TEXTS.GENRE_MW_COMPOSER, ...combinedGroups(0, 2) },
        { name: FILTER_TEXTS.SINCE, filterTypes: [], isDisabled: true },
        { name: FILTER_TEXTS.ALL_CAST_CREW, ...combinedGroups(0, 2) },
      ];
    }

    if (isTablet && !Object.keys(customGroups).length) {
      return [...filtersList.slice(0, 4), { name: FILTER_TEXTS.ALL_CAST_CREW, ...combinedGroups(4) }];
    }

    return filtersList;
  };

  const items = useMemo(createItems, [customGroups, filtersList, isMobile, isSmallScreen, isTablet]);

  const sinceYear =
    partialDateConversion({ year: entity?.stats?.performanceSince }) || defaultFilters?.since_year?.[0]?.slug;
  return (
    <div
      className={classnames(classes.filterContainer, styles?.root, {
        [classes.filterContainerDetailed]: layout === VARIANT.DETAILED,
      })}
    >
      {items?.map((filter, index) => (
        <div
          key={index}
          className={classnames(classes.filterContainer__box, {
            [styles?.filterGroupBox]: !!styles?.filterGroupBox,
            [classes.disableBtn]: filter?.isDisabled,
          })}
          onClick={e => handleOnClick(e, filter)}
        >
          <Typography size={12} className={classes.truncateTwoLines}>
            {t(filter?.name)} {filter.name === FILTER_TEXTS.SINCE && sinceYear}
          </Typography>
          {filter?.count >= 1 && (
            <Typography size={12} className={classes.count}>
              {filter?.count}
            </Typography>
          )}
          {!filter?.isDisabled && (
            <SpriteIcon className={classes.filterContainer__box_icon} icon="filter_list" size={12} />
          )}
        </div>
      ))}
    </div>
  );
};

export default FilterGroupButton;
