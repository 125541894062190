const { getHttpInstance } = require('../serverHttpInstance');
const auth = require('../auth');
const { parseServerTimingHeader } = require('./serverTimingHeader');

const api = async (path, { method = 'GET', params, timeout = 1000, session = null } = {}) => {
  const token = auth.getToken({ session });
  const headers = {
    'Content-Type': 'application/json',
    ...(token && { authorization: `Bearer ${auth.getToken({ session })}` }),
  };

  const url =
    typeof window === 'undefined'
      ? `${process.env.FUSION_API_URL}/${path}`
      : `${process.env.FRONTBASE_URL}/api/${path}`;

  try {
    const instance = await getHttpInstance(new URL(url).origin);

    const request = {
      method,
      headers,
      url,
      params,
      timeout,
    };

    const response = await instance.request(request);

    if (!response.data) {
      return response.data;
    }

    const serverTiming = parseServerTimingHeader(response?.headers?.['server-timing']);

    return {
      ...response.data,
      serverTiming,
    };
  } catch (err) {
    console.error(err);

    if (err?.response?.status === 404) {
      return null;
    }

    throw err;
  }
};

const serverAPI = {
  get: async (path, params, timeout, session) => api(path, { params, timeout, session, method: 'GET' }),
};

module.exports = serverAPI;
