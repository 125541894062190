const { pickBy } = require('lodash');
const dayjs = require('../../server/utils/dayjs');
const safeDecodeURL = require('../../server/utils/safeDecodeURL');
const {
  ENTITY_TYPES,
  ROUTE_DATE_FORMATS,
  REGULAR_EXPRESSIONS,
  ID_PREFIX_SKIPPED_FILTER_TYPES,
} = require('../constants');
const { SPECIAL_KEYWORD_CONTEXT_PARAMS } = require('../constants/configurations');

const shouldSkipIDPrefix = filterType => ID_PREFIX_SKIPPED_FILTER_TYPES.includes(filterType);

const transformDatePath = path => {
  const pathParts = path.split('-') || [];

  if (pathParts.length > 3) {
    return {
      valid: false,
    };
  }

  const [yearString, monthString, dateString] = pathParts.reverse();

  if (
    !REGULAR_EXPRESSIONS.YEAR_MATCH.test(yearString) ||
    (dateString && !REGULAR_EXPRESSIONS.DATE_MATCH.test(dateString)) ||
    (monthString && !REGULAR_EXPRESSIONS.MONTH_MATCH.test(monthString))
  ) {
    return {
      valid: false,
    };
  }

  const capitalisedDatePath = path
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-');

  const dateMonthYear = dayjs(capitalisedDatePath, ROUTE_DATE_FORMATS.DATE_MONTH_YEAR, true);

  if (dateMonthYear.isValid()) {
    return {
      valid: true,
      start: dateMonthYear.startOf('day'),
      end: dateMonthYear.endOf('day'),
    };
  }

  const monthYear = dayjs(capitalisedDatePath, ROUTE_DATE_FORMATS.MONTH_YEAR, true);

  if (monthYear.isValid()) {
    return {
      valid: true,
      start: monthYear.startOf('month'),
      end: monthYear.endOf('month'),
    };
  }

  const year = dayjs(capitalisedDatePath, ROUTE_DATE_FORMATS.YEAR, true);

  if (year.isValid()) {
    return {
      valid: true,
      start: year.startOf('year'),
      end: year.endOf('year'),
    };
  }

  return {
    valid: false,
  };
};

const transformDateToPath = ({ dateFrom, dateTo, path }) => {
  const dateFromObj = dayjs(dateFrom, ROUTE_DATE_FORMATS.STANDARD, true);
  const dateToObj = dayjs(dateTo, ROUTE_DATE_FORMATS.STANDARD, true);

  const today = dayjs().startOf('day');

  if (dateFrom && !dateTo && dateFromObj.isValid()) {
    if (path) {
      const startOfDay = dateFromObj.startOf('day');
      const shiftStartDaysFromToday = startOfDay.diff(today, 'days');

      const validSpecialTerms = Object.keys(
        pickBy(SPECIAL_KEYWORD_CONTEXT_PARAMS, value => {
          const { type, shiftDateEnd, shiftDateStart } = value;

          if (type === ENTITY_TYPES.DATE) {
            return shiftStartDaysFromToday === shiftDateStart && !shiftDateEnd && shiftDateEnd !== 0;
          }

          return false;
        }),
      );

      if (validSpecialTerms.includes(path)) {
        return {
          valid: true,
          path,
        };
      }
    }

    return {
      valid: true,
      queryParams: {
        date_from: dateFromObj.format(ROUTE_DATE_FORMATS.STANDARD),
      },
    };
  }

  if (dateTo && !dateFrom && dateToObj.isValid()) {
    if (path) {
      const endOfDay = dateToObj.endOf('day');
      const shiftEndDaysFromToday = endOfDay.diff(today, 'days');

      const validSpecialTerms = Object.keys(
        pickBy(SPECIAL_KEYWORD_CONTEXT_PARAMS, value => {
          const { type, shiftDateEnd, shiftDateStart } = value;

          if (type === ENTITY_TYPES.DATE) {
            return shiftEndDaysFromToday === shiftDateEnd && !shiftDateStart && shiftDateStart !== 0;
          }

          return false;
        }),
      );

      if (validSpecialTerms.includes(path)) {
        return {
          valid: true,
          path,
        };
      }
    }

    return {
      valid: true,
      queryParams: {
        date_to: dateToObj.format(ROUTE_DATE_FORMATS.STANDARD),
      },
    };
  }

  if (!dateFromObj.isValid() || !dateToObj.isValid()) {
    return {
      valid: false,
    };
  }

  const startOfYear = dateFromObj.startOf('year');
  const endOfYear = dateToObj.endOf('year');

  const isFromStartOfYear = dateFromObj.isSame(startOfYear, 'day');
  const isToEndOfYear = dateToObj.isSame(endOfYear, 'day');

  if (isFromStartOfYear && isToEndOfYear && dateFromObj.isSame(dateToObj, 'year')) {
    return {
      valid: true,
      path: `${startOfYear.format(ROUTE_DATE_FORMATS.YEAR)}`.toLowerCase(),
    };
  }

  const startOfMonth = dateFromObj.startOf('month');
  const endOfMonth = dateToObj.endOf('month');

  const isFromStartOfMonth = dateFromObj.isSame(startOfMonth, 'day');
  const isToEndOfMonth = dateToObj.isSame(endOfMonth, 'day');

  if (
    isFromStartOfMonth &&
    isToEndOfMonth &&
    dateFromObj.isSame(dateToObj, 'year') &&
    dateFromObj.isSame(dateToObj, 'month')
  ) {
    let datePath = `${startOfMonth.format(ROUTE_DATE_FORMATS.MONTH_YEAR)}`.toLowerCase();

    if (path) {
      const shiftStartDaysFromToday = startOfMonth.diff(today, 'days');
      const shiftEndDaysFromToday = endOfMonth.diff(today, 'days');

      const validSpecialTerms = Object.keys(
        pickBy(SPECIAL_KEYWORD_CONTEXT_PARAMS, value => {
          const { type, shiftDateEnd, shiftDateStart } = value;

          if (type === ENTITY_TYPES.DATE) {
            return shiftStartDaysFromToday === shiftDateStart && shiftEndDaysFromToday === shiftDateEnd;
          }

          return false;
        }),
      );

      if (validSpecialTerms.includes(path)) {
        datePath = path;
      }
    }

    return {
      valid: true,
      path: datePath,
    };
  }

  const startOfDay = dateFromObj.startOf('day');
  const endOfDay = dateToObj.endOf('day');

  const shiftStartDaysFromToday = startOfDay.diff(today, 'days');
  const shiftEndDaysFromToday = endOfDay.diff(today, 'days');

  const validSpecialTerms = Object.keys(
    pickBy(SPECIAL_KEYWORD_CONTEXT_PARAMS, value => {
      const { type, shiftDateEnd, shiftDateStart } = value;

      if (type === ENTITY_TYPES.DATE) {
        return shiftStartDaysFromToday === shiftDateStart && shiftEndDaysFromToday === shiftDateEnd;
      }

      return false;
    }),
  );

  const isFromStartOfDay = dateFromObj.isSame(startOfDay, 'day');
  const isToEndOfDay = dateToObj.isSame(endOfDay, 'day');
  const hasOneMatchingSpecialTerm = validSpecialTerms?.length === 1;

  if (isFromStartOfDay && isToEndOfDay && dateFromObj.isSame(dateToObj, 'day')) {
    const dateFormattedPath = `${startOfDay.format(ROUTE_DATE_FORMATS.DATE_MONTH_YEAR)}`.toLowerCase();
    const dateSpecialTerm = hasOneMatchingSpecialTerm ? validSpecialTerms?.[0] : null;

    let datePath = dateFormattedPath;

    if ((path && validSpecialTerms.includes(path)) || path === dateFormattedPath) {
      datePath = path;
    } else if (hasOneMatchingSpecialTerm) {
      datePath = dateSpecialTerm;
    }

    return {
      valid: true,
      path: datePath,
    };
  }

  if (hasOneMatchingSpecialTerm) {
    return {
      valid: true,
      path: validSpecialTerms?.[0],
    };
  }

  return {
    valid: true,
    queryParams: {
      date_from: dateFromObj.format(ROUTE_DATE_FORMATS.STANDARD),
      date_to: dateToObj.format(ROUTE_DATE_FORMATS.STANDARD),
    },
  };
};

const sanitizeURLPrefixSlash = url => (url?.startsWith('/') ? url : `/${url}`);

const removeLanguageFromUrl = (url = '') => {
  const [pathname, search] = url.split('?');
  const path = pathname.replace(REGULAR_EXPRESSIONS.URL_REPLACE_LANGUAGE_TRAILING_SLASH, '');

  return search ? `${path}?${search}` : path;
};

const sanitizeURL = ({ url }) => {
  const [pathname, query] = url?.split('?');
  const searchParams = new URLSearchParams(query || '');
  searchParams.delete('debug');
  searchParams.delete('context');
  const searchString = searchParams.toString();

  const finalURL = `${pathname}${searchString ? `?${searchString}` : ''}`;

  return safeDecodeURL(sanitizeURLPrefixSlash(removeLanguageFromUrl(finalURL)));
};

const getPagePathnameWithLanguage = (url, language) => {
  const pathname = removeLanguageFromUrl((url || '').split('?')[0]);
  const pageURL = pathname?.startsWith('http')
    ? pathname
    : `${process.env.FRONTBASE_URL}${pathname.startsWith('/') ? '' : '/'}${pathname}`;

  if (!language) {
    return pageURL;
  }

  return `${pageURL}/${language}`;
};

module.exports = {
  transformDatePath,
  transformDateToPath,
  sanitizeURLPrefixSlash,
  removeLanguageFromUrl,
  sanitizeURL,
  getPagePathnameWithLanguage,
  shouldSkipIDPrefix,
};
