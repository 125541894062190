/* eslint-disable no-dupe-class-members */
const dayjs = require('../../server/utils/dayjs');

const {
  ATTRIBUTE_TYPES,
  FILTER_TYPES,
  ENTITY_TYPES,
  ORG_TYPES,
  ROUTE_DATE_FORMATS,
  ROUTE_RESERVED_KEYWORDS,
  ROUTE_PART_TYPE,
  ROUTE_VALIDITY,
} = require('../constants');
const {
  ENTITIES_WITH_PAGE,
  SPECIAL_KEYWORD_CONTEXT_PARAMS,
  INVALID_ROUTE_REASON,
} = require('../constants/configurations');

class RoutePathContext {
  #index = 0;

  #type = ROUTE_PART_TYPE.VARIABLE;

  #configuration = null;

  #path = '';

  #pathFilterType = null;

  #prevPath = '';

  #nextPath = '';

  #basePath = '';

  #isBasePathReserved = false;

  #isParentPathValid = false;

  #isPrevPathReserved = false;

  #isPrevPathAllowedWithFilters = false;

  #prevPathConfiguration = null;

  #baseEntityType = null;

  #parentEntityType = null;

  #matchedEntity = null;

  #matchedMultipleEntities = false;

  #matchesFound = false;

  #validEntitiesForPathWithBasePath = [];

  constructor({ parts, index = 0, baseEntityType, parentEntityType, isParentPathValid, matchedEntity }) {
    this.#index = index;
    this.#baseEntityType = baseEntityType;
    this.#parentEntityType = parentEntityType;
    const { match, found, multiple } = matchedEntity || {};
    this.#matchedEntity = match;
    this.#matchedMultipleEntities = multiple;
    this.#matchesFound = found;

    const currentPart = parts[index];
    const { type, path, filterType, configuration } = currentPart || {};

    this.#type = type;
    this.#path = path;
    this.#pathFilterType = filterType;
    this.#configuration = configuration;

    const prevPart = parts[index - 1];
    this.#prevPath = parts[index - 1]?.path || '';
    this.#prevPathConfiguration = parts[index - 1]?.configuration || {};
    this.#isParentPathValid = isParentPathValid || false;
    this.#isPrevPathAllowedWithFilters = parts[index - 1]?.configuration?.allowedWithFilters || false;
    this.#isPrevPathReserved = prevPart?.type === ROUTE_PART_TYPE.RESERVED;

    this.#nextPath = parts[index + 1]?.path || '';

    const basePart = parts[0];
    this.#basePath = basePart?.path || '';
    this.#validEntitiesForPathWithBasePath = basePart?.configuration?.validEntitiesForPathWithBasePath || [];
    this.#isBasePathReserved = basePart?.type === ROUTE_PART_TYPE.RESERVED;
  }

  #validateReservedPath() {
    const {
      allowedAsBasePath = false,
      allowedAsSubPath = false,
      subPathRequired = false,
      subPathForBasePaths = [],
      subPathForBaseEntities = [],
      subPathForEntities = [],
      subPathForSubPaths = [],
    } = this.#configuration || {};

    if (subPathRequired && !this.#nextPath?.length) {
      return [false, INVALID_ROUTE_REASON.SUB_PATH_MISSING];
    }

    if (this.#index === 0) {
      return [allowedAsBasePath, INVALID_ROUTE_REASON.NOT_ALLOWED_AS_BASE_PATH];
    }

    if (allowedAsSubPath) {
      if (subPathForBaseEntities.includes(this.#baseEntityType)) {
        return [true];
      }

      if (subPathForEntities.includes(this.#parentEntityType)) {
        return [true];
      }

      if (this.#index === 1) {
        return [subPathForBasePaths.includes(this.#basePath), INVALID_ROUTE_REASON.INVALID_SUB_PATH_FOR_BASE_PATH];
      }

      if (this.#index > 1) {
        return [subPathForSubPaths.includes(this.#prevPath), INVALID_ROUTE_REASON.INVALID_SUB_PATH_FOR_SUB_PATH];
      }
    }

    return [false, INVALID_ROUTE_REASON.NOT_ALLOWED_AS_SUB_PATH];
  }

  #checkIfEntityTypeAllowedForPath(entityType) {
    if (!this.#isBasePathReserved) {
      return true;
    }

    return this.#validEntitiesForPathWithBasePath.includes(entityType);
  }

  #hasValidMatchedEntityType() {
    if (this.#matchedEntity?.entityType) {
      const allowedMatchedEntityType = this.#checkIfEntityTypeAllowedForPath(this.#matchedEntity?.entityType);

      if (!allowedMatchedEntityType) {
        return [allowedMatchedEntityType, INVALID_ROUTE_REASON.NOT_ALLOWED_ENTITY_TYPE];
      }

      if ([ENTITY_TYPES.CITY, ENTITY_TYPES.COUNTRY].includes(this.#matchedEntity?.entityType)) {
        const validParentEntityType =
          this.#matchedEntity?.entityType === ENTITY_TYPES.CITY ? ENTITY_TYPES.COUNTRY : ENTITY_TYPES.REGION;

        const shouldSkipParentValdityCheck =
          this.#matchedEntity?.entityType === ENTITY_TYPES.COUNTRY && this.#parentEntityType !== ENTITY_TYPES.REGION;

        if (!shouldSkipParentValdityCheck) {
          const hasValidParent =
            this.#isParentPathValid &&
            this.#matchedEntity?.parents?.some(
              ({ entityType, entity }) => entityType === validParentEntityType && entity?.slug === this.#prevPath,
            );

          return [hasValidParent, INVALID_ROUTE_REASON.INVALID_PARENT_PATH];
        }
      } else if (this.#matchedEntity?.entityType === ENTITY_TYPES.STAGING_TYPE) {
        const hasValidParent = this.#isParentPathValid && this.#parentEntityType === ENTITY_TYPES.WORK_TYPE;

        return [hasValidParent, INVALID_ROUTE_REASON.INVALID_PARENT_PATH];
      }

      return [this.#matchedEntity?.entityType !== ENTITY_TYPES.UNKNOWN, INVALID_ROUTE_REASON.UNKNOWN_ENTITY_TYPE];
    }

    let error = this.#matchesFound
      ? INVALID_ROUTE_REASON.CONFLICTING_MATCHED_ENTITIES
      : INVALID_ROUTE_REASON.NO_MATCHED_ENTITY;

    if (this.#matchedMultipleEntities) {
      error = INVALID_ROUTE_REASON.MULTIPLE_MATCHED_ENTITIES;
    }

    return [false, error];
  }

  #validateVariablePath() {
    const [hasValidMatchedEntityType, reason] = this.#hasValidMatchedEntityType();

    if (hasValidMatchedEntityType) {
      if (this.#index === 0) {
        return [ENTITIES_WITH_PAGE.includes(this.#matchedEntity?.entityType), INVALID_ROUTE_REASON.NO_PAGE_FOR_ENTITY];
      }

      if (this.#isPrevPathReserved) {
        if (this.#isPrevPathAllowedWithFilters) {
          const { validEntitiesForFilters } = this.#prevPathConfiguration || {};

          return [
            validEntitiesForFilters.includes(this.#matchedEntity?.entityType),
            INVALID_ROUTE_REASON.NOT_ALLOWED_WITH_FILTERS,
          ];
        }

        return [false, INVALID_ROUTE_REASON.NOT_ALLOWED_WITH_FILTERS];
      }

      return [true];
    }

    if ([FILTER_TYPES.DATE, FILTER_TYPES.SEASON].includes(this.#pathFilterType)) {
      const allowedMatchedEntityType = this.#checkIfEntityTypeAllowedForPath(this.#configuration?.entityType);

      return [allowedMatchedEntityType, INVALID_ROUTE_REASON.NOT_ALLOWED_ENTITY_TYPE];
    }

    return [false, reason];
  }

  #getDateFilterContext({ start, end }) {
    return {
      filterType: FILTER_TYPES.DATE,
      entityType: ENTITY_TYPES.DATE,
      ...(start && { date_from: start.format(ROUTE_DATE_FORMATS.STANDARD) }),
      ...(end && { date_to: end.format(ROUTE_DATE_FORMATS.STANDARD) }),
    };
  }

  #getSeasonFilterContext({ start }) {
    return {
      filterType: FILTER_TYPES.SEASON,
      entityType: ENTITY_TYPES.SEASON,
      entity: {
        id: start.format(ROUTE_DATE_FORMATS.YEAR),
      },
    };
  }

  #getReservedPathContext() {
    const contextParams = SPECIAL_KEYWORD_CONTEXT_PARAMS[this.#path] || null;

    const { type } = contextParams || {};

    if (type === FILTER_TYPES.DATE) {
      const { shiftDateStart, shiftDateEnd } = contextParams;
      const today = dayjs().startOf('day');
      let start = null;
      let end = null;

      if (typeof shiftDateStart !== 'undefined') {
        start = today.add(shiftDateStart, 'days');
      }

      if (typeof shiftDateEnd !== 'undefined') {
        end = today.add(shiftDateEnd, 'days');
      }

      return this.#getDateFilterContext({ start, end });
    }

    return null;
  }

  #isOrgTypeVenue({ attributes }) {
    return (
      attributes?.[ATTRIBUTE_TYPES.ENTITY]?.find(({ entityType }) => entityType === ENTITY_TYPES.ORGANIZATION_TYPE)
        ?.entity?.slug === ORG_TYPES.VENUE
    );
  }

  #getVariablePathContext() {
    if (this.#pathFilterType === FILTER_TYPES.DATE) {
      return this.#getDateFilterContext(this.#configuration);
    }

    if (this.#pathFilterType === FILTER_TYPES.SEASON) {
      return this.#getSeasonFilterContext(this.#configuration);
    }

    if (!this.#matchedEntity) {
      return null;
    }

    const { entityType, parents, attributes, entity } = this.#matchedEntity || {};

    return {
      ...(this.#pathFilterType && { filterType: this.#pathFilterType }),
      ...(!!entityType && { entityType }),
      ...(!!entity?.id && { entity }),
      ...(parents?.length > 0 && { parents }),
      ...(attributes && {
        attributes,
        ...(this.#isOrgTypeVenue({ attributes }) && { isVenue: true }),
      }),
    };
  }

  #validate() {
    if (this.#type === ROUTE_PART_TYPE.RESERVED) {
      return this.#validateReservedPath();
    }

    return this.#validateVariablePath();
  }

  #getContext() {
    if (this.#type === ROUTE_PART_TYPE.RESERVED) {
      return this.#getReservedPathContext();
    }

    return this.#getVariablePathContext();
  }

  #getFilterTypeKeyValue({ entity, entityType, filterType }) {
    if (filterType) {
      return filterType;
    }

    if (entityType === ENTITY_TYPES.PROFILE) {
      return FILTER_TYPES.WHO;
    }

    if (entityType === ENTITY_TYPES.ORGANIZATION) {
      return FILTER_TYPES.WHERE;
    }

    if ([ENTITY_TYPES.REGION, ENTITY_TYPES.COUNTRY, ENTITY_TYPES.CITY].includes(entityType)) {
      if (
        this.#basePath === ROUTE_RESERVED_KEYWORDS.productions ||
        (this.#basePath === ROUTE_RESERVED_KEYWORDS.casting && !!entity?.id) ||
        ENTITIES_WITH_PAGE.includes(this.#index === 0 ? entityType : this.#baseEntityType)
      ) {
        return FILTER_TYPES.WHERE;
      }

      switch (entityType) {
        case ENTITY_TYPES.REGION:
          return FILTER_TYPES.REGION;
        case ENTITY_TYPES.COUNTRY:
          return FILTER_TYPES.COUNTRY;
        case ENTITY_TYPES.CITY:
          return FILTER_TYPES.CITY;
        default:
          return null;
      }
    }

    if (ENTITY_TYPES.WORK === entityType) {
      return FILTER_TYPES.WHAT;
    }

    if ([ENTITY_TYPES.WORK_TYPE, ENTITY_TYPES.STAGING_TYPE].includes(entityType)) {
      return FILTER_TYPES.GENRE;
    }

    if (entityType === ENTITY_TYPES.ORGANIZATION_TYPE) {
      return FILTER_TYPES.ORGANIZATION_TYPE;
    }

    if (entityType === ENTITY_TYPES.PROFESSION) {
      return FILTER_TYPES.PROFESSION;
    }

    return null;
  }

  context() {
    const [valid, reason] = this.#validate();
    const context = this.#getContext();

    return {
      path: this.#path,
      type: this.#type,
      validity: valid ? ROUTE_VALIDITY.VALID : ROUTE_VALIDITY.INVALID,
      ...(!valid && { reason }),
      ...(context && {
        context,
        filterTypeKey: this.#getFilterTypeKeyValue(context),
      }),
    };
  }
}

module.exports = RoutePathContext;
