import React, { useMemo } from 'react';
import { useQuery } from 'utils/react-query';
import take from 'lodash/take';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import EntityName from 'components/Globals/EntityName';
import SeparatorList, { SEPARATOR_TYPES } from 'components/uiLibrary/SeparatorList';

import {
  TP,
  AGGREGATION_TYPES,
  DATE_FORMATS,
  ENTITY_TYPE,
  BOOLEAN_SEARCH_PARTS,
  BOOLEAN_OPERATOR_LABELS,
  CASTING_TOOL_SLUGS,
} from 'constants/index';
import { FILTER_AGGREGATION_SEARCH, PRODUCTIONS } from 'constants/endpointConstants';

import useAppContext from 'utils/hooks/useAppContext';
import { getBooleanSearchQuery } from 'utils/casting';
import { getCreatorsName } from 'utils/works';
import { createDate } from 'utils/date';
import { useTranslation } from 'src/i18n';

import queries from 'containers/Work/queries';
import searchQueries from 'containers/Search/queries';
import { FILTER_TYPES } from 'operabase-router/constants';

import classes from './CastingRoles.module.scss';

const ROLES_LIMIT = 2;

const WorkRoleBlock = ({ workId, roleId, filters, multipleRoles = false, trackingData = {} }) => {
  const { data: castCrewData } = useQuery(
    queries.getCastCrewDetails({
      filters: {
        work_id: workId,
        ...filters,
      },
      queryConfig: { enabled: !!workId },
    }),
  );

  const { data: work } = useQuery(
    searchQueries.getSelectedOption({
      identifier: workId,
      endpoint: FILTER_AGGREGATION_SEARCH(PRODUCTIONS),
      queryParams: {
        work_id: workId,
        aggregation_type: AGGREGATION_TYPES.WORK,
      },
      queryConfig: {
        enabled: !!workId,
      },
    }),
  );

  const { composers, roleName } = useMemo(() => {
    const matchedRole = castCrewData?.reduce((acc, item) => {
      if (!acc) {
        return item?.data?.find(member => member?.id?.toString() === roleId?.toString());
      }
      return acc;
    }, null);

    return {
      composers: getCreatorsName(work),
      roleName: matchedRole?.name,
    };
  }, [work, roleId, castCrewData]);

  const composersTitle = composers?.length > 0 && (
    <Typography size={18} weight="bold">
      (
      <SeparatorList
        separator={SEPARATOR_TYPES.BULLET}
        data={composers?.map(composer => (
          <EntityName
            name={composer?.name}
            entityType={composer.entityType}
            entity={composer}
            trackingData={trackingData}
            isRaw
          />
        ))}
      />
      )
    </Typography>
  );

  if (multipleRoles) {
    return (
      <div className={classes.workRole__multiple}>
        <Typography variant="p" size={12} weight="bold">
          {roleName}
        </Typography>
        <Typography variant="p" size={12}>
          <EntityName entityType={ENTITY_TYPE.WORK} entity={work} trackingData={trackingData} isRaw /> {composersTitle}
        </Typography>
      </div>
    );
  }

  return (
    <Typography variant="p" className={classes.workRole__single} size={18} weight="bold" truncate>
      <EntityName entityType={ENTITY_TYPE.WORK} entity={work} trackingData={trackingData} isRaw />
      {composersTitle}
      <Typography size={18} weight="bold" className={classes.workRole__single_spacing30}>
        {roleName}
      </Typography>
    </Typography>
  );
};

const CastingRoles = ({ trackingData = {} }) => {
  const { t } = useTranslation('NS_CASTING_TOOL');
  const { obRouteContext } = useAppContext();
  const { linkProps, filterParams } = obRouteContext;
  const { filters } = linkProps;

  const { queryParts, extraCount } = useMemo(() => {
    const parts = getBooleanSearchQuery(filters.expression);
    const queryPartsCount = ROLES_LIMIT * 2 - 1;
    const extraParts = Math.ceil(parts?.length / 2) - ROLES_LIMIT;

    return {
      queryParts: take(parts, queryPartsCount),
      extraCount: extraParts > 0 ? extraParts : 0,
    };
  }, [filters.expression]);

  return (
    <div className={classes.root}>
      {queryParts?.map(({ type, value }, index) => {
        const key = `${type}-${index}`;
        if (type === BOOLEAN_SEARCH_PARTS.OPERATOR) {
          return (
            <Typography variant="p" size={12} weight="bold" key={key} className={classes.roles__operator}>
              {t(BOOLEAN_OPERATOR_LABELS[value])}
            </Typography>
          );
        }

        const role = value?.[CASTING_TOOL_SLUGS.WORK_ROLE];

        return (
          <WorkRoleBlock
            key={key}
            workId={value?.[CASTING_TOOL_SLUGS.WORK]}
            roleId={role?.id}
            roleType={role?.type}
            filters={{
              ...(filterParams?.[FILTER_TYPES.SINCE_YEAR] && {
                date_from: createDate(`${filterParams?.[FILTER_TYPES.SINCE_YEAR]}`).format(DATE_FORMATS.FULL_DATE),
              }),
              [FILTER_TYPES.COUNTRY]: filterParams?.[FILTER_TYPES.COUNTRY],
            }}
            multipleRoles={queryParts?.length > 1}
            trackingData={trackingData}
          />
        );
      })}
      {extraCount > 0 && (
        <LinkButton variant="text" size="small" className={classes.roles__extra} disableUnderline>
          {t(`${TP}.FN_X_MORE_ROLES`, { number: extraCount })}
        </LinkButton>
      )}
    </div>
  );
};

export default CastingRoles;
