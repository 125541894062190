import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { DATE_FORMATS } from 'constants/index';

dayjs.extend(advancedFormat);
dayjs.extend(isSameOrAfter);

export const createDate = (date, format) => {
  if (format && date) {
    dayjs.extend(customParseFormat);
    return dayjs(date, format);
  }
  if (date) {
    return dayjs(date);
  }
  return dayjs();
};

export const getMonthFromString = mon => {
  const d = Date.parse(`${mon} 1, 2012`);
  if (!Number.isNaN(d)) {
    return new Date(d).getMonth() + 1;
  }
  return -1;
};

export const getCurrentDateTime = () => {
  dayjs.extend(utc);
  return dayjs.utc().format();
};

export const guessTimezone = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs.tz.guess();
};

export const sortTimes = timeList => {
  if (timeList?.length === 1) {
    return timeList;
  }

  return timeList?.sort((a, b) => {
    const aTime = getNumericParts(a?.time);
    const bTime = getNumericParts(b?.time);

    return aTime?.[0] - bTime?.[0] || aTime?.[1] - bTime?.[1];
  });

  function getNumericParts(t) {
    return t?.split(':')?.map(x => +x);
  }
};

/**
 * Convert API api hh:mm:ss to hh:mm
 *
 * @param timeString
 * @returns {string}
 */
export const convertTimeStringToTimePicker = timeString => {
  if (typeof timeString === 'string') {
    return timeString.slice(0, 5);
  }
  return '';
};

export const isDateToday = date => {
  dayjs.extend(isToday);
  return date.isToday();
};

export const getTimestampForDisplay = (date, format) => {
  dayjs.extend(advancedFormat);

  return date.format(format);
};

export const createDatewithTz = (date, tz) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  if (tz) {
    try {
      return dayjs(date).tz(tz);
    } catch (err) {
      return dayjs(date);
    }
  }

  return dayjs(date);
};

export const getTimezone = (datetime, abbrTimezone = true) => {
  const detectedTimezone = createDate(datetime).format('zzz');

  if (abbrTimezone) {
    return detectedTimezone.match(/\b\w/g).join('');
  }

  return detectedTimezone;
};

export const isValidDate = d => !Number.isNaN(Date.parse(d));

export const getValidDateRange = (fromDate, toDate, format = 'YYYY-MM-DD') => {
  const fromDt = isValidDate(fromDate);
  const toDt = isValidDate(toDate);
  // no dates -> set to today
  if (!fromDt && !toDt) {
    return {
      dayFrom: createDate().format(format),
      dayTo: createDate().format(format),
    };
  }

  if (fromDt && !toDt) {
    const date = createDate(fromDate);
    return {
      dayFrom: date.format(format),
      dayTo: date.add(1, 'years').format(format),
    };
  }
  if (!fromDt && toDt) {
    const date = createDate(toDate);

    return {
      dayFrom: date.subtract(1, 'years').format(format),
      dayTo: date.format(format),
    };
  }

  return {
    dayFrom: createDate(fromDate).format(format),
    dayTo: createDate(toDate).format(format),
  };
};

export const getFormattedDateRange = ({
  from,
  to,
  separator = '-',
  asParts = false,
  showSeasonPeriod = false,
  dateFormat = DATE_FORMATS.DAY_SHORT_MONTH_YEAR,
}) => {
  const isValidFromDate = !!from && isValidDate(from);
  const isValidToDate = !!to && isValidDate(to);

  if (!isValidFromDate || !isValidToDate) {
    if (asParts) {
      return {};
    }

    return '';
  }

  const fromDateObj = createDate(from).startOf('day');
  const toDateObj = createDate(to).startOf('day');

  const isSameYear = fromDateObj.isSame(toDateObj, 'year');

  if (showSeasonPeriod) {
    const fromYear = fromDateObj.format('YYYY');
    const toYear = toDateObj.format('YYYY');

    if (isSameYear) {
      return fromYear;
    }
    return `${fromYear}/${toYear}`;
  }

  const isSameMonthYear = fromDateObj.isSame(toDateObj, 'month');
  const isSameDayMonthYear = fromDateObj.isSame(toDateObj, 'day');

  let rangeStart = null;
  let divider = ` ${separator} `;
  let rangeEnd = null;

  if (isSameDayMonthYear) {
    // Case: 1 Jan 2021 - 1 Jan 2021 - same date, month & year
    rangeStart = fromDateObj.format(dateFormat);
    rangeEnd = '';
    divider = '';
  } else if (isSameMonthYear) {
    // Case: 1 Jan - 31 Jan 2021 - same month & year but different dates
    rangeStart = fromDateObj.format(DATE_FORMATS.DATE);
    rangeEnd = toDateObj.format(dateFormat);
  } else if (isSameYear) {
    // Case: 1 Jan 2021 - 31 Dec 2021 - same year but different months
    rangeStart = fromDateObj.format(DATE_FORMATS.DAY_MONTH);
    rangeEnd = toDateObj.format(dateFormat);
  } else {
    // Case: 1 Jan 2021 - 1 Jan 2022 - same date & month but different years
    // Case: 1 Jan 2021 - 2 Jan 2022 - same date but different month & years
    // Case: 1 Jan 2021 - 2 Feb 2022 - different date, month & years
    rangeStart = fromDateObj.format(dateFormat);
    rangeEnd = toDateObj.format(dateFormat);
  }

  if (asParts) {
    return {
      rangeStart,
      separator: divider,
      rangeEnd,
      weekdayStart: fromDateObj.format('ddd'),
      weekdayEnd: toDateObj.format('ddd'),
      fromISO: fromDateObj.toISOString(),
      toISO: toDateObj.toISOString(),
    };
  }

  return `${rangeStart}${divider}${rangeEnd}`;
};

export const isDateSameOrAfter = (date, comparisonDate, unit = 'day') =>
  createDate(date).isSameOrAfter(createDate(comparisonDate), unit);
