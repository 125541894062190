import { URL_SLUG_PREFIXES, ENTITY_TYPE_ROUTE_MAP, VALID_QUERY_PARAMS } from 'constants/index';
import { ROUTE_RESERVED_KEYWORDS, ENTITY_TYPES } from 'operabase-router/constants';
import { getLanguage } from 'utils/queriesUtil';
import { getFilterURLParams } from './filters';

export const getURLWithLanguage = (url, language) => {
  if (!language) {
    return url;
  }

  if (url?.length > 0) {
    const [path, query] = url?.split('?');
    const lastPathPart = path?.split('/')?.slice(-1)?.[0];

    if (lastPathPart?.length === 2 || lastPathPart === 'keys') {
      if (lastPathPart === language) {
        return url;
      }

      const replaceLength = lastPathPart === 'keys' ? -4 : -2;

      return `${path.slice(0, replaceLength)}${language}${query ? `?${query}` : ''}`;
    }

    const pathWithLang = `${path}${path.slice(-1) === '/' ? language : `/${language}`}`;

    if (query?.length > 0) {
      return `${pathWithLang}?${query}`;
    }

    return pathWithLang;
  }

  return url;
};

const BASE_URL = process?.env?.FRONTBASE_URL || '';

const getLinkProps = props => {
  const {
    baseRoute,
    entityType: requestedEntityType,
    entity,
    country,
    path: pathValue,
    queryParams: initialQueryParams = {},
    filters = {},
    language: requestedLanguage,
    shallow = true,
    edit = false,
    pro = false,
    onlyLinkProps = false,
    rel,
    title,
    scrollTo,
  } = props || {};
  const language = requestedLanguage || getLanguage(requestedLanguage);
  let entityType = requestedEntityType;
  const shouldLinkToProPage =
    entity && entityType === ENTITY_TYPES.PROFILE && (pro || baseRoute === ROUTE_RESERVED_KEYWORDS.casting);

  const getBaseRoute = base => {
    let rootPage = '';

    if (base === ROUTE_RESERVED_KEYWORDS.pro) {
      rootPage = '[type]';
    } else if (
      entity &&
      entityType &&
      ![ENTITY_TYPES.ORGANIZATION, ENTITY_TYPES.PROFILE, ENTITY_TYPES.MANAGER].includes(entityType)
    ) {
      rootPage = '[id]';
    }

    if (!rootPage) {
      return base;
    }

    return `${base}/${rootPage}`;
  };

  let mainRoute = '';

  if (entityType && entity && ENTITY_TYPE_ROUTE_MAP[entityType]) {
    if (shouldLinkToProPage && entityType === ENTITY_TYPES.PROFILE) {
      mainRoute = ROUTE_RESERVED_KEYWORDS.casting;
    } else {
      mainRoute = ENTITY_TYPE_ROUTE_MAP[entityType];
    }
  } else if (entityType && !entity) {
    if (entityType === ENTITY_TYPES.ORGANIZATION) {
      mainRoute = ROUTE_RESERVED_KEYWORDS.organisations;
    } else if (entityType === ENTITY_TYPES.PROFILE) {
      mainRoute = ROUTE_RESERVED_KEYWORDS.artists;
    } else if (ENTITY_TYPE_ROUTE_MAP[entityType]) {
      mainRoute = ENTITY_TYPE_ROUTE_MAP[entityType];
    }
  } else if (baseRoute) {
    mainRoute = baseRoute;
  }

  if (!entity && !entityType) {
    if (mainRoute === ROUTE_RESERVED_KEYWORDS.artists) {
      entityType = ENTITY_TYPES.PROFILE;
    } else if (mainRoute === ROUTE_RESERVED_KEYWORDS.organisations) {
      entityType = ENTITY_TYPES.ORGANIZATION;
    }
  }

  const baseHref = getBaseRoute(mainRoute);
  let asPath = mainRoute;

  if (entityType && (entity || country?.slug)) {
    let entityIdPrefix = '';

    if (entity && [ENTITY_TYPES.ORGANIZATION, ENTITY_TYPES.PROFILE].includes(entityType)) {
      entityIdPrefix = URL_SLUG_PREFIXES.find(item => item.entityType === entityType)?.prefix || '';
    }

    if (
      country?.slug &&
      [
        ROUTE_RESERVED_KEYWORDS.companies,
        ROUTE_RESERVED_KEYWORDS.venues,
        ROUTE_RESERVED_KEYWORDS.managers,
        ROUTE_RESERVED_KEYWORDS.seasons,
      ].includes(mainRoute)
    ) {
      asPath += '/';
      asPath += country?.slug;
    }

    if (entity?.id) {
      if (!asPath.endsWith('/')) {
        asPath += '/';
      }
      if (entity?.slug) {
        asPath += `${entity?.slug}-`;
      }

      if (entityIdPrefix) {
        asPath += entityIdPrefix;
      }

      asPath += entity?.id;
    }
  }

  if (edit && entity && [ENTITY_TYPES.ORGANIZATION, ENTITY_TYPES.PROFILE].includes(entityType)) {
    asPath = `${asPath}/edit`;
  }

  const appendSubPath = ({ as: asValue, href: hrefValue, subPath: subPathValue = '', isSubPath = false }) => {
    let subPath = subPathValue || '';

    if (subPath.startsWith('/')) {
      subPath = subPath.slice(1);
    }

    const loweredPath = subPath.toLowerCase();
    const newAs = `${asValue}${loweredPath ? `${asValue === '/' ? '' : '/'}${loweredPath}` : ''}`;
    let newHref = hrefValue;

    const shouldSkipAppendSuffix =
      (/.+\[\[\.\.\.\w+\]\]/g.test(newHref) || /.+\[\w+\]/g.test(newHref)) &&
      ![ENTITY_TYPES.WORK, ENTITY_TYPES.PRODUCTION].includes(entityType);

    if (!shouldSkipAppendSuffix) {
      if (entityType && entity && !isSubPath) {
        newHref = `${newHref}${newHref.endsWith('/') ? '' : '/'}[[...action]]`;
      } else if (
        [
          ROUTE_RESERVED_KEYWORDS.artists,
          ROUTE_RESERVED_KEYWORDS.organisations,
          ROUTE_RESERVED_KEYWORDS.casting,
          ROUTE_RESERVED_KEYWORDS.seasons,
          ROUTE_RESERVED_KEYWORDS.companies,
          ROUTE_RESERVED_KEYWORDS.venues,
          ROUTE_RESERVED_KEYWORDS.managers,
          ROUTE_RESERVED_KEYWORDS.composers,
        ].includes(newHref) ||
        newHref === ''
      ) {
        newHref = `${newHref}${newHref.endsWith('/') ? '' : '/'}[[...action]]`;
      } else if (entity && [ENTITY_TYPES.WORK, ENTITY_TYPES.PRODUCTION].includes(entityType)) {
        if (!isSubPath) {
          newHref = `${newHref}${newHref.endsWith('/') ? '' : '/'}[[...action]]`;
        }
      } else if (loweredPath) {
        newHref = `${newHref}${newHref.endsWith('/') ? '' : '/'}${loweredPath}`;
      }
    }

    return {
      as: newAs,
      href: newHref,
    };
  };

  const { as, href } = appendSubPath({ as: asPath, href: baseHref, subPath: pathValue });

  const getSubPath = ({
    title: subPathTitle,
    path: subPath,
    queryParams: subPathQueryParams,
    filters: subPathFilters,
    onlyLinkProps: onlySubPathLinkProps,
    mergeFilters = false,
    mergeQueryParams = false,
    scrollTo: scrollToValue,
  }) => {
    const { path, queryParams: params } = getFilterURLParams({
      entityType,
      entityId: entity?.id,
      filters: {
        ...(mergeFilters && (filters || {})),
        ...(subPathFilters || {}),
      },
      queryParams: {
        ...(mergeQueryParams && (initialQueryParams || {})),
        ...(subPathQueryParams || {}),
        ...(scrollToValue && { [VALID_QUERY_PARAMS.SCROLL_TO]: scrollToValue }),
      },
      path: subPath || '',
    });

    let { as: asSubPath, href: hrefSubPath } = appendSubPath({ as, href, subPath: path, isSubPath: true });

    let url = asSubPath;

    if (language) {
      url = getURLWithLanguage(`${BASE_URL}${asSubPath}`, language);
    }

    const searchParams = new URLSearchParams(params || {});

    if (params) {
      searchParams.sort();
      const queryString = decodeURIComponent(searchParams.toString());

      if (queryString) {
        hrefSubPath = `${hrefSubPath}?${queryString}`;
        asSubPath = `${asSubPath}?${queryString}`;
        url = `${url}?${queryString}`;
      }
    }

    return {
      as: asSubPath,
      href: hrefSubPath,
      shallow,
      rel,
      title: subPathTitle || title,
      ...(!onlySubPathLinkProps && {
        searchParams,
        url,
        getSubPath,
      }),
    };
  };

  return getSubPath({ queryParams: initialQueryParams, filters, onlyLinkProps, scrollTo });
};

export default getLinkProps;
