import { captureException as SentryCaptureException, captureMessage as SentryCaptureMessage } from '@sentry/nextjs';
import { FILTER_TYPES, ROUTE_RESERVED_KEYWORDS, ENTITY_TYPES } from 'operabase-router/constants';

const TRANSACTION_PAGE_MAP = {
  [ENTITY_TYPES.PROFILE]: 'Artist',
  [ENTITY_TYPES.ORGANIZATION]: 'Organization',
  [ENTITY_TYPES.PRODUCTION]: 'Production',
  ERROR: '404 Error',
  HOME: 'Homepage',
  COMPOSERS: 'Composers',
};

// NOTE: Generate custom sentry transaction name
export const getSentryTransactionName = ({
  basePath,
  entityType,
  entityId,
  mainPath,
  subPath,
  edit,
  paths,
  filters,
  orgTypeSlug,
}) => {
  const transactionParts = [];
  const { [FILTER_TYPES.PAGE]: page, [FILTER_TYPES.LETTER]: letter, [FILTER_TYPES.QUERY]: query } = filters || {};

  if (entityType) {
    transactionParts.push(TRANSACTION_PAGE_MAP[entityType]);

    if (entityId) {
      transactionParts.push('Stub Page');
    } else {
      transactionParts.push('Index Page');

      if (query || letter || page) {
        transactionParts.push('(SEARCH)');
      } else if (ENTITY_TYPES.PROFILE === entityType) {
        if (paths?.length === 1) {
          transactionParts.push(`(${paths?.[0]})`);
        }
      } else if (ENTITY_TYPES.ORGANIZATION === entityType) {
        const hasOrgTypeFilter = !!orgTypeSlug;
        const hasAppliedFilters = hasOrgTypeFilter ? paths?.length > 1 : paths?.length > 0;

        if (hasOrgTypeFilter) {
          transactionParts.push(`(${orgTypeSlug})`);
        }

        if (hasAppliedFilters) {
          transactionParts.push('(With FILTERS)');
        }
      }
    }

    if (edit) {
      transactionParts.push('(EDIT)');
    }

    const tabs = [];

    if (mainPath) {
      tabs.push(mainPath);
    }

    if (subPath) {
      tabs.push(subPath);
    }

    if (mainPath || subPath) {
      transactionParts.push(`(${tabs.join('/')})`);
    }
  } else if (basePath === '' || !basePath) {
    if (paths?.length > 0) {
      transactionParts.push(TRANSACTION_PAGE_MAP.ERROR);
    } else {
      transactionParts.push(TRANSACTION_PAGE_MAP.HOME);
    }
  } else if (basePath) {
    let basePageName = '';

    switch (basePath) {
      case ROUTE_RESERVED_KEYWORDS.composers:
        basePageName = TRANSACTION_PAGE_MAP.COMPOSERS;
        break;
      default:
        basePageName = basePath.split('/').pop();
        break;
    }

    transactionParts.push(`${basePageName} Index Page`);

    if (query || letter || page) {
      transactionParts.push('(SEARCH)');
    }
  }

  return transactionParts.join(' ');
};

const transformSentryContext = context => ({
  ...(context || {}),
  extra: {
    ...(context?.extra || {}),
    buildNumber: process.env.BUILD_NUMBER,
    ssr: typeof window === 'undefined',
  },
});

export const sentryCaptureException = (error, context) => {
  const transformedContext = transformSentryContext(context);

  SentryCaptureException(error, transformedContext);
};

export const sentryCaptureMessage = (message, context = {}) => {
  const transformedContext = transformSentryContext(context);

  SentryCaptureMessage(message, transformedContext);
};

export const sentrySanitizeUrl = url => {
  let urlPath;
  try {
    urlPath = new URL(url).pathname;
  } catch (e) {
    return url;
  }

  const sanitizedPath = urlPath.split('/').reduce((acc, path) => {
    if (!path) {
      return acc;
    }

    if (/\d/.test(path)) {
      return `${acc}/:id`;
    }

    return `${acc}/${path}`;
  }, '');

  return sanitizedPath;
};
