import React from 'react';
import dynamic from 'next/dynamic';
import { ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';
import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';
import { usePaywallContext } from 'utils/hooks/usePaywallContext';

const PaywallModal = dynamic(() => import('components/Globals/FootPrintTracking/PaywallModal/PaywallModal'));

const PaywallModalWrapper = ({ children }) => {
  const { isLoggedIn, paywallType } = useAppContext();
  const { pathname } = usePageContext();
  const { showPaywall } = usePaywallContext();
  const shouldShowPaywall = !isLoggedIn || (showPaywall && isLoggedIn) || paywallType;
  const isExcludePaywall = [ROUTE_RESERVED_KEYWORDS.login, ROUTE_RESERVED_KEYWORDS.register, ROUTE_RESERVED_KEYWORDS.payment].some(route =>
    pathname.includes(route),
  );
  if (isExcludePaywall) {
    return children;
  }
  return (
    <>
      {children}
      {shouldShowPaywall && <PaywallModal />}
    </>
  );
};

export default PaywallModalWrapper;
