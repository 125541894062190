import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import classes from './SpriteIcon.module.scss';

const SpriteIcon = ({ icon, hover = false, active = false, size, className, onClick, ariaLabel }) => {
  const { hasHover, hasActive, icons } = useMemo(() => {
    const iconPrefix = icon?.toLowerCase();
    const hasHoverState = !!hover;
    const hasActiveState = !!active;
    let hoverIcon = null;
    let activeIcon = null;

    if (hasHoverState) {
      if (typeof hover === 'string') {
        hoverIcon = hover;
      } else {
        hoverIcon = `${iconPrefix}:hover`;
      }
    }

    if (hasActiveState) {
      if (typeof active === 'string') {
        activeIcon = active;
      } else {
        activeIcon = `${iconPrefix}:active`;
      }
    }

    const sprite = '/svg/sprite.svg';

    return {
      hasHover: hasHoverState,
      hasActive: hasActiveState,
      icons: {
        default: `${sprite}#${iconPrefix}`,
        hover: `${sprite}#${hoverIcon}`,
        active: `${sprite}#${activeIcon}`,
      },
    };
  }, [icon, hover, active]);

  return (
    <svg
      className={classnames(classes.icon, {
        [classes.hover]: hasHover,
        [classes.active]: hasActive,
        [classes.clickable]: !!onClick,
        [className]: !!className,
      })}
      {...(size && { style: { height: `${size}px`, width: `${size}px` } })}
      {...(onClick && { onClick })}
      {...(ariaLabel && { 'aria-label': ariaLabel })}
    >
      <use className={classes.baseIcon} xlinkHref={icons.default} />
      {hasHover && <use className={classes.hoverIcon} xlinkHref={icons.hover} />}
      {hasActive && <use className={classes.activeIcon} xlinkHref={icons.active} />}
    </svg>
  );
};

SpriteIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  hover: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  size: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default SpriteIcon;
