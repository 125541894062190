import { useMemo } from 'react';

import { useUserData, useUserPermissions, useUserProfiles } from 'utils/hooks/useAuthenticatedUser';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';
import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from './hooks/useAppContext';

function usePermissions(targetEntityId, targetEntityType) {
  const { entity, entityType } = usePageContext();

  let targetId = entity?.id || targetEntityId;
  const { isLoggedIn } = useAppContext();
  const userData = useUserData();
  const userPermissions = useUserPermissions();
  const userProfiles = useUserProfiles();
  const { profileData, entityType: defaultEntityType } = useGetTargetProfile(targetId);
  if (!targetId) {
    targetId = profileData?.id;
  }
  const entityTypeResolved = entityType || targetEntityType || defaultEntityType;
  const permissions = useMemo(() => {
    const allPermissions = new Set();
    const isPermReady = userPermissions.loadedOnce;
    if (isPermReady) {
      userPermissions.permissions.forEach(p => allPermissions.add(p.identifier));
    }
    // NOTE: meta:42 is used for ADMIN user
    const permSet = {
      has: permString =>
        allPermissions.has(`user:${userData.id}:${permString}:meta:42`) ||
        allPermissions.has(`user:${userData.id}:${permString}:${entityTypeResolved}:${targetId}`),
    };
    const newPermissions = {
      castingToolPermissions: {
        hasAccess: permSet.has('castingTool.standardSearch:allow'),
      },
      productionPermissions: {
        canDeleteAsAdmin: permSet.has('productions:delete'),
        canDelete: permSet.has('productions:delete'),
        canCancel: permSet.has('productions:update'),
        canPublish: permSet.has('productions:update'),
        canPublishAll: permSet.has('productions:update'),
        canScrape: permSet.has('productions.scrape:create'),
      },
      artistPermissions: {
        canEdit: permSet.has('editPage:allow'),
        repertoire: {
          canView: permSet.has('profile.repertoire:list'),
          canEdit: permSet.has('profile.repertoire:update'),
          canDelete: permSet.has('profile.repertoire:delete'),
          canAdd: permSet.has('profile.repertoire:create'),
        },
        addInfo: {
          profileInfo: permSet.has('profile.information:update'),
          biography: permSet.has('profile.biography:update'),
          updatePerformances: permSet.has('productions:update'),
          addPerformances: permSet.has('productions:create'),
          musicalWorks: permSet.has('works:create'),
          changeRequest: permSet.has('productions:update'),
          digitalPresence: permSet.has('profile.socials:update'),
          photos: permSet.has('photos:create'),
          videos: permSet.has('videos:create'),
          repertoire: permSet.has('profile.repertoire:update'),
          reviews: permSet.has('reviews:create'),
          contactInfo: permSet.has('profile.contactInformation:update'),
          agencyInfo: permSet.has('profile.agencyRepresentation:update'),
          socialMedia: permSet.has('profile.socials:update'),
        },
        showHide: {
          photos: permSet.has('photos.toggleVisibility:update'),
          videos: permSet.has('videos.toggleVisibility:update'),
          reviews: permSet.has('reviews.toggleVisibility:update'),
          performances: permSet.has('productions.toggleVisibility:update'),
          artistPerformedWith: permSet.has('profile.peopleWorkedWith.toggleVisibility:update'),
          orgWorkedWith: permSet.has('profile.organizationsWorkedWith.toggleVisibility:update'),
          venuesPerformedAt: permSet.has('profile.venuesPerformedAt:update'),
          agencyRepresentation: permSet.has('profile.agencyRepresentation.toggleVisibility:update'),
          contactInfoOnPublicSite: permSet.has('profile.contactInformation.toggleVisibility:update'),
          repertoire: permSet.has('profile.repertoire.toggleVisibility:update'),
          contactInfoOnCastingTool: permSet.has('profile.contactInformation.toggleVisibility:update'),
          agencyRepresentationOnCastingTool: permSet.has('profile.agencyRepresentation.toggleVisibility:update'),
          futureRepertoireOnCastingTool: permSet.has('profile.futureRepertoire.toggleVisibility:update'),
        },
        canShareAccess: permSet.has('profile.shareAccess:create'),
        redMaskRequest: permSet.has('profile.redMaskVerification:create'),
        visibilityBoost: permSet.has('profile.visibilityBoost:get'),
      },
      organizationPermissions: {
        canEdit: permSet.has('editPage:allow'),
        addInfo: {
          info: permSet.has('organization.description:create'),
          rentals: permSet.has('rentals:create'),
          updatePerformances: permSet.has('productions:create'),
          addPerformances: permSet.has('productions:create'),
          digitalPresence: permSet.has('organization.socials:create'),
          photos: permSet.has('photos:create'),
          videos: permSet.has('videos:create'),
          reviews: permSet.has('reviews:create'),
          managementStaff: permSet.has('organization.staff:update'),
          contactInfo: permSet.has('organization.contactInformation:create'),
          agencyInfo: permSet.has('organization.agencyRepresentation:create'),
        },
        teams: {
          canEdit: permSet.has('organization.staff.accesses:update'),
          canDelete: permSet.has('organization.staff.accesses:delete'),
        },
        showHide: {
          photos: permSet.has('photos.toggleVisibility:update'),
          videos: permSet.has('videos.toggleVisibility:update'),
          reviews: permSet.has('reviews:create'),
          performances: permSet.has('productions.toggleVisibility:update'),
        },
        canShareAccess: permSet.has('organization.staff:create'),
        castingTool: permSet.has('castingTool.standardSearch:allow'),
        redMaskRequest: permSet.has('organization.redMaskVerification:create'),
      },
      agencyPermissions: {
        canEdit: permSet.has('agency.management:allow'),
        canAddOffice: permSet.has('agency.offices:create'),
        canAddArtist: permSet.has('agency.rosterManagement:create'),
      },
      isAdmin: permSet.has('frontbase.superuser:allow'),
      isTranslator: permSet.has('frontbase.translator:allow'),
    };
    return newPermissions;
  }, [userPermissions.loadedOnce, userPermissions.permissions, userData.id, targetId]);
  // NOTE: Above useEffect should re-trigger only if there is changes in dependencies
  return { ...permissions, activeProfile: profileData, isLoggedIn, userData, userProfiles };
}

export default usePermissions;
