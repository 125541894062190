/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollBlocker from 'utils/hooks/useScrollBlocker';

import useOnKeypress, { keyCodes } from 'utils/hooks/useOnKeypress';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import LinkButton from '../LinkButton';
import Typography from '../Typography';
import ReactPortal from '../ReactPortal';
import Drawer from '../Drawer';

import classes from './Modal.module.scss';
import SpriteIcon from '../SpriteIcon';

const Modal = ({
  isOpen,
  title,
  subTitle,
  children,
  onClose,
  footer,
  onHandleFooterAction,
  className,
  styles = { modalHeader: '' },
  allowMobileDrawer = false,
  allowMobileDrawerFooter = false,
  isDrawer = false,
  header,
  disabledCloseHandler = false,
  disableEnforceFocus,
  icon,
  placement,
  wrapperId,
  blockBackgroundScroll = false,
  dataAttributes = {},
  closeTitle = '',
}) => {
  const modalRef = useRef();
  const isMobile = useMediaQuery('(max-width:600px)');
  const onCloseHandler = useCallback(
    e => {
      if (isOpen && !disabledCloseHandler) {
        e.stopPropagation();

        if (onClose) {
          onClose(e);
        }
      }
    },
    [isOpen, onClose, disabledCloseHandler],
  );

  // MUI Drawer itself has its own scroll blocker
  useScrollBlocker(isOpen && !isMobile && !isDrawer && !blockBackgroundScroll);
  useOnKeypress(keyCodes.ESCAPE, onCloseHandler);
  useOnClickOutside(modalRef, onCloseHandler);

  const modalNode = (
    <div
      className={classnames(classes.modal, {
        [classes.modalFade]: isOpen,
        [styles.modalRoot]: !!styles.modalRoot,
      })}
      {...dataAttributes}
    >
      <div
        className={classnames(classes.modalContainer, {
          [styles.modalContainer]: !!styles.modalContainer,
          [className]: !!className,
        })}
        ref={modalRef}
      >
        {title && (
          <div
            className={classnames(classes.modalContainer__header, {
              [styles.modalHeader]: !!styles.modalHeader,
            })}
          >
            {icon && <SpriteIcon icon={icon} size={26} className={classnames({ [styles.icon]: !!styles.icon })} />}
            <div className={classes.modalContainer__header_wrapper}>
              <div className={classes.title}>
                <Typography
                  variant="h5"
                  weight="medium"
                  size="20"
                  className={classnames({ [styles.title]: !!styles.title })}
                >
                  {title}
                </Typography>
              </div>
              {subTitle && (
                <Typography
                  size="14"
                  weight="medium"
                  variant="span"
                  className={classnames({ [styles.subTitle]: !!styles.subTitle })}
                >
                  {subTitle}
                </Typography>
              )}
            </div>
            {onClose && (
              <LinkButton
                variant="text"
                styles={{
                  root: classnames(classes.closeIcon, {
                    [styles.closeIcon]: !!styles.closeIcon,
                  }),
                }}
                leftIcon={<SpriteIcon icon="modal_close" size={16} />}
                onClick={onClose}
                disableHover
                disableUnderline
                stopPropagation
                preventDefault
              >
                {closeTitle && (
                  <Typography size="12" weight="medium">
                    {closeTitle}
                  </Typography>
                )}
              </LinkButton>
            )}
          </div>
        )}
        {header && <div className={classes.modalContainer__header}>{header}</div>}
        <div
          className={classnames(classes.modalContent, {
            [styles.modalContent]: !!styles.modalContent,
          })}
        >
          {children}
        </div>
        {/* TODO: Need to rework this logic */}
        {footer?.length > 0 && (
          <div
            className={classnames(classes.modalFooter, {
              [styles.modalFooter]: !!styles.modalFooter,
            })}
          >
            {footer?.map(item => (
              <LinkButton
                onClick={e => onHandleFooterAction(item, e)}
                rightIcon={item?.rightIcon}
                leftIcon={item?.leftIcon}
                variant={item?.variant}
                loading={item?.isLoading}
                shape={item?.shape}
                disabled={item?.disabled}
              >
                {item?.name}
              </LinkButton>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  if (isOpen) {
    if ((allowMobileDrawer && isMobile) || isDrawer) {
      return (
        <Drawer
          className={className}
          isOpen={isOpen}
          onClose={onClose}
          title={title}
          subTitle={subTitle}
          header={header}
          styles={styles}
          disableEnforceFocus={disableEnforceFocus}
          leftIcon={icon && <SpriteIcon icon={icon} size={20} />}
          placement={placement}
        >
          {children}
          {allowMobileDrawerFooter && footer?.length > 0 && (
            <div
              className={classnames(classes.modalFooter, {
                [styles.modalFooter]: !!styles.modalFooter,
              })}
            >
              {footer?.map(item => (
                <LinkButton
                  onClick={e => onHandleFooterAction(item, e)}
                  rightIcon={item?.rightIcon}
                  leftIcon={item?.leftIcon}
                  variant={item?.variant}
                  shape={item?.shape}
                >
                  {item?.name}
                </LinkButton>
              ))}
            </div>
          )}
        </Drawer>
      );
    }

    return <ReactPortal wrapperId={wrapperId}>{modalNode}</ReactPortal>;
  }

  return null;
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  header: PropTypes.node,
  icon: PropTypes.string,
  placement: PropTypes.string,
};

const ModalWrapper = props => {
  const { isOpen } = props;
  if (!isOpen) {
    return null;
  }
  return <Modal {...props} />;
};

ModalWrapper.propTypes = Modal.propTypes;
export default ModalWrapper;
