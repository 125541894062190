import get from 'lodash/get';
import reduce from 'lodash/reduce';

import {
  ARTIST_TYPE_ID,
  AUDIENCE_TYPE_ID,
  CASTING_TYPE_ID,
  ACADEMICS_TYPE_ID,
  JOURNALIST_TYPE_ID,
  AGENT_TYPE_ID,
  AOS_TYPE_ID,
} from 'constants/consts';
import route from 'constants/routes';
import { getShortProfessionDetails } from 'utils/artistUtils';
import { getRouteWithSlug } from 'utils/common';
import { TP, COMPANY_TYPE_ID, AGENCY_TYPE_ID } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';

export const getProfileName = profile => profile?.name;

export const getProfilePhotoURL = profile =>
  get(profile, 'image.small', '') ||
  get(profile, 'organizations[0].organization.image.small', '') ||
  get(profile, 'agencies[0].image.small', '');

export const getProfileSubTitle = profile => {
  const profileTypeId = get(profile, 'profileType.id');

  switch (profileTypeId) {
    case ARTIST_TYPE_ID: {
      const profession = getShortProfessionDetails(profile);
      return profession;
    }
    case AUDIENCE_TYPE_ID:
    case ACADEMICS_TYPE_ID:
    case JOURNALIST_TYPE_ID:
      return profile.profileType.name;

    case AGENT_TYPE_ID:
      return profile.profileType.name;
    case AOS_TYPE_ID:
      return get(profile, 'organizations[0].organization.country.name', '');
    default:
      return '';
  }
};

export const getProfilesByTypes = profiles => {
  const profileByTypes = reduce(
    profiles,
    (acc, profile) => {
      if (profile?.profileType?.id === AOS_TYPE_ID) {
        return { ...acc, organization: [...get(acc, 'organization', []), profile] };
      }

      if (!acc?.[profile?.profileType?.id]) {
        return { ...acc, [profile?.profileType?.id]: [profile] };
      }

      return { ...acc, [profile?.profileType?.id]: [...acc[profile?.profileType?.id], profile] };
    },
    {},
  );

  return profileByTypes;
};

// TODO - remove below method and will use getUpdateProfilePath
export const getProfilePath = (profile, getLinkProps, isEdit = false) => {
  const profileTypeId = get(profile, 'profileType.id');

  switch (profileTypeId) {
    case ARTIST_TYPE_ID:
      return getLinkProps({
        entity: profile,
        entityType: ENTITY_TYPES.PROFILE,
        pro: false,
        ...(isEdit && { edit: isEdit }),
      })?.as;
    case AGENT_TYPE_ID:
      return getRouteWithSlug(route.MANAGERS, get(profile, 'agencies[0]'));
    case AOS_TYPE_ID:
      return getLinkProps({
        entity: get(profile, 'organizations[0].organization'),
        entityType: ENTITY_TYPES.ORGANIZATION,
      })?.as;
    case CASTING_TYPE_ID:
      return route.CASTING_TOOL;
    case ACADEMICS_TYPE_ID:
    case JOURNALIST_TYPE_ID:
      return route.PRODUCTIONS;
    default:
      return getLinkProps()?.as;
  }
};

export const getUpdateProfilePath = profile => {
  switch (profile?.entityType) {
    case ENTITY_TYPES.PROFILE:
      return {
        entityType: ENTITY_TYPES.PROFILE,
        entity: profile,
        pro: false,
        edit: true,
      };
    case ENTITY_TYPES.AGENCY:
      return {
        entityType: ENTITY_TYPES.AGENCY,
        entity: profile?.agencies?.[0],
      };
    case ENTITY_TYPES.ORGANIZATION:
      return {
        entityType: ENTITY_TYPES.ORGANIZATION,
        entity: profile,
      };
    default:
      return {};
  }
};

export const showNotificationIcon = activeProfile =>
  activeProfile?.profileType?.id === ARTIST_TYPE_ID ||
  (activeProfile?.profileType?.id === AOS_TYPE_ID && activeProfile?.organizations?.[0]?.organization?.id);

export const getProfileTypeEditLabel = profileTypeId => {
  switch (profileTypeId) {
    case COMPANY_TYPE_ID:
      return `${TP}.UPDATE_SCHEDULE`;
    case ARTIST_TYPE_ID:
      return `${TP}.FN_MANAGE_PROFILE`;
    case AGENCY_TYPE_ID:
      return `${TP}.UPDATE_ROSTER`;
    default:
      return `${TP}.UPDATE_PROFILE`;
  }
};

export const getGiftSubscriptionEnabled = ({ profileSubscriptions, profile }) =>
  profileSubscriptions?.length > 0 && profile?.profileType?.id === ARTIST_TYPE_ID;

export const getProfileErrors = ({ profile, profileIdsPendingAccess }) => {
  const isPendingVerification = profile?.validationStatus?.slug === 'pending';
  const isPendingAccess = profileIdsPendingAccess.includes(profile?.id);
  // TODO REG FIX pending registration, change check to check for completed date
  // const isPendingRegistration = registration?.step < REGISTRATION_STEPS.finished;
  const errors = { isPendingVerification, isPendingAccess, isPendingRegistration: false };

  return {
    ...errors,
    isShowErrorIcon: Object.values(errors).some(val => val === true),
  };
};

// NOTE: pending profile are coming all regardless of user, this util is to get unique profiles for user

export const getUniqueProfiles = profileAccessRequests => {
  const seenIds = new Set();

  return profileAccessRequests
    ?.filter(request => {
      if (!request) return false;

      let uniqueId = null;

      if (request?.organization?.id) {
        uniqueId = request.organization.id;
      } else if (request?.agency?.id) {
        uniqueId = request?.agency.id;
      } else if (request?.profile?.id) {
        uniqueId = request.profile.id;
      }

      if (uniqueId && !seenIds.has(uniqueId)) {
        seenIds.add(uniqueId);
        return true;
      }
      return false;
    })
    .map(
      request =>
        // NOTE: Return the first valid object among organization, agency, or profile
        request,
    )
    .filter(item => item); // NOTE: Remove any null or invalid entries
};

export const getAllProfilesWithPending = (profileAccesses, allPendingProfiles) => {
  const addPendingFlag = (profiles, isPending) =>
    (profiles || []).map(profile => ({
      ...profile,
      isPending,
    }));

  const pendingProfilesWithFlag = addPendingFlag(allPendingProfiles, true);
  const profileAccessesWithFlag = addPendingFlag(profileAccesses, false);

  return [...profileAccessesWithFlag, ...pendingProfilesWithFlag];
};
