import pick from 'lodash/pick';

import { BOOLEAN_SEARCH_PARTS, CASTING_TOOL_SLUGS, CASTING_TOOL_EXPRESSION_PREFIX } from 'constants/index';

import { splitBooleanSearchQuery } from 'utils/globals/filters';
import invert from 'lodash/invert';

export const getCountriesFromFilters = options =>
  Array.from(
    options?.reduce((acc, { entity }) => {
      if (entity?.id) {
        acc.add(entity.id);
      }

      return acc;
    }, new Set()),
  );

export const getBooleanExpressionPrefix = ({ key, value }) => {
  const matchKey = key === CASTING_TOOL_SLUGS.WORK ? key : value?.type;
  const prefix = CASTING_TOOL_EXPRESSION_PREFIX[matchKey] || '';
  return prefix;
};

export const getExpressionParts = expression => {
  const parts = expression?.split(':');

  const filters = parts?.reduce((acc, part) => {
    const [prefix, ...remaining] = part || '';
    const value = remaining?.join('');
    const prefixSlugsMapping = invert(CASTING_TOOL_EXPRESSION_PREFIX);

    const key = prefixSlugsMapping[prefix];

    if ([CASTING_TOOL_SLUGS.WORK_ROLE, CASTING_TOOL_SLUGS.PROFESSION, CASTING_TOOL_SLUGS.ENSEMBLE].includes(key)) {
      acc[CASTING_TOOL_SLUGS.WORK_ROLE] = {
        type: key,
        id: Number.parseInt(value, 10),
      };
    } else if (CASTING_TOOL_SLUGS.WORK_ROLE_REFERENCE === key) {
      acc[CASTING_TOOL_SLUGS.WORK_ROLE] = {
        type: CASTING_TOOL_SLUGS.WORK_ROLE, // NOTE: Do not change else option will not be pre selected on filters
        id: value,
      };
    } else if (CASTING_TOOL_SLUGS.COUNTRY === key) {
      acc[key] = value?.split(',')?.map(id => Number.parseInt(id, 10));
    } else if (key) {
      acc[key] = Number.parseInt(value, 10);
    }

    return acc;
  }, {});

  return filters;
};

export const getBooleanSearchQuery = expression =>
  splitBooleanSearchQuery(expression)?.map(search => {
    if (search?.type === BOOLEAN_SEARCH_PARTS.QUERY) {
      return {
        type: BOOLEAN_SEARCH_PARTS.QUERY,
        value: getExpressionParts(search?.value),
      };
    }

    return search;
  });

export const getBooleanExpressionString = booleanQuery => {
  const expression = booleanQuery
    ?.map(({ type, value: queryValue }) => {
      if (type === BOOLEAN_SEARCH_PARTS.OPERATOR) {
        return queryValue;
      }

      const query = Object.keys(pick(queryValue, [CASTING_TOOL_SLUGS.WORK, CASTING_TOOL_SLUGS.WORK_ROLE]))
        .map(key => {
          const keyValue = queryValue?.[key];

          if (keyValue) {
            const id = key === CASTING_TOOL_SLUGS.WORK ? keyValue : keyValue?.id || keyValue?.slug;

            const prefix = getBooleanExpressionPrefix({ key, value: keyValue });

            if (prefix) {
              return `${prefix}${id}`;
            }
          }

          return null;
        })
        .filter(Boolean)
        .join(':');

      return query;
    })
    .join('');

  return expression;
};

export const findCreditGroupByRole = (data, role) => {
  if (!data || !role) return null;

  return data.reduce((acc, item) => {
    if (acc) return acc;

    if (item?.slugType === role?.type) {
      const matchingMember = item?.data?.find(member => member?.id === role?.id);
      return matchingMember?.id ? { ...item, role: matchingMember } : acc;
    }

    return acc;
  }, null);
};
