const {
  ENTITY_TYPES,
  ENTITY_TYPES_TRANSLATION_KEY_MAP,
  ROUTE_PART_TYPE,
  PAGE_DYNAMIC_ROUTES,
  ROUTE_VALIDITY,
} = require('../constants');
const { RESERVED_TRANSLATIONS } = require('../constants/seoTranslations');
const { ROUTE_CONFIGURATIONS } = require('../constants/configurations');

const LinkGenerator = require('./LinkGenerator');
const getEntitySlug = require('../utils/getEntitySlug');
const getEntityHeadlineParts = require('../utils/getEntityHeadlineParts');
const getDateRangeLabel = require('../utils/getDateRangeLabel');

class BreadcrumbsResolver {
  #linkGenerator = null;

  #translationFn = value => value;

  constructor({ language, translationFn }) {
    this.#linkGenerator = new LinkGenerator({ language });

    if (translationFn) {
      this.#translationFn = translationFn;
    }
  }

  resolve({ paths = [], mainPath, page }) {
    const mainPathReplace = new RegExp(`/${mainPath}$`);

    const breadcrumbs = paths.reduce((acc, item, index) => {
      if (index === 0) {
        const homeLinkProps = this.#linkGenerator.getLinkProps({ onlyLinkProps: true });

        acc.push({
          name: this.#translationFn(RESERVED_TRANSLATIONS.OPERABASE),
          path: homeLinkProps?.as,
          href: homeLinkProps?.href,
        });
      }

      const { entity, attributes } = item?.context || {};
      const { entityType: pageEntityType, entity: pageEntity, pro } = page || {};
      const pageEntityId = pageEntity?.id;

      if (index === 0) {
        if (pageEntityType) {
          const entityListLinkProps = this.#linkGenerator.getLinkProps({
            onlyLinkProps: true,
            entityType: pageEntityType,
            pro,
          });

          acc.push({
            name: this.#translationFn(ENTITY_TYPES_TRANSLATION_KEY_MAP[pageEntityType]?.plural) || pageEntityType,
            path: entityListLinkProps?.as,
            href: entityListLinkProps?.href,
          });

          if (pageEntityId && pageEntityId === entity?.id) {
            const entityLinkProps = this.#linkGenerator.getLinkProps({
              onlyLinkProps: true,
              entityType: pageEntityType,
              entity: pageEntity,
              pro,
            });

            const { headline, relevantAttributes } = getEntityHeadlineParts({
              entity,
              attributes,
              translationFn: this.#translationFn,
            });
            const finalHeadline = [...relevantAttributes, ...headline];

            acc.push({
              name: entity?.name,
              ...(finalHeadline?.length > 0 && { headline: finalHeadline }),
              path: entityLinkProps?.as,
              href: entityLinkProps?.href,
            });
          }
        } else {
          const basePathLinkProps = this.#linkGenerator.getLinkProps({
            onlyLinkProps: true,
            basePath: item.path,
          });

          let name = entity?.name || this.#translationFn(ROUTE_CONFIGURATIONS[item.path]?.translationKey) || item.path;

          if (item.context?.entityType === ENTITY_TYPES.DATE) {
            name = this.#translationFn(
              getDateRangeLabel({ dateFrom: item.context.date_from, dateTo: item.context.date_to }),
            );
          }

          acc.push({
            name,
            path: basePathLinkProps?.as,
            href: basePathLinkProps?.href,
          });
        }
      }

      if (item.validity === ROUTE_VALIDITY.INVALID) {
        return acc;
      }

      if (index > 0) {
        const { path: prevAsPath, href: prevHrefPath } = acc[acc.length - 1] || {};
        const isCaptureAllRoute = prevHrefPath.includes(PAGE_DYNAMIC_ROUTES.ACTIONS);

        let path = '';
        let name = '';

        if (item.context?.entityType === ENTITY_TYPES.DATE) {
          path = item.path;
          name = this.#translationFn(
            getDateRangeLabel({ dateFrom: item.context.date_from, dateTo: item.context.date_to }),
          );
        } else if (item.type === ROUTE_PART_TYPE.RESERVED) {
          path = item.path;
          const routeConfig = ROUTE_CONFIGURATIONS[item.path];
          name = this.#translationFn(routeConfig?.translationKey) || item.path;
        } else if (item.type === ROUTE_PART_TYPE.VARIABLE) {
          const slug = getEntitySlug(item.context);
          name = item.context.entity?.name;

          if (slug) {
            path = slug;
          }
        }

        let newHrefPath = prevHrefPath;

        if (!isCaptureAllRoute && path) {
          newHrefPath = `${prevHrefPath}/${path}`;
        }

        const prevAsPathWithoutMainPath = prevAsPath.replace(mainPathReplace, '');
        let newAsPath = prevAsPathWithoutMainPath;

        if (path) {
          newAsPath = `${prevAsPathWithoutMainPath}/${path}`;
        }

        if (mainPath && mainPath !== path && (!pageEntityId || pageEntityId !== entity?.id)) {
          newAsPath = `${newAsPath}/${mainPath}`;
        }

        const { headline, relevantAttributes } = getEntityHeadlineParts({
          entity,
          attributes,
          translationFn: this.#translationFn,
        });
        const finalHeadline = [...relevantAttributes, ...headline];

        acc.push({
          name,
          ...(finalHeadline?.length > 0 && { headline: finalHeadline }),
          path: newAsPath,
          href: newHrefPath,
        });
      }

      return acc;
    }, []);

    return breadcrumbs;
  }
}

module.exports = BreadcrumbsResolver;
