const { TP } = require('./index');

const RESERVED_TRANSLATIONS = {
  OPERABASE: `${TP}.m_OPERABASE`,
  PERFORMANCES: `${TP}.FN_PERFORMANCES`,
  DATE_IN_BRACKETS: `${TP}.FN_SEO_DATE_IN_BRACKETS`,
  TICKETS_ONLINE: `${TP}.FN_SEO_PAGE_TITLE_TICKETS_ONLINE`,
  TICKETS_AVAILABLE: `${TP}.FN_SEO_PAGE_TITLE_GET_TICKETS`,
  WATCH_ONLINE: `${TP}.FN_SEO_PAGE_TITLE_WATCH_ONLINE`,
  WATCH_ONLINE_TAG: `${TP}.FN_WATCH_ONLINE_VIDEOS`,
  GET_TICKETS_FOR_UPCOMING: `${TP}.FN_SEO_PAGE_GET_TICKETS_FOR_UPCOMING`,
  UPCOMING_PERFORMANCES: `${TP}.FN_SEO_PAGE_TITLE_UPCOMING_PERFORMANCE_LIST`,
  PAST_PERFORMANCES: `${TP}.FN_SEO_PAGE_TITLE_PAST_PERFORMANCE_LIST`,
  ENTITY_TAB: `${TP}.FN_SEO_PAGE_TITLE_ENTITY_TAB`,
  CONTACT_REPRESENTATION_DETAILS: `${TP}.FN_SEO_PAGE_CONTACT_REPRESENTATION_DETAILS`,
  ARTIST_DESC_DETAILS: `${TP}.FN_SEO_ARTIST_DESC_DETAILS`,
  ORG_TYPE_IN_LOCATION: `${TP}.FN_ORG_TYPE_IN_LOCATION`,
  MANAGER_DESC_DETAILS: `${TP}.FN_SEO_MANAGER_DESC_DETAILS`,
};

const SEO_TRANSLATIONS = {
  HOMEPAGE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_HOMEPAGE`,
    description: `${TP}.FN_SEO_PAGE_DESC_HOMEPAGE`,
  },
  PRODUCTIONS: {
    title: `${TP}.SEO_TITLE_PERFORMANCE_INDEX`,
    description: '',
  },
  ARTISTS: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ARTIST_INDEX`,
    description: `${TP}.FN_SEO_PAGE_DESC_ARTIST_INDEX`,
  },
  ARTISTS_VOICE_TYPE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ARTIST_INDEX_SINGERS`,
    description: `${TP}.FN_SEO_PAGE_DESC_ARTIST_INDEX_SINGERS`,
  },
  ARTISTS_INSTRUMENTALIST: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ARTIST_INDEX_INSTRUMENTALISTS`,
    description: `${TP}.FN_SEO_PAGE_DESC_ARTIST_INDEX_INSTRUMENTALISTS`,
  },
  ARTISTS_OTHER: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ARTIST_INDEX_PROFESSIONS`,
    description: `${TP}.FN_SEO_PAGE_DESC_ARTIST_INDEX_PROFESSIONS`,
  },
  ORGANIZATIONS: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ORGANIZATION_INDEX`,
    description: `${TP}.FN_SEO_PAGE_DESC_ORGANIZATION_INDEX`,
  },
  ORGANIZATIONS_ORG_TYPE: {
    title: '',
    description: `${TP}.FN_SEO_ARTS_ORGANISATIONS_ORG_TYPE_DESC`,
  },
  ORGANIZATIONS_COUNTRY: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ORGANIZATION_INDEX_COUNTRY`,
    description: `${TP}.FN_SEO_PAGE_DESC_ORGANIZATION_INDEX_COUNTRY`,
  },
  ORGANIZATIONS_CITY: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ORGANIZATION_INDEX_CITY`,
    description: `${TP}.FN_SEO_PAGE_DESC_ORGANIZATION_INDEX_CITY`,
  },
  ORGANIZATIONS_ORG_TYPE_LOCATION: {
    title: `${TP}.FN_ORG_TYPE_IN_LOCATION`,
    description: `${TP}.FN_SEO_ORG_TYPE_LOCATION_DESC`,
  },
  MANAGERS: {
    title: `${TP}.FN_SEO_PAGE_TITLE_MANAGER_INDEX`,
    description: `${TP}.SEO_DESC_MANAGERS_INDEX`,
  },
  MANAGERS_COUNTRY: {
    title: `${TP}.SEO_TITLE_MANAGERS_LIST`,
    description: `${TP}.SEO_DESC_MANAGERS_LIST`,
  },
  ORGANIZATION_PERFORMANCES_NO_FILTERS: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ENTITY_PERFORMANCES_1`,
    description: `${TP}.FN_SEO_PAGE_DESCRIPTION_ENTITY_PERFORMANCES_2`,
  },
  PROFILE_PERFORMANCES_NO_FILTERS: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ENTITY_PERFORMANCES_1`,
    description: `${TP}.FN_SEO_PAGE_DESCRIPTION_ENTITY_PERFORMANCES_1`,
  },
  ENTITY_PERFORMANCES_GENRE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ENTITY_PERFORMANCES_2`,
    description: '',
  },
  ENTITY_PERFORMANCES_WHAT: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ENTITY_PERFORMANCES_3`,
    description: '',
  },
  ENTITY_PERFORMANCES_WHERE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ENTITY_PERFORMANCES_4`,
    description: '',
  },
  ENTITY_PERFORMANCES_GENRE_WHERE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ENTITY_PERFORMANCES_5`,
    description: '',
  },
  ENTITY_PERFORMANCES_WHAT_WHERE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ENTITY_PERFORMANCES_6`,
    description: '',
  },
  ENTITY_PERFORMANCES_GENRE_WHAT_WHERE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ENTITY_PERFORMANCES_7`,
    description: '',
  },
  ENTITY_PERFORMANCES_GENRE_WHAT: {
    title: `${TP}.FN_SEO_PAGE_TITLE_ENTITY_PERFORMANCES_8`,
    description: '',
  },
  GENRE_PERFORMANCES_NO_FILTERS: {
    title: `${TP}.FN_SEO_PAGE_TITLE_GENRE_PERFORMANCES_1`,
    description: '',
  },
  GENRE_PERFORMANCES_WHO: {
    title: `${TP}.FN_SEO_PAGE_TITLE_GENRE_PERFORMANCES_2`,
    description: '',
  },
  GENRE_PERFORMANCES_WHERE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_GENRE_PERFORMANCES_3`,
    description: '',
  },
  GENRE_PERFORMANCES_WHO_WHERE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_GENRE_PERFORMANCES_4`,
    description: '',
  },
  GENRE_PERFORMANCES_WHO_WHAT: {
    title: `${TP}.FN_SEO_PAGE_TITLE_GENRE_PERFORMANCES_5`,
    description: '',
  },
  GENRE_PERFORMANCES_WHO_WHAT_WHERE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_GENRE_PERFORMANCES_6`,
    description: '',
  },
  GENRE_PERFORMANCES_WHAT_WHERE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_GENRE_PERFORMANCES_7`,
    description: '',
  },
  GENRE_PERFORMANCES_WHAT: {
    title: `${TP}.FN_SEO_PAGE_TITLE_GENRE_PERFORMANCES_8`,
    description: '',
  },
  WHAT_PERFORMANCES_NO_FILTERS: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHAT_PERFORMANCES_1`,
    description: '',
  },
  WHAT_PERFORMANCES_WHO: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHAT_PERFORMANCES_2`,
    description: '',
  },
  WHAT_PERFORMANCES_WHERE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHAT_PERFORMANCES_3`,
    description: '',
  },
  WHAT_PERFORMANCES_WHO_WHERE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHAT_PERFORMANCES_4`,
    description: '',
  },
  WHAT_PERFORMANCES_WHO_GENRE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHAT_PERFORMANCES_5`,
    description: '',
  },
  WHAT_PERFORMANCES_WHO_GENRE_WHERE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHAT_PERFORMANCES_6`,
    description: '',
  },
  WHAT_PERFORMANCES_GENRE_WHERE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHAT_PERFORMANCES_7`,
    description: '',
  },
  WHAT_PERFORMANCES_GENRE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHAT_PERFORMANCES_8`,
    description: '',
  },
  WHERE_PERFORMANCES_NO_FILTERS: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHERE_PERFORMANCES_1`,
    description: '',
  },
  WHERE_PERFORMANCES_WHO: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHERE_PERFORMANCES_2`,
    description: '',
  },
  WHERE_PERFORMANCES_WHAT: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHERE_PERFORMANCES_3`,
    description: '',
  },
  WHERE_PERFORMANCES_WHO_WHAT: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHERE_PERFORMANCES_4`,
    description: '',
  },
  WHERE_PERFORMANCES_WHO_GENRE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHERE_PERFORMANCES_5`,
    description: '',
  },
  WHERE_PERFORMANCES_WHO_GENRE_WHAT: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHERE_PERFORMANCES_6`,
    description: '',
  },
  WHERE_PERFORMANCES_GENRE_WHAT: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHERE_PERFORMANCES_7`,
    description: '',
  },
  WHERE_PERFORMANCES_GENRE: {
    title: `${TP}.FN_SEO_PAGE_TITLE_WHERE_PERFORMANCES_8`,
    description: '',
  },
};

module.exports = {
  SEO_TRANSLATIONS,
  RESERVED_TRANSLATIONS,
};
